export const conditional_perfect: any = [
  {
    type: 'card',
    title: 'Conditional perfect',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Se me habría olvidado.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>would have</b> forgotten",
            audio:"unit5-220.mp3"
          },
          {
            text: "Yo no habría esperado.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>wouldn't have</b> waited",
            audio:"unit5-221.mp3"
          },
          {
            text: "¿Qué habrías hecho tú?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What <b>would</b> you <b>have</b> done?",
            audio:"unit5-222.mp3"
          },
          {
            text: "¿No habrías venido?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Wouldn't</b> you <b>have</b> come?",
            audio:"unit5-223.mp3"
          },
        ]
      },
    ]
  },

  {
    type: 'card',
    title: 'The If clause',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Si yo hubiera sabido.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If I <b>had known</b>",
            audio:"unit5-224.mp3"
          },
          {
            text: "Si yo hubiera podido.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If I <b>had been able to</b>",
            audio:"unit5-225.mp3"
          }
        ]
      },
    ]
  },

  {
    type:'english',
    id: '57a',
    items: [
      {
        rows: [
          {
            text: "I would have done it",
            audio: "unit5-95.mp3"
          },
          {
            text: "I wouldn't have done it",
            audio: "unit5-96.mp3"
          },
          {
            text: "Would you have done it?",
            audio: "unit5-97.mp3"
          },
          {
            text: "Wouldn't you have done it?",
            audio: "unit5-98.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He would have gone",
            audio: "unit5-99.mp3"
          },
          {
            text: "He wouldn't have gone",
            audio: "unit5-100.mp3"
          },
          {
            text: "Would he have gone?",
            audio: "unit5-101.mp3"
          },
          {
            text: "Wouldn't he have gone?",
            audio: "unit5-102.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "If I had known",
            audio: "unit5-230.mp3"
          },
          {
            text: "If I had studied",
            audio: "unit5-231.mp3"
          },
          {
            text: "If I had been able to",
            audio: "unit5-232.mp3"
          },
          {
            text: "If I had had the money",
            audio: "unit5-233.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I would have stayed if I had known",
            audio: "unit5-226.mp3"
          },
          {
            text: "You would have passed the test if you had studied",
            audio: "unit5-227.mp3"
          },
          {
            text: "I would have come if I had been able to",
            audio: "unit5-228.mp3"
          },
          {
            text: "We would have bought it if we had had the money",
            audio: "unit5-229.mp3"
          }
        ]
      }
    ]
  },

  {
    type:'translate',
    id: '57b',
    items: [
      {
        rows: [
          {text:'Yo lo habría hecho.'},
          {text:'Yo no lo habría hecho.'},
          {text:'¿Tú lo habrías hecho?'},
          {text:'¿No lo habrías hecho?'},
        ]
      },
      {
        rows: [
          {text:'Él habría ido.'},
          {text:'Él no habría ido.'},
          {text:'¿Él habría ido?'},
          {text:'¿Él no habría ido?'},
        ]
      },
      {
        rows: [
          {text:'Si yo hubiera sabido.'},
          {text:'Si yo hubiera estudiado.'},
          {text:'Si yo hubiese podido'},
          {text:'Si yo hubiera tenido el dinero.'},
        ]
      },
      {
        rows: [
          {text:'Yo me habría quedado si hubiera sabido.'},
          {text:'Tú habrías aprobado el examen si hubieras estudiado.'},
          {text:'Yo habría venido si hubiese podido'},
          {text:'Lo habríamos comprado si hubiésemos tenido la plata.'},
        ]
      },
    ]
  },
]
