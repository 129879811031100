export const interrogative_negative: any = [
  {
    type: 'card',
    title: 'Interrogativo negativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se antepone "
          },
          {
            type: "link",
            text: "<b>don't</b>",
            audio: "unit1-351.mp3"
          },
          {
            text: " al pronombre."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿No conoces a <i>nadie</i>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Don't </b> you  know  <i>anybody</i>?",
            audio: "unit1-162.mp3",
            type: 'link'
          },
          {
            text: "¿No tenemos <i>clase</i>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Don't</b> we have <i>class</i>?",
            audio: "unit1-163.mp3",
            type: 'link'
          },
          {
            text: "¿No manejo <i>bien</i>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Don't</b> I drive <i>well</i>?",
            audio: "unit1-164.mp3",
            type: 'link'
          },
          {
            text: "¿Ellos no <i>lo</i> usan?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Don't</b> they  use <i>it</i>?",
            audio: "unit1-165.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '4a',
    items: [
      {
        rows: [
          {
            text: "Don't you speak Spanish?",
            audio: "unit1-166.mp3"
          },
          {
            text: "Don't we speak Spanish?",
            audio: "unit1-167.mp3"
          },
          {
            text: "Don't they speak Spanish?",
            audio: "unit1-168.mp3"
          },
          {
            text: "Don't I speak Spanish?",
            audio: "unit1-169.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Don't you finish at 5 thirty?",
            audio: "unit1-170.mp3"
          },
          {
            text: "Don't we finish at 5 thirty?",
            audio: "unit1-171.mp3"
          },
          {
            text: "Don't they finish at 5 thirty?",
            audio: "unit1-172.mp3"
          },
          {
            text: "Don't I finish at 5 thirty?",
            audio: "unit1-173.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '4b',
    items: [
      {
        rows: [
          {
            text:'¿No hablas español?'
          },
          {
            text: "¿Acaso no hablamos español?"
          },
          {
            text: "¿Ellos no hablan español?"
          },
          {
            text: "¿Acaso no hablo español?"
          }
        ]
      },
      {
        rows: [
          {
            text: "¿No necesitas nada?",
          },
          {
            text: "¿No necesitamos nada?",
          },
          {
            text: "¿Ellos no necesitan nada?",
          },
          {
            text: "¿No necesito nada?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿No conoces a nadie allá?",
          },
          {
            text: "¿No conocemos a nadie allá?",
          },
          {
            text: "¿Ellos no conocen a nadie allá?",
          },
          {
            text: "¿No conozco a nadie allá?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿No juego bien?",
          },
          {
            text: "¿No juegas bien?",
          },
          {
            text: "¿No jugamos bien?",
          },
          {
            text: "¿Ellos no juegan bien?",
          }
        ]
      },
    ]
  }
]
