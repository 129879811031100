export const to_vocab: any = [
    {
    type: 'card',
    title: 'Vocabulario adicional',
    items: [
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>be</b>",
                  audio: "unit2-83.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ser / estar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>happy</i>",
                  audio: "unit2-84.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>feliz</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>alone</i>",
                  audio: "unit2-85.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>solo(a)</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>sad</i>",
                  audio: "unit2-86.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>triste</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>come back</b>",
                  audio: "unit2-87.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>regresar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  text: "(",
                },
                {
                  type: "link",
                  text: "<i>from</i>",
                  audio: "unit2-88.mp3"
                },
                {
                  text: ")",
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "(<i>de</i>)"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>go home</b>",
                  audio: "unit2-89.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ir a casa</b>"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>go to the</b>",
                  audio: "unit2-90.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ir al</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>movies</i>",
                  audio: "unit2-91.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>cine</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>bank</i>",
                  audio: "unit2-92.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>banco</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>beach</i>",
                  audio: "unit2-93.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>playa</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>doctor</i>",
                  audio: "unit2-94.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>médico</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>dentist</i>",
                  audio: "unit2-95.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>odontólogo</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>go to</b>",
                  audio: "unit2-96.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ir al</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>work</i>",
                  audio: "unit2-97.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>trabajo</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>school</i>",
                  audio: "unit2-98.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>colegio</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>Madrid</i>",
                  audio: "unit2-99.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>Madrid</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>try</b><br/>",
                  audio: "unit2-100.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>intentarlo</b>. No se usa <i>it</i>."
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>spend</b>",
                  audio: "unit2-103.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>gastar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>less</i>",
                  audio: "unit2-102.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>menos</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>spend</b>",
                  audio: "unit2-103.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>pasar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the weekend</i>",
                  audio: "unit2-104.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el fin de semana</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>continue</b><br/>",
                  audio: "unit2-105.mp3"
                },
                {
                  type: "link",
                  text: "To <b>go on</b>",
                  audio: "unit2-106.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>seguir</b><br/>"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>put it</b>",
                  audio: "unit2-107.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ponerlo</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>on the table</i>",
                  audio: "unit2-108.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>sobre la mesa</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>read</b>",
                  audio: "unit2-109.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>leer</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the story</i>",
                  audio: "unit2-110.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>la historia</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the mail</i>",
                  audio: "unit2-111.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el correo</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>write</b>",
                  audio: "unit2-112.mp3"
                },
                {
                  text: "(to)"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>escribir</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>Ana</i>",
                  audio: "unit2-113.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>a Ana</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>repeat</b>",
                  audio: "unit2-114.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>repetir</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the test</i>",
                  audio: "unit2-115.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el examen</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>win</b>",
                  audio: "unit2-116.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ganar</b>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>lose</b>",
                  audio: "unit2-117.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>perder</b>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>later</i>",
                  audio: "unit2-118.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>más tarde</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>in 10 minutes</i>",
                  audio: "unit2-119.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>en 10 minutos</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>next week</i>",
                  audio: "unit2-120.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>la semana que viene</i> &#42;"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>next month</i>",
                  audio: "unit2-121.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el mes que viene</i> &#42;"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>next year</i>",
                  audio: "unit2-122.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el año que viene</i> &#42;"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "&#42; Nótese la omisión del artículo en inglés."
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '34',
    items: [
      {
        rows: [
          {text: "Queremos estar juntos."},
          {text: "Tenemos que estar juntos."},
          {text: "Quiero que estemos juntos."},
          {text: "No quiero estar triste."},
          {text: "No tienes por qué estar triste."},
          {text: "No quiero que estés triste."},
          {text: "¿A qué hora quieres estar allá?"},
          {text: "¿A qué hora tienes que estar allá?"},
          {text: "¿A qué hora quieres que yo esté allá?"},
          {text: "¿Yo quiero estar con él?"},
          {text: "¿Tengo que estar con él?"},
          {text: "¿Quieres que yo esté con él?"},
          {text: "¿Ellos no quieren estar aquí?"},
          {text: "¿Ellos no tienen que estar aquí?"},
          {text: "¿Ellos no quieren que tú estés aquí?"},
        ]
      },
      {
        rows: [
          {text: "Tienes que irte."},
          {text: "Tienes que presentar el examen."},
          {text: "Tienes que gastar menos."},
          {text: "Tienes que ir a trabajar."},
          {text: "Tienes que leer el correo."},
          {text: "Tienes que aprobar el examen."},
          {text: "Tienes que ir al médico."},
          {text: "Tienes que ganar."},
          {text: "Tienes que repetir el examen."},
          {text: "Tienes que intentarlo."},
          {text: "Tienes que escribir la historia."},
          {text: "Tienes que llegar a casa temprano."},
          {text: "Tienes que ir con él."},
          {text: "Tienes que estar allá a las 2."},
          {text: "Tienes que ponerlo ahí."},
          {text: "Tienes que seguir."},
          {text: "Tienes que regresar el viernes."},
          {text: "Tienes que pasar el fin de semana allá."},
          {text: "Tienes que oír esto."},
          {text: "Tienes que mandarle un SMS (a él)."},
        ]
      },
      {
        rows: [
          {text: "Quiero que seas feliz."},
          {text: "Quiero que vayas al banco."},
          {text: "Quiero que llegues (aquí) temprano."},
          {text: "Quiero que me des más tiempo."},
          {text: "Quiero que repitas el examen."},
          {text: "Quiero que regreses el domingo."},
          {text: "Quiero que vayas al odontólogo."},
          {text: "Quiero que lo pienses."},
          {text: "Quiero que ganes."},
          {text: "Quiero que leas el correo."},
          {text: "Quiero que apruebes el examen."},
          {text: "Quiero que me prestes tu llave."},
          {text: "Quiero que lo intentes."},
          {text: "Quiero que llegues a casa antes de las 10."},
          {text: "Quiero que lo pongas ahí."},
          {text: "Quiero que oigas esto."},
          {text: "Quiero que le escribas a Alan."},
          {text: "Quiero que sigas."},
        ]
      },
      {
        rows: [
          {text: "No quiero que gastes tanto."},
          {text: "No quiero que se te pierda."},
          {text: "No quiero que vendas tus dólares."},
          {text: "No quiero que traigas nada."},
          {text: "No quiero que lo pongas ahí."},
          {text: "No quiero que leas mi correo."},
        ]
      },
      {
        rows: [
          {text: "¿Por qué no regresas más tarde?"},
          {text: "¿Por qué no regresas el año que viene?"},
          {text: "¿Por qué no regresas antes de las 10?"},
          {text: "¿Por qué no regresas el lunes?"},
          {text: "¿Por qué no regresas la semana que viene?"},
          {text: "¿Por qué no regresas dentro de 10 minutos?"},
          {text: "¿Por qué no regresas el miércoles?"},
          {text: "¿Por qué no regresas el mes que viene?"},
          {text: "¿Por qué no regresas el martes?"},
          {text: "¿Por qué no regresas dentro de 6 meses?"},
          {text: "¿Por qué no regresas dentro de 20 minutos?"},
          {text: "¿Por qué no regresas el viernes?"},
          {text: "¿Por qué no regresas a las 7 y media?"},

        ]
      },
    ]
  },
]
