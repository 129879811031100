import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { Platform } from '@ionic/angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import firebase from 'firebase'
import { from, of } from 'rxjs'
import { flatMap, map, tap } from 'rxjs/operators'
import { LoggerService } from 'src/app/services/logger.service'
import { User, UserPlan } from './model'
import { actions } from './reducers'


@Injectable()
export class UserEffects {

    private loginReturnPath = null

    init$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.init),
                tap(() => {this.loginReturnPath = this.router.url}),
                flatMap(_ => this.auth.user.pipe(
                    flatMap(user => {
                        if (user == null) {
                            return of(actions.loaded())
                        } else {
                            return this.upsertProfile({
                                uid: user.uid,
                                email: user.email,
                                displayName: user.displayName,
                                photoURL: user.photoURL,
                                plan: UserPlan.free
                            }).pipe(map(profile => actions.logIn({ user: profile })))
                        }
                    })
                ))
            )
    )

    signIn$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.signIn),
                tap(props => {this.loginReturnPath = props.referrer}),
                tap(() => this.router.navigate(['/login']))
            ),
        { dispatch: false }
    )

    googleSignIn$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.googleSignIn),
                tap(_ => this.googleSignIn())),
        { dispatch: false }
    )

    localSignIn$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.localSignIn),
                flatMap(
                    auth => {
                        const localAuth = from(firebase.auth().signInWithEmailAndPassword(auth.email, auth.password))
                        return localAuth.pipe(
                            flatMap(user => {
                                if (user == null) {
                                    return of(actions.loaded())
                                } else {
                                    return this.getProfile(user.user.uid).pipe(
                                        flatMap(
                                            profile => {
                                                if (profile == null) {
                                                    return of(actions.error({code: 'INVALID_PROFILE', message: 'Missing profile for localSignIn'}))
                                                } else {
                                                    return this.upsertProfile({
                                                        uid: user.user.uid,
                                                        email: user.user.email,
                                                        displayName: user.user.displayName,
                                                        photoURL: user.user.photoURL,
                                                        plan: UserPlan.free
                                                    }).pipe(map(profile => actions.logIn({ user: profile })))
                                                }
                                            }
                                        )
                                    )}
                            }))
                    })
            )
    )

    localSignUp$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.localSignUp),
                flatMap(
                    (auth) => {
                        const localAuth = from(firebase.auth().createUserWithEmailAndPassword(auth.email, auth.password))
                        return localAuth.pipe(
                            flatMap(user => {
                                if (user == null) {
                                    return of(actions.loaded())
                                } else {
                                    return this.getProfile(user.user.uid).pipe(
                                        flatMap(
                                            profile => {
                                                if (profile == null) {
                                                    return of(actions.error({code: 'INVALID_PROFILE', message: 'Missing profile for localSignIn'}))
                                                } else {
                                                    return this.upsertProfile({
                                                        uid: user.user.uid,
                                                        email: user.user.email,
                                                        displayName: user.user.displayName,
                                                        photoURL: user.user.photoURL,
                                                        plan: UserPlan.free
                                                    }).pipe(map(profile => actions.logIn({ user: profile })))
                                                }
                                            }
                                        )
                                    )}
                            }))
                    })
            )
    )

    login$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.logIn),
                tap(() => {
                    if (this.loginReturnPath != null) {
                        this.router.navigate([this.loginReturnPath])
                    }
                }),
                tap( props => {
                    const lastLogins = this.firestore.collection('lastLogins')
                    lastLogins.doc(props.user.uid).set({
                        timestamp: firebase.database.ServerValue.TIMESTAMP,
                    })
                } )
            ),
        { dispatch: false }
    )

    signup$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.signUp),
                tap(() => this.router.navigate(['/welcome'])),
                tap(props => {
                    const signups = this.firestore.collection('signups')
                    signups.doc(props.user.uid).set({
                        displayName: props.user.displayName,
                        timestamp: firebase.database.ServerValue.TIMESTAMP,
                        provider: props.provider
                    })
                })
            ),
        { dispatch: false }
    )

    logout$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.logOut),
                tap(() => {
                })
            ),
        { dispatch: false }
    )

    error$ = createEffect(
        () => this.actions$.
            pipe(
                ofType(actions.error),
                tap(error => this.logger.log(`Authentication error: ${error.message}`)),
                tap(_ => this.logger.log('Logging out')),
                map(_ => actions.logOut()),
            )
    )

    private googleSignIn() {
      if (this.platform.is('hybrid')) {
        this.google.login({
          webClientId: '472760506130-tme1faju0ek70bdeu6ajqf0lofq18ui0.apps.googleusercontent.com',
          offline: true
        }).then( res => {
          const googleCredential = firebase.auth.GoogleAuthProvider.credential(res.idToken)
          this.logger.debug(JSON.stringify(googleCredential))
          return firebase.auth().signInWithCredential(googleCredential)
        }).catch(err => {
            this.logger.log(JSON.stringify(err))
        })

      } else {
        const provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/userinfo.email')
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
        this.auth.signInWithRedirect(provider)
        return from(this.auth.getRedirectResult())
      }
    }

    private getProfile(uid: string) {
        const users = this.firestore.collection('users')
        return users.doc(uid).get().pipe(
            map(
                doc => {
                    if (doc.exists) {
                        return {
                            uid: doc.data()['uid'],
                            email: doc.data()['email'],
                            displayName: doc.data()['displayName'],
                            photoURL: doc.data()['photoURL'],
                            plan: doc.data()['plan'],
                        }
                    } else {
                        return null
                    }
                }
            )
        )
    }

    private upsertProfile(user: User) {
        const users = this.firestore.collection('users')
        return users.doc(user.uid).get().pipe(
            map(
                doc => {
                    if (doc.exists) {
                        let plan = UserPlan.free
                        if ('plan' in doc) {
                            let plan = doc['plan']
                        }
                        const profile = {...user, ...{ plan: plan }}
                        doc.ref.update(profile)
                        return profile
                    } else {
                        const profile = {...user, ...{ plan: UserPlan.free }}
                        doc.ref.set(profile)
                        return profile
                    }
                })
        )
    }

    async getSubscriptionStatus(user: User){
      const subscriptions = this.firestore.collection("subscriptions")
      .doc("users")
      .collection(user['uid'], ref => ref.orderBy('end','desc').limit(1));

      const status =  await subscriptions.ref.get().then(sub => {
          if (sub.empty) {
            return 'UNKNOWN';
          } else {
            const now = Date.now();
            if (now > sub.docs[0].data()["end"]){
              if (sub.docs[0].data()["orderId"] == "0"){
                return 'TRIAL';
              } else {
                return 'ACTIVE';
              }
            } else {
              return 'INACTIVE';
            }
          }
      });
      return status;
    }

    constructor(
        private actions$: Actions,
        private auth: AngularFireAuth,
        private firestore: AngularFirestore,
        private router: Router,
        private platform: Platform,
        private google: GooglePlus,
        private logger: LoggerService
    ) { }

}
