export const structures_7: any = [
  {
    type: 'structures',
    id: '7',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"I want <b>you to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Quiero que tú &#8230;",
          },
          {
            text:"I don't want <b>him/her</b> to &#8230;",
          },
          {
            level:'secondary',
            text: "No quiero que él/ella &#8230;",
          },
          {
            text: "Do you want <b>me to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Quieres que yo &#8230;?",
          },
          {
            text: "Don't you want <b>us to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No quieres que nosotros &#8230;?",
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '29',
    items: [
      {
        rows: [
          {text: "Quiero que vengas."},
          {text: "No quiero que vengas."},
          {text: "¿Cuándo quieres que yo venga?"},
          {text: "¿Por qué no quieres que yo venga?"},
        ]
      },
      {
        rows: [
          {text: "Quiero que lo hagas."},
          {text: "No quiero que ella lo haga."},
          {text: "¿Cómo quieres que yo lo haga?"},
          {text: "¿Por qué no quieres que nosotros lo hagamos?"},
        ]
      },
      {
        rows: [
          {text: "Quiero que te quedes aquí."},
          {text: "No quiero que te quedes aquí."},
          {text: "¿Cuánto tiempo quieres que yo me quede aquí?"},
          {text: "¿Por qué no quieres que nos quedemos aquí?"},
        ]
      },
      {
        rows: [
          {text: "Él quiere que la esposa maneje."},
          {text: "Él no quiere que la esposa maneje."},
          {text: "¿Por qué él quiere que la esposa maneje?"},
          {text: "¿Por qué él no quiere que la esposa maneje?"},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Esta <b>construcción especial</b> sólo se puede usar con <b>WANT</b>. No puede usarse con otros verbos tales como <i>know</i>, <i>think</i> o <i>say</i>."
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '30',
    items: [
      {
        rows: [
          {text: "Él dice que estudia."},
          {text: "Él quiere que yo estudie."},
        ]
      },
      {
        rows: [
          {text: "Yo sé que tú me entiendes."},
          {text: "Quiero que me entiendas."},
        ]
      },
      {
        rows: [
          {text: "No me digas que ella sabe."},
          {text: "No quiero que ella se entere."},
        ]
      },
      {
        rows: [
          {text: "No sé lo que tú piensas."},
          {text: "No quiero que pienses que no sé."},
        ]
      },
      {
        rows: [
          {text: "¿Crees que tenemos clase?"},
          {text: "¿Quieres que tengamos clase?"},
        ]
      },
      {
        rows: [
          {text: "¿Es verdad que él maneja?"},
          {text: "¿No quieres que él maneje?"},
        ]
      },
      {
        rows: [
          {text: "¿No te parece que ella habla demasiado?"},
          {text: "¿Por qué no quieres que ella hable?"},
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '31',
    items: [
      {
        rows: [
          {text: "Quiero ayudarte."},
          {text: "Quiero que tú me ayudes."},
        ]
      },
      {
        rows: [
          {text: "Él quiere decírselo (a ella)."},
          {text: "Él quiere que yo se lo diga (a ella)."},
        ]
      },
      {
        rows: [
          {text: "No quiero preguntarle a nadie."},
          {text: "No quiero que nadie me pregunte."},
        ]
      },
      {
        rows: [
          {text: "Ella no quiero verlo (a él)."},
          {text: "Ella no quiere que él la vea."},
        ]
      },
      {
        rows: [
          {text: "¿Cuándo quieres hablar con ellos?"},
          {text: "¿Cuándo quieres que yo hable con ellos?"},
          {text: "¿Cuándo quieres que ellos hablen contigo?"},

        ]
      },
    ]
  },

]
