export const conditional: any = [
  {
    type: 'card',
    title: "The conditional: would(n't)",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "To express the <b>conditional</b>, the Spanish tense ending in <b>-ría</b>, the auxiliary used in English is: "
          },
          {
            text: "would",
            audio:"unit5-194.mp3"
          },
          {
            text: " / "
          },
          {
            text: "wouldn't",
            audio:"unit5-195.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "In the <b>affirmative</b>, <i>would</i> is usually contracted to <b>'d</b> after the pronoun."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo <b>esperaría</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I'd</b> wait",
            audio:"unit5-196.mp3"
          },
          {
            text: "Yo no la <b>llamaría</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I wouldn't</b> call her",
            audio:"unit5-197.mp3"
          },
          {
            text: "¿Cómo lo <b>dirías tú</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "How <b>would you</b> say it?",
            audio:"unit5-198.mp3"
          },
          {
            text: "¿No me <b>ayudarías</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Wouldn't you</b> help me?",
            audio:"unit5-199.mp3"
          },

        ]
      },
    ]
  },
  {
    type: 'card',
    title: "The conditional of can: could(n't)",
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Podrías ir hoy.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You <b>could</b> go today",
            audio:"unit5-200.mp3"
          },
          {
            text: "Yo no podría hacer eso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>couldn't</b> do that <sup>1</sup>",
            audio:"unit5-201.mp3"
          },
          {
            text: "¿Podrías llamarla?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Could</b> you call her?",
            audio:"unit5-202.mp3"
          },
          {
            text: "¿No podríamos intentarlo?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Couldn't</b> we try?",
            audio:"unit5-203.mp3"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<sup>1</sup> The context will determine if <b>I couldn't</b> is ‘<i>Yo no podría</i>' or ‘<i>No pude</i>'."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "I tried to use it but I couldn't",
            audio:"unit5-204.mp3"
          },
          {
            level:'secondary',
            text: "Traté de usarlo pero no <b>pude</b>.",
            audio:"none"

          },
          {
            text: "I couldn't use drugs",
            audio:"unit5-205.mp3"
          },
          {
            level:'secondary',
            text: "Yo no <b>podría</b> usar drogas",
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type: 'card',
    title: "The If clause",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Like in Spanish, the natural complement of a conditional sentence is the <b>if clause</b>. The <b>verb</b> in this phrase is always in the <b>past</b>."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Si yo pudiera.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If I <b>could</b>",
            audio:"unit5-206.mp3"
          },
          {
            text: "Si yo fuera tú.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If I <b>were</b> you <sup>1</sup>",
            audio:"unit5-207.mp3"
          },
          {
            text: "Si tú quisieras",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If you <b>wanted</b> to",
            audio:"unit5-208.mp3"
          },
          {
            text: "Si yo tuviera plata",
            audio:"none"
          },
          {
            level:'secondary',
            text: "If I <b>had</b> money",
            audio:"unit5-209.mp3"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<sup>1</sup> In this structure, <b>were</b> is used all the time."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Si él estuviera aquí.",
            audio:"none"

          },
          {
            level:'secondary',
            text: "If he were here",
            audio:"unit5-210.mp3"
          },
          {
            text: "Si fuera posible.",
            audio:"none"

          },
          {
            level:'secondary',
            text: "If it were possible",
            audio:"unit5-211.mp3"
          },
          {
            text: "Si estuviéramos juntos.",
            audio:"none"

          },
          {
            level:'secondary',
            text: "If we were together",
            audio:"unit5-212.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'english',
    id: '55a',
    items: [
      {
        rows: [
          {
            text: "I'd go if I could",
            audio: "unit5-55.mp3"
          },
          {
            text: "I'd play if I could",
            audio: "unit5-56.mp3"
          },
          {
            text: "I'd buy it if I had money",
            audio: "unit5-57.mp3"
          },
          {
            text: "I'd practice with you if I had more time",
            audio: "unit5-60.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I wouldn't tell her if I were you",
            audio: "unit5-58.mp3"
          },
          {
            text: "I wouldn't sell it if I were you",
            audio: "unit5-59.mp3"
          },
          {
            text: "He'd help me if he were here",
            audio: "unit5-61.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You could come with us if you wanted to",
            audio: "unit5-214.mp3"
          },
          {
            text: "He could play if he wanted to",
            audio: "unit5-215.mp3"
          },
          {
            text: "They could sleep here if they wanted to",
            audio: "unit5-216.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How would you say it?",
            audio: "unit5-62.mp3"
          },
          {
            text: "Where could I go?",
            audio: "unit5-217.mp3"
          },
          {
            text: "When would we start?",
            audio: "unit5-218.mp3"
          },
          {
            text: "What time could she be here?",
            audio: "unit5-219.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Wouldn't you go on?",
            audio: "unit5-67.mp3"
          },
          {
            text: "Couldn't they have class today?",
            audio: "unit5-68.mp3"
          },
          {
            text: "Wouldn't we spend more?",
            audio: "unit5-70.mp3"
          },
          {
            text: "Couldn't she stay till Sunday?",
            audio: "unit5-69.mp3"
          },
        ]
      },

    ]
  },

  {
    type:'translate',
    id: '55b',
    items: [
      {
        rows: [
          {text:'Yo iría si pudiera.'},
          {text:'Yo jugaría si pudiera.'},
          {text:'Yo lo compraría si tuviera plata.'},
          {text:'Yo practicaría contigo si tuviera más tiempo.'},
        ]
      },
      {
        rows: [
          {text:'Yo no se lo diría a ella si fuera tú.'},
          {text:'Yo no lo vendería si fuera tú.'},
          {text:'Él me ayudaría si estuviera aquí.'},
        ]
      },
      {
        rows: [
          {text:'Podrías venir con nosotros si quisieras.'},
          {text:'Él podría jugar si quisiera.'},
          {text:'Ellos podrían dormir aquí si quisieran.'},
        ]
      },
      {
        rows: [
          {text:'¿Cómo lo dirías tú?'},
          {text:'¿Adónde podría ir yo?'},
          {text:'¿Cuándo empezaríamos?'},
          {text:'¿A qué hora podría ella estar aquí?'},
        ]
      },
      {
        rows: [
          {text:'¿No seguirías?'},
          {text:'¿Ellos no podrían tener clase hoy?'},
          {text:'¿No gastaríamos más?'},
          {text:'¿Ella no podría quedarse hasta el domingo?'},
        ]
      },
    ]
  },

]
