export const compound: any = [
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Recuerda que no hay forma interrogativa en la segunda frase de las oraciones compuestas."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Adónde quiere vivir él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Where does he want to live?",
            audio: "unit2-0.mp3",
            type: 'link'
          },
          {
            text: "¿Sabes dónde quiere vivir?	",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <b>where he wants</b> to live?",
            audio: "unit2-1.mp3",
            type: 'link'
          },
          {
            text: "¿Qué vas a hacer?	",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What are you going to do?",
            audio: "unit2-44.mp3",
            type: 'link'
          },
          {
            text: "¿Sabes qué voy a hacer?	",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <strong>what I'm going</strong> to do?",
            audio: "unit2-3.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '40',
    items: [
      {
        rows: [
          {text: "¿Cuándo va a empezar él?"},
          {text: "¿Sabes cuándo va a empezar él?"},
          {text: "¿Él sabe cuándo va a empezar?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuánto tiempo se quedará él allá?"},
          {text: "¿Sabes cuánto tiempo él se quedará allá?"},
          {text: "¿Él sabe cuánto tiempo se quedará allá?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuánto tiene que pagar él?"},
          {text: "¿Sabes cuánto tiene que pagar él?"},
          {text: "¿Él sabe cuánto tiene que pagar?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué él no quiere aprender inglés?"},
          {text: "¿Sabes por qué él no quiere aprender inglés?"},
          {text: "¿Él sabe por qué él no quiere aprender  inglés?"},
        ]
      },
      {
        rows: [
          {text: "¿Dónde viviremos?"},
          {text: "¿Sabes dónde viviremos?"},
        ]
      },
      {
        rows: [
          {text: "¿A qué hora tenemos que llamar? "},
          {text: "¿Sabes a qué hora tenemos que llamar?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuántas computadoras vamos a necesitar? "},
          {text: "¿Sabes cuántas computadoras vamos a necesitar?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuántas horas va a trabajar ella?"},
          {text: "¿Sabes cuántas horas va a trabajar ella?"},
          {text: "¿Ella sabe cuántas horas va a trabajar?"},
        ]
      },
      {
        rows: [
          {text: "¿Qué quiere estudiar ella?"},
          {text: "¿Sabes lo que ella quiere estudiar?"},
          {text: "¿Ella sabe lo que quiere estudiar?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuándo terminará ella el curso?"},
          {text: "¿Sabes cuándo terminará ella el curso?"},
          {text: "¿Ella sabe cuándo terminará el curso?"},
        ]
      },
    ]
  },
  {
    type:'english',
    id: '41',
    items: [
      {
        rows: [
          {
            text: "If she wants to get up early she has to go to bed early",
            audio: "unit2-4.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What time are we going to play?",
            audio: "unit2-5.mp3"
          },
          {
            text: "Do you know what time we're going to play?",
            audio: "unit2-6.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't worry, I won't forget",
            audio: "unit2-7.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm afraid that nobody's going to help you",
            audio: "unit2-8.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't we have to come next week?",
            audio: "unit2-9.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't know why you think he won't pass the test",
            audio: "unit2-10.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He'll come back in three weeks",
            audio: "unit2-11.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do you want to put it there?",
            audio: "unit2-12.mp3"
          },
          {
            text: "Are you sure that you want to put it there?",
            audio: "unit2-13.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Wait, I want you to hear this",
            audio: "unit2-14.mp3"
          },
          {
            text: "I don't want you to think I don't want to tell you",
            audio: "unit2-15.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "As you know, she's going to bring her friend",
            audio: "unit2-16.mp3"
          },
          {
            text: "I'm afraid that's what we'll have to do",
            audio: "unit2-17.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I think nobody wants to take the test",
            audio: "unit2-18.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't know if I'll be here next year",
            audio: "unit2-19.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't you going to read the mail?",
            audio: "unit2-20.mp3"
          },
          {
            text: "Won't you read the mail?",
            audio: "unit2-21.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I hope that we don't have to be together",
            audio: "unit2-22.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm going to ask him if he wants to come with us",
            audio: "unit2-23.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How do you feel?",
            audio: "unit2-24.mp3"
          },
          {
            text: "How do you want me to feel?",
            audio: "unit2-25.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't tell me we're going to have another teacher",
            audio: "unit2-26.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "The thing is she doesn't want anybody to know",
            audio: "unit2-27.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do you know who's going to buy an apartment?",
            audio: "unit2-28.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She says that she has to be there at seven",
            audio: "unit2-29.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Where is Ana going to live?",
            audio: "unit2-30.mp3"
          },
          {
            text: "Does Ana know where she's going to live?",
            audio: "unit2-31.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Till what time will you be here?",
            audio: "unit2-32.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He knows he has to come to class if he wants to practice",
            audio: "unit2-33.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is it true that she doesn't want to go to the doctor?",
            audio: "unit2-34.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you call me in ten minutes?",
            audio: "unit2-35.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Like I said, everybody's going to continue",
            audio: "unit2-36.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you sure that they're going to lend you the money?",
            audio: "unit2-37.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I want you to tell me what you want me to do",
            audio: "unit2-38.mp3"
          },
        ]
      },
    ]
  },
]
