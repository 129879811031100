export const introductory_phrases: any = [
  {
    type: 'card',
    title: 'Vocabulario',
    items: [
      {
        type: 'tokenized_example',
        rows: [
          {
            tokens:[
              {
                text:"That's why",
                audio:"unit1-111.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Por eso  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I know",
                audio:"unit1-112.mp3",
                type: "link",
              },
              {
                text:" (that) &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Yo sé que &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"He says",
                audio:"unit1-113.mp3",
                type: "link",
              },
              {
                text:" (that) &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Él dice que &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I think",
                audio:"unit1-114.mp3",
                type: "link",
              },
              {
                text:" (that) &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Creo / me parece que  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I don't know why",
                audio:"unit1-115.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"No sé por qué  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"What if",
                audio:"unit1-116.mp3",
                type: "link",
              },
              {
                text:" &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"¿Qué pasa si &#8230;? / ¿Y si &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"The thing is",
                audio:"unit1-717.mp3",
                type: "link",
              },
              {
                text:" _ / ",
                audio:"none"
              },
              {
                text:"It's just that",
                audio:"unit1-117.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              },
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Lo que pasa es que  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"Is it okay if",
                audio:"unit1-118.mp3",
                type: "link",
              },
              {
                text:" &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"¿No hay problema si &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"Don't tell me",
                audio:"unit1-119.mp3",
                type: "link",
              },
              {
                text:" (that) &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"No me digas que &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I hope",
                audio:"unit1-120.mp3",
                type: "link",
              },
              {
                text:" (that) &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Espero que _ / Ojalá  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I don't know whether",
                audio:"unit1-718.mp3",
                type: "link",
              },
              {
                text:" / ",
                audio:"none"
              },
              {
                text:"I don't know if",
                audio:"unit1-757.mp3",
                type: "link",
              },
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"No sé si  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"Is it true that",
                audio:"unit1-122.mp3",
                type: "link",
              },
              {
                text:"&#185; &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"¿Es verdad que &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"That's what",
                audio:"unit1-123.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Eso es lo que  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"That's not what",
                audio:"unit1-124.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Eso no es lo que  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"What do you mean",
                audio:"unit1-125.mp3",
                type: "link",
              },
              {
                text:" &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"¿Cómo que  &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"Are you sure that",
                audio:"unit1-758.mp3",
                type: "link",
              },
              {
                text:"&#185; &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"¿Estás seguro/a que &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"By the way,",
                audio:"unit1-771.mp3",
                type: "link",
              },
              {
                text:"&#8230;?",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Por cierto, &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"I'm afraid that",
                audio:"unit1-128.mp3",
                type: "link",
              },
              {
                text:"&#185; &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Me temo que  &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"As you know",
                audio:"unit1-129.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Como tú sabes, &#8230;",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text:"As I said",
                audio:"unit1-719.mp3",
                type: "link",
              },
              {
                text:" / ",
                audio:"none"
              },
              {
                text:"Like I said",
                audio:"unit1-130.mp3",
                type: "link",
              },
              {
                text:" &#8230;",
                audio:"none"
              },
            ]
          },
          {
            level:'secondary',
            tokens: [
              {
                text:"Como dije, &#8230;",
                audio:"none"
              }
            ]
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cualquier oración afirmativa o negativa puede ir precedida de una frase introductoria sin que esto afecte la construcción. <b>No puede omitirse el pronombre</b> del segundo verbo."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Por eso estudiamos aquí.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "That's why</i> <b>we</b> study here.",
            audio: "none",
          },
          {
            text: "Creo que tengo uno.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I think</i> <b>I</b> have one.",
            audio: "none",
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'En este tipo de oraciones "que" es "that". Generalmente se omite, pero puede usarse si se desea. Las frases marcadas con &#185; suenan mejor con <b>that</b>.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Es verdad que ella trabaja?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Is it true <i>that</i></b> she works?",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Is it true she works?",
            audio: "none",
          },
          {
            text: "Ella dice que no maneja.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She says she</b> doesn't drive.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "She says <i>that</i> she doesn't drive.",
            audio: "none",
          },
          {
            text: "¿Estás seguro que él paga?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Are you sure <i>that</i></b> he pays?",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Are you sure he pays?",
            audio: "none",
          },
          {
            text: "Me temo que no sé.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I'm afraid <i>that</i></b> I don't know.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "I'm afraid I don't know.",
            audio: "none",
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'To <b>think</b>: <b>creer que</b> + oración:'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Creo <b>que</b> te gusta.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I think you like it.",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'To <b>believe</b>: <b>creer</b> + complemento (personal o impersonal):'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Te</b> creo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I believe you.",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Sin embargo, es posible usar <i>believe</i>  todo el tiempo:'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Creo que te gusta.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I believe that you like it.",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Igual que sucede en español con "lo" y "lo que", <b>what</b> e <b>it</b> son incompatibles en la misma oración en inglés.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "El sabe que yo <b>lo</b> uso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He knows I use <b>it</b>.",
            audio:"none"
          },
          {
            text: "El sabe <b>lo que</b> yo uso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He knows <b>what</b> I use.",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '23',
    items: [
      {rows: [
        {text:"<b>Por eso</b> pregunto."},
        {text:"<b>Por eso</b> ella no maneja."},
        {text:"<b>Por eso</b> él gasta tanto."},
      ]},
      {rows: [
        {text:"<b>Yo sé que</b> no me crees."},
        {text:"<b>Yo sé que</b> ella lo ama (a él)."},
        {text:"<b>Yo sé que</b> la esposa no trabaja."},
      ]},
      {rows: [
        {text:"<b>Él dice que</b> te conoce."},
        {text:"<b>Él dice que</b> (él) paga más."},
        {text:"<b>Él dice que</b> no entiende."},
      ]},
      {rows: [
        {text:"<b>Creo que</b> sabes por qué."},
        {text:"<b>Creo que</b> hablas demasiado."},
        {text:"<b>Creo que</b> necesitas esto."},
      ]},
      {rows: [
        {text:"<b>No sé por qué</b> no ves TV."},
        {text:"<b>No sé por qué</b> él dice eso."},
        {text:"<b>No sé por qué</b> tu amigo no te ayuda."},
      ]},
      {rows: [
        {text:"<b>¿Qué pasa si</b> él no paga?"},
        {text:"<b>¿Qué pasa si</b> a ella se le olvida?"},
        {text:"<b>¿Qué pasa si</b> no vendemos la casa?"},
      ]},
      {rows: [
        {text:"<b>Lo que pasa es que</b> ella no se siente bien."},
        {text:"<b>Lo que pasa es que</b> ellos tienen problemas."},
        {text:"<b>Lo que pasa es que</b> nadie estudia."},
      ]},
      {rows: [
        {text:"¿<b>No hay problema si</b> traigo a una amiga?"},
        {text:"¿<b>No hay problema si</b> mi amiga se queda aquí?"},
        {text:"¿<b>No hay problema si</b> mi amiga no compra el libro?"},
      ]},
      {rows: [
        {text:"<b>No me digas que</b> él no te espera."},
        {text:"<b>No me digas que</b> ellos venden computadoras."},
        {text:"<b>No me digas que</b> Uds. nunca practican."},
      ]},
      {rows: [
        {text:"<b>Ojalá que</b> te sientas mejor."},
        {text:"<b>Ojalá que</b> todo el mundo venga."},
        {text:"<b>Ojalá que</b> ella no se lo diga (a él)."},
      ]},
      {rows: [
        {text:"<b>No sé si</b> ella sabe."},
        {text:"<b>No sé si</b> ellos practican juntos."},
        {text:"<b>No sé si</b> él todavía vive allá."},
      ]},
      {rows: [
        {text:"¿<b>Es verdad que</b> tenemos clase mañana?"},
        {text:"¿<b>Es verdad que</b> el esposo no trabaja?"},
        {text:"¿<b>Es verdad que</b> ella le da el aventón (a él)?"},
      ]},
      {rows: [
        {text:"<b>Eso es lo que</b> yo pienso."},
        {text:"<b>Eso es lo que</b> tú necesitas."},
        {text:"<b>Eso es lo que</b> yo siento."},
      ]},
      {rows: [
        {text:"<b>Eso no es lo que</b> él dice ahora."},
        {text:"<b>Eso no es lo que</b> yo entiendo."},
        {text:"<b>Eso no es lo que</b> ella quiere."},
      ]},
      {rows: [
        {text:"¿<b>Cómo que</b> yo no pienso en ti?"},
        {text:"¿<b>Cómo que</b> él te presta plata?"},
        {text:"¿<b>Cómo que</b> no te gusta?"},
      ]},
      {rows: [
        {text:"¿<b>Estás seguro/a que</b> tienes suficiente plata?"},
        {text:"¿<b>Estás seguro/a que</b> él quiere uno?"},
        {text:"¿<b>Estás seguro/a que</b> necesitamos esto?"},
      ]},
      {rows: [
        {text:"<b>Por cierto</b>, todavía tengo tu llave"},
        {text:"<b>Por cierto</b>, ¿quién vive ahí?"},
        {text:"<b>Por cierto</b>, el juego empieza a las 2"},
      ]},
      {rows: [
        {text:"<b>Me temo que</b> no sé."},
        {text:"<b>Me temo que</b> ella tiene mi libro."},
        {text:"<b>Me temo que</b> tú no vienes a clase."},
      ]},
      {rows: [
        {text:"<b>Como tú sabes</b>, él vive allá."},
        {text:"<b>Como tú sabes</b>, él compra dólares."},
        {text:"<b>Como tú sabes</b>, él nos presta el carro."},
      ]},
      {rows: [
        {text:"<b>Como dije</b>, tenemos clase."},
        {text:"<b>Como dije</b>, no me gusta."},
        {text:"<b>Como dije</b>, ella paga."},
      ]},
      ]
  },
  {
    type:'english',
    id: '24',
    items: [
      {rows: [{text: "That's why they use the other computer",audio: "unit1-131.mp3"}]},
      {rows: [{text: "Do you know what time we start?",audio: "unit1-132.mp3"}]},
      {rows: [{text: "She says she feels better today",audio: "unit1-133.mp3"}]},
      {rows: [{text: "Wait, I think I still have her phone number",audio: "unit1-134.mp3"}]},
      {rows: [{text: "What if nobody passes the test?",audio: "unit1-135.mp3"}]},
      {rows: [{text: "Don't worry, he doesn't drink when he drives",audio: "unit1-136.mp3"}]},
      {rows: [{text: "That's what you think, that's not what I think",audio: "unit1-137.mp3"}]},
      {rows: [{text: "What do you mean you always give him a ride?",audio: "unit1-138.mp3"}]},
      {rows: [{text: "The thing is she finishes at 6 thirty",audio: "unit1-139.mp3"}]},
      {rows: [{text: "Is it okay if I bring someone?",audio: "unit1-140.mp3"}]},
      {rows: [{text: "Do you think I talk too much?",audio: "unit1-141.mp3"}]},
      {rows: [{text: "I don't know if you know his sister",audio: "unit1-142.mp3"}]},
      {rows: [{text: "Don't tell me you still remember those stories",audio: "unit1-143.mp3"}]},
      {rows: [{text: "He thinks he plays very well",audio: "unit1-144.mp3"}]},
      {rows: [{text: "Doesn't he know how many hours he works?",audio: "unit1-145.mp3"}]},
      {rows: [{text: "As you know, his wife doesn't work",audio: "unit1-146.mp3"}]},
      {rows: [{text: "I don't think you eat too much",audio: "unit1-147.mp3"}]},
      {rows: [{text: "Does anybody know what time the game starts?",audio: "unit1-148.mp3"}]},
      {rows: [{text: "I hope she doesn't forget",audio: "unit1-149.mp3"}]},
      {rows: [{text: "Is it true that we don't have class on Friday?",audio: "unit1-150.mp3"}]},
      {rows: [{text: "I don't know why you don't ask them",audio: "unit1-151.mp3"}]},
      {rows: [{text: "Do you think that's why she doesn't call me anymore?",audio: "unit1-152.mp3"}]},
      {rows: [{text: "Like I said, I never see them",audio: "unit1-153.mp3"}]},
      {rows: [{text: "What do you mean you don't think about that?",audio: "unit1-154.mp3"}]},
      {rows: [{text: "I'm afraid that she doesn't speak Spanish",audio: "unit1-155.mp3"}]},
      {rows: [{text: "I hope you like it",audio: "unit1-156.mp3"}]},
      {rows: [{text: "Don't tell me nobody says anything",audio: "unit1-157.mp3"}]},
      {rows: [{text: "By the way, how much do you want for your cellphone?",audio: "unit1-158.mp3"}]},
      {rows: [{text: "The thing is I never get home before her",audio: "unit1-159.mp3"}]},
      {rows: [{text: "Are you sure that you still need those books?",audio: "unit1-160.mp3"}]},
    ]
  }

]
