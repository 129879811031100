export const structures_19: any = [
  {
    type: 'structures',
    id: '19',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I (past)</b>",
          },
          {
            level:'secondary',
            text: "Yo (pasado)",
          },
          {
            text:"<b>I  didn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo no &#8230;",
          },
          {
            text:"<b>Did you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tú  &#8230;aste/iste?",
          },
          {
            text:"<b>Didn't you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tú no  &#8230;aste/iste?",
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Consideraciones finales',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "El verbo cambia únicamente en el afirmativo. Permanece invariable en las demás formas."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "I play<b>ed</b>",
            audio: "unit4-463.mp3",
            type: 'link'
          },
          {
            text: "I didn't pla<b>y</b>",
            audio: "unit4-464.mp3",
            type: 'link'
          },
          {
            text: "Did you pla<b>y</b>?",
            audio: "unit4-465.mp3",
            type: 'link'
          },
          {
            text: "Didn't you pla<b>y</b>?",
            audio: "unit4-466.mp3",
            type: 'link'
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '24',
    items: [
      {
        rows: [
          {text:'Me acordé.'},
          {text:'No me acordé.'},
          {text:'¿Te acordaste?'},
          {text:'¿No te acordaste?'},
        ]
      },
      {
        rows: [
          {text:'Él estudió.'},
          {text:'Él no estudió.'},
          {text:'¿Él estudió?'},
          {text:'¿Él no estudió?'},
        ]
      },
      {
        rows: [
          {text:'Lo usamos.'},
          {text:'No lo usamos.'},
          {text:'¿Lo usamos?'},
          {text:'¿No lo usamos?'},
        ]
      },
      {
        rows: [
          {text:'Jugaste bien.'},
          {text:'No jugaste bien.'},
          {text:'¿Jugué bien?'},
          {text:'¿No jugué bien?'},
        ]
      },
      {
        rows: [
          {text:'Ella llamó la semana pasada.'},
          {text:'Ella no llamó la semana pasada.'},
          {text:'¿Ella llamó la semana pasada?'},
          {text:'¿Ella no llamó la semana pasada?'},
        ]
      },
      {
        rows: [
          {text:'Ellos se quedaron hasta el domingo.'},
          {text:'Ellos no se quedaron hasta el domingo.'},
          {text:'¿Ellos se quedaron hasta el domingo?'},
          {text:'¿Ellos no se quedaron hasta el domingo?'},
        ]
      },
    ]
  }
]
