export const can_extra: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>I have a friend who  &#8230;</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Tengo un amigo que &#8230;",
            audio: "none",
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'La estructura inglesa es muy parecida a la española salvo que en inglés se usa <b>who</b>. No debe olvidarse la -<b>s</b> cuando corresponda.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "I have a friend who live<b>s</b> there",
            audio:"unit2-347.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '49',
    items: [
      {
        rows: [
          {text: "Tengo un amigo que habla inglés."},
          {text: "Tengo un amigo que juega golf."},
          {text: "Tengo un amigo que vende computadoras."},
          {text: "Tengo un amigo que come demasiado."},
          {text: "Tengo un amigo que que compra carros."},
        ]
      },
      {
        rows: [
          {text: "Tengo un amigo que estudia aquí."},
          {text: "Tengo un amigo que  quiere estudiar aquí."},
          {text: "Tengo un amigo que  que va a estudiar aquí."},
          {text: "Tengo un amigo que  quiere que yo estudie aquí."},
        ]
      },
      {
        rows: [
          {text: "Tengo una amiga que tiene que aprender inglés."},
          {text: "Tengo una amiga que va a aprender inglés."},
          {text: "Tengo una amiga que debe aprender inglés."},
          {text: "Tengo una amiga que quiere que yo aprenda inglés."},
          {text: "Tengo una amiga que debería aprender inglés."},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>I'd like to see her</b>",
            audio:"unit2-145.mp3"
          },
          {
            level:'secondary',
            text: "Quisiera verla.",
            audio: "none",
          },
          {
            text: "<b>What would you like to eat?</b>",
            audio:"unit2-146.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué te gustaría comer?",
            audio: "none",
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Tanto en inglés como en español, <i>Quiero verla</i> y <i>¿Qué quieres comer?</i> pueden expresarse más elegantemente: <i><b>Quisiera</b> verla</i> y <i>¿Qué <b>te gustaría</b> comer?</i>. No es obligatorio usar estos giros pero es preciso entenderlos por ser frecuentes en la conversación.'
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '50',
    items: [
      {
        rows: [
          {text: "Quiero hablar contigo."},
          {text: "Me gustaría hablar contigo."},
          {text: "Quiero jugar."},
          {text: "Quisiera jugar."},
          {text: "Quiero aprender inglés."},
          {text: "Me gustaría aprender inglés."},
          {text: "Quiero ver el libro."},
          {text: "Quisiera ver el libro."},
        ]
      },
      {
        rows: [
          {text: "¿Qué quieres comer?"},
          {text: "¿Qué te gustaría comer?"},
          {text: "¿Adónde quieres ir?"},
          {text: "¿Adónde te gustaría ir?"},
          {text: "¿Cuándo quieres empezar?"},
          {text: "¿Cuándo te gustaría empezar?"},
          {text: "¿Cuánto tiempo quieres quedarte?"},
          {text: "¿Cuánto tiempo quisieras quedarte?"},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "He doesn't <b>know how</b> to start",
            audio:"unit2-147.mp3"
          },
          {
            level:'secondary',
            text: "Él no sabe cómo empezar.",
            audio: "none",
          },
          {
            text: "She knows <b>how to</b> read and write",
            audio:"unit2-148.mp3"
          },
          {
            level:'secondary',
            text: "Ella sabe leer y escribir.",
            audio: "none",
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Cuando en español <i>saber</i> va seguido de otro verbo, el uso de <i>cómo</i> es opcional. <b>En inglés nunca puede omitirse <i>how</i> antes de <i>to</i></b>.'
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '51',
    items: [
      {
        rows: [
          {text: "No sé hacerlo."},
          {text: "Ella no sabe manejar."},
          {text: "No sé cómo empezar."},
          {text: "Ella no sabe cómo decírselo (a él)."},
          {text: "No sé jugar."},
          {text: "Él no sabe estudiar."},
          {text: "No sé cómo contestar."},
          {text: "Él no sabe cómo ayudarla (a ella)."},
        ]
      },
      {
        rows: [
          {text: "Él no estudia."},
          {text: "Él no sabe estudiar."},
          {text: "Él no lee."},
          {text: "Él no sabe leer."},
          {text: "Ella no contesta."},
          {text: "Ella no sabe cómo contestar."},
          {text: "Ella no escribe. "},
          {text: "Ella no sabe escribir."},
          {text: "No lo hago."},
          {text: "No sé hacerlo."},
          {text: "No empiezo."},
          {text: "No sé cómo empezar."},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "She doesn't know <b>what to</b> do",
            audio:"unit2-149.mp3"
          },
          {
            level:'secondary',
            text: "Ella no sabe qué hacer.",
            audio: "none",
          },
          {
            text: "I don't know <b>what</b> you think",
            audio:"unit2-150.mp3"
          },
          {
            level:'secondary',
            text: "No sé qué piensas tú.",
            audio: "none",
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "En inglés, <i>No sé <b>qué</b> &#8230;</i>  es siempre <i>I don't know <b>what</b> &#8230;</i>. Generalmente, la expresión va seguida del verbo en infinitivo (<i>She doesn't know what to do</i>), pero puede ir seguida del verbo en presente (<i>I don't know what you think</i>)."
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '52',
    items: [
      {
        rows: [
          {text: "No sé qué decir."},
          {text: "No sé qué decirte."},
          {text: "No sé qué contestar."},
          {text: "No sé qué pensar."},
          {text: "No sé qué comer."},
        ]
      },
      {
        rows: [
          {text: "Ella no sabe qué hacer."},
          {text: "Ella no sabe qué leer."},
          {text: "Ella no sabe qué estudiar."},
          {text: "Ella no sabe qué traer."},
          {text: "Ella no sabe qué escribir."},
        ]
      },
      {
        rows: [
          {text: "No sé qué pensar."},
          {text: "No sé qué piensas tú."},
          {text: "Él no sabe qué estudiar."},
          {text: "No sé qué estudia él."},
          {text: "No sé qué decir."},
          {text: "No sé qué dices tú."},
          {text: "Ella no sabe qué hacer allá."},
          {text: "No sé qué hace ella allá."},
          {text: "Ellos no saben qué vender."},
          {text: "No se qué venden ellos."},
        ]
      },
    ]
  },
  {
    type:'english',
    id: '53',
    items: [
      {
        rows: [
          {
            text: "Stay here and don't do anything",
            audio: "unit2-151.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We want to be together",
            audio: "unit2-152.mp3"
          },
          {
            text: "We'd like to be together",
            audio: "unit2-153.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "If they study, they should pass the test",
            audio: "unit2-154.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Can I start?",
            audio: "unit2-155.mp3"
          },
          {
            text: "Start",
            audio: "unit2-156.mp3"
          },
          {
            text: "Tell him to start",
            audio: "unit2-157.mp3"
          },
          {
            text: "Shouldn't we start?",
            audio: "unit2-273.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let's see how much you remember",
            audio: "unit2-159.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why should I believe you?",
            audio: "unit2-160.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "That's why I say that she doesn't know how to work",
            audio: "unit2-161.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You mustn't go with her",
            audio: "unit2-162.mp3"
          },
          {
            text: "You don't have to go with her",
            audio: "unit2-163.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I know a teacher who can help you",
            audio: "unit2-164.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let me tell you something: she won't like it",
            audio: "unit2-165.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Tell them to practice if they want to win on Saturday",
            audio: "unit2-166.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't think you should go to the beach if you have to study",
            audio: "unit2-167.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Ask Ana if you don't believe me",
            audio: "unit2-168.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When can I talk to him?",
            audio: "unit2-169.mp3"
          },
          {
            text: "When am I going to talk to him?",
            audio: "unit2-170.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "The thing is she doesn't know what to do",
            audio: "unit2-171.mp3"
          },
          {
            text: "The thing is she doesn't know what she wants to do",
            audio: "unit2-172.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She drives a BMW; she must have money",
            audio: "unit2-173.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let me see how much I can lend you",
            audio: "unit2-174.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Someone has to tell her",
            audio: "unit2-175.mp3"
          },
          {
            text: "Someone should tell her",
            audio: "unit2-176.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I have a friend who's going to live there",
            audio: "unit2-177.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't think I can be here at 7",
            audio: "unit2-178.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm going to tell you but you mustn't tell anybody",
            audio: "unit2-179.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Shouldn't he be with his wife and kids?",
            audio: "unit2-180.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let's go, we won't have class today",
            audio: "unit2-181.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When do you want to start?",
            audio: "unit2-230.mp3"
          },
          {
            text: "When would you like to start?",
            audio: "unit2-183.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm afraid that I won't be able to come next week",
            audio: "unit2-184.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How many times do I have to tell you that you can't read my mail?",
            audio: "unit2-185.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Tell them not do to anything yet",
            audio: "unit2-186.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "May I ask why you don't come to class?",
            audio: "unit2-187.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I know I shouldn't sell the car but I need the money",
            audio: "unit2-188.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You must do what you think  you have to do",
            audio: "unit2-189.mp3"
          },
        ]
      },
    ]
  },
]
