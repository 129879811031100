import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of, Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { User } from '../store/user';
import { AppState } from 'src/app/store/state';

import { Platform } from '@ionic/angular';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { Plugins } from '@capacitor/core'
const { ExerciseChecker } = Plugins;
import { Filesystem } from '@capacitor/filesystem';


interface TextCorrection {
  correction: string;
  ok: boolean;
}

interface TranscriptResult {
  transcript: string;
  ok: boolean;
}

enum Result {
  PASS,
  FAIL,
  UNKNOWN
}

@Injectable({
  providedIn: 'root'
})
export class ExerciseProviderService {

  user$: Observable<User>;
  uid: string;

  id: any;
  exercises: any;
  index: number;
  exerciseMap: any = {};

  recPath: string;

  progress: any = {
    oral:{
      correct:0,
      fails:0,
      total:0
    },
    written:{
      correct:0,
      fails:0,
      total:0
    }
  }
  progress$: BehaviorSubject<any>;

  completed: any;
  completed$: BehaviorSubject<any>;

  review: any;
  review$: BehaviorSubject<any>;

  currentTranscript: TranscriptResult;
  timeout: boolean;
  cancelTranscript: boolean;
  recordingStart: any;

  readonly extension = 'm4a';
  readonly completion_threshold = 0.80;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private platform: Platform,
    private afs: AngularFirestore,
    private afstore: AngularFireStorage,
  ) {
    this.index = -1;
    this.cancelTranscript = false;
    if (this.platform.is("hybrid")){
      this.init();
    }
    this.completed = new Set();
    this.completed$ = new BehaviorSubject<any>(this.completed);

    this.review = []
    this.review$ = new BehaviorSubject<any>(this.review);

    this.user$ = this.store.select('user');
    this.user$.subscribe(data=>{
      this.uid = data['uid'];
      this.afs.collection('progress').doc('lessons').collection('completion').doc(this.uid).valueChanges().pipe(take(1)).subscribe(data => {
        if (data !== undefined){
          for (let i of data['ids']){
            this.completed.add(i);
          }
        }
        this.completed$.next(this.completed);
      })
    })

    this.progress$ = new BehaviorSubject<any>(this.progress);
  }

  getCompletion(){
    return this.completed$;
  }

  getReview(){
    return this.review$;
  }

  getProgress(){
    return this.progress$;
  }

  fetchExercises(id){

    let lessons = this.afs.collection('exercises').doc('lessons').collection(id, ref => ref.orderBy('pos')).valueChanges({ idField: 'questionId' });
    lessons.pipe(take(1)).subscribe(data => {
      data = data.map(e => {
        e['type'] = 'question';
        return e;
      })
      this.exerciseMap[id] = data;
      this.exercises = this.exerciseMap[id];

      let exerciseMap = {}

      for (let i in this.exercises) {
        this.exercises[i]['result'] = Result.UNKNOWN;
        exerciseMap[this.exercises[i]['questionId']] = this.exercises[i]
      }

      let p = this.afs.collection('progress').doc('exercises').collection(this.uid).doc('lessons').collection(this.id).valueChanges({ idField: 'questionId' });
      p.pipe(take(1)).subscribe(data => {
        for (let i in data) {
          let result = data[i]
          if (result['questionId'] in exerciseMap){
            if (result['result']=='FAIL'){
              exerciseMap[result['questionId']]['result'] = Result.FAIL;
            } else {
              exerciseMap[result['questionId']]['result'] = Result.PASS;
            }
          }
        }
        this.initilizeLesson();
      })
    })
  }

  fetchReview(id){
    let lessons = this.afs.collection('exercises').doc('lessons').collection(id, ref => ref.orderBy('pos')).valueChanges({ idField: 'questionId' });
    lessons.pipe(take(1)).subscribe(exercises => {

      let exerciseMap = {}

      for (let e of exercises) {
        e['result'] = Result.UNKNOWN;
        exerciseMap[e['questionId']] = e;
      }

      let p = this.afs.collection('progress').doc('exercises').collection(this.uid).doc('lessons').collection(id).valueChanges({ idField: 'questionId' });
      p.pipe(take(1)).subscribe(results => {

        const total = results.length;
        this.review = []

        for (let result of results) {
          const id = result['questionId'];
          if (result['result']=='FAIL'){
            exerciseMap[id]['ok'] = false;
          } else {
            exerciseMap[id]['ok'] = true;
          }

          let e = this.afs.collection('exercises').doc(result['action']).collection(this.uid).doc(result['ts']).valueChanges();
          e.pipe(take(1)).subscribe(exercise => {

            if (result['action'] == 'written'){
              exerciseMap[id]['time'] = new Date(exercise['start']).toLocaleString();
              exerciseMap[id]['correction'] = this.formatCorrection(exercise['correction']);
              exerciseMap[id]['expected'] = exercise['expected'];
              exerciseMap[id]['answer'] = exercise['answer'];
              exerciseMap[id]['translation'] = exercise['expected'];
            }
            else {
              exerciseMap[id]['time'] = new Date(exercise['start']).toLocaleString();
              exerciseMap[id]['audio'] = exercise['audio'];
              exerciseMap[id]['expected'] = exercise['expected'];
              exerciseMap[id]['reaction'] = exercise['reaction'];
              exerciseMap[id]['transcript'] = exercise['transcript'];
              exerciseMap[id]['translation'] = exercise['expected'];
            }

            this.review.push(exerciseMap[id]);
            if (this.review.length == total){
              this.review.sort((a,b) => (a['pos'] > b['pos']) ? 1 : ((b['pos'] > a['pos']) ? -1 : 0));
              this.review$.next(this.review);
            }
          })
        }
      })
    })
  }

  complete(id){
    this.completed.add(id);
    this.saveCompletion();
    this.completed$.next(this.completed);
  }

  saveCompletion(){
    let completed = Array.from(this.completed.values());
    this.afs.collection('progress').doc('lessons').collection('completion').doc(this.uid).set({
      ids: completed
    })
  }

  markCompletion(){
    let total = this.progress['oral']['total'] + this.progress['written']['total']
    let correct = this.progress['oral']['correct'] + this.progress['written']['correct']
    if (correct / total > this.completion_threshold){
      if (!this.completed.has(this.id)){
        this.completed.add(this.id)
        this.saveCompletion();
        this.completed$.next(this.completed);
      }
    } else {
      if (this.completed.has(this.id)){
        this.completed.delete(this.id);
        this.saveCompletion();
        this.completed$.next(this.completed);
      }
    }
  }

  initilizeLesson(){

    this.progress['oral']['correct'] = 0;
    this.progress['oral']['fails'] = 0;
    this.progress['oral']['total'] = 0;
    this.progress['written']['correct'] = 0;
    this.progress['written']['fails'] = 0;
    this.progress['written']['total'] = 0;

    for (let i in this.exercises) {
      if (this.exercises[i]['action']=='written'){
        this.progress['written']['total'] += 1;
        if (this.exercises[i]['result'] == Result.PASS){
          this.progress['written']['correct'] += 1;
        } else if(this.exercises[i]['result'] == Result.FAIL){
          this.progress['written']['fails'] += 1;
        }
      } else {
        this.progress['oral']['total'] += 1;
        if (this.exercises[i]['result'] == Result.PASS){
          this.progress['oral']['correct'] += 1;
        } else if(this.exercises[i]['result'] == Result.FAIL){
          this.progress['oral']['fails'] += 1;
        }
      }
    }

    this.progress$.next(this.progress);
  }

  async setId(id){
    this.id = id;
    this.index = -1;

    this.progress$.next({
      oral:{
        correct:0,
        fails:0,
        total:0
      },
      written:{
        correct:0,
        fails:0,
        total:0
      }
    });

    if (!(id in this.exerciseMap)){
      this.fetchExercises(id);
    } else {
      this.exercises = this.exerciseMap[id];
      this.initilizeLesson();
    }
  }

  nextIndex(){
    this.index += 1;
    if (this.index == this.exercises.length){
      this.index = 0;
    }
  }

  previousIndex(){
    this.index -= 1;
    if (this.index == -1){
      this.index = this.exercises.length - 1;
    }
  }

  changeAction(action){
    let i = this.index;
    this.nextIndex();
    while(this.exercises[this.index]['action']!=action){
      if (i==this.index){
        return;
      }
      this.nextIndex();
    }
    this.previousIndex()
  }

  reset(action){
    for (let i in this.exercises) {
      if (this.exercises[i]['action'] == action){
        this.exercises[i]['result'] = Result.UNKNOWN;
      }
      let p = this.afs.collection('progress').doc('exercises').collection(this.uid).doc('lessons').collection(this.id).valueChanges({ idField: 'questionId' });
      p.pipe(take(1)).subscribe(data => {
        for (let i in data) {
          let result = data[i]
          if (result['action'] == action) {
              this.afs.collection('progress').doc('exercises').collection(this.uid).doc('lessons').collection(this.id).doc(result['questionId']).delete();
          }
        }
      })
    }
    this.progress[action]['fails'] = 0;
    this.progress[action]['correct'] = 0;
    this.markCompletion();
  }

  getNextExercise(){
    let i = this.index;
    do {
      this.nextIndex()
      if (this.exercises[this.index]['result']!=Result.PASS){
        let e = this.exercises[this.index];
        let ts = new Date().getTime();
        e['start'] = ts;
        return e;
      }
    } while (i!=this.index);
    return null;
  }

  navigate(id){
    let params = {
      id: id
    }
    this.router.navigate(['/exercises', params])
  }

  init(){
    return ExerciseChecker.initialize({});
  }

  convertToBlob(data:string, contentType:string){
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: contentType});
    return blob;
  }

  getTranscript(){
    return ExerciseChecker.getTranscript({}).then(result => {

      const previousResult = this.exercises[this.index]['result']
      const exercise = this.exercises[this.index]

      let ts = new Date().getTime();

      if (result['ok']){
        this.updateProgress(Result.PASS, previousResult, ts.toString());
      } else {
        this.updateProgress(Result.FAIL, previousResult, ts.toString());
      }

      let ex_log = this.afs.collection('exercises').doc('oral').collection(this.uid).doc(ts.toString());
      let fileId = 'voice_' + ts + '.' + this.extension;
      let reaction = this.recordingStart - exercise['start'];
      ex_log.set({
        'translation': exercise['question'],
        'audio': fileId,
        'expected': exercise['answer'],
        'start': exercise['start'],
        'transcript': result['transcript'],
        'eval': result['ok'],
        'model': result['model'],
        'code': result['code'],
        'end': ts,
        'reaction': reaction,
        'key': exercise['key']
      })


      Filesystem.readFile({
        path: this.recPath
      }).then(contents => {
        let blob = this.convertToBlob(contents.data, 'audio/' + this.extension)
        let audioPath = 'audio/'+this.uid+'/'+fileId;
        const audioref = this.afstore.ref(audioPath);
        audioref.put(blob);
      });

      result['reaction'] = reaction;
      return result;
    })
  }

  onTimeout(callback){
    ExerciseChecker.addListener('TimeoutEvent', callback);
  }

  stopRecording(filename){
    this.recPath = filename;
    return ExerciseChecker.stopRecording({filename:filename.replace(/^file:\/\//, '')});
  }

  startRecording(expected){
    this.recordingStart = new Date().getTime();
    return ExerciseChecker.startRecording({expected:expected});
  }

  cancelRecording(expected){
    return ExerciseChecker.cancelRecording({});
  }

  formatCorrection(correction){
    correction = correction.replace(/</g, '#');
    correction = correction.replace(/>/g, '</b>');
    correction = correction.replace(/{/g, '<s>');
    correction = correction.replace(/ }/g, '</s> ');
    correction = correction.replace(/}/g, '</s>');

    correction = correction.replace(/#/g, '<b>');
    return correction;
  }

  async correctText(answer){

    const previousResult = this.exercises[this.index]['result']
    const exercise = this.exercises[this.index]

    const correct = await ExerciseChecker.evaluateText({answer:answer, expected:exercise['answer']});

    let ts = new Date().getTime().toString();
    let e_log = this.afs.collection('exercises').doc('written').collection(this.uid).doc(ts)

    if ( (correct.code === 'EQUAL') || (correct.code === '' && correct.value > 0.5) || (correct.code === 'ALTERNATIVES')) {

      this.updateProgress(Result.PASS, previousResult, ts)

      e_log.set({
        'answer': answer,
        'correction': answer,
        'grade':correct.value,
        'code': correct.code,
        'expected': exercise['answer'],
        'start': exercise['start'],
        'success': -1,
        'key': exercise['key'],
        'translation': exercise['question']
      })

      return new Promise<TextCorrection>((resolve, reject) => {
        resolve({
          correction: exercise['answer'],
          ok: true,
        });
      });

    } else {

      this.updateProgress(Result.FAIL, previousResult, ts)
      const correction = await ExerciseChecker.correctText({answer:answer, expected:exercise['answer']});
      const success = await ExerciseChecker.validate({correction:correction.value, expected:exercise['answer']});

      const valid_correction = (success.value > 0.5) ? this.formatCorrection(correction.value) : exercise['answer'];

      e_log.set({
        'answer': answer,
        'correction': correction.value,
        'grade':correct.value,
        'code': correct.code,
        'expected': exercise['answer'],
        'start': exercise['start'],
        'success': success.value,
        'key': exercise['key'],
        'translation': exercise['question']
      })

      return new Promise<TextCorrection>((resolve, reject) => {
        resolve({
          correction: valid_correction,
          ok: false,
        });
      });
    }
  }

  updateProgress(result, previousResult, id){

    let e = this.exercises[this.index];
    let p = this.afs.collection('progress').doc('exercises').collection(this.uid).doc('lessons').collection(this.id).doc(e['questionId']);

    if (previousResult!=Result.PASS){
      if ((result == Result.FAIL) && (previousResult == Result.UNKNOWN)){
        this.progress[e['action']]['fails'] +=1;
        p.set({
          result: "FAIL",
          action: e['action'],
          key: e['key'],
          ts: id,
          id: e['questionId']
        });
      }
      if ((result == Result.PASS) && (previousResult == Result.UNKNOWN)){
        this.progress[e['action']]['correct'] +=1;
        this.markCompletion();
        p.set({
          result: "PASS",
          action: e['action'],
          key: e['key'],
          ts: id,
          id: e['questionId']
        })
      }
      if ((result == Result.PASS) && (previousResult == Result.FAIL)){
        this.progress[e['action']]['fails'] -=1;
        this.progress[e['action']]['correct'] +=1;
        this.markCompletion();
        p.set({
          result: "PASS",
          action: e['action'],
          key: e['key'],
          ts: id,
          id: e['questionId']
        })
      }

      this.exercises[this.index]['result'] = result;

      this.progress$.next(this.progress);
    }
  }

  getAudioURL(audioFile){
    const ref = this.afstore.ref('audio/' + this.uid + '/' + audioFile);
    return ref.getDownloadURL();
  }
}
