export const structures_20: any = [
  {
    type: 'structures',
    id: '20',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I wanted to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo quería &#8230;ar/er/ir",
          },
          {
            text:"<b>He didn't want to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Él no quería &#8230;ar/er/ir",
          },
          {
            text:"<b>He refused to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Él no quiso &#8230;ar/er/ir",
          },
          {
            text:"<b>Did you want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Querías &#8230;ar/er/ir?",
          },
          {
            text:"<b>Didn’t you want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿No querías &#8230;ar/er/ir?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '31',
    items: [
      {
        rows: [
          {text:'Yo quería ir al banco.'},
          {text:'Yo no quería ir al banco.'},
          {text:'Por eso no quise ir al banco.'},
          {text:'¿Querías ir al banco?'},
          {text:'¿No querías ir al banco?'},
        ]
      },
      {
        rows: [
          {text:'Ella quería venir.'},
          {text:'Ella no quería venir.'},
          {text:'Ella no quiso venir.'},
          {text:'¿Ella quería venir?'},
          {text:'¿No quería venir ella?'},
        ]
      },
      {
        rows: [
          {text:'Él quería jugar.'},
          {text:'Él no quería jugar.'},
          {text:'Él no quiso jugar.'},
          {text:'¿Él quería jugar?'},
          {text:'¿Él no quería jugar?'},
        ]
      },
    ]
  }
]
