export const to_be_vocab_1: any = [
  {
    type: 'card',
    items: [
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>fine</b><br/>",
                  audio: "unit3-174.mp3"
                },
                {
                  type: "link",
                  text: "<b>okay</b>",
                  audio: "unit3-175.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "bien"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "(",
                  audio: "none"
                },
                {
                  type: "link",
                  text: "so",
                  audio: "unit3-550.mp3"
                },
                {
                  text: ") ",
                  audio: "none"
                },
                {
                  type: "link",
                  text: "<b>happy</b><br/>",
                  audio: "unit3-176.mp3"
                },
                {
                  type: "link",
                  text: "<b>sad</b>",
                  audio: "unit3-177.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "(tan) feliz<br/>triste"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>angry</b><br/>",
                  audio: "unit3-178.mp3"
                },
                {
                  type: "link",
                  text: "<b>wrong</b>",
                  audio: "unit3-179.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "bravo(a)<br/>equivocado(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>intelligent</b>",
                  audio: "unit3-180.mp3"
                },
                {
                  text: " / "
                },
                {
                  type: "link",
                  text: "<b>smart</b>",
                  audio: "unit3-181.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "inteligente"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>sick</b><br/>",
                  audio: "unit3-182.mp3"
                },
                {
                  type: "link",
                  text: "<b>dead</b>",
                  audio: "unit3-183.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "enfermo(a)<br/>muerto(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>nice</b><br/>",
                  audio: "unit3-184.mp3"
                },
                {
                  type: "link",
                  text: "<b>crazy</b>",
                  audio: "unit3-185.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "simpático(a)<br/>loco(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>nervous</b><br/>",
                  audio: "unit3-186.mp3"
                },
                {
                  type: "link",
                  text: "<b>scared</b>",
                  audio: "unit3-187.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "nervioso(a)<br/>asustado(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "("
                },
                {
                  type: "link",
                  text: "very",
                  audio: "unit3-555.mp3"
                },
                {
                  text: ") "
                },
                {
                  type: "link",
                  text: "<b>pretty</b><br/>",
                  audio: "unit3-188.mp3"
                },
                {
                  type: "link",
                  text: "<b>beautiful</b>",
                  audio: "unit3-189.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "(muy) bonito(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>handsome</b>",
                  audio: "unit3-190.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "buenmozo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>pregnant</b><br/>",
                  audio: "unit3-191.mp3"
                },
                {
                  type: "link",
                  text: "<b>bored</b>",
                  audio: "unit3-192.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "embarazada<br/>aburrido(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>busy</b><br/>",
                  audio: "unit3-193.mp3"
                },
                {
                  type: "link",
                  text: "<b>tired</b>",
                  audio: "unit3-194.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "ocupado(a)<br/>cansado(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>in a hurry</b><br/>",
                  audio: "unit3-195.mp3"
                },
                {
                  type: "link",
                  text: "<b>late</b>",
                  audio: "unit3-196.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "apurado(a)<br/>retrasado(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>married</b>",
                  audio: "unit3-197.mp3"
                },
                {
                  text: "(to)"
                },
              ]
            },
            right: {
              tokens:[
                {
                  text: "casado(a) (con)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>worried</b>",
                  audio: "unit3-557.mp3"
                },
                {
                  text: "(about)"
                },
              ]
            },
            right: {
              tokens:[
                {
                  text: "preocupado(a) (por)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>in love</b>",
                  audio: "unit3-558.mp3"
                },
                {
                  text: "(with)"
                },
              ]
            },
            right: {
              tokens:[
                {
                  text: "enamorado(a) (de)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>at home</b><br/>",
                  audio: "unit3-200.mp3"
                },
                {
                  type: "link",
                  text: "<b>alone</b>",
                  audio: "unit3-201.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "en casa<br/>solo(a)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>upstairs</b><br/>",
                  audio: "unit3-202.mp3"
                },
                {
                  type: "link",
                  text: "<b>downstairs</b>",
                  audio: "unit3-203.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "arriba<br/>abajo"
                }
              ]
            },
          },
        ]
      },
    ]
  },

  {
    type:'english',
    id: '1a',
    items: [
      {
        rows: [
          {
            text: "I'm fine",
            audio: "unit3-204.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She's beautiful",
            audio: "unit3-205.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm bored",
            audio: "unit3-206.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We're worried",
            audio: "unit3-207.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm nervous",
            audio: "unit3-208.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You're wrong",
            audio: "unit3-209.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm in a hurry",
            audio: "unit3-210.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He's busy",
            audio: "unit3-211.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm tired",
            audio: "unit3-212.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "They're upstairs",
            audio: "unit3-213.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm sick",
            audio: "unit3-214.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She's pregnant",
            audio: "unit3-215.mp3"
          },
        ]
      },

    ]
  },

  {
    type:'translate',
    id: '1b',
    items: [
      {rows: [{text: "Estoy bien."}]},
      {rows: [{text: "Ella es bonita."}]},
      {rows: [{text: "Estoy aburrido(a)."}]},
      {rows: [{text: "Estamos preocupados."}]},
      {rows: [{text: "Estoy nervioso(a)."}]},
      {rows: [{text: "Estás equivocado(a)."}]},
      {rows: [{text: "Estoy apurado(a)."}]},
      {rows: [{text: "Él está ocupado."}]},
      {rows: [{text: "Estoy cansado(a)."}]},
      {rows: [{text: "Ellos están arriba."}]},
      {rows: [{text: "Estoy enfermo(a)."}]},
      {rows: [{text: "Ella está embarazada."}]},
    ]
  },
  {
    type:'english',
    id: '2a',
    items: [
      {
        rows: [
          {
            text: "I'm not so sure",
            audio: "unit3-216.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She's not pregnant",
            audio: "unit3-217.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She isn't worried",
            audio: "unit3-218.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm not at home",
            audio: "unit3-219.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We're not in USA",
            audio: "unit3-220.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We aren't together",
            audio: "unit3-221.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm not sad",
            audio: "unit3-222.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "They're not my friends",
            audio: "unit3-223.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "They aren't ready",
            audio: "unit3-224.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm not angry",
            audio: "unit3-225.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You're not sick",
            audio: "unit3-226.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You aren't alone",
            audio: "unit3-227.mp3"
          },
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '2b',
    items: [
      {rows: [{text: "No estoy tan seguro(a)."}]},
      {rows: [{text: "Ella no está embarazada."}]},
      {rows: [{text: "Ella no está preocupada."}]},
      {rows: [{text: "No estoy en casa."}]},
      {rows: [{text: "No estamos en USA."}]},
      {rows: [{text: "No estamos juntos."}]},
      {rows: [{text: "No estoy triste."}]},
      {rows: [{text: "Ellos no son mis amigos."}]},
      {rows: [{text: "Ellos no están listos."}]},
      {rows: [{text: "No estoy bravo(a)."}]},
      {rows: [{text: "No estás enfermo(a)."}]},
      {rows: [{text: "No estás solo(a)."}]},
    ]
  },
  {
    type:'english',
    id: '3a',
    items: [
      {
        rows: [
          {
            text: "Are you scared?",
            audio: "unit3-228.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is he sick?",
            audio: "unit3-229.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are we ready?",
            audio: "unit3-230.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is she pregnant?",
            audio: "unit3-231.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you busy?",
            audio: "unit3-232.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Am I wrong?",
            audio: "unit3-233.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you crazy?",
            audio: "unit3-234.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is she here?",
            audio: "unit3-235.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are we married?",
            audio: "unit3-648.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are they in love?",
            audio: "unit3-236.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is he dead?",
            audio: "unit3-237.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you okay?",
            audio: "unit3-238.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '3b',
    items: [
      {rows: [{text: "¿Estás asustado(a)?"}]},
      {rows: [{text: "¿Él está enfermo?"}]},
      {rows: [{text: "¿Estamos listos?"}]},
      {rows: [{text: "¿Ella está embarazada?"}]},
      {rows: [{text: "¿Estás ocupado(a)?"}]},
      {rows: [{text: "¿Estoy equivocado(a)?"}]},
      {rows: [{text: "¿Estás loco(a)?"}]},
      {rows: [{text: "¿Ella está aquí?"}]},
      {rows: [{text: "¿Acaso estamos casados?"}]},
      {rows: [{text: "¿Ellos están enamorados?"}]},
      {rows: [{text: "¿Él está muerto?"}]},
      {rows: [{text: "¿Estás bien?"}]},
    ]
  },
  {
    type:'english',
    id: '4a',
    items: [
      {
        rows: [
          {
            text: "Aren't you happy?",
            audio: "unit3-239.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't he busy?",
            audio: "unit3-240.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't you scared?",
            audio: "unit3-241.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Am I not with you?",
            audio: "unit3-242.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't they married?",
            audio: "unit3-243.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't she upstairs?",
            audio: "unit3-244.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't you angry?",
            audio: "unit3-245.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't he at home?",
            audio: "unit3-246.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't we together?",
            audio: "unit3-247.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '4b',
    items: [
      {rows: [{text: "¿No eres feliz?"}]},
      {rows: [{text: "¿Él no está ocupado?"}]},
      {rows: [{text: "¿No estás asustado?"}]},
      {rows: [{text: "¿Acaso no estoy contigo?"}]},
      {rows: [{text: "¿Ellos no están casados?"}]},
      {rows: [{text: "¿Ella no está arriba?"}]},
      {rows: [{text: "¿No estás bravo?"}]},
      {rows: [{text: "¿Él no está en casa?"}]},
      {rows: [{text: "¿Acaso no estamos juntos?"}]},
    ]
  },
  {
    type:'translate',
    id: '5',
    items: [
      {
        rows: [
          {text: "Estoy preocupado(a)."},
          {text: "Entiendo."},
        ]
      },
      {
        rows: [
          {text: "Ella es bonita."},
          {text: "Ella estudia aquí."},
        ]
      },
      {
        rows: [
          {text: "Estamos listos."},
          {text: "Practicamos todos los días."},
        ]
      },
      {
        rows: [
          {text: "Él está cansado."},
          {text: "Él sabe."},
        ]
      },
      {
        rows: [
          {text: "Estás loco(a)."},
          {text: "Tienes tiempo."},
        ]
      },
      {
        rows: [
          {text: "Ellos están abajo."},
          {text: "Ellos me dan el aventón."},
        ]
      },
      {
        rows: [
          {text: "Él no está en casa."},
          {text: "Él no se acuerda."},
        ]
      },
      {
        rows: [
          {text: "Ellos no están aquí."},
          {text: "Ellos no viven aquí."},
        ]
      },
      {
        rows: [
          {text: "Tú no eres feliz."},
          {text: "No manejas bien."},
        ]
      },
      {
        rows: [
          {text: "No estoy apurado(a)."},
          {text: "No me siento bien."},
        ]
      },
      {
        rows: [
          {text: "No estamos en USA."},
          {text: "No practicamos todos los días."},
        ]
      },
      {
        rows: [
          {text: "Ella no es la esposa de él."},
          {text: "Ella no trabaja."},
        ]
      },
      {
        rows: [
          {text: "¿Estás bien?"},
          {text: "¿Quieres uno?"},
        ]
      },
      {
        rows: [
          {text: "¿Ella está embarazada?"},
          {text: "¿Ella sabe?"},
        ]
      },
      {
        rows: [
          {text: "¿Estamos equivocados(as)?"},
          {text: "¿Necesitamos esto?"},
        ]
      },
      {
        rows: [
          {text: "¿Estoy loco(a)?"},
          {text: "¿Yo digo eso?"},
        ]
      },
      {
        rows: [
          {text: "¿Ellos están asustados?"},
          {text: "¿Ellos estudian?"},
        ]
      },
      {
        rows: [
          {text: "¿Él es tan buenmozo?"},
          {text: "¿Él paga tanto?"},
        ]
      },
      {
        rows: [
          {text: "¿No estás preocupado?"},
          {text: "¿No te gusta?"},
        ]
      },
      {
        rows: [
          {text: "¿Él no es tu profesor?"},
          {text: "¿Él no maneja?"},
        ]
      },
      {
        rows: [
          {text: "¿Acaso no somos felices?"},
          {text: "¿Acaso no estudiamos juntos?"},
        ]
      },
      {
        rows: [
          {text: "¿Acaso no estoy contigo?"},
          {text: "¿Acaso no te ayudo?"},
        ]
      },
      {
        rows: [
          {text: "¿Ella no es tu amiga?"},
          {text: "¿Ella no viene a clase?"},
        ]
      },
      {
        rows: [
          {text: "¿Ellos no están en casa?"},
          {text: "¿Ellos no se acuerdan?"},
        ]
      },
    ]
  },
]
