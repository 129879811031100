export const to_be_past_interrogative_negative: any = [
  {
    type: 'card',
    title: "Pasado interrogativo negativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>Wasn't I?</b>",
            audio: "unit3-586.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estaba / era?</i>",
            audio: "none",
          },
          {
            text: "<b>Weren't you?</b>",
            audio: "unit3-587.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estabas / eras?</i>",
            audio: "none",
          },
          {
            text: "<b>Wasn't he?</b>",
            audio: "unit3-588.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Él no estaba / era?</i>",
            audio: "none",
          },
          {
            text: "<b>Wasn't she?</b>",
            audio: "unit3-589.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ella no estaba / era?</i>",
            audio: "none",
          },
          {
            text: "<b>Wasn't it?</b>",
            audio: "unit3-590.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estaba / era?</i>",
            audio: "none",
          },
          {
            text: "<b>Weren't we?</b>",
            audio: "unit3-591.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estábamos / éramos?</i>",
            audio: "none",
          },
          {
            text: "<b>Weren't they?</b>",
            audio: "unit3-592.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ellos no estaban / eran?</i>",
            audio: "none",
          },
        ]
      },
    ]
  }
]
