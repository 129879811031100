import { Component } from '@angular/core';

@Component({
  selector: 'app-about-page',
  templateUrl: 'about.page.html',
  styleUrls: ['about.page.scss']
})
export class AboutPage {

  constructor() {}

}
