export const complements_vocab_2: any = [
  {
    type: 'card',
    title: 'Verbos adicionales',
    items: [
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To help me",
                  audio: "unit1-10.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "ayudarme"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To talk to me",
                  audio: "unit1-11.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "hablar conmigo"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To believe you",
                  audio: "unit1-12.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "creerte"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To love you",
                  audio: "unit1-13.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "amarte"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To see him",
                  audio: "unit1-14.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "verlo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To call him",
                  audio: "unit1-15.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "llamarlo (a él)"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To hear her",
                  audio: "unit1-16.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "oírla"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To tell her",
                  audio: "unit1-17.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "decirle (a ella)"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To ask us",
                  audio: "unit1-18.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "preguntarnos"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To answer us",
                  audio: "unit1-19.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "contestarnos"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To lend them",
                  audio: "unit1-20.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "prestarles"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To give them",
                  audio: "unit1-21.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "darles"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>a ride</i>",
                  audio: "unit1-22.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el aventón</i>"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To bring",
                  audio: "unit1-26.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "traer"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To text",
                  audio: "unit1-23.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "mandar un SMS"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To think",
                  audio: "unit1-24.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "pensar"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>about you</i>",
                  audio: "unit1-25.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>en ti</i>"
                }
              ]
            },
          }
        ]
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To wait",
                  audio: "unit1-27.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "esperar"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>for her</i>",
                  audio: "unit1-28.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>por ella</i>"
                }
              ]
            },
          }
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Precisiones sobre los verbos',
    items: [
      {
        type: 'p_link',
        subtitle:"<i>To <b>tell.</b></i>",
        tokens: [
          {
            text: "Siempre debe ir <b>seguido</b> de un <b>complemento personal</b>. Si <b>no</b> hay tal complemento personal, debe usarse '<b>say</b>'."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Qué dices?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What do you say?",
            audio:"none"
          },
          {
            text: "¿Qué <b>me</b> dices?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What do you <b>tell me</b>?",
            audio:"none"
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "No digo nada.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't say anything.",
            audio:"none"
          },
          {
            text: "No se lo digo <b>a ella</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't <b>tell her</b>.",
            audio:"none"
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "Él lo dice todo en inglés.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He says everything in English",
            audio:"none"
          },
          {
            text: "Él <b>nos</b> lo cuenta todo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>tells us</b> everything.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"<i>To <b>give</b>. To <b>lend</b>.</i>",
        tokens: [
          {
            text: 'Igual que en español, el  complemento  personal precede al otro complemento. '
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él me lo da todo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He gives <b>me</b> everything.",
            audio:"none"
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "Yo le presto mi libro (a él)",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I lend <b>him</b> my book.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"<i>To <b>talk</b>.</i>",
        tokens: [
          {
            text: 'Hablar en general.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él habla demasiado.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He talks too much.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"<i>To <b>speak</b>.</i>",
        tokens: [
          {
            text: 'Hablar un idioma.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él habla inglés.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He speaks English.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"<i>To talk <b>to</b>. To wait <b>for</b>. To think <b>about</b></i>",
        tokens: [
          {
            text: 'Nótense las respectivas  preposiciones  que deben usarse si hay un complemento personal.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Hablas con él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you talk <b>to</b> him?",
            audio:"none"
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "Él nos espera.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He waits <b>for</b> us",
            audio:"none"
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "¿No piensas en mí?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't you think <b>about</b> me?",
            audio:"none"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '16a',
    items: [
      {
        rows: [
          {
            text: "Él  me ayuda.",
          },
          {
            text: "Él nos ayuda.",
          },
          {
            text: "Él la ayuda (a ella).",
          }
        ]
      },
      {
        rows: [
          {
            text: "Ella no le pregunta a él.",
          },
          {
            text: "Ella no te pregunta.",
          },
          {
            text: "Ella no les pregunta (a ellos).",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Qué te dice ella?",
          },
          {
            text: "¿Qué le dice ella (a él)?",
          },
          {
            text: "¿Qué les dice ella (a ellos)?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Por qué él no me cree?",
          },
          {
            text: "¿Por qué él no le cree (a ella)?",
          },
          {
            text: "¿Por qué él no nos cree?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Quién le da el aventón (a él)?",
          },
          {
            text: "¿Quién te da el aventón?",
          },
          {
            text: "¿Quién les da el aventón (a ellos)?",
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Qué me traes?",
          },
          {
            text: "¿Qué les traes?",
          },
          {
            text: "¿Qué nos traes?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Por qué no le mandas un SMS a él?",
          },
          {
            text: "¿Por qué no le mandas un SMS a ella?",
          },
          {
            text: "¿Por qué no les mandas un SMS a ellos?",
          }
        ]
      },
      {
        rows: [
          {
            text: "Ella empieza antes que yo.",
          },
          {
            text: "Ella empieza antes que él.",
          },
          {
            text: "Ella empieza antes que nosotros.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Él no habla contigo.",
          },
          {
            text: "Él no habla con ella.",
          },
          {
            text: "Él no habla con ellos.",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Ella no tiene clase después de él?",
          },
          {
            text: "¿Ella no tiene clase después de ellos?",
          },
          {
            text: "¿Ella no tiene clase después de mí?",
          }
        ]
      },
      {
        rows: [
          {
            text: "Él no trabaja para mí.",
          },
          {
            text: "Él no trabaja para ella.",
          },
          {
            text: "Él no trabaja para ellos.",
          },
          {
            text: "Él no trabaja para ti.",
          },
          {
            text: "Él no trabaja para él.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Lo uso. - Los uso.",
          },
          {
            text: "Lo quiero. - Los quiero.",
          },
          {
            text: "Lo tengo. - Los tengo.",
          },
          {
            text: "Lo necesito. - Los necesito.",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Qué dice él?",
          },
          {
            text: "¿Qué te dice él?",
          },
        ]
      },
      {
        rows: [
          {
            text: "No digo nada.",
          },
          {
            text: "No le digo nada a ella.",
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Cómo lo digo?",
          },
          {
            text: "¿Cómo se lo digo (a él)?",
          },
        ]
      },
      {
        rows: [
          {
            text: "Ella lo dice todo en inglés.",
          },
          {
            text: "Ella me lo cuenta todo.",
          },
        ]
      },
      {
        rows: [
          {
          text: "¿Por qué no lo dices?",
          },
          {
            text: "¿Por qué no nos lo dices?",
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '16b',
    items: [
      {
        rows: [
          {
            text: "You ask; I answer",
            audio: "unit1-29.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How does she feel?",
            audio: "unit1-30.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't go to bed so late",
            audio: "unit1-31.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why doesn't his wife wait for him?",
            audio: "unit1-32.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Ready?",
            audio: "unit1-35.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You're welcome",
            audio: "unit1-34.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I mean",
            audio: "unit1-608.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Excuse me",
            audio: "unit1-582.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Wait",
            audio: "unit1-594.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How much do they pay?",
            audio: "unit1-38.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Who gives you a ride?",
            audio: "unit1-39.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't they lend you the money?",
            audio: "unit1-40.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She doesn't get home early",
            audio: "unit1-41.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Thirteen",
            audio: "unit1-711.mp3"
          },
          {
            text: "Thirty",
            audio: "unit1-42.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Fifteen",
            audio: "unit1-712.mp3"
          },
          {
            text: "Fifty",
            audio: "unit1-43.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Seventeen",
            audio: "unit1-713.mp3"
          },
          {
            text: "Seventy",
            audio: "unit1-44.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Nineteen",
            audio: "unit1-714.mp3"
          },
          {
            text: "Ninety",
            audio: "unit1-45.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Nobody knows us",
            audio: "unit1-715.mp3"
          },
          {
            text: "We don't know anybody",
            audio: "unit1-46.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When do we have class?",
            audio: "unit1-47.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Everybody does it",
            audio: "unit1-48.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't we sell the car?",
            audio: "unit1-49.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "See you on Thursday",
            audio: "unit1-572.mp3"
          },
          {
            text: "See you on Wednesday",
            audio: "unit1-571.mp3"
          },
          {
            text: "See you on Tuesday",
            audio: "unit1-570.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He works; she studies",
            audio: "unit1-53.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do I need the book?",
            audio: "unit1-54.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Her husband doesn't speak English",
            audio: "unit1-485.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't I help you?",
            audio: "unit1-56.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Come on, let's go",
            audio: "unit1-578.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Take care",
            audio: "unit1-568.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Hurry up",
            audio: "unit1-595.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I think so",
            audio: "unit1-604.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You pay too much",
            audio: "unit1-61.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "My friend has class in the morning",
            audio: "unit1-62.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You don't understand me",
            audio: "unit1-63.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doesn't Alan drink?",
            audio: "unit1-64.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Pleased to meet you",
            audio: "unit1-561.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Here you are",
            audio: "unit1-592.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm sorry",
            audio: "unit1-591.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Go ahead",
            audio: "unit1-609.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What do you tell me?",
            audio: "unit1-716.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What does the teacher say?",
            audio: "unit1-69.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He gets here before me",
            audio: "unit1-70.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't you think about that?",
            audio: "unit1-71.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why doesn't anybody speak?",
            audio: "unit1-72.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Hey",
            audio: "unit1-606.mp3"
          },
          {
            text: "How do you say?",
            audio: "unit1-73.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Have a nice day",
            audio: "unit1-579.mp3"
          },
          {
            text: "You too",
            audio: "unit1-580.mp3"
          },
        ]
      },
    ]
  }
]
