export const was_were_supposed_to: any = [
  {
    type: 'card',
    title:"Was/were supposed to",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'La misma diferencia de matiz que existe en español entre "Tuve que ir" (y fui) y "Yo tenia que ir" (pero no fui) existe en inglés.'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: '<b>Tenía que</b> se expresa mediante <b>was</b> / <b>were supposed to</b>.'
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>You were supposed to &#8230;</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Tú tenías que &#8230;",
            audio:"none"
          },
          {
            text: "<b>She was supposed to &#8230;</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Ella tenía que &#8230;",
            audio:"none"
          },
                    {
            text: "<b>They were supposed to &#8230;</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Ellos tenían que &#8230;",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Tú tenías que traerlo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You were supposed to bring it</b>",
            audio:"unit4-387.mp3"
          },
          {
            text: "Él tenía que ayudarme.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He was supposed to help me</b>",
            audio:"unit4-388.mp3"
          },
          {
            text: "Teníamos que llamarla.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>We were supposed to call her</b>",
            audio:"unit4-389.mp3"
          },
          {
            text: "Tenía que empezar a las 2.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It was supposed to start at 2</b>",
            audio:"unit4-390.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '36a',
    items: [
      {
        rows: [
          {
            text: "I was supposed to do it",
            audio: "unit4-391.mp3"
          },
          {
            text: "You were supposed to do it",
            audio: "unit4-392.mp3"
          },
          {
            text: "She was supposed to do it",
            audio: "unit4-393.mp3"
          },
          {
            text: "We were supposed to do it",
            audio: "unit4-394.mp3"
          },
          {
            text: "Who was supposed to do it?",
            audio: "unit4-395.mp3"
          },
          {
            text: "They were supposed to do it",
            audio: "unit4-396.mp3"
          },
          {
            text: "He was supposed to do it",
            audio: "unit4-397.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '36b',
    items: [
      {
        rows: [
          {text:'Yo tenía que ir al banco.'},
          {text:'Yo no sabía que yo tenía que ir al banco.'},
          {text:'Tuve que ir al banco.'},
        ]
      },
      {
        rows: [
          {text:'Teníamos que jugar el sábado.'},
          {text:'Yo no sabía que teníamos que jugar el sábado.'},
          {text:'Tuvimos que jugar el sábado.'},
        ]
      },
      {
        rows: [
          {text:'Él tenía que comprar el libro.'},
          {text:'Él no sabía que él tenía que comprar el libro.'},
          {text:'Él tuvo que comprar el libro.'},
        ]
      },
      {
        rows: [
          {text:'Tú tenías que hacerlo.'},
          {text:'Yo pensé que tú tenías que hacerlo.'},
          {text:'Tú tuviste que hacerlo.'},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Por supuesto, si en español "tenía" no va seguido de "que" no se puede usar <b>was</b> / <b>were supposed to</b>.'
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo creía que yo tenía uno.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I thought I had one</b>",
            audio:"unit4-70.mp3"
          },
          {
            text: "Yo creía que yo tenía que ir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I thought I was supposed to go</b>",
            audio:"unit4-71.mp3"
          },
          {
            text: "Te dije que no teníamos clase.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I told you we didn't have class</b>",
            audio:"unit4-72.mp3"
          }
        ]
      },
    ]
  }
]
