export const structures_21: any = [
  {
    type: 'structures',
    id: '21',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I wanted you to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo quería que tú &#8230;aras/ieras",
          },
          {
            text:"<b>I didn’t want him to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo no quería que él &#8230;ara/iera",
          },
          {
            text:"<b>Did you want me to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Querías que yo &#8230;ara/iera?",
          },
          {
            text:"<b>Didn't you want us to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿No querías que nosotros &#8230;áramos/iéramos?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '34',
    items: [
      {
        rows: [
          {text:'Yo quería que tú estudiaras.'},
          {text:'Yo quería que tú te quedaras aquí '},
          {text:'Yo quería que tú ganaras.'},
        ]
      },
      {
        rows: [
          {text:'Yo quería que él viniera.'},
          {text:'Yo quería que él me ayudara.'},
          {text:'Yo quería que él leyera la historia.'},
        ]
      },
      {
        rows: [
          {text:'Yo no quería que ella oyera.'},
          {text:'Yo no quería que ella vendiera la casa.'},
          {text:'Yo no quería que ella manejara.'},
        ]
      },
      {
        rows: [
          {text:'Yo no quería que ellos trabajaran.'},
          {text:'Yo no quería que ellos se olvidaran. '},
          {text:'Yo no quería que ellos trajeran nada.'},
        ]
      },
      {
        rows: [
          {text:'¿Qué querías que yo hiciera?'},
          {text:'¿Qué querías que yo dijera?'},
          {text:'¿Qué querías que yo comprara?'},
        ]
      },
      {
        rows: [
          {text:'¿Por qué no querías que nosotros viéramos TV?'},
          {text:'¿Por qué no querías que nosotros jugáramos?'},
          {text:'¿Por qué no querías que nosotros presentáramos el examen?'},
        ]
      }
    ]
  }
]
