export const conjunctions: any = [
  {
    type: 'card',
    title: 'Conjunciones',
    items: [
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"because",
                  audio:"unit1-217.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"porque",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"if",
                  audio:"unit1-218.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"si",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"what",
                  audio:"unit1-219.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"lo que",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"so",
                  audio:"unit1-220.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"así que / entonces",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"everything",
                  audio:"unit1-417.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"todo lo que",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"and",
                  audio:"unit1-222.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"si",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"but",
                  audio:"unit1-223.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"pero",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"or",
                  audio:"unit1-224.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"o",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Las oraciones compuestas no son más que dos o más frases unidas por conectores llamados conjunciones. A diferencia del español, <b>en inglés no puede omitirse el pronombre correspondiente a cada verbo</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Ella hace lo que quiere.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She does <b><i>what</i> she</b> wants",
            audio: "unit1-225.mp3",
            type: 'link'
          },
          {
            text: "No sé así que pregunto.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't know <b><i>so</i> I</b> ask",
            audio: "unit1-226.mp3",
            type: 'link'
          },
          {
            text: "Vengo porque tengo clase.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I come <b>because</b> I have class",
            audio: "unit1-227.mp3",
            type: 'link'
          },
          {
            text: "Si estudias, aprendes.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b><i>If</i> you</b> study, <b>you</b> learn",
            audio: "unit1-228.mp3",
            type: 'link'
          },
          {
            text: "Él sabe pero se le olvida.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He knows <b><i>but</i> he</b> forgets",
            audio: "unit1-229.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Únicamente con <b>and, but</b> y <b>or</b> se puede, si se quiere, omitir el segundo pronombre si éste es idéntico al primero."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él trabaja y estudia.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He</b> works and (he) studies.",
            audio: "none"
          },
          {
            text: "Él trabaja y ella estudia.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He</b> works and <b>she</b> studies",
            audio: "none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Todos los interrogativos pueden usarse como conjunciones. En este caso, <b>no hay forma interrogativa</b> después de la conjunción."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Contesto cuando sé.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I answer when I know.",
            audio: "none"
          },
          {
            text: "No sé dónde vive ella.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't know where she lives.",
            audio: "none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Siguiendo la regla anterior, la segunda frase de las preguntas que empiecen con <b>Do you know</b> nunca están en interrogativo."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Dónde trabaja él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Where does he work?",
            audio: "none"
          },
          {
            text: "¿Sabes dónde trabaja él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <b>where he</b> works?",
            audio: "none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Cuánto paga ella?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "How much does she pay?",
            audio: "none"
          },
          {
            text: "¿Sabes cuánto paga ella?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <b>how much she</b> pays?",
            audio: "none"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '21a',
    items: [
      {
        rows: [
          {
            text: "Where do you live?",
            audio: "unit1-315.mp3"
          },
          {
            text: "Do you know where I live?",
            audio: "unit1-231.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Where does he work?",
            audio: "unit1-232.mp3"
          },
          {
            text: "Do you know where he works?",
            audio: "unit1-233.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "What do you need?",
            audio: "unit1-234.mp3"
          },
          {
            text: "Do you know what you need?",
            audio: "unit1-235.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "What does she want?",
            audio: "unit1-236.mp3"
          },
          {
            text: "Do you know what she wants?",
            audio: "unit1-237.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How do you know?",
            audio: "unit1-238.mp3"
          },
          {
            text: "Do you know how I know?",
            audio: "unit1-239.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How does he get there?",
            audio: "unit1-240.mp3"
          },
          {
            text: "Do you know how he gets there?",
            audio: "unit1-241.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '21b',
    items: [
      {rows: [
        {text:"¿Dónde vives?"},
        {text:"¿Sabes dónde vivo?"},
      ]},
      {rows: [
        {text:"¿Dónde trabaja él?"},
        {text:"¿Sabes dónde trabaja (él)?"},
      ]},
      {rows: [
        {text:"¿Qué necesitas?"},
        {text:"¿Sabes qué necesitas?"},
      ]},
      {rows: [
        {text:"¿Qué quiere ella?"},
        {text:"¿Sabes qué quiere (ella)?"},
      ]},
      {rows: [
        {text:"¿Cómo lo sabes?"},
        {text:"¿Sabes cómo lo sé?"},
      ]},
      {rows: [
        {text:"¿Cómo llega él aquí?"},
        {text:"¿Sabes cómo él llega aquí?"},
      ]},
      {rows: [
        {text:"¿Por qué no te gusta?"},
        {text:"¿Sabes por qué no me gusta?"},
      ]},
      {rows: [
        {text:"¿Por qué el esposo no bebe?"},
        {text:"¿Sabes por qué el esposo no bebe?"},
      ]},
      {rows: [
        {text:"¿Cuándo tienes clase?"},
        {text:"¿Sabes cuándo tienes clase?"},
      ]},
      {rows: [
        {text:"¿Cuándo me llama ella?"},
        {text:"¿Sabes cuándo me llama (ella)?"},
      ]},
      {rows: [
        {text:"¿Por qué preguntas?"},
        {text:"¿Sabes por qué pregunto?"},
      ]},
      {rows: [
        {text:"¿Por qué ella come tanto?"},
        {text:"¿Sabes por qué ella come tanto?"},
      ]},
      {rows: [
        {text:"¿Cuántas horas practicas?"},
        {text:"¿Sabes cuántas horas practico?"},
      ]},
      {rows: [
        {text:"¿Cuántas horas estudia ella?"},
        {text:"¿Sabes cuántas horas estudia ella?"},
      ]},
      {rows: [
        {text:"¿Por qué no entiendes?"},
        {text:"¿Sabes por qué no entiendes?"},
      ]},
      {rows: [
        {text:"¿Por qué él no juega?"},
        {text:"¿Sabes por qué él no juega?"},
      ]}
    ]
  },
  {
    type:'english',
    id: '22',
    items: [
      {
        rows: [
          {
            text: "He plays well because he practices all the time",
            audio: "unit1-242.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "If I go to bed early I get up early",
            audio: "unit1-243.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I have her phone number but I don't have her address",
            audio: "unit1-244.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't they pay if they don't come to class?",
            audio: "unit1-245.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You know very well what he wants: her money",
            audio: "unit1-246.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When they don't understand they ask the teacher",
            audio: "unit1-247.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How much do you need?",
            audio: "unit1-721.mp3"
          },
          {
            text: "Do you know how much you need?",
            audio: "unit1-248.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We understand better when you give us an example",
            audio: "unit1-249.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She has another cellphone but she never uses it",
            audio: "unit1-250.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't remember him, I remember his friend",
            audio: "unit1-251.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "In the afternoon they study or watch TV",
            audio: "unit1-252.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Where does Alan work?",
            audio: "unit1-722.mp3"
          },
          {
            text: "Do you know where Alan works?",
            audio: "unit1-253.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We don't live in a house, we live in an apartment",
            audio: "unit1-254.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you ask Ana if you don't believe me?",
            audio: "unit1-255.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She doesn't buy it because she doesn't have money",
            audio: "unit1-256.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do you understand everything they say?",
            audio: "unit1-257.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "If she finishes before her boyfriend she waits for him",
            audio: "unit1-258.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She works and studies",
            audio: "unit1-723.mp3"
          },
          {
            text: "He works and she studies",
            audio: "unit1-259.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He starts at 7 but I start at 7 thirty",
            audio: "unit1-260.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you tell me what you want?",
            audio: "unit1-261.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I still have it but I don't use it anymore",
            audio: "unit1-262.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You forget something: his girlfriend doesn't speak Spanish",
            audio: "unit1-263.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do you know how I feel when you say that?",
            audio: "unit1-264.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "If you want, I have those books at home",
            audio: "unit1-265.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I mean, you don't learn because you don't study",
            audio: "unit1-266.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "If they don't come to class the teacher calls their parents",
            audio: "unit1-267.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When I start something I finish it",
            audio: "unit1-268.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What time does he get here?",
            audio: "unit1-710.mp3"
          },
          {
            text: "Do you know what time he gets here?",
            audio: "unit1-269.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I understand some words, I don't understand everything",
            audio: "unit1-270.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She always comes because she has an aunt here",
            audio: "unit1-271.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He doesn't talk, he just drives",
            audio: "unit1-756.mp3"
          },
        ]
      },
    ]
  },
]
