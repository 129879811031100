export const past_can: any = [
  {
    type: 'card',
    title:"En pasado de CAN",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Igual que en español, hay dos maneras de expresar algo que fue (o no fue) posible en el pasado: <i>podía</i> o <i>pude</u>.'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Así como en español nunca hay confusión para usar estos tiempos verbales, tampoco debe haberla en inglés si se tiene presente que'
          }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"pude &#8594;",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>was/were able to</b>",
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"podía &#8594;",
                }
              ]
            },
            right: {
              tokens: [
                {
                  type:"link",
                  text:"<b>could</b>",
                  audio:"unit4-520.mp3"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'La excepción es'
          }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"No pude &#8230; &#8594;",
                }
              ]
            },
            right: {
              tokens: [
                {
                  type:"link",
                  text:"<b>I couldn't<b>",
                  audio:"unit4-521.mp3"
                },
                {
                  text: "&#8230;"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "aunque también puede usarse: <b>I wasn't able to &#8230;</b>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No pude ir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I couldn't go</b>",
            audio:"unit4-48.mp3"
          },
          {
            text: "Te dije que no podías ir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I told you you couldn't go</b>",
            audio:"unit4-49.mp3"
          },
          {
            text: "¿Pudiste ir?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Were you able to go?</b>",
            audio:"unit4-50.mp3"
          },
          {
            text: "Pensé que (yo) podía ir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I thought I could go</b>",
            audio:"unit4-51.mp3"
          },
          {
            text: "Pude ir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I was able to go</b>",
            audio:"unit4-52.mp3"
          },

        ]
      },
    ]
  },

  {
    type:'english',
    id: '40',
    items: [
      {
        rows: [
          {
            text: "I couldn't sleep",
            audio: "unit4-53.mp3"
          },
          {
            text: "He couldn't study",
            audio: "unit4-54.mp3"
          },
          {
            text: "We couldn't start the course",
            audio: "unit4-55.mp3"
          },
          {
            text: "She couldn't go on",
            audio: "unit4-56.mp3"
          },
          {
            text: "They couldn't go to the bank",
            audio: "unit4-57.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Were you able to talk to her?",
            audio: "unit4-58.mp3"
          },
          {
            text: "Were you able to play?",
            audio: "unit4-59.mp3"
          },
          {
            text: "Were you able to study?",
            audio: "unit4-60.mp3"
          },
          {
            text: "Were you able to see her?",
            audio: "unit4-61.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I thought I could do it",
            audio: "unit4-62.mp3"
          },
          {
            text: "I was able to do it",
            audio: "unit4-63.mp3"
          },
          {
            text: "I thought we could go to work",
            audio: "unit4-64.mp3"
          },
          {
            text: "We were able to go to work",
            audio: "unit4-65.mp3"
          },
          {
            text: "I thought he could pay",
            audio: "unit4-66.mp3"
          },
          {
            text: "He was able to pay",
            audio: "unit4-67.mp3"
          },
          {
            text: "I thought they could practice",
            audio: "unit4-68.mp3"
          },
          {
            text: "They were able to practice",
            audio: "unit4-69.mp3"
          },
        ]
      },
    ]
  }
]
