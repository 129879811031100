export const structures_22: any = [
  {
    type: 'structures',
    id: '22',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I had to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Tuve que &#8230;ar/er/ir",
          },
          {
            text:"<b>You were supposed to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Tú tenías que &#8230;ar/er/ir",
          },
          {
            text:"<b>She was supposed to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Ella tenía que &#8230;ar/er/ir",
          },
          {
            text:"<b>I didn't have to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No tuve que &#8230;ar/er/ir",
          },
          {
            text:"<b>Did you have to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tuviste que &#8230;ar/er/ir?",
          },
          {
            text:"<b>Didn't you have to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿No tuviste que &#8230;ar/er/ir?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '37',
    items: [
      {
        rows: [
          {text:'Tuve que hacerlo.'},
          {text:'Yo tenía que hacerlo.'},
          {text:'Tú tenías que hacerlo.'},
          {text:'No tuve que hacerlo.'},
          {text:'¿Tuviste que hacerlo?'},
          {text:'¿No tuviste que hacerlo?'},
        ]
      },
      {
        rows: [
          {text:'Ella tuvo que irse hoy.'},
          {text:'Ella tenía que irse hoy.'},
          {text:'Ellos tenían que irse hoy.'},
          {text:'Ella no tuvo que irse hoy.'},
          {text:'¿Ella tuvo que irse hoy?'},
          {text:'¿Ella no tuvo que irse hoy?'},
        ]
      },
      {
        rows: [
          {text:'Tuvimos que pagar más.'},
          {text:'No sabíamos que teníamos que pagar más.'},
          {text:'Yo no sabía que yo tenía que pagar más.'},
          {text:'No tuvimos que pagar más.'},
          {text:'¿Por qué tuvimos que pagar más?'},
          {text:'¿No tuvimos que pagar más?'},
        ]
      },
      {
        rows: [
          {text:'Ellos tuvieron que repetir el examen.'},
          {text:'Yo pensé que ellos tenían que repetir el examen.'},
          {text:'Yo pensé que yo tenía que repetir el examen.'},
          {text:'¿Ellos tuvieron que repetir el examen?'},
          {text:'¿Ellos no tuvieron que repetir el examen?'},
        ]
      },
    ]
  }
]
