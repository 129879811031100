export const wanted_to: any = [
  {
    type: 'card',
    title:"Wanted to",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Igual que en español, sólo las distintas formas de <b>want</b> se ponen en el <b>pasado</b>. El segundo verbo no cambia.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo quería esperar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I wanted to wait</b>",
            audio:"unit4-467.mp3"
          },
          {
            text: "Él no quería jugar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He didn't want to play</b>",
            audio:"unit4-468.mp3"
          },
          {
            text: "¿Querías ir?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Did you want to go?</b>",
            audio:"unit4-469.mp3"
          },
          {
            text: "¿No querías hacerlo?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Didn't you want to do it?</b>",
            audio:"unit4-470.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Sólo en el negativo hay un giro especial que indica que la acción no se llevó a cabo de ninguna manera:'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él no quiso jugar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He refused to play</b>",
            audio:"unit4-471.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '30',
    items: [
      {
        rows: [
          {
            text: "I wanted to know",
            audio: "unit4-472.mp3"
          },
          {
            text: "I wanted to do it",
            audio: "unit4-473.mp3"
          },
          {
            text: "I wanted to tell her",
            audio: "unit4-474.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He didn't want to answer",
            audio: "unit4-475.mp3"
          },
          {
            text: "He didn't want to help me",
            audio: "unit4-476.mp3"
          },
          {
            text: "He didn't want to use my book",
            audio: "unit4-477.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He refused to answer",
            audio: "unit4-478.mp3"
          },
          {
            text: "He refused to help me",
            audio: "unit4-479.mp3"
          },
          {
            text: "He refused to use my book",
            audio: "unit4-480.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Did you want to say something?",
            audio: "unit4-481.mp3"
          },
          {
            text: "What did she want to study?",
            audio: "unit4-482.mp3"
          },
          {
            text: "Where did they want to go?",
            audio: "unit4-483.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why didn't you want to try?",
            audio: "unit4-484.mp3"
          },
          {
            text: "Why didn't we want to take the test?",
            audio: "unit4-485.mp3"
          },
          {
            text: "Why didn't he want to wait?",
            audio: "unit4-486.mp3"
          },
        ]
      },
    ]
  },
]
