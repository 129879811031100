export const interrogative: any = [
  {
    type: 'card',
    title: 'Interrogativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se antepone "
          },
          {
            type: "link",
            text: "<b>do</b>",
            audio: "unit1-174.mp3"
          },
          {
            text: " al pronombre."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Trabajas?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Do</b> you  work?",
            audio: "unit1-183.mp3",
            type: 'link'
          },
          {
            text: "¿Queremos <i>el libro</i>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Do</b> we  want  <i>the book</i>?",
            audio: "unit1-176.mp3",
            type: 'link'
          },
          {
            text: "¿Ellos saben <i>eso</i>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Do</b> they  know  <i>that</i>?",
            audio: "unit1-177.mp3",
            type: 'link'
          },
          {
            text: "¿Yo <i>lo</i> necesito?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Do</b> I  need  <i>it</i>?",
            audio: "unit1-178.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '3a',
    items: [
      {
        rows: [
          {
            text: "Do you start at 9?",
            audio: "unit1-179.mp3"
          },
          {
            text: "Do we start at 9?",
            audio: "unit1-180.mp3"
          },
          {
            text: "Do they start at 9?",
            audio: "unit1-181.mp3"
          },
          {
            text: "Do I start at 9?",
            audio: "unit1-182.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Do you work?",
            audio: "unit1-183.mp3"
          },
          {
            text: "Do we work?",
            audio: "unit1-184.mp3"
          },
          {
            text: "Do they work?",
            audio: "unit1-185.mp3"
          },
          {
            text: "Do I work?",
            audio: "unit1-186.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '3b',
    items: [
      {
        rows: [
          {
            text:'¿Empiezas a las 9?'
          },
          {
            text: "¿Empezamos a las 9?"
          },
          {
            text: "¿Ellos empiezan a las 9?"
          },
          {
            text: "¿Yo empiezo a las 9?"
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Trabajas?",
          },
          {
            text: "¿Acaso trabajamos?",
          },
          {
            text: "¿Ellos trabajan?",
          },
          {
            text: "¿Acaso trabajo?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Tienes clase?",
          },
          {
            text: "¿Tenemos clase?",
          },
          {
            text: "¿Ellos tienen clase?",
          },
          {
            text: "¿Tengo clase?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Juegas bien?",
          },
          {
            text: "¿Jugamos bien?",
          },
          {
            text: "¿Ellos juegan bien?.",
          },
          {
            text: "¿Yo juego bien?",
          }
        ]
      },
    ]
  }
]
