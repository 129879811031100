export const structures_4: any = [
  {
    type: 'structures',
    id: '4',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>What do you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Qué &#8230; tú?",
          },
          {
            text: "<b>What does she &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Qué &#8230; ella?",
          },
          {
            text: "<b>What does your friend &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Qué &#8230; tu amiga?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Where do you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Dónde &#8230; tú?",
          },
          {
            text: "<b>Where does he &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Dónde &#8230; él?",
          },
          {
            text: "<b>Where does her husband &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Dónde &#8230; el esposo?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>How do you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Cómo &#8230; tú?",
          },
          {
            text: "<b>How does she &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Cómo &#8230; ella?",
          },
          {
            text: "<b>How does Ana &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Cómo &#8230; Ana?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Why don't you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Por qué tú no &#8230;?",
          },
          {
            text: "<b>Why doesn't he &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Por qué él no &#8230;?",
          },
          {
            text: "<b>Why doesn't your dad &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Por qué tu papá no &#8230;?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '13',
    items: [
      {
        rows: [
          {text: "¿Qué quieres tú?"},
          {text: "¿Qué quiere él?"},
          {text: "¿Qué quiere tu amigo?"}
        ]
      },
      {
        rows: [
          {text: "¿Dónde vives?"},
          {text: "¿Dónde vive ella?"},
          {text: "¿Dónde vive tu amiga?"}
        ]
      },
      {
        rows: [
          {text: "¿Cómo te sientes?"},
          {text: "¿Cómo se siente ella?"},
          {text: "¿Cómo se siente la esposa?"}
        ]
      },
      {
        rows: [
          {text: "¿Cuándo practicas?"},
          {text: "¿Cuándo practica él?"},
          {text: "¿Cuándo practica el esposo?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué dices eso?"},
          {text: "¿Por qué él dice eso?"},
          {text: "¿Por qué el profesor dice eso?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué no ves TV?"},
          {text: "¿Por qué ella no ve TV?"},
          {text: "¿Por qué Ana no ve TV?"},
        ]
      },
      {
        rows: [
          {text: "¿A qué hora te acuestas?"},
          {text: "¿A qué hora se acuesta ella?"},
          {text: "¿A qué hora se acuesta tu mamá?"},
        ]
      },
      {
        rows: [
          {text: "¿Hasta qué hora trabajas?"},
          {text: "¿Hasta qué hora trabaja él?"},
          {text: "¿Hasta qué hora trabaja tu papá?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuántas horas duermes?"},
          {text: "¿Cuántas horas duerme ella?"},
          {text: "¿Cuántas horas duerme Ana?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué no estudias?"},
          {text: "¿Por él no estudia?"},
          {text: "¿Por qué Alan no estudia?"},
        ]
      },
    ]
  }
]
