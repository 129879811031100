export const structures_11: any = [
  {
    type: 'structures',
    id: '11',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I can</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Puedo &#8230;ar/er/ir",
          },
          {
            text:"<b>I can't</b> &#8230;",
          },
          {
            level:'secondary',
            text: "No puedo &#8230;ar/er/ir",
          },
          {
            text:"<b>I won't be able to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "No podré &#8230;ar/er/ir",
          },
          {
            text: "<b>Can  you</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Puedes &#8230;ar/er/ir?",
          },
          {
            text: "<b>Can I</b> &#8230;?",
          },
          {
            text: "<b>May I</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Puedo yo &#8230;ar/er/ir",
          },
          {
            text: "<b>Can't you</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No puedes &#8230;ar/er/ir",
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "En inglés formal,  <b>May I &#8230?</b>   se usa en lugar de <b>Can I &#8230?</b> para solicitar permiso."
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '44',
    items: [
      {
        rows: [
          {text: "Puedo preguntar."},
          {text: "No puedo recordar."},
          {text: "No podré venir."},
          {text: "¿Puedes ayudarme?"},
          {text: "¿Me permites verlo?"},
          {text: "¿No puedes esperar?"},
          {text: "¿No puedo jugar?"},
        ]
      },
      {
        rows: [
          {text: "Puedes preguntar."},
          {text: "Ellos no pueden ganar."},
          {text: "Él no podrá ir a trabajar."},
          {text: "¿Podemos empezar?"},
          {text: "¿Ella no puede estar con nosotros?"},
        ]
      },
      {
        rows: [
          {text: "Podemos irnos."},
          {text: "No podemos irnos."},
          {text: "¿Podemos irnos?"},
          {text: "¿Cuándo podemos irnos?"},
        ]
      },
      {
        rows: [
          {text: "Puedes ponerlo ahí."},
          {text: "No puedes ponerlo ahí."},
          {text: "¿Puedes ponerlo ahí?"},
          {text: "¿Puedo ponerlo ahí?"},
          {text: "¿No puedo ponerlo ahí?"},
        ]
      },
    ]
  }
]
