import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { actions } from 'src/app/store/user';
import { AppState } from '../../store/state';

export enum LoginComponentType {
  'login', 'signup', 'reset'
}

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const passwordConfirm = control.get('passwordConfirm');

  return (password.value === passwordConfirm.value) ? null : { passwordMismatch: true };
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() title = '';
  @Input() subTitle = '';
  @Input() action = 'Ingresa';
  @Input() socialAction = 'Ingresar con ';

  ngDestroyed$ = new Subject();
  form: FormGroup;
  serverMessage: string;
  loading: false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    const simpleValidators = {
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    };
    this.form = this.fb.group(simpleValidators);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
  }

  googleSignIn() {
    this.store.dispatch(actions.googleSignIn());
  }

  localSignIn(email: string, password: string) {
    this.store.dispatch(
      actions.localSignIn({ email, password }));
  }

  get emailControl() {
    return (this.form.get('email'));
  }

  get email() {
    return this.emailControl.value;
  }

  set email(newEmail) {
    this.form.patchValue({email: newEmail});
  }

  get passwordControl() {
    return (this.form.get('password'));
  }

  get password() {
    return this.passwordControl.value;
  }

  set password(newPassword) {
    this.form.patchValue({password: newPassword});
  }

  async onSubmit() {
      this.localSignIn(this.email, this.password);
  }

}
