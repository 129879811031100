import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File } from '@ionic-native/file/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Media } from '@ionic-native/media/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { firebaseConfig } from './app.config';
import { InitModule } from './init/init.module';
import { AboutPageModule } from './pages/about/about.module';
import { RecordingPageModule } from './pages/exercises/recording/recording.module';
import { HomePageModule } from './pages/home/home.module';
import { LearnPageModule } from './pages/learn/learn.module';
import { LoginPageModule } from './pages/login/login.module';
import { SignupPageModule } from './pages/signup/signup.module';
import { ContentProviderService } from './services/content-provider.service';
import { ExerciseProviderService } from './services/exercise-provider.service';
import { effects, metaReducers, reducers } from './store/state';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FontAwesomeModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    InitModule,
    AppRoutingModule,
    HomePageModule,
    LoginPageModule,
    LearnPageModule,
    SignupPageModule,
    AboutPageModule,
    RecordingPageModule,
  ],
  providers: [
    ContentProviderService,
    ExerciseProviderService,
    GooglePlus,
    File,
    TextToSpeech,
    AndroidPermissions,
    Media,
    NativeAudio,
    HTTP,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {

}
