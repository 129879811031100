export const modal: any = [
  {
    type: 'card',
    title: "Can",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>Can('t)</b> se usa para expresar una posibilidad. Tiene una construcción muy particular:"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: 'No llevan  <i>"s"</i> en la 3ª persona singular',
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>can</b> stay here",
            audio: "unit2-65.mp3",
            type: 'link'
          },
          {
            text: 'No admiten otros auxiliares',
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>can't</b> stay here",
            audio: "unit2-66.mp3",
            type: 'link'
          },
          {
            text: 'Nunca van seguidos de <i>"to"</i>.',
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '42a',
    items: [
      {
        rows: [
          {
            text: "I can try",
            audio: "unit2-67.mp3"
          },
          {
            text: "You can try",
            audio: "unit2-68.mp3"
          },
          {
            text: "We can try",
            audio: "unit2-69.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I can't help you",
            audio: "unit2-70.mp3"
          },
          {
            text: "He can't help you",
            audio: "unit2-71.mp3"
          },
          {
            text: "They can't help you",
            audio: "unit2-72.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Can I stay?",
            audio: "unit2-73.mp3"
          },
          {
            text: "Can you stay?",
            audio: "unit2-74.mp3"
          },
          {
            text: "Can my friend stay?",
            audio: "unit2-75.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Can't you wait?",
            audio: "unit2-76.mp3"
          },
          {
            text: "Can't she wait?",
            audio: "unit2-77.mp3"
          },
          {
            text: "Can't they wait?",
            audio: "unit2-78.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '42b',
    items: [
      {
        rows: [
          {text: "Puedo intentarlo."},
          {text: "Puedes intentarlo."},
          {text: "Podemos intentarlo."},
        ]
      },
      {
        rows: [
          {text: "No puedo ayudarte."},
          {text: "Él no puede ayudarte."},
          {text: "Ellos no pueden ayudarte."},
        ]
      },
      {
        rows: [
          {text: "¿Qué puedes hacer?"},
          {text: "¿Qué puede hacer él?"},
          {text: "¿Qué puedo hacer?"},
        ]
      },
      {
        rows: [

          {text: "¿Puedo quedarme?"},
          {text: "¿Puedes quedarte?"},
          {text: "¿Mi amiga puede quedarse?"},
        ]
      },
      {
        rows: [
          {text: "¿No puedes esperar?"},
          {text: "¿Ella no puede esperar?"},
          {text: "¿Ellos no pueden esperar?"},
        ]
      },
    ]
  },
]
