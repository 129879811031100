export const structures_13: any = [
  {
    type: 'structures',
    id: '13',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>&#8230;</b>",
          },
          {
            level:'secondary',
            text: "&#8230;",
          },
          {
            text:"<b>Don't</b> &#8230;",
          },
          {
            level:'secondary',
            text: "No &#8230;",
          },
          {
            text: "<b>Let's</b> &#8230;",
          },
          {
            level:'secondary',
            text: "&#8230;emos/amos.",
          },
          {
            text: "<b>Let me</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Déjame &#8230;ar/er/ir",
          },
          {
            text: "<b>Tell him to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Dile a él que &#8230;",
          },
          {
            text: "<b>Tell him not to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Dile a él que no &#8230;",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '48',
    items: [
      {
        rows: [
          {text: "Hazlo."},
          {text: "No lo hagas."},
          {text: "Hagámoslo."},
          {text: "Déjame hacerlo."},
          {text: "Dile a él que lo haga."},
          {text: "Dile a él que no lo haga."}
        ]
      },
      {
        rows: [
          {text: "Espera a Ana."},
          {text: "No esperes a Ana."},
          {text: "Esperemos a Ana."},
          {text: "Déjame esperar a Ana."},
          {text: "Dile a Ana que espere."},
          {text: "Dile a Ana que no espere."},
        ]
      },
      {
        rows: [
          {text: "Presenta el examen."},
          {text: "No presentes el examen."},
          {text: "Presentemos el examen."},
          {text: "Déjame presentar el examen."},
          {text: "Diles que presenten el examen."},
          {text: "Diles que no presenten el examen."},
        ]
      },
      {
        rows: [
          {text: "Cómpralo."},
          {text: "No lo compres."},
          {text: "Comprémoslo."},
          {text: "Déjame comprarlo."},
          {text: "Dile a Alan que lo compre."},
          {text: "Dile a Alan que no lo compre."},
        ]
      },
    ]
  }
]
