export const to_be_present_interrogative: any = [
  {
    type: 'card',
    title: "Presente interrogativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>Am I?</b>",
            audio: "unit3-273.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estoy? / ¿soy?</i>",
            audio: "none",
          },
          {
            text: "<b>Are you?</b>",
            audio: "unit3-274.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estás? / ¿eres?</i>",
            audio: "none",
          },
          {
            text: "<b>Is he?</b>",
            audio: "unit3-275.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Él está / es?</i>",
            audio: "none",
          },
          {
            text: "<b>Is she?</b>",
            audio: "unit3-276.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ella está / es?</i>",
            audio: "none",
          },
          {
            text: "<b>Is it?</b>",
            audio: "unit3-277.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Está? / ¿Es?</i>",
            audio: "none",
          },
          {
            text: "<b>Are we?</b>",
            audio: "unit3-278.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estamos? / ¿Somos?</i>",
            audio: "none",
          },
          {
            text: "<b>Are they?</b>",
            audio: "unit3-279.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ellos están / son?</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
