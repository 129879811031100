export const past_compound: any = [
  {
    type: 'card',
    title:"Oraciones compuestas",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: '<b>No hay forma interrogativa</b> en la 2ª parte de la oración.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Qué dijo él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>What did he say?</b>",
            audio:"unit4-5.mp3"
          },
          {
            text: "¿Sabes qué dijo él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Do you know what he said?</b>",
            audio:"unit4-1.mp3"
          },

        ]
      },
    ]
  },
  {
    type:'translate',
    id: '29',
    items: [
      {
        rows: [
          {text:'¿Adónde fue él?'},
          {text:'¿Sabes adónde fue él?'},
        ]
      },
      {
        rows: [
          {text:'¿Qué dijo él?'},
          {text:'¿Sabes lo que él dijo?'},
        ]
      },
      {
        rows: [
          {text:'¿Por qué no jugó él?'},
          {text:'¿Sabes por qué él no jugó?'},
        ]
      },
      {
        rows: [
          {text:'¿Cuándo empezamos?'},
          {text:'¿Recuerdas cuándo empezamos?'},
        ]
      },
      {
        rows: [
          {text:'¿A qué hora vinimos?'},
          {text:'¿Recuerdas a qué hora vinimos?'},
        ]
      },
      {
        rows: [
          {text:'¿Por qué no jugamos?'},
          {text:'¿Recuerdas por qué no jugamos?'},
        ]
      },
      {
        rows: [
          {text:'¿Cuánto pagaste?'},
          {text:'¿Sabes cuánto pagué?'},
        ]
      },
      {
        rows: [
          {text:'¿Cómo te sentiste?'},
          {text:'¿Sabes cómo me sentí?'},
        ]
      },
      {
        rows: [
          {text:'¿Por qué no me lo dijiste?'},
          {text:'¿Sabes por qué no te lo dije?'},
        ]
      },
    ]
  },
]
