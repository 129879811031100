export const structures_9: any = [
  {
    type: 'structures',
    id: '9',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I'm going to</b> &#8230;",
          },

          {
            level:'secondary',
            text: "Voy a &#8230;ar/er/ir",
          },
          {
            text:"<b>I'm not going to </b> &#8230;",
          },
          {
            level:'secondary',
            text: "No voy a &#8230;ar/er/ir",
          },
          {
            text: "<b>Are you going to </b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Vas a &#8230;ar/er/ir?",
          },
          {
            text: "<b>Aren't you going to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No vas a &#8230;ar/er/ir?",
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text:"<b>He's going to</b> &#8230;",
          },

          {
            level:'secondary',
            text: "Él va a &#8230;ar/er/ir",
          },
          {
            text:"<b>He's not going to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Él no va a &#8230;ar/er/ir",
          },
          {
            text: "<b>Is he going to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Él va a &#8230;ar/er/ir?",
          },
          {
            text: "<b>Isn't he going to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Él no va a &#8230;ar/er/ir?",
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text:"<b>They're going to</b> &#8230;",
          },

          {
            level:'secondary',
            text: "Ellos van a &#8230;ar/er/ir",
          },
          {
            text:"<b>They aren't going to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Ellos no van a &#8230;ar/er/ir",
          },
          {
            text: "<b>Are they going to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Ellos van a &#8230;ar/er/ir?",
          },
          {
            text: "<b>Aren't they going to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Ellos no van a &#8230;ar/er/ir?",
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '36',
    items: [
      {
        rows: [
          {text: "Voy a esperar."},
          {text: "No voy a esperar."},
          {text: "¿Vas a esperar?"},
          {text: "¿Cuánto tiempo vas a esperar?"},
          {text: "¿No vas a esperar?"},
        ]
      },
      {
        rows: [
          {text: "Él va a regresar."},
          {text: "Él no va a regresar."},
          {text: "¿Él va a regresar?"},
          {text: "¿Cuándo va a regresar él?"},
          {text: "¿Él no va a regresar?"},
        ]
      },
      {
        rows: [
          {text: "Ellos van a pagar."},
          {text: "Ellos no van a pagar."},
          {text: "¿Ellos van a pagar?"},
          {text: "¿Cuánto van a pagar ellos?"},
          {text: "¿Ellos no van a pagar?"},
        ]
      },
      {
        rows: [
          {text: "Voy a decírselo (a él)."},
          {text: "No se lo voy a decir (a él)."},
          {text: "¿Se lo vas a decir (a él)?"},
          {text: "¿Cómo se lo vas a decir (a él)?"},
          {text: "¿No se lo vas a decir (a él)?"},
        ]
      },
      {
        rows: [
          {text: "Ana va a comprar comida."},
          {text: "Ana no va comprar comida."},
          {text: "¿Ana va a comprar comida?"},
          {text: "¿Cuánta comida va a comprar Ana?"},
          {text: "¿Ana no va a comprar comida?"},
        ]
      },
      {
        rows: [
          {text: "Vamos a practicar."},
          {text: "No vamos a practicar."},
          {text: "¿Vamos a practicar?"},
          {text: "¿Cuántas horas vamos a practicar?"},
          {text: "¿No vamos a practicar?"},
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '37',
    items: [
      {
        rows: [
          {text: "Pago."},
          {text: "Quiero pagar."},
          {text: "Voy a pagar."},
          {text: "Tengo que pagar."},
          {text: "El quiere que yo pague."},
        ]
      },
      {
        rows: [
          {text: "Él quiere venir todos los días."},
          {text: "Él va a venir todos los días."},
          {text: "Él tiene que venir todos los días."},
          {text: "Él quiere que vengamos todos los días."},
          {text: "Él viene todos los días."},
        ]
      },
      {
        rows: [
          {text: "Vamos a seguir."},
          {text: "Tenemos que seguir."},
          {text: "Queremos que sigas."},
          {text: "Seguimos."},
          {text: "Queremos seguir."},
        ]
      },
      {
        rows: [
          {text: "No lo hago."},
          {text: "No quiero hacerlo."},
          {text: "No lo voy a hacer."},
          {text: "No tengo por qué hacerlo."},
          {text: "No quiero que lo hagas."},
        ]
      },
      {
        rows: [
          {text: "Ella no quiere usarlo."},
          {text: "Ella no lo va a usar."},
          {text: "Ella no tiene por qué usarlo."},
          {text: "Ella no quiere que él lo use."},
          {text: "Ella no lo usa."},
        ]
      },
      {
        rows: [
          {text: "Ellos no van a esperar."},
          {text: "Ellos no tienen por qué esperar."},
          {text: "Ellos no quieren que esperemos."},
          {text: "Ellos no esperan."},
          {text: "Ellos no quieren esperar."},
        ]
      },
      {
        rows: [
          {text: "¿Lo compras?"},
          {text: "¿Quieres comprarlo?"},
          {text: "¿Vas a comprarlo?"},
          {text: "¿Tienes que comprarlo?"},
          {text: "¿Quieres que yo lo compre?"},
        ]
      },
      {
        rows: [
          {text: "¿Qué quiere estudiar ella?"},
          {text: "¿Qué va a estudiar ella?"},
          {text: "¿Qué tiene que estudiar ella?"},
          {text: "¿Qué quieres que ella estudie?"},
          {text: "¿Qué estudia ella?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuándo vamos a empezar?"},
          {text: "¿Cuándo tenemos que empezar?"},
          {text: "¿Cuándo quieres que empecemos?"},
          {text: "¿Cuándo empezamos?"},
          {text: "¿Cuándo queremos empezar?"},
        ]
      },
      {
        rows: [
          {text: "¿No ves TV?"},
          {text: "¿No quieres ver TV?"},
          {text: "¿No vas a ver TV?"},
          {text: "¿No tienes que ver TV?"},
          {text: "¿No quieres que ellos vean TV?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué él no quiere jugar?"},
          {text: "¿Por qué él no va a jugar?"},
          {text: "¿Por qué él no tiene que jugar?"},
          {text: "¿Por qué él no quiere que tú juegues?"},
          {text: "¿Por qué él no juega?"},
        ]
      },
      {
        rows: [
          {text: "¿Ellos no van a llamar?"},
          {text: "¿Ellos no tienen que llamar?"},
          {text: "¿Ellos no quieren que llamemos?"},
          {text: "¿Ellos no llaman?"},
          {text: "¿Ellos no quieren llamar?"},
        ]
      },
      {
        rows: [
          {text: "¿Quién lo usa?"},
          {text: "¿Quién quiere usarlo?"},
          {text: "¿Quién lo va a usar?"},
          {text: "¿Quién tiene que usarlo?"},
          {text: "¿Quién quiere que yo lo use?"},
        ]
      },
    ]
  },
]
