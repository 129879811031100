import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecordingPageRoutingModule } from './recording-routing.module';
import { RecordingPage } from './recording.page';


@NgModule({
  imports: [
    RecordingPageRoutingModule,
    SharedModule
  ],
  declarations: [RecordingPage]
})
export class RecordingPageModule {}
