export const past_irregular: any = [
  {
    type: 'card',
    title: 'Verbos irregulares',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Igual que en español, los verbos irregulares no tienen regla fija para formar el pasado afirmativo, por lo cual éste debe automatizarse por medio de la práctica."
          },
        ]
      },

      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To know",
                  audio: "unit4-168.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>knew</b>",
                  audio: "unit4-236.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To have",
                  audio: "unit4-170.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>had</b>",
                  audio: "unit4-237.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To understand",
                  audio: "unit4-172.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>understood</b>",
                  audio: "unit4-238.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To think",
                  audio: "unit4-174.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>thought</b>",
                  audio: "unit4-239.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To say",
                  audio: "unit4-176.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>said</b>",
                  audio: "unit4-240.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To tell",
                  audio: "unit4-178.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>told</b>",
                  audio: "unit4-241.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To come",
                  audio: "unit4-180.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>came</b>",
                  audio: "unit4-242.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To speak",
                  audio: "unit4-182.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>spoke</b>",
                  audio: "unit4-243.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To go",
                  audio: "unit4-184.mp3"
                },
                {
                  text: " (to bed)"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>went</b>",
                  audio: "unit4-244.mp3"
                },
                {
                  text: " (to bed)"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To do",
                  audio: "unit4-186.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>did</b>",
                  audio: "unit4-245.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To see",
                  audio: "unit4-188.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>saw</b>",
                  audio: "unit4-246.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To get up",
                  audio: "unit4-190.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>got up</b>",
                  audio: "unit4-247.mp3"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To get",
                  audio: "unit4-192.mp3"
                },
                {
                  text: " home / here"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>got</b>",
                  audio: "unit4-193.mp3"
                },
                {
                  text: " home / here"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To feel",
                  audio: "unit4-194.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>felt</b>",
                  audio: "unit4-249.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To buy",
                  audio: "unit4-196.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>bought</b>",
                  audio: "unit4-250.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To sell",
                  audio: "unit4-198.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>sold</b>",
                  audio: "unit4-251.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To pay",
                  audio: "unit4-200.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>paid</b>",
                  audio: "unit4-252.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To bring",
                  audio: "unit4-202.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>brought</b>",
                  audio: "unit4-253.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To forget",
                  audio: "unit4-204.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>forgot</b>",
                  audio: "unit4-254.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To sleep",
                  audio: "unit4-206.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>slept</b>",
                  audio: "unit4-255.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To spend",
                  audio: "unit4-208.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>spent</b>",
                  audio: "unit4-256.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To lend",
                  audio: "unit4-210.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>lent</b>",
                  audio: "unit4-257.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To give",
                  audio: "unit4-212.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>gave</b>",
                  audio: "unit4-258.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To hear",
                  audio: "unit4-525.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>heard</b>",
                  audio: "unit4-526.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To take",
                  audio: "unit4-214.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>took</b>",
                  audio: "unit4-259.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To eat",
                  audio: "unit4-216.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>ate</b>",
                  audio: "unit4-261.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To drink",
                  audio: "unit4-218.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>drank</b>",
                  audio: "unit4-262.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To drive",
                  audio: "unit4-220.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>drove</b>",
                  audio: "unit4-263.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To win",
                  audio: "unit4-222.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>won</b>",
                  audio: "unit4-264.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To lose",
                  audio: "unit4-224.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>lost</b>",
                  audio: "unit4-265.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To put",
                  audio: "unit4-226.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>put</b>",
                  audio: "unit4-524.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To read",
                  audio: "unit4-228.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>read</b>",
                  audio: "unit4-508.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To write",
                  audio: "unit4-230.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>wrote</b>",
                  audio: "unit4-268.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To keep",
                  audio: "unit4-232.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>kept</b>",
                  audio: "unit4-233.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To be",
                  audio: "unit4-234.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>was</b>",
                  audio: "unit4-502.mp3"
                },
                {
                  text: " / "
                },
                {
                  type: "link",
                  text: "<b>were</b>",
                  audio: "unit4-235.mp3"
                },
              ]
            }
          },
        ]
      }
    ]
  },

  {
    type:'english',
    id: '25',
    items: [
      {
        rows: [
          {
            text: "knew",
            audio: "unit4-236.mp3"
          },
          {
            text: "had",
            audio: "unit4-237.mp3"
          },
          {
            text: "understood",
            audio: "unit4-238.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "thought",
            audio: "unit4-239.mp3"
          },
          {
            text: "said",
            audio: "unit4-240.mp3"
          },
          {
            text: "told",
            audio: "unit4-241.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "did",
            audio: "unit4-245.mp3"
          },
          {
            text: "saw",
            audio: "unit4-246.mp3"
          },
          {
            text: "got up",
            audio: "unit4-247.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "got home",
            audio: "unit4-248.mp3"
          },
          {
            text: "felt",
            audio: "unit4-249.mp3"
          },
          {
            text: "bought",
            audio: "unit4-250.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "sold",
            audio: "unit4-251.mp3"
          },
          {
            text: "paid",
            audio: "unit4-252.mp3"
          },
          {
            text: "brought",
            audio: "unit4-253.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "forgot",
            audio: "unit4-254.mp3"
          },
          {
            text: "slept",
            audio: "unit4-255.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "spent",
            audio: "unit4-256.mp3"
          },
          {
            text: "lent",
            audio: "unit4-257.mp3"
          },
          {
            text: "gave",
            audio: "unit4-258.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "took",
            audio: "unit4-259.mp3"
          },
          {
            text: "heard",
            audio: "unit4-260.mp3"
          },
          {
            text: "ate",
            audio: "unit4-261.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "drank",
            audio: "unit4-262.mp3"
          },
          {
            text: "drove",
            audio: "unit4-263.mp3"
          },
          {
            text: "won",
            audio: "unit4-264.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "lost",
            audio: "unit4-265.mp3"
          },
          {
            text: "put",
            audio: "unit4-266.mp3"
          },
          {
            text: "read",
            audio: "unit4-508.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "wrote",
            audio: "unit4-268.mp3"
          },
          {
            text: "kept",
            audio: "unit4-269.mp3"
          },
        ]
      }
    ]
  }
]
