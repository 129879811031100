export const questions: any = [
  {
    type: 'card',
    title: 'Interrogativos',
    items: [
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"What?",
                  audio:"unit1-301.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Qué?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Where?",
                  audio:"unit1-302.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Dónde?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How?",
                  audio:"unit1-303.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cómo?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"When?",
                  audio:"unit1-304.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuándo?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Why?",
                  audio:"unit1-305.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Por qué?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"What time?",
                  audio:"unit1-306.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿A qué hora?",
                  audio:"none"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"Till what time?",
                  audio:"unit1-307.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Hasta qué hora?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How long?",
                  audio:"unit1-308.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuánto tiempo?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How much?",
                  audio:"unit1-309.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuánto?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How much money?",
                  audio:"unit1-310.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuánta plata?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How many times?",
                  audio:"unit1-311.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuántas veces?",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"How many hours?",
                  audio:"unit1-312.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"¿Cuántas horas?",
                  audio:"none"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Los interrogativos se colocan delante de cualquier forma interrogativa. <b>Por lo tanto, no pueden omitirse  do(n't) / does(n't)</b>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i>What</i> <b>do you</b> want?",
            audio: "unit1-313.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Qué</i> quieres tú?",
            audio:"none"
          },
          {
            text: "<i>What</i> <b>does he</b> want?",
            audio: "unit1-314.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Qué</i> quiere él?",
            audio:"none"
          },
          {
            text: "<i>Where</i> <b>do you</b> live?",
            audio: "unit1-315.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Dónde</i> vives?",
            audio:"none"
          },
          {
            text: "<i>Where</i> <b>does your</b> friend live?",
            audio: "unit1-316.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Dónde</i> vive tu amigo/a?",
            audio:"none"
          },
          {
            text: "<i>Why</i> <b>don't you</b> drive?",
            audio: "unit1-317.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Por qué</i> no manejas?",
            audio:"none"
          },
          {
            text: "<i>Why</i> <b>doesn't his</b> wife drive?",
            audio: "unit1-318.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Por qué</i> la esposa no maneja?",
            audio:"none"
          },
          {
            text: "<i>What</i> <b>doesn't he</b> understand?",
            audio: "unit1-319.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿<i>Qué es lo que</i> él no entiende?",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Igual que en español, si <b>How much</b>  y  <b>How many</b>  van seguidos de un complemento, éste se coloca inmediatamente después del interrogativo, sin afectar la estructura."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "How much does he need?",
            audio: "unit1-320.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿Cuánto necesita él?",
            audio:"none"
          },
          {
            text: "How much <b>money</b> does he need?",
            audio: "unit1-321.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿Cuánta <b>plata</b> necesita él?",
            audio:"none"
          },
          {
            text: "How many do we have?",
            audio: "unit1-322.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿Cuántos tenemos?",
            audio:"none"
          },
          {
            text: "How many <b>hours</b> do we have?",
            audio: "unit1-323.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "¿Cuántas <b>horas</b> tenemos?",
            audio:"none"
          }
        ]
      },
    ]
  }
]
