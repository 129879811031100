export const adverbs: any = [
  {
    type: 'card',
    title: 'Adverbios colocados antes del verbo',
    items: [
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"always",
                  audio:"unit1-0.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"siempre",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"never",
                  audio:"unit1-1.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"nunca",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"still",
                  audio:"unit1-2.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"todavía",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"just",
                  audio:"unit1-752.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"sólo",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "I <b>always</b> forget",
            audio: "unit1-4.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Siempre se me olvida.",
            audio:"none"
          },
          {
            text: "We <b>never</b> see them",
            audio: "unit1-3.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Nunca los vemos.",
            audio:"none"
          },
          {
            text: "She <b>still</b> lives here",
            audio: "unit1-5.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Todavía vive aquí.",
            audio:"none"
          },
          {
            text: "I <b>just</b> remember that",
            audio: "unit1-753.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Sólo recuerdo eso.",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Adverbios colocados al final de la oración',
    items: [
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"from time to time",
                  audio:"unit1-764.mp3",
                  type: 'link'
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"de vez en cuando",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"yet",
                  audio:"unit1-6.mp3",
                  type: 'link'
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"(no) &#8230; todavía",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"anymore",
                  audio:"unit1-7.mp3",
                  type: 'link'
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"ya no &#8230;",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "We <b>don't</b> know anything <b>yet</b>",
            audio: "unit1-8.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "No sabemos nada todavía.",
            audio:"none"
          },
          {
            text: "He <b>doesn't</b> work here <b>anymore</b>",
            audio: "unit1-9.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Ya no trabaja aquí (él).",
            audio:"none"
          },
          {
            text: "I use it <b>from time to time</b>",
            audio: "unit1-765.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Lo uso de vez en cuando.",
            audio:"none"
          },
        ]
      },
    ]
  },
]
