export const to_be_extra_1: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>Where are you going?</b>",
            audio:"unit3-465.mp3"
          },
          {
            level:'secondary',
            text: "¿Adónde vas?",
            audio:"none"
          },
          {
            text: "<b>She isn't coming today</b>",
            audio:"unit3-466.mp3"
          },
          {
            level:'secondary',
            text: "Ella no viene hoy",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: '<b>To go</b>  y  <b>To come (back)  </b> siempre se usarán en el presente continuo salvo que la oración implique un hábito: cuando, por ejemplo, se utilice <i>todos los días, siempre, nunca, los sábados, etc.</i>'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i><b>Vengo</b> mañana.</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I'm coming</b> tomorrow",
            audio:"unit3-467.mp3",
          },
          {
            text: "<i>Vengo <b>todos los días</b></i>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <i>come</i> <b>every day<b/>",
            audio:"unit3-468.mp3"
          },
          {
            text: "<i><b>No voy</b> al banco hoy</i>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I'm not going</b> to the bank today",
            audio:"unit3-469.mp3"
          },
          {
            text: "<i><b>Nunca</b> voy al banco</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>never</b><i> go</i> to the bank",
            audio:"unit3-470.mp3"
          },

          {
            text: "<b>¿Vienes?</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Are you coming</b>?",
            audio:"unit3-471.mp3"
          },
          {
            text: "¿Vienes <b>los viernes?</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you come <b>on Fridays?</b>",
            audio:"unit3-472.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '10a',
    items: [
      {
        rows: [
          {
            text: "I'm going with them",
            audio: "unit3-552.mp3"
          },
          {
            text: "I always go with them",
            audio: "unit3-474.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He isn't going to the bank",
            audio: "unit3-475.mp3"
          },
          {
            text: "He never goes to the bank",
            audio: "unit3-476.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Where are we going?",
            audio: "unit3-477.mp3"
          },
          {
            text: "Where do we go every day?",
            audio: "unit3-478.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't she coming?",
            audio: "unit3-559.mp3"
          },
          {
            text: "Doesn't she come on Fridays?",
            audio: "unit3-480.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "They aren't coming today",
            audio: "unit3-481.mp3"
          },
          {
            text: "They never come",
            audio: "unit3-482.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm coming",
            audio: "unit3-483.mp3"
          },
          {
            text: "I come all the time",
            audio: "unit3-484.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '10b',
    items: [
      {
        rows: [
          {text: "Voy contigo."},
          {text: "Él dice que va."},
        ]
      },
      {
        rows: [
          {text: "Ella va pero yo no voy."},
          {text: "Yo voy pero ella no va."},
        ]
      },
      {
        rows: [
          {text: "¿Tú vas? "},
          {text: "¿Quién va contigo?"},
        ]
      },
      {
        rows: [
          {text: "¿Adónde vas? "},
          {text: "¿Adónde va él?"},
        ]
      },
      {
        rows: [
          {text: "¿Tú no vas? "},
          {text: "¿Ana no va?"},
        ]
      },
      {
        rows: [
          {text: "Vengo mañana."},
          {text: "No sé si él viene hoy."},
        ]
      },
      {
        rows: [
          {text: "No vengo el viernes."},
          {text: "Ella no viene con nosotros."},
        ]
      },
      {
        rows: [
          {text: "¿Vienes o qué?"},
          {text: "¿Quién viene mañana?"},
        ]
      },
      {
        rows: [
          {text: "¿Tú no vienes?"},
          {text: "¿El profesor no viene hoy?"},
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '10c',
    items: [
      {
        rows: [
          {text: "Vamos al cine."},
          {text: "Vamos al cine los lunes."},
        ]
      },
      {
        rows: [
          {text: "Yo voy hoy y mi amiga va mañana."},
          {text: "Yo voy en las mañanas y mi amiga va en las tardes."},
        ]
      },
      {
        rows: [
          {text: "Vengo mañana"},
          {text: "Vengo todos los días."},
        ]
      },
      {
        rows: [
          {text: "Ella viene el martes. "},
          {text: "Ella viene los martes."},
        ]
      },
      {
        rows: [
          {text: "Pregúntale a él si viene."},
          {text: "Pregúntale a él si viene la semana que viene."},
        ]
      },
      {
        rows: [
          {text: "Él viene con la novia."},
          {text: "Él siempre viene con la novia."},
        ]
      },
      {
        rows: [
          {text: "No voy al banco hoy."},
          {text: "Nunca voy al banco."},
        ]
      },
      {
        rows: [
          {text: "Por supuesto que no voy al banco todos los días."},
        ]
      },
      {
        rows: [
          {text: "¿Adónde vas?"},
          {text: "¿Adónde vas todas las semanas?"},
        ]
      },
      {
        rows: [
          {text: "¿Adónde va ella?"},
          {text: "¿Adónde va ella todo el tiempo?"},
        ]
      },
      {
        rows: [
          {text: "¿Vienes conmigo?"},
          {text: "¿Siempre vienes en la mañana?"},
        ]
      },
      {
        rows: [
          {text: "No sé si alguien va."},
          {text: "Creo que todo el mundo viene."},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>What's wrong?</b>",
            audio:"unit3-485.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué pasa?",
            audio: "none",
          },
          {
            text: "<b>What's the matter with her?</b>",
            audio:"unit3-486.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué le pasa (a ella)?",
            audio: "none",
          },
          {
            text: "<b>What's going on here?</b>",
            audio:"unit3-487.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué está pasando aquí?",
            audio: "none",
          },
          {
            text: "<b>Don't be silly!</b>",
            audio:"unit3-488.mp3"
          },
          {
            level:'secondary',
            text: "¡No seas necio!",
            audio: "none",
          },
          {
            text: "<b>What are you looking for?</b>",
            audio:"unit3-489.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué estás buscando?",
            audio: "none",
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '10d',
    items: [
      {
        rows: [
          {text: "¿Qué pasa?"},
          {text: "Quiero saber qué pasa."},
          {text: "Dime qué pasa."},
        ]
      },
      {
        rows: [
          {text: "¿Qué te pasa?"},
          {text: "¿Qué nos pasa?"},
          {text: "No sé qué me pasa hoy."},
        ]
      },
      {
        rows: [
          {text: "¿Qué está pasando aquí?"},
          {text: "¿Alguien sabe qué está pasando?"},
        ]
      },
      {
        rows: [
          {text: "No seas tonto."},
          {text: "Dile a él que no sea tonto."},
        ]
      },
      {
        rows: [
          {text: "No estés triste."},
          {text: "Dile a ella que no esté triste."},
        ]
      },
      {
        rows: [
          {text: "¿Qué andas buscando?"},
          {text: "¿Qué está buscando ella?"},
        ]
      },
      {
        rows: [
          {text: "Estoy buscando mi celular."},
          {text: "Ana te anda buscando."},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Start work<b>ing</b>",
            audio:"unit3-490.mp3"
          },
          {
            level:'secondary',
            text: "Empieza a trabajar.",
            audio: "none",
          },
          {
            text: "Keep try<b>ing</b>",
            audio:"unit3-491.mp3"
          },
          {
            level:'secondary',
            text: "Sigue intentándolo.",
            audio: "none",
          },
          {
            text: "Stop drink<b>ing</b>",
            audio:"unit3-492.mp3"
          },
          {
            level:'secondary',
            text: "Deja de beber.",
            audio: "none",
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cuando los verbos <b>start, keep</b> y <b>stop</b> vayan seguidos de otro verbo, este <i>segundo verbo</i> debe estar en <i>gerundio</i> en inglés.<br/><br/>"
          },
          {
            text: "Únicamente con <b>start</b> es posible usar la construcción con <b>to</b>, pero ésta no es la primera opción al hablar.<br/>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Empieza a trabajar.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Start <b>working</b>",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Start <b>to work</b>",
            audio: "none",
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '10e',
    items: [
      {
        rows: [
          {text: "Empieza a jugar."},
          {text: "Sigue jugando."},
          {text: "Deja de jugar."},
        ]
      },
      {
        rows: [
          {text: "Empiecen a hablar."},
          {text: "Sigan hablando."},
          {text: "Dejen de hablar."},
        ]
      },
      {
        rows: [
          {text: "Empieza a usarlo."},
          {text: "Sigue usándolo."},
          {text: "Deja de usarlo."},
        ]
      },
      {
        rows: [
          {text: "Él quiere empezar a trabajar."},
          {text: "Él quiere seguir trabajando."},
          {text: "Él quiere dejar de trabajar."},
        ]
      },
      {
        rows: [
          {text: "¿Puedo empezar a venir?"},
          {text: "¿Puedo seguir viniendo?"},
          {text: "¿Puedo dejar de venir?"},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Why are you crying?",
            audio:"unit3-493.mp3"
          },
          {
            level:'secondary',
            text: "¿Por qué estás llorando?",
            audio: "none",
          },
          {
            text: "I'm just kidding",
            audio:"unit3-494.mp3"
          },
          {
            level:'secondary',
            text: "Sólo estoy bromeando.",
            audio: "none",
          },
          {
            text: "He's listening to music",
            audio:"unit3-495.mp3"
          },
          {
            level:'secondary',
            text: "Él está oyendo música.",
            audio: "none",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '10f',
    items: [
      {
        rows: [
          {text: "¿Por qué estás llorando?"},
          {text: "Anda, deja de llorar."},
          {text: "Alguien está llorando."},
          {text: "No quiero que llores."},
        ]
      },
      {
        rows: [
          {text: "Estoy bromeando."},
          {text: "¿Me estás tomando el pelo?"},
          {text: "No estoy bromeando."},
          {text: "Debes estar bromeando."},
        ]
      },
      {
        rows: [
          {text: "Estamos oyendo música."},
          {text: "Siempre oigo música."},
          {text: "¿Te gustaría oír música?"},
        ]
      },
      {
        rows: [
          {text: "No estás escuchando."},
          {text: "Te estoy escuchando."},
          {text: "¿Estás escuchando?"},
        ]
      },
    ]
  },
]
