export const structures_1: any = [
  {
    type: 'structures',
    id: '1',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>I &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo &#8230;",
          },
          {
            text: "<b>I don't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo no &#8230;",
          },
          {
            text: "<b>Do you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tú  &#8230;?",
          },
          {
            text: "<b>Don't you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tú  no &#8230;?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>We &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Nosotros &#8230;",
          },
          {
            text: "<b>We don't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Nosotros no &#8230;",
          },
          {
            text: "<b>Do we &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Nosotros  &#8230;?",
          },
          {
            text: "<b>Don't you &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Nosotros  no &#8230;?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>They &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Ellas/ellos &#8230;",
          },
          {
            text: "<b>They don't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Ellas/ellos no &#8230;",
          },
          {
            text: "<b>Do they &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Ellas/ellos  &#8230;?",
          },
          {
            text: "<b>Don't they &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Ellas/ellos  no &#8230;?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>You &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Tú &#8230;",
          },
          {
            text: "<b>You don't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Tú no &#8230;",
          },
          {
            text: "<b>Do I &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿(Acaso) yo  &#8230;?",
          },
          {
            text: "<b>Don't I &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿(Acaso) yo no &#8230;?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '5',
    items: [
      {
        rows: [
          {text: "Entiendo."},
          {text: "No entiendo."},
          {text: "¿Entiendes?"},
          {text: "¿No entiendes?"}
        ]
      },
      {
        rows: [
          {text: "Me levanto temprano."},
          {text: "No me levanto temprano."},
          {text: "¿Te levantas temprano?"},
          {text: "¿No te levantas temprano?"},
        ]
      },
      {
        rows: [
          {text: "Me siento mejor."},
          {text: "No me siento mejor."},
          {text: "¿Te sientes mejor?"},
          {text: "¿No te sientes mejor?"},
        ]
      },
      {
        rows: [
          {text: "Tenemos clase."},
          {text: "No tenemos clase."},
          {text: "¿Tenemos clase?"},
          {text: "¿No tenemos clase?"},
        ]
      },
      {
        rows: [
          {text: "Practicamos todos los días."},
          {text: "No practicamos todos los días."},
          {text: "¿Practicamos todos los días?"},
          {text: "¿No practicamos todos los días?"},
        ]
      },
      {
        rows: [
          {text: "Sabemos eso."},
          {text: "No sabemos eso."},
          {text: "¿Sabemos eso?"},
          {text: "¿Acaso no sabemos eso?"},
        ]
      },
      {
        rows: [
          {text: "Ellas hablan inglés."},
          {text: "Ellas no hablan inglés."},
          {text: "¿Ellas hablan inglés?"},
          {text: "¿Ellas no hablan inglés?"},
        ]
      },
      {
        rows: [
          {text: "Ellos estudian juntos."},
          {text: "Ellos no estudian juntos."},
          {text: "¿Ellos estudian juntos?"},
          {text: "¿Ellos no estudian juntos?"},
        ]
      },
      {
        rows: [
          {text: "Ellos usan el libro."},
          {text: "Ellos no usan el libro."},
          {text: "¿Ellos usan el libro?"},
          {text: "¿Ellos no usan el libro?"},
        ]
      },
      {
        rows: [
          {text: "Manejas rápido."},
          {text: "No manejas rápido."},
          {text: "¿Yo manejo rápido?"},
          {text: "¿Yo no manejo rápido?"},
        ]
      },
      {
        rows: [
          {text: "Tú lo usas bien."},
          {text: "Tú no lo usas bien."},
          {text: "¿Yo lo uso bien?"},
          {text: "¿No lo uso bien?"},
        ]
      },
      {
        rows: [
          {text: "Necesitas el libro."},
          {text: "No necesitas el libro."},
          {text: "¿Necesito el libro?"},
          {text: "¿No necesito el libro?"},
        ]
      },
    ]
  }
]
