import { Injectable, isDevMode } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() {}

  log(message: string) {
    console.log(message)
  }

  debug(message: string) {
    if (isDevMode()) {
      console.log(message)
    }
  }
}
