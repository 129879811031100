export const determiners: any = [
  {
    type: 'card',
    title: 'Determinadores',
    items: [
      {
        type: 'columns-2',
        rows: [
                    {
            left: {
              tokens: [
                  {
                    text:"A",
                    audio:"unit1-720.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"un / una",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"An",
                    audio:"unit1-197.mp3",
                    type: "link",
                  },
              ]
            },
            right: {
              tokens: [
                  {
                    text:"un / una",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"Another",
                    audio:"unit1-188.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"otro / otra",
                    audio:"none"
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"The",
                    audio:"unit1-189.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"el / la / los / las",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"The other",
                    audio:"unit1-190.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"el otro / las otras",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"This",
                    audio:"unit1-191.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"este / esta",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"That",
                    audio:"unit1-192.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"ese / esa",
                    audio:"none"
                  }
              ]
            }
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"Some",
                    audio:"unit1-193.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"algunos / algunas",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"Many",
                    audio:"unit1-194.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"muchos / muchas",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"These",
                    audio:"unit1-195.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"estos / estas",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"Those",
                    audio:"unit1-196.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"esos / esas",
                    audio:"none"
                  }
              ]
            }
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"El artículo indefinido es ",
            audio:"none"
          },
          {
            text:"<b>a</b>",
            audio:"unit1-720.mp3",
            type: "link",
          },
          {
            text:" si la palabra siguiente empieza por <b>consonante</b> (a book / a house), y ",
            audio:"none"
          },
          {
            text:"<b>an</b>",
            audio:"unit1-197.mp3",
            type: "link",
          },
          {
            text:" si la palabra siguiente empieza por <b>vocal</b> (an example) o <b>h muda </b> (an hour).",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '19a',
    items: [
      {
        rows: [
          {
            text: "She has a house there",
            audio: "unit1-199.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has an aunt there",
            audio: "unit1-200.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has a brother there",
            audio: "unit1-201.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has an uncle there",
            audio: "unit1-202.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has a son there",
            audio: "unit1-203.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has an apartment there",
            audio: "unit1-204.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She has a daughter there",
            audio: "unit1-205.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use a book",
            audio: "unit1-207.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use another book",
            audio: "unit1-208.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use the book",
            audio: "unit1-209.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use the other book",
            audio: "unit1-210.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use some books",
            audio: "unit1-211.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use many books",
            audio: "unit1-212.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use this book",
            audio: "unit1-213.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use that book",
            audio: "unit1-214.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use these books",
            audio: "unit1-215.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use those books",
            audio: "unit1-216.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '19b',
    items: [
      {rows: [
        {text:"<i>Uso</i> un libro."},
        {text:"<i>Uso</i> otro libro."},
        {text:"<i>Uso</i> el libro."},
        {text:"<i>Uso</i> el otro libro."},
        {text:"<i>Uso</i> unos libros."},
        {text:"<i>Uso</i> muchos libros."},
        {text:"<i>Uso</i> este libro."},
        {text:"<i>Uso</i> ese libro."},
        {text:"<i>Uso</i> estos libros."},
        {text:"<i>Uso</i> esos libros."},
      ]},
      {rows: [
        {text:"<i>Me sé</i> una historia."},
        {text:"<i>Me sé</i> otra historia."},
        {text:"<i>Me sé</i> la historia."},
        {text:"<i>Me sé</i> la otra historia."},
        {text:"<i>Me sé</i> unas historia."},
        {text:"<i>Me sé</i> muchas historias."},
        {text:"<i>Me sé</i> esta historia."},
        {text:"<i>Me sé</i> esa historia."},
        {text:"<i>Me sé</i> estas historias."},
        {text:"<i>Me sé</i> esas historias."},
      ]},
      {rows: [
        {text:"¿<i>Quieres</i> una llave?"},
        {text:"¿<i>Quieres</i> otra llave?"},
        {text:"¿<i>Quieres</i> la llave?"},
        {text:"¿<i>Quieres</i> la otra llave?"},
        {text:"¿<i>Quieres</i> esta llave?"},
        {text:"¿<i>Quieres</i> esa llave?"},
        {text:"¿<i>Quieres</i> unas llaves?"},
        {text:"¿<i>Quieres</i> las llaves?"},
        {text:"¿<i>Quieres</i> las otras llaves?"},
        {text:"¿<i>Quieres</i> estas llaves?"},
        {text:"¿<i>Quieres</i> esas llaves?"},
      ]},
      {rows: [
        {text:"<i>Ellos necesitan</i> una computadora."},
        {text:"<i>Ellos necesitan</i> otra computadora."},
        {text:"<i>Ellos necesitan</i> la computadora."},
        {text:"<i>Ellos necesitan</i> la otra computadora."},
        {text:"<i>Ellos necesitan</i> unas computadoras."},
        {text:"<i>Ellos necesitan</i> muchas computadoras."},
        {text:"<i>Ellos necesitan</i> esta computadora."},
        {text:"<i>Ellos necesitan</i> esa computadora."},
        {text:"<i>Ellos necesitan</i> estas computadoras."},
        {text:"<i>Ellos necesitan</i> esas computadoras."},
      ]},
      {rows: [
        {text:"<i>Veo a</i> un hombre."},
        {text:"<i>Veo </i> algunas personas."},
        {text:"<i>Veo </i> mucha gente."},
      ]},
      {rows: [
        {text:"<i>Veo a</i> una mujer."},
        {text:"<i>Veo</i> unas mujeres"},
        {text:"<i>Veo</i> muchas mujeres."},
      ]},
      {rows: [
        {text:"<i>Veo a</i> un muchacho."},
        {text:"<i>Veo</i> algunos muchachos."},
        {text:"<i>Veo</i> muchos muchachos."},
      ]}
    ]
  }
]
