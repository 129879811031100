export const had_to: any = [
  {
    type: 'card',
    title:"Had to",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Sólo <b>had</b> en el <b>pasado</b>. El segundo verbo no cambia.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo tuve que esperar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I had to wait</b>",
            audio:"unit4-511.mp3"
          },
          {
            text: "Él no tuvo que jugar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He didn't have to play</b>",
            audio:"unit4-512.mp3"
          },
          {
            text: "¿Tuviste que ir?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Did you have to go?</b>",
            audio:"unit4-513.mp3"
          },
          {
            text: "¿No tuviste que  hacerlo?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Didn't you have to do it?</b>",
            audio:"unit4-514.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '35',
    items: [
      {
        rows: [
          {
            text: "I had to go",
            audio: "unit4-274.mp3"
          },
          {
            text: "I didn't have to go",
            audio: "unit4-275.mp3"
          },
          {
            text: "Where did you have to go?",
            audio: "unit4-276.mp3"
          },
          {
            text: "Didn't you have to go?",
            audio: "unit4-277.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She had to come",
            audio: "unit4-278.mp3"
          },
          {
            text: "She didn't have to come",
            audio: "unit4-279.mp3"
          },
          {
            text: "When did she have to come?",
            audio: "unit4-280.mp3"
          },
          {
            text: "Didn't she have to come?",
            audio: "unit4-281.mp3"
          },
        ]
      },
    ]
  }
]
