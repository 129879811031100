export const to_be_present_affirmative: any = [
  {
    type: 'card',
    title: "Presente afirmativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>I'm</b>",
            audio: "unit3-406.mp3",
            type: 'link'
          },
          {
            text: "<b>I am</b>",
            audio: "unit3-407.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Estoy / soy</i>",
            audio: "none",
          },
          {
            text: "",
            audio: "none",
          },
          {
            text: "<b>You're</b>",
            audio: "unit3-408.mp3",
            type: 'link'
          },
          {
            text: "<b>You are</b>",
            audio: "unit3-409.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Estás / eres</i>",
            audio: "none",
          },
          {
            text: "<b>He's</b>",
            audio: "unit3-410.mp3",
            type: 'link'
          },
          {
            text: "<b>He is</b>",
            audio: "unit3-411.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Él está / es</i>",
            audio: "none",
          },
          {
            text: "<b>She's</b>",
            audio: "unit3-412.mp3",
            type: 'link'
          },
          {
            text: "<b>She is</b>",
            audio: "unit3-413.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ella está / es</i>",
            audio: "none",
          },
          {
            text: "<b>It's</b>",
            audio: "unit3-414.mp3",
            type: 'link'
          },
          {
            text: "<b>It is</b>",
            audio: "unit3-415.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Está / es</i>",
            audio: "none",
          },
          {
            text: "<b>We're</b>",
            audio: "unit3-416.mp3",
            type: 'link'
          },
          {
            text: "<b>We are</b>",
            audio: "unit3-417.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Estamos / somos</i>",
            audio: "none",
          },
          {
            text: "<b>They're</b>",
            audio: "unit3-418.mp3",
            type: 'link'
          },
          {
            text: "<b>They are</b>",
            audio: "unit3-419.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ellos están / son</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
