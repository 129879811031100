export const nouns: any = [
  {
    type: 'card',
    title: 'Vocabulario',
    items: [
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"house",
                    audio:"unit1-618.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"casa(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"car",
                    audio:"unit1-619.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"auto(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"story",
                    audio:"unit1-620.mp3",
                    type: "link",
                  },
                  {
                    text:"(ies)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"historia(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"boy",
                    audio:"unit1-621.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"muchacho(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"girl",
                    audio:"unit1-622.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"muchacha(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"man",
                    audio:"unit1-669.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hombre",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"woman",
                    audio:"unit1-624.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"mujer",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"men",
                    audio:"unit1-674.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hombres",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"women",
                    audio:"unit1-690.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"mujeres",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"course",
                    audio:"unit1-627.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"curso(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"game",
                    audio:"unit1-628.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"juego(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"room",
                    audio:"unit1-629.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"cuarto(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"key",
                    audio:"unit1-630.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"llave(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"family",
                    audio:"unit1-631.mp3",
                    type: "link",
                  },
                  {
                    text:"(ies)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"familia(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"parents",
                    audio:"unit1-632.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"padres",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"children",
                    audio:"unit1-682.mp3",
                    type: "link",
                  },
                  {
                    text:" / ",
                    audio:"none"
                  },
                  {
                    text:"kids",
                    audio:"unit1-633.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"niños",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"son",
                    audio:"unit1-634.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hijo(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"daughter",
                    audio:"unit1-635.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hija(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"brother",
                    audio:"unit1-636.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hermano(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"sister",
                    audio:"unit1-637.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hermana(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"boyfriend",
                    audio:"unit1-638.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"novio(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"girlfriend",
                    audio:"unit1-639.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hermana(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"uncle",
                    audio:"unit1-640.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"tío(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"aunt",
                    audio:"unit1-641.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"tía(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"boss",
                    audio:"unit1-642.mp3",
                    type: "link",
                  },
                  {
                    text:"(es)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"jefe(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"people",
                    audio:"unit1-643.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"gente",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"question",
                    audio:"unit1-644.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"pregunta(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"apartment",
                    audio:"unit1-645.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"apartamento(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"office",
                    audio:"unit1-646.mp3",
                    type: "link",
                  },
                  {
                    text:"(es)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"oficina(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"hour",
                    audio:"unit1-647.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"hora(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"idea",
                    audio:"unit1-648.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"idea(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"pencil",
                    audio:"unit1-649.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"lápiz(-ces)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"eraser",
                    audio:"unit1-650.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"borrador(es)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"example",
                    audio:"unit1-651.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"ejemplo(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"problem",
                    audio:"unit1-652.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"problema(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"word",
                    audio:"unit1-653.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"palabra(s)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"sentence",
                    audio:"unit1-654.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"oración(es)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"computer",
                    audio:"unit1-655.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"computadora(es)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"cellphone",
                    audio:"unit1-656.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"celular(es)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"phone number",
                    audio:"unit1-657.mp3",
                    type: "link",
                  },
                  {
                    text:"(s)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"(nº de) teléfono",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"address",
                    audio:"unit1-658.mp3",
                    type: "link",
                  },
                  {
                    text:"(es)",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"dirección(es)",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"glasses",
                    audio:"unit1-666.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"lentes",
                    audio:"none"
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"El <b>plural</b> de los sustantivos se forma agregando <b><i>-s</i></b>  al singular."
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"book",
                    audio:"unit1-727.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"books",
                    audio:"unit1-660.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"house",
                    audio:"unit1-618.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"houses",
                    audio:"unit1-685.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"boy",
                    audio:"unit1-621.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"boys",
                    audio:"unit1-679.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"sentence",
                    audio:"unit1-654.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"sentences",
                    audio:"unit1-663.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Si el sustantivo termina en <i>consonante + y</i>, el plural es <b><i>-ies</i></b>."
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"family",
                    audio:"unit1-631.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"families",
                    audio:"unit1-686.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"story",
                    audio:"unit1-620.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"stories",
                    audio:"unit1-681.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Si el sustantivo termina en <i>-s</i>, se agrega <b><i>-es</i></b> al sigunal, con la sílaba fonética adicional."
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"glass",
                    audio:"unit1-728.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"glasses",
                    audio:"unit1-666.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"boss",
                    audio:"unit1-642.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"bosses",
                    audio:"unit1-687.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"Algunos sustantivos tienen un plural <b>irregular</b>:"
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"man",
                    audio:"unit1-669.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"men",
                    audio:"unit1-674.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"woman",
                    audio:"unit1-624.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"women",
                    audio:"unit1-690.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"<b>People</b> es siempre <b>plural</b> en inglés."
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"unas personas",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"some people",
                    audio:"unit1-670.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"mucha gente",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"→ ",
                    audio:"none"
                  },
                  {
                    text:"many people",
                    audio:"unit1-671.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '18',
    items: [
      {
        rows: [
          {
            text: "Keys",
            audio: "unit1-672.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Hours",
            audio: "unit1-673.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Men",
            audio: "unit1-674.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Courses",
            audio: "unit1-675.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Questions",
            audio: "unit1-676.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Rooms",
            audio: "unit1-677.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Classes",
            audio: "unit1-678.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Boys",
            audio: "unit1-679.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Examples",
            audio: "unit1-680.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Stories",
            audio: "unit1-681.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Children",
            audio: "unit1-682.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Pencils",
            audio: "unit1-683.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Ideas",
            audio: "unit1-684.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Houses",
            audio: "unit1-685.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Families",
            audio: "unit1-686.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Bosses",
            audio: "unit1-687.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Words",
            audio: "unit1-688.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Erasers",
            audio: "unit1-689.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Women",
            audio: "unit1-690.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Offices",
            audio: "unit1-691.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Girls",
            audio: "unit1-692.mp3"
          }
        ]
      }
    ]
  },
]
