export const negative_3: any = [
  {
    type: 'card',
    title: 'Negativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa  "
          },
          {
            type: "link",
            text: "<b>doesn't</b>",
            audio: "unit1-471.mp3"
          },
          {
            text: ",  y el verbo no lleva  <b>'s'</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Ella no lo usa.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She  <b>doesn't</b>  use it",
            audio: "unit1-466.mp3",
            type: 'link'
          },
          {
            text: "Él no ve TV.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>doesn't</b> watch TV",
            audio: "unit1-467.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Como en el afirmativo, otros sujetos también pueden utilizarse tanto en el negativo como en el interrogativo e interrogativo-negativo."
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '8a',
    items: [
      {
        rows: [
          {
            text: "He doesn't feel well",
            audio: "unit1-483.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She doesn't drive",
            audio: "unit1-484.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Her husband doesn't speak English",
            audio: "unit1-485.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "His wife doesn't work",
            audio: "unit1-486.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Your friend doesn't study",
            audio: "unit1-487.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "My mom doesn't need one",
            audio: "unit1-488.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "My dad doesn't use it",
            audio: "unit1-489.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Ana doesn't know",
            audio: "unit1-490.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "The teacher doesn't have class today",
            audio: "unit1-491.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '8b',
    items: [
      {
        rows: [
          {
            text:'Él no se siente bien.'
          },
        ]
      },
      {
        rows: [
          {
            text: "Ella no maneja."
          },
        ]
      },
      {
        rows: [
          {
            text: "El esposo no habla inglés."
          },
        ]
      },
      {
        rows: [
          {
            text: "La esposa no trabaja."
          },
        ]
      },
      {
        rows: [
          {
            text: "Tu amigo no estudia.",
          },
        ]
      },
      {
        rows: [
          {
            text: "Mi mamá no necesita uno.",
          },
        ]
      },
      {
        rows: [
          {
            text: "Mi papá no lo usa.",
          },
        ]
      },
      {
        rows: [
          {
            text: "Ana no sabe.",
          },
        ]
      },
      {
        rows: [
          {
            text: "El profesor no tiene clase hoy.",
          }
        ]
      },
    ]
  }
]
