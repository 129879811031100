import { isDevMode } from '@angular/core'
import { ActionReducer, MetaReducer } from '@ngrx/store'
import * as fromUserEffects from './user/effects'
import * as fromUsers from './user/model'
import * as fromUserReducers from './user/reducers'

export type UserState = fromUsers.User | fromUsers.NullUser

export interface AppState {
    user: UserState
}

export const reducers = {
    user: fromUserReducers.reducer
}

export const effects = [
    fromUserEffects.UserEffects
]

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    const DEBUG = isDevMode()
    const SENSIBLE_ACTIONS = [
        fromUserReducers.actions.localSignIn.type as string,
        fromUserReducers.actions.localSignUp.type as string
    ]
    console.debug('sensible_actions:', SENSIBLE_ACTIONS)
    return (state, action) => {
        if (DEBUG && !(SENSIBLE_ACTIONS.includes(action.type))) {
            state = reducer(state, action)
            console.log('action:', JSON.stringify(action))
            console.log('state:', JSON.stringify(state))
        } else {
            if (DEBUG) {
                if (action.type === fromUserReducers.actions.localSignIn.type) {
                    const localSignIn = action as typeof fromUserReducers.actions.localSignIn
                    console.log('action', {type: action.type, username: localSignIn['email']})
                    console.log('state:', state)

                } else {
                    console.log(`refusing to log sensible action ${action.type}, missing handler`)
                }
            }
        }
        return state
    }
}

export const metaReducers: MetaReducer<any>[] = [
    debug
]
