export const can_future: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "I won't <b>be able to</b> &#8230;",
            audio: "unit2-348.mp3"
          },
          {
            level:'secondary',
            text: "No podré &#8230;ar/er/ir",
            audio: "none",
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa <b>be able to</b> con todas las estructuras del futuro simple, aunque el <b>negativo</b> es la forma más usada."
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Esta estructura tiene poca aplicación práctica, puesto que tanto en español como en inglés se prefiere usar el presente.<br/>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            level:'secondary',
            text: "No podré venir el lunes",
            audio:"none"
          },
          {
            level:'secondary',
            text: "No puedo venir el lunes.",
            audio: "none",
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '43a',
    items: [
      {
        rows: [
          {
            text: "I can't come",
            audio: "unit2-191.mp3"
          },
          {
            text: "I won't be able to come",
            audio: "unit2-192.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He can't finish the course",
            audio: "unit2-193.mp3"
          },
          {
            text: "He won't be able to finish the course",
            audio: "unit2-323.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Ana can't go on",
            audio: "unit2-194.mp3"
          },
          {
            text: "Ana won't be able to go on",
            audio: "unit2-195.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "We can't play",
            audio: "unit2-196.mp3"
          },
          {
            text: "We won't be able to play",
            audio: "unit2-324.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You can't win",
            audio: "unit2-197.mp3"
          },
          {
            text: "You won't be able to win",
            audio: "unit2-198.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '43b',
    items: [
      {
        rows: [
          {text: "No puedo venir."},
          {text: "No podré venir."},
        ]
      },
      {
        rows: [
          {text: "Él no puede terminar el curso."},
          {text: "Él no podrá terminar el curso."},
        ]
      },
      {
        rows: [
          {text: "Ana no puede seguir."},
          {text: "Ana no podrá seguir."},
        ]
      },
      {
        rows: [
          {text: "No podemos jugar."},
          {text: "No podremos jugar."},
        ]
      },
      {
        rows: [
          {text: "Uds. no pueden ganar."},
          {text: "Uds. no podrán ganar."},
        ]
      },
    ]
  }
]
