export const have_to: any = [
  {
    type: 'card',
    title: 'HAVE TO',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Tener <b>que &#8230; ar</b> / <b>er</b> / <b>ir</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Tengo que estudi<b>ar</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I have <b>to</b> study",
            audio: "unit2-274.mp3",
            type: 'link'
          },
          {
            text: "El tiene que pag<b>ar</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He has <b>to</b> pay",
            audio: "unit2-275.mp3",
            type: 'link'
          },
          {
            text: "No tienes por qué hac<b>er</b>lo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You don't have <b>to</b> do it",
            audio: "unit2-276.mp3",
            type: 'link'
          },
          {
            text: "¿Tienes que trabaj<b>ar</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you have <b>to</b> work?",
            audio: "unit2-277.mp3",
            type: 'link'
          },
          {
            text: "¿Qué tengo que hac<b>er</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What do I have <b>to</b> do?",
            audio: "unit2-292.mp3",
            type: 'link'
          },
          {
            text: "¿No tienes que estudi<b>ar</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't you have <b>to</b> study?",
            audio: "unit2-279.mp3",
            type: 'link'
          },
          {
            text: "¿No tenemos que ven<b>ir</b>?	",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't we have <b>to</b> come?",
            audio: "unit2-280.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'No hay diferencia entre las dos formas <i>"no tienes que"</i> y <i>"no tienes por qué"</i>. <b>Nunca se usa why</b>.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No tienes por qué quedarte aquí.",
            audio:"none"
          },
          {
            text: "No tienes que quedarte aquí.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You don't have to stay here",
            audio: "unit2-281.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Por supuesto, si <i>tener</i> no va seguido de un verbo no puede usarse <i>to</i> después de <i>have</i> en inglés.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Tengo comida.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I have food",
            audio: "unit2-282.mp3",
            type: 'link'
          },
          {
            text: "Tengo que comprar comida.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I have to buy food",
            audio: "unit2-283.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '32a',
    items: [
      {
        rows: [
          {
            text: "You have to speak English",
            audio: "unit2-284.mp3"
          },
          {
            text: "He has to speak English",
            audio: "unit2-285.mp3"
          },
          {
            text: "We have to speak English",
            audio: "unit2-286.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You don't have to pay today",
            audio: "unit2-287.mp3"
          },
          {
            text: "Ana doesn't have to pay today",
            audio: "unit2-288.mp3"
          },
          {
            text: "They don't have to pay today",
            audio: "unit2-289.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What do you have to do?",
            audio: "unit2-290.mp3"
          },
          {
            text: "What does he have to do?",
            audio: "unit2-291.mp3"
          },
          {
            text: "What do I have to do?",
            audio: "unit2-292.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't you have to come?",
            audio: "unit2-293.mp3"
          },
          {
            text: "Doesn't she have to come?",
            audio: "unit2-294.mp3"
          },
          {
            text: "Don't I have to come?",
            audio: "unit2-295.mp3"
          },
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '32b',
    items: [
      {
        rows: [
          {text: "Tienes que aprender."},
          {text: "Él tiene que aprender."},
          {text: "Tenemos que aprender."},
        ]
      },
      {
        rows: [
          {text: "No tienes por qué pagar hoy."},
          {text: "Ana no tiene por qué pagar hoy."},
          {text: "Ellos no tienen por qué pagar hoy."},
        ]
      },
      {
        rows: [
          {text: "¿Qué tienes que hacer?"},
          {text: "¿Qué tiene que hacer él?"},
          {text: "¿Qué tengo que hacer?"},
        ]
      },
      {
        rows: [
          {text: "¿No tienes que venir?"},
          {text: "¿Ella no tiene que venir?"},
          {text: "¿No tengo que venir?"},
        ]
      },
    ]
  },
]
