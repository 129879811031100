import { createReducer, createAction, Action, props, on } from '@ngrx/store'
import { nullUser, User } from './model'

export const actions = {
  init: createAction('[User] init'),
  loaded: createAction('[User] loaded'),
  auth: createAction('[User] auth'),
  signIn: createAction('[User] signIn', props<{referrer: string}>()),
  googleSignIn: createAction('[User] googleAuth'),
  localSignIn: createAction('[User] auth', props<{email: string, password: string}>()),
  localSignUp: createAction('[User] register', props<{email: string, password: string}>()),
  logIn: createAction('[User] logIn', props<{user: User}>()),
  logOut: createAction('[User] logOut'),
  error: createAction('[User] error', props<{code: string, message: string}>()),
  signUp: createAction('[User] signUp', props<{user: User, provider: string}>())
}

const reducerSingleton = createReducer(
    nullUser as User,
    on(actions.logOut, _ => nullUser),
  on(actions.logIn, (_, { user }) => user),
  on(actions.signUp, (_, { user }) => user)
)

export function reducer(state: User | undefined, action: Action) {
    return reducerSingleton(state, action)
}
