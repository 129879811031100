export const there_is_are: any = [
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'A diferencia del español,  <b><i>Hay</i></b>,  así como el pasado  <b><i>Había</i></b>  / <b><i>Hubo</i></b>, se expresa de dos maneras según el complemento sea singular o plural.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>There's</b> enough food",
            audio:"unit3-632.mp3"
          },
          {
            level:'secondary',
            text: "Hay suficiente comida",
            audio:"none"
          },
          {
            text: "<b>There isn't</b> enough food",
            audio:"unit3-633.mp3"
          },
          {
            level:'secondary',
            text: "No hay suficiente comida",
            audio:"none"
          },
          {
            text: "<b>Is there</b> enough food?",
            audio:"unit3-634.mp3"
          },
          {
            level:'secondary',
            text: "¿Hay suficiente comida?",
            audio:"none"
          },
          {
            text: "<b>Isn't</b> there enough food?",
            audio:"unit3-635.mp3"
          },
          {
            level:'secondary',
            text: "¿No hay suficiente comida?",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>There are</b> many mistakes",
            audio:"unit3-636.mp3"
          },
          {
            level:'secondary',
            text: "Hay muchos errores.",
            audio:"none"
          },
          {
            text: "<b>There aren't</b> many mistakes",
            audio:"unit3-637.mp3"
          },
          {
            level:'secondary',
            text: "No hay muchos errores.",
            audio:"none"
          },
          {
            text: "<b>Are there</b> many mistakes?",
            audio:"unit3-638.mp3"
          },
          {
            level:'secondary',
            text: "¿Hay muchos errores?",
            audio:"none"
          },
          {
            text: "<b>Aren't there</b> many mistakes?",
            audio:"unit3-639.mp3"
          },
          {
            level:'secondary',
            text: "¿No hay muchos errores?",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>There was</b> a lot of traffic",
            audio:"unit3-640.mp3"
          },
          {
            level:'secondary',
            text: "Había mucho tráfico.",
            audio:"none"
          },
          {
            text: "<b>There wasn't</b> a lot of traffic",
            audio:"unit3-641.mp3"
          },
          {
            level:'secondary',
            text: "No había mucho tráfico.",
            audio:"none"
          },
          {
            text: "<b>Was there</b> a lot of traffic?",
            audio:"unit3-642.mp3"
          },
          {
            level:'secondary',
            text: "¿Había mucho tráfico?",
            audio:"none"
          },
          {
            text: "<b>Wasn't there</b> a lot of traffic?",
            audio:"unit3-643.mp3"
          },
          {
            level:'secondary',
            text: "¿No había mucho tráfico?",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>There were</b> many people",
            audio:"unit3-644.mp3"
          },
          {
            level:'secondary',
            text: "Había mucha gente.",
            audio:"none"
          },
          {
            text: "<b>There weren't</b> many people",
            audio:"unit3-645.mp3"
          },
          {
            level:'secondary',
            text: "No había mucha gente.",
            audio:"none"
          },
          {
            text: "<b>Were there</b> many people?",
            audio:"unit3-646.mp3"
          },
          {
            level:'secondary',
            text: "¿Había mucha gente?",
            audio:"none"
          },
          {
            text: "<b>Weren't there</b> many people?",
            audio:"unit3-647.mp3"
          },
          {
            level:'secondary',
            text: "¿No había mucha gente?",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '15',
    items: [
      {
        rows: [
          {
            text: "There's enough time",
            audio: "unit3-433.mp3"
          },
          {
            text: "There isn't enough time",
            audio: "unit3-434.mp3"
          },
          {
            text: "Is there enough time?",
            audio: "unit3-435.mp3"
          },
          {
            text: "Isn't there enough time?",
            audio: "unit3-436.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There are many people",
            audio: "unit3-437.mp3"
          },
          {
            text: "There aren't many people",
            audio: "unit3-438.mp3"
          },
          {
            text: "Are there many people?",
            audio: "unit3-439.mp3"
          },
          {
            text: "Aren't there many people?",
            audio: "unit3-440.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There was enough time",
            audio: "unit3-441.mp3"
          },
          {
            text: "There wasn't enough time",
            audio: "unit3-442.mp3"
          },
          {
            text: "Was there enough time?",
            audio: "unit3-443.mp3"
          },
          {
            text: "Wasn't there enough time?",
            audio: "unit3-444.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There were many people",
            audio: "unit3-445.mp3"
          },
          {
            text: "There weren't many people",
            audio: "unit3-446.mp3"
          },
          {
            text: "Were there many people?",
            audio: "unit3-447.mp3"
          },
          {
            text: "Weren't there many people?",
            audio: "unit3-448.mp3"
          },
        ]
      },
    ]
  },
  {
    type: 'structures',
    id: '18',
    title: true,
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>There is &#8230;</b> / <b>There are &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Hay &#8230;",
          },
          {
            text:"<b>There isn't &#8230;</b> / <b>There aren't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No hay &#8230;",
          },
          {
            text:"<b>Is there &#8230;</b> / <b>Are there &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿Hay &#8230;?",
          },
          {
            text:"<b>Isn't there &#8230;</b> / <b>Aren't there &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿No hay &#8230;?",
          },
          {
            text: "",
          },
          {
            text:"<b>There was &#8230;</b> / <b>There were &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Había &#8230;",
          },
          {
            text:"<b>There wasn't &#8230;</b> / <b>There weren't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No había &#8230;",
          },
          {
            text:"<b>Was there &#8230;</b> / <b>Were there &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿Había &#8230;?",
          },
          {
            text:"<b>Wasn't there &#8230;</b> / <b>Weren't  there &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿No había &#8230;?",
          },
          {
            text: "<br/>",
          },
          {
            text: "Contracción opcional:  <b>There's &#8230;</b>",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '16',
    items: [
      {
        rows: [
          {text: "Hay suficiente tiempo."},
          {text: "No hay suficiente tiempo."},
          {text: "¿Hay suficiente tiempo?"},
          {text: "¿No hay suficiente tiempo?"},
        ]
      },
      {
        rows: [
          {text: "Hay alguien en casa."},
          {text: "No hay nadie en casa."},
          {text: "¿Hay alguien en casa?"},
          {text: "¿No hay nadie en casa?"},
        ]
      },
      {
        rows: [
          {text: "Había suficiente agua."},
          {text: "No había suficiente agua."},
          {text: "¿Había suficiente agua?"},
          {text: "¿No había suficiente agua?"},
        ]
      },
      {
        rows: [
          {text: "Había algo para ti."},
          {text: "No había nada para ti."},
          {text: "¿Había algo para mí?"},
          {text: "¿No había nada para mí?"},
        ]
      },
      {
        rows: [
          {text: "Hay mucha gente."},
          {text: "No hay mucha gente."},
          {text: "¿Hay mucha gente?"},
          {text: "¿No hay mucha gente?"},
        ]
      },
      {
        rows: [
          {text: "Había mucha gente."},
          {text: "No había mucha gente."},
          {text: "¿Había mucha gente?"},
          {text: "¿No había mucha gente?"},
        ]
      },
      {
        rows: [
          {text: "Hay muchas palabras nuevas."},
          {text: "No hay muchas palabras nuevas."},
          {text: "¿Hay muchas palabras nuevas?"},
          {text: "¿No hay muchas palabras nuevas?"},
        ]
      },
      {
        rows: [
          {text: "Hubo muchas preguntas."},
          {text: "No hubo muchas preguntas."},
          {text: "¿Hubo muchas preguntas?"},
          {text: "¿No hubo muchas preguntas?"},
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '17',
    items: [
      {
        rows: [
          {text: "Hay algo que quiero decirte."},
          {text: "Hay muchas cosas que quiero decirte."},
          {text: "Había muchas palabras nuevas."},
        ]
      },
      {
        rows: [
          {text: "No hay suficiente tiempo."},
          {text: "No hubo suficiente tiempo."},
        ]
      },
      {
        rows: [
          {text: "No hay mucha gente."},
          {text: "No había mucha gente."},
        ]
      },
      {
        rows: [
          {text: "¿Cuánta plata hay?"},
          {text: "¿Cuánta plata había?"},
          {text: "¿Recuerdas cuánta plata había?"},
        ]
      },
      {
        rows: [
          {text: "¿Cuánta gente hay?"},
          {text: "¿Cuánta gente había?"},
          {text: "¿Recuerdas cuánta gente había?"},
        ]
      },
      {
        rows: [
          {text: "¿Hay muchos niños?"},
          {text: "¿Había muchos niños?"},
        ]
      },
      {
        rows: [
          {text: "Como dije, no había mucho tráfico."},
        ]
      },
      {
        rows: [
          {text: "¿No hay nada para mí?"},
          {text: "¿No había nadie en casa?"},
        ]
      },
      {
        rows: [
          {text: "Hay muchos problemas."},
        ]
      },
      {
        rows: [
          {text: "Claro que había más plata."},
        ]
      },
      {
        rows: [
          {text: "Hay un problema."},
          {text: "No me digas que hubo un problema."},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cuando en español no haya ninguna palabra entre <i>Hay</i> y el complemento, en inglés se usa generalmente: "
          }
        ]
      },

      {
        type: 'columns-2',
        display:"left",
          rows: [
            {
              left: {
                tokens: [
                  {
                    text:"some",
                    audio:"unit3-603.mp3",
                    type:"link"
                  }
                ]
              },
              right: {
                tokens: [
                  {
                    text:"en el afirmativo",
                    audio:"none"
                  }
                ]
              }
            },
            {
              left: {
                tokens: [
                  {
                    text:"no",
                    audio:"unit3-604.mp3",
                    type:"link"
                  }
                ]
              },
              right: {
                tokens: [
                  {
                    text:"en el negativo",
                    audio:"none"
                  }
                ]
              }
            },
            {
              left: {
                tokens: [
                  {
                    text:"any",
                    audio:"unit3-605.mp3",
                    type:"link"
                  }
                ]
              },
              right: {
                tokens: [
                  {
                    text:"en las formas <br/>interrogativas",
                    audio:"none"
                  }
                ]
              }
            }
        ]
      },

      {
        type: 'p_link',
        tokens: [
          {
            text: "Esto es especialmente válido cuando se habla de comidas y bebidas.",
          }
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "<i>Hay café</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There is <b>some</b> coffee",
            audio:"unit3-450.mp3"
          },
          {
            text: "<i>No hay café</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There's <b>no</b> coffee",
            audio:"unit3-451.mp3"
          },
          {
            text: "<i>¿Hay café?</i>",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Is there <b>any</b> coffee?",
            audio:"unit3-452.mp3"
          },
          {
            text: "<i>¿No hay café?</i>",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Isn't there <b>any</b> coffee?",
            audio:"unit3-606.mp3"
          },
          {
            text: "",
            audio: "none",
          },
          {
            text: "<i>Hubo algunos problemas</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There were <b>some</b> problems",
            audio:"unit3-607.mp3"
          },
          {
            text: "<i>No hubo problemas</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There were <b>no</b> problems",
            audio:"unit3-608.mp3"
          },
          {
            text: "<i>¿Hubo problemas?</i>",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Were there <b>any</b> problems?",
            audio:"unit3-609.mp3"
          },
          {
            text: "<i>¿No hubo problemas?</i>",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Weren't there <b>any</b> problems?",
            audio:"unit3-610.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "De estas estructuras, las más utilizadas son las negativas.",
          }
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "<i>No hay tiempo</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There's no time",
            audio:"unit3-453.mp3"
          },
          {
            text: "<i>No hubo clase</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There was no class",
            audio:"unit3-458.mp3"
          },
          {
            text: "<i>No hay habitaciones</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There are no rooms",
            audio:"unit3-611.mp3"
          },
          {
            text: "<i>No hubo juegos hoy</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There were no games today",
            audio:"unit3-612.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "En estas estructuras, <b>Nobody</b> y <b>Nothing</b> son la primera opción para expresar <i>nadie</i> y <i>nada</i>:",
          }
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "<i>No había nadie en la casa</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There was nobody in the house",
            audio:"unit3-613.mp3"
          },
          {
            text: "<i>No hay nada allá</i>.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "There's nothing there",
            audio:"unit3-614.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '18',
    items: [
      {
        rows: [
          {
            text: "There's no time",
            audio: "unit3-453.mp3"
          },
          {
            text: "There was no time",
            audio: "unit3-454.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There are no problems",
            audio: "unit3-455.mp3"
          },
          {
            text: "There were no problems",
            audio: "unit3-456.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There's no class",
            audio: "unit3-457.mp3"
          },
          {
            text: "There was no class",
            audio: "unit3-458.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There's no traffic",
            audio: "unit3-630.mp3"
          },
          {
            text: "There was no traffic",
            audio: "unit3-631.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There is nobody there",
            audio: "unit3-459.mp3"
          },
          {
            text: "There was nobody there",
            audio: "unit3-460.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There are no mistakes",
            audio: "unit3-461.mp3"
          },
          {
            text: "There were no mistakes",
            audio: "unit3-462.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There's nothing there",
            audio: "unit3-463.mp3"
          },
          {
            text: "There was nothing there",
            audio: "unit3-464.mp3"
          },
        ]
      },
    ]
  },
]
