import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { CheckboxWrapperComponent } from '../components/checkbox-wrapper/checkbox-wrapper.component'
import { CountdownTimerComponent } from '../components/countdown-timer/countdown-timer.component'
import { LoginComponent } from '../components/login/login.component'
import { PasswordComponent } from '../components/password/password.component'
import { SignupComponent } from '../components/signup/signup.component'
import { IconsModule } from '../icons/icons.module'
import { ModalShellComponent } from '../shells/modal-shell/modal-shell.component'
import { TabsShellComponent } from '../shells/tabs-shell/tabs-shell.component'


const modules = [
  CommonModule,
  FormsModule,
  IonicModule,
  IconsModule,
  FormsModule,
  ReactiveFormsModule
]

const components = [
  CheckboxWrapperComponent,
  CountdownTimerComponent,
  ModalShellComponent,
  TabsShellComponent,
  LoginComponent,
  SignupComponent,
  PasswordComponent
]

const providers = []

@NgModule({
  declarations: [ ...components ],
  imports: [ ...modules ],
  exports: [ ...components, ...modules ],
  providers: [...providers]

})
export class SharedModule { }
