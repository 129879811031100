export const to_be_extra_2: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>I'm hungry</b>",
            audio:"unit3-395.mp3"
          },
          {
            level:'secondary',
            text: "Tengo hambre.",
            audio:"none"
          },
          {
            text: "<b>You'll be thirsty</b>",
            audio:"unit3-396.mp3"
          },
          {
            level:'secondary',
            text: "Te dará sed.",
            audio:"none"
          },
          {
            text: "<b>I wasn't sleepy</b>",
            audio:"unit3-397.mp3"
          },
          {
            level:'secondary',
            text: "Yo no tenía sueño.",
            audio:"none"
          },
          {
            text: "<b>Aren't you warm?</b>",
            audio:"unit3-398.mp3"
          },
          {
            level:'secondary',
            text: "¿No tienes calor?",
            audio:"none"
          },
          {
            text: "<b>You must be cold</b>",
            audio:"unit3-399.mp3"
          },
          {
            level:'secondary',
            text: "Debes tener frío.",
            audio:"none"
          },
          {
            text: "<b>You were right</b>",
            audio:"unit3-400.mp3"
          },
          {
            level:'secondary',
            text: "Tú tenías razón.",
            audio:"none"
          },
          {
            text: "<b>It's cold</b>",
            audio:"unit3-401.mp3"
          },
          {
            level:'secondary',
            text: "Hace frío.",
            audio:"none"
          },
          {
            text: "<b>It was hot</b>",
            audio:"unit3-402.mp3"
          },
          {
            level:'secondary',
            text: "Hizo calor.",
            audio:"none"
          },
          {
            text: "<b>How old is he?</b>",
            audio:"unit3-403.mp3"
          },
          {
            level:'secondary',
            text: "¿Cuántos años tiene él?",
            audio:"none"
          },
          {
            text: "<b>He's 6 year old</b>",
            audio:"unit3-561.mp3"
          },
          {
            level:'secondary',
            text: "Él tiene 6 años.",
            audio:"none"
          },
          {
            text: "<b>She was born there</b>",
            audio:"unit3-405.mp3"
          },
          {
            level:'secondary',
            text: "Ella nació allá.",
            audio:"none"
          },

        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cuando en español <b><i>tener</i></b> implique <b><i>sensaciones</i></b>,  deberá usarse en inglés  <b><i>to be</i></b>   en lugar de  <i>to have</i>. Esta indicación puede servir para aclarar un poco el concepto en inglés, pero en definitiva es la práctica lo que va a proporcionar un perfecto dominio de estos giros. Ejemplo:"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Tengo sueño.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I'm sleepy</b>",
            audio:"unit3-291.mp3"
          },
          {
            text: "Tengo uno.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I have one",
            audio:"unit3-298.mp3"
          },
          {
            text: "No puedes tener hambre.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You can't <b>be hungry</b>",
            audio:"unit3-293.mp3"
          },
          {
            text: "No puedes tener clase.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You can't have class",
            audio:"unit3-294.mp3"
          },
          {
            text: "¿Tienes frío?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Are you cold?</b>",
            audio:"unit3-295.mp3"
          },
          {
            text: "¿Tienes dinero?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you have money?",
            audio:"unit3-296.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '19',
    items: [
      {
        rows: [
          {text: "Tengo hambre."},
          {text: "Tengo frío."},
          {text: "Tengo razón."},
          {text: "Tengo sed."},
          {text: "Tengo sueño."},
          {text: "Tengo calor."},
        ]
      },
      {
        rows: [
          {text: "Debes tener sueño."},
          {text: "Debes tener sed."},
          {text: "Debes tener hambre."},
          {text: "Debes tener razón."},
          {text: "Debes tener frío."},
          {text: "Debes tener calor."},
        ]
      },
      {
        rows: [
          {text: "¿No tienes frío?"},
          {text: "¿No tienes sueño?"},
          {text: "¿No tienes calor?"},
          {text: "¿No tienes hambre?"},
          {text: "¿No tienes sed?"},
        ]
      },
      {
        rows: [
          {text: "(Yo) no tenía sueño. "},
          {text: "(Yo) no tenía sed."},
          {text: "(Yo) no tenía frío."},
          {text: "(Yo) no tenía hambre."},
          {text: "(Yo) no tenía calor."},
        ]
      },
      {
        rows: [
          {text: "No me digas que tienes sueño."},
          {text: "No me digas que tienes sed."},
          {text: "No me digas que tienes hambre."},
          {text: "No me digas que tienes razón."},
          {text: "No me digas que tienes frío."},
          {text: "No me digas que tienes calor."},
        ]
      },
      {
        rows: [
          {text: "Te dará sueño."},
          {text: "Te dará sed."},
          {text: "Te dará hambre."},
          {text: "Estarás en lo correcto."},
          {text: "Te dará frío."},
          {text: "Te dará calor."},
        ]
      },
      {
        rows: [
          {text: "¿Quién tiene 20 años?"},
          {text: "¿Quién tiene sueño?"},
          {text: "¿Quién tiene sed?"},
          {text: "¿Quién tiene hambre?"},
          {text: "¿Quién tiene razón?"},
          {text: "¿Quién tiene frío?"},
          {text: "¿Quién tiene calor?"},
        ]
      },
      {
        rows: [
          {text: "¿Cómo puedes tener sueño?"},
          {text: "¿Cómo puedes tener sed?"},
          {text: "¿Cómo puedes tener  hambre?"},
          {text: "¿Cómo puedes tener razón?"},
          {text: "¿Cómo puedes tener frío?"},
          {text: "¿Cómo puedes tener calor?"},
        ]
      },
      {
        rows: [
          {text: "No teníamos sueño."},
          {text: "No teníamos sed."},
          {text: "No teníamos hambre."},
          {text: "No teníamos razón."},
          {text: "No teníamos frío."},
          {text: "No teníamos calor."},
        ]
      },
      {
        rows: [
          {text: "¿No tenías sueño?"},
          {text: "¿No tenías sed?"},
          {text: "¿No tenías hambre?"},
          {text: "¿No tenías razón?"},
          {text: "¿No tenías frío?"},
          {text: "¿No tenías calor?"},
        ]
      },
      {
        rows: [
          {text: "Tu mamá tiene razón."},
          {text: "Mis padres tenían razón."},
          {text: "Si él tiene razón, yo estoy equivocado."},
          {text: "Yo estaba seguro que yo tenía razón."},
        ]
      },
      {
        rows: [
          {text: "¿Cuántos años tiene él?"},
          {text: "Él tiene 13 años."},
          {text: "¿Cuánto años tenías tú?"},
          {text: "Yo tenía 11 ó 12 años."},
        ]
      },
      {
        rows: [
          {text: "Hace calor hoy."},
          {text: "Hizo calor ayer."},
          {text: "Hará calor mañana."},
          {text: "Debe hacer frío allá."},
          {text: "No hará frío allá."},
        ]
      },
    ]
  },
  {
    type:'english',
    id: '20',
    items: [
      {
        rows: [
          {
            text: "I'm thirsty",
            audio: "unit3-297.mp3"
          },
          {
            text: "I have one",
            audio: "unit3-298.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There's something I don't understand",
            audio: "unit3-299.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Where are you going?",
            audio: "unit3-465.mp3"
          },
          {
            text: "Do you know where we are going?",
            audio: "unit3-301.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He's 2 years old",
            audio: "unit3-302.mp3"
          },
          {
            text: "He has the car",
            audio: "unit3-303.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There are many problems",
            audio: "unit3-304.mp3"
          },
          {
            text: "There were many problems",
            audio: "unit3-305.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He isn't coming today",
            audio: "unit3-306.mp3"
          },
          {
            text: "He doesn't come every day",
            audio: "unit3-307.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm not sleepy",
            audio: "unit3-308.mp3"
          },
          {
            text: "I don't have it",
            audio: "unit3-309.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There's no water",
            audio: "unit3-310.mp3"
          },
          {
            text: "There was no time",
            audio: "unit3-454.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Start using it",
            audio: "unit3-312.mp3"
          },
          {
            text: "Keep using it",
            audio: "unit3-313.mp3"
          },
          {
            text: "Stop using it",
            audio: "unit3-314.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He says he isn't cold",
            audio: "unit3-315.mp3"
          },
          {
            text: "He says he doesn't have class",
            audio: "unit3-316.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "There are no games today",
            audio: "unit3-317.mp3"
          },
          {
            text: "There were no games today",
            audio: "unit3-318.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What's wrong?",
            audio: "unit3-485.mp3"
          },
          {
            text: "What's the matter with you?",
            audio: "unit3-320.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you hungry?",
            audio: "unit3-321.mp3"
          },
          {
            text: "Do you have another pencil?",
            audio: "unit3-322.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is there class?",
            audio: "unit3-323.mp3"
          },
          {
            text: "Was there class?",
            audio: "unit3-324.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What are you looking for?",
            audio: "unit3-489.mp3"
          },
          {
            text: "What's going on here?",
            audio: "unit3-487.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How old is he?",
            audio: "unit3-403.mp3"
          },
          {
            text: "How many cars does he have?",
            audio: "unit3-328.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't there another teacher?",
            audio: "unit3-329.mp3"
          },
          {
            text: "Wasn't there another teacher?",
            audio: "unit3-330.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm just kidding",
            audio: "unit3-494.mp3"
          },
          {
            text: "I wasn't listening",
            audio: "unit3-332.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't you warm?",
            audio: "unit3-398.mp3"
          },
          {
            text: "Don't you have another brother?",
            audio: "unit3-334.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I was sure there was something for me",
            audio: "unit3-335.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why are you crying?",
            audio: "unit3-493.mp3"
          },
          {
            text: "I don't want you to cry for me",
            audio: "unit3-337.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't she hungry?",
            audio: "unit3-338.mp3"
          },
          {
            text: "Doesn't she have an uncle in Madrid?",
            audio: "unit3-339.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't there anybody at home?",
            audio: "unit3-340.mp3"
          },
          {
            text: "Wasn't there anybody at home?",
            audio: "unit3-341.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I think you're right",
            audio: "unit3-342.mp3"
          },
          {
            text: "I have to say that you were right",
            audio: "unit3-343.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Is there something I can do?",
            audio: "unit3-344.mp3"
          },
          {
            text: "You must be cold",
            audio: "unit3-284.mp3"
          },
          {
            text: "You must have my book",
            audio: "unit3-345.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Isn't there anything you need?",
            audio: "unit3-346.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "You're going to be thirsty",
            audio: "unit3-347.mp3"
          },
          {
            text: "You're going to have to study",
            audio: "unit3-348.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "The story was hard because there were many new words",
            audio: "unit3-349.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you sure he's 18?",
            audio: "unit3-350.mp3"
          },
          {
            text: "Are you sure he has our phone number?",
            audio: "unit3-351.mp3"
          },
        ]
      },
    ]
  },
]
