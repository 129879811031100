export const expressions: any = [
  {
    type: 'card',
    items: [

      {
        type: 'tokenized_example',
        rows: [
          {
            tokens:[
              {
                text: "Hello<br/>",
                audio: "unit1-558.mp3",
                type: 'link'
              },
              {
                text: "Hi",
                audio: "unit1-559.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hola",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>How are you?",
                audio: "unit1-731.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo estás?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Fine, thank you.",
                audio: "unit1-560.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Bien, gracias",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Pleased to meet you",
                audio: "unit1-561.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Mucho gusto",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "It was nice meeting you",
                audio: "unit1-562.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Fue un placer",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Good morning",
                audio: "unit1-563.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Buenos días",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Good afternoon",
                audio: "unit1-564.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Buenas tardes",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Good evening",
                audio: "unit1-565.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Buenas noches",
                audio:"none"
              }
            ]
          },


          {
            tokens:[
              {
                text: "<br/>Bye<br/>",
                audio: "unit1-566.mp3",
                type: 'link'
              },
              {
                text: "Goodbye",
                audio: "unit1-567.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Chao",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Take care",
                audio: "unit1-568.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Cuídate",
                audio:"none"
              }
            ]
          },


          {
            tokens:[
              {
                text: "<br/>See you on Monday",
                audio: "unit1-569.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el lunes",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you on Tuesday",
                audio: "unit1-570.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el martes",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "See you on Wednesday",
                audio: "unit1-571.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el miércoles",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you on Thursday",
                audio: "unit1-572.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el jueves",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you on Friday",
                audio: "unit1-573.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el viernes",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you on Saturday",
                audio: "unit1-574.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el sábado",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you on Sunday",
                audio: "unit1-575.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta el domingo",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you tomorrow",
                audio: "unit1-576.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta mañana",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "See you later",
                audio: "unit1-577.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hasta luego",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Come on, let's go",
                audio: "unit1-578.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Anda, vámonos",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Have a nice day",
                audio: "unit1-579.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Buen día",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "You too",
                audio: "unit1-580.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Igualmente",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Pardon me?",
                audio: "unit1-581.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo dice?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Excuse me",
                audio: "unit1-582.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Permiso",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Yes",
                audio: "unit1-583.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Si",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "No",
                audio: "unit1-584.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "No",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Please",
                audio: "unit1-585.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Por favor",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Again?",
                audio: "unit1-586.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Otra vez?",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Thanks",
                audio: "unit1-587.mp3",
                type: 'link'
              },
              {
                text: "<br/>Thank you",
                audio: "unit1-588.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Gracias",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "You're welcome",
                audio: "unit1-589.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "A la orden",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "<br/>Sorry",
                audio: "unit1-590.mp3",
                type: 'link'
              },
              {
                text: "<br/>I'm sorry",
                audio: "unit1-591.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Lo siento",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "<br/>Here you are",
                audio: "unit1-592.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Aquí tiene",
                audio:"none"
              }
            ]
          },


          {
            tokens:[
              {
                text: "<br/>Ready?",
                audio: "unit1-593.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Listo?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Wait",
                audio: "unit1-594.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Ya va",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Hurry up",
                audio: "unit1-595.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Apúrate",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "Right now?",
                audio: "unit1-768.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Ahora mismo?",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "Not today",
                audio: "unit1-595.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Hoy no",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>What's your name?",
                audio: "unit1-596.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo te llamas?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "My name is",
                audio: "unit1-597.mp3",
                type: 'link'
              },
              {
                text: " &#8230;",
                audio:"none"
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Me llamo &#8230;",
                audio:"none"
              }
            ]
          },


          {
            tokens:[
              {
                text: "<br/>How do you say &#8230;?",
                audio: "unit1-598.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo se dice &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "How do you spell &#8230;?",
                audio: "unit1-599.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo se escribe &#8230;?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "How do you pronounce &#8230;?",
                audio: "unit1-600.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Cómo se pronuncia &#8230;?",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "What's &#8230;?",
                audio: "unit1-601.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Qué significa &#8230;?",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Could you repeat that, please?",
                audio: "unit1-602.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "¿Podría repetir, por favor?",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Of course",
                audio: "unit1-603.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Claro",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>I think so",
                audio: "unit1-604.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Creo que sí",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "I don't think so",
                audio: "unit1-605.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Creo que no",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Hey",
                audio: "unit1-606.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Oye",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Don't worry",
                audio: "unit1-607.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "No te preocupes",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "I mean",
                audio: "unit1-608.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "O sea",
                audio:"none"
              }
            ]
          },

          {
            tokens:[
              {
                text: "<br/>Go ahead",
                audio: "unit1-609.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Dale",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Me too",
                audio: "unit1-610.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Yo también",
                audio:"none"
              }
            ]
          },
          {
            tokens:[
              {
                text: "Me neither",
                audio: "unit1-611.mp3",
                type: 'link'
              }
            ]
          },
          {
            level:'secondary',
            tokens:[
              {
                text: "Yo tampoco",
                audio:"none"
              }
            ]
          },


        ]
      },
    ]
  }
]
