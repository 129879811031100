export const past_ing: any = [
  {
    type: 'card',
    title:"El pasado contínuo",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Como se vio anteriormente, en el pasado continuo tienen que usarse las distintas formas del <b>pasado</b> de <b>to be</b>.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Lo hicimos.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>We did it</b>",
            audio:"unit4-398.mp3"
          },
          {
            text: "Lo estábamos haciendo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>We were doing it</b>",
            audio:"unit4-399.mp3"
          },

        ]
      },
    ]
  },
  {
    type:'translate',
    id: '28',
    items: [
      {
        rows: [
          {text:'Te esperé.'},
          {text:'Yo te estaba esperando.'},
          {text:'Ellos ganaron.'},
          {text:'Ellos estaban ganando.'},
        ]
      },
      {
        rows: [
          {text:'No lo leímos.'},
          {text:'No lo estábamos leyendo.'},
          {text:'No manejé.'},
          {text:'Yo no estaba manejando.'},
        ]
      },
      {
        rows: [
          {text:'¿Qué comiste?'},
          {text:'¿Qué estabas comiendo?'},
          {text:'¿Quién usó la computadora?'},
          {text:'¿Quién estaba usando la computadora?'},
        ]
      },
      {
        rows: [
          {text:'¿No leíste la historia?'},
          {text:'¿No estabas leyendo la historia?'},
          {text:'¿Él no trabajó allá?'},
          {text:'¿Él no estaba trabajando allá?'},
        ]
      },
    ]
  },
]
