export const pp_extra_1: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Tengo tiempo que no &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I haven't &#8230 <b>for a long time</b>",
            audio:"none"
          },
          {
            text: "Él no &#8230 desde entonces",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He hasn't &#8230 <b>since then</b>",
            audio:"none"
          },

        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'When using <i>for a long time</i> and <i>since then</i>, the verb must always be in the present perfect.'
          }
        ]
      },
    ]
  },

  {
    type:'english',
    id: '46',
    items: [
      {
        rows: [
          {
            text: "I haven't played for a long time",
            audio: "unit5-119.mp3"
          },
          {
            text: "I haven't played since then",
            audio: "unit5-120.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "She hasn't called for a long time",
            audio: "unit5-121.mp3"
          },
          {
            text: "She hasn't called since then",
            audio: "unit5-122.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "They haven't had class for a long time",
            audio: "unit5-123.mp3"
          },
          {
            text: "They haven't had class since then",
            audio: "unit5-124.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "He hasn't come for a long time",
            audio: "unit5-125.mp3"
          },
          {
            text: "He hasn't come since then",
            audio: "unit5-126.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "We haven't seen her for a long time",
            audio: "unit5-127.mp3"
          },
          {
            text: "We haven't seen her since then",
            audio: "unit5-128.mp3"
          }
        ]
      },
    ]
  },

  {
    type:'translate',
    id: '47',
    items: [
      {
        rows: [
          {text:'Tengo tiempo que no juego.'},
          {text:'No juego desde entonces.'},
        ]
      },
      {
        rows: [
          {text:'Ella tiene tiempo que no llama.'},
          {text:'Ella no llama desde entonces.'},
        ]
      },
      {
        rows: [
          {text:'Ellos tienen tiempo sin clase.'},
          {text:'Ellos no tienen clase desde entonces.'},
        ]
      },
      {
        rows: [
          {text:'Hace tiempo que no llueve.'},
          {text:'No ha llovido desde entonces.'},
        ]
      },
      {
        rows: [
          {text:'No la vemos desde hace tiempo.'},
          {text:'No la hemos visto desde entonces.'},
        ]
      },
    ]
  },
]
