export const past_negative_interrogative: any = [
  {
    type: 'card',
    title: 'Negativo e interrogativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa "
          },
          {
            type: "link",
            text: "<b>didn't</b>",
            audio: "unit4-516.mp3"
          },
          {
            text: " en lugar de <b>don't</b>/<b>doesn't</b> y "
          },
          {
            type: "link",
            text: "<b>did</b>",
            audio: "unit4-517.mp3"
          },
          {
            text: " en lugar de <b>do</b>/<b>does</b>."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No estudié.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I didn't study</b>",
            audio: "unit4-2.mp3",
            type: 'link'
          },
          {
            text: "Ella no sabía.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She didn't know</b>",
            audio: "unit4-3.mp3",
            type: 'link'
          },
          {
            text: "¿Entendiste?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Did you understand?</b>",
            audio: "unit4-4.mp3",
            type: 'link'
          },
          {
            text: "¿Qué dijo él?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>What did he say?</b>",
            audio: "unit4-5.mp3",
            type: 'link'
          },
          {
            text: "¿No lo hiciste?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Didn't you do it?</b>",
            audio: "unit4-6.mp3",
            type: 'link'
          },
          {
            text: "¿Por qué ella no vino?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Why didn't she come?</b>",
            audio: "unit4-7.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '21a',
    items: [
      {
        rows: [
          {
            text: "I didn't hear you",
            audio: "unit4-8.mp3"
          },
          {
            text: "You didn't understand me",
            audio: "unit4-9.mp3"
          },
          {
            text: "He didn't come",
            audio: "unit4-316.mp3"
          },
          {
            text: "We didn't have class",
            audio: "unit4-11.mp3"
          },
          {
            text: "They didn't play well",
            audio: "unit4-12.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "Did you study?",
            audio: "unit4-13.mp3"
          },
          {
            text: "Where did she go?",
            audio: "unit4-14.mp3"
          },
          {
            text: "How long did he stay?",
            audio: "unit4-15.mp3"
          },
          {
            text: "When did they come back?",
            audio: "unit4-16.mp3"
          },
          {
            text: "What did I tell you?",
            audio: "unit4-17.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "Why didn't you wait?",
            audio: "unit4-18.mp3"
          },
          {
            text: "Why didn't he help you?",
            audio: "unit4-19.mp3"
          },
          {
            text: "Why didn't I see you?",
            audio: "unit4-20.mp3"
          },
          {
            text: "Why didn't she go on?",
            audio: "unit4-21.mp3"
          },
          {
            text: "Why didn't they answer?",
            audio: "unit4-22.mp3"
          }
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '21b',
    items: [
      {
        rows: [
          {text:'No fui a trabajar.'},
          {text: "Tú no fuiste a trabajar."},
          {text: "Él no fue a trabajar."},
          {text: "Ellos no fueron a trabajar."}
        ]
      },
      {
        rows: [
          {text:'No se lo dije (a él).'},
          {text:'Ella no vino.'},
          {text:'No hicimos nada.'},
          {text:'Él no siguió.'},
          {text:'No me entendiste.'},
          {text:'Ellos no terminaron el curso.'},
        ]
      },
      {
        rows: [
          {text:'¿Qué dijiste?'},
          {text:'¿Cómo se sintió ella?'},
          {text:'¿Adónde fuimos?'},
          {text:'¿Cuánto gasté?'},
          {text:'¿Cuándo empezaron ellos?'},
        ]
      },
      {
        rows: [
          {text:'¿No presentaste el examen?'},
          {text:'¿Por qué no vinimos el martes?'},
          {text:'¿No te lo dije?'},
          {text:'¿Por qué ellos no preguntaron?'},
          {text:'¿Ella no te llamó?'},
        ]
      },
    ]
  }
]
