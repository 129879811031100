export const past_participle: any = [
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "In Spanish, the <b>past participle</b> is the verbal form ending in <b>-ado</b> / <b>-ido</b>."
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "For most English verbs, the past participle has the same form as the preterite."
          },
        ]
      },
      {
        type: 'columns-3',
        columns: {
          left: '<i>Infinitive</i>',
          middle: '<i>Preterite</i>',
          right: '<i>Past<br/>participle</i>'
        },
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"To call",
                    audio:"unit5-0.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"called",
                    audio:"unit5-4.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>called</b>",
                  audio:"unit5-4.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To bring",
                    audio:"unit5-1.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"brought",
                    audio:"unit5-5.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>brought</b>",
                  audio:"unit5-5.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To start",
                    audio:"unit5-2.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"started",
                    audio:"unit5-6.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>started</b>",
                  audio:"unit5-6.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To pay",
                    audio:"unit5-3.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"paid",
                    audio:"unit5-7.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>paid</b>",
                  audio:"unit5-7.mp3",
                  type: "link",
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "However, like in Spanish - <i>hecho</i>, <i>visto</i>, <i>escrito</i> - some verbs have an <b>irregular past participle</b>. These have to be mastered through practice. The following are the verbs seen in the course which have an irregular past participle."
          },
        ]
      },

      {
        type: 'columns-3',
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"To speak",
                    audio:"unit5-8.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"spoke",
                    audio:"unit5-24.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>spoken</b>",
                  audio:"unit5-159.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To go",
                    audio:"unit5-9.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"went",
                    audio:"unit5-25.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>gone</b>",
                  audio:"unit5-160.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To see",
                    audio:"unit5-10.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"saw",
                    audio:"unit5-26.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>seen</b>",
                  audio:"unit5-161.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To forget",
                    audio:"unit5-11.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"forgot",
                    audio:"unit5-27.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>forgotten</b>",
                  audio:"unit5-162.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To come",
                    audio:"unit5-12.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"came",
                    audio:"unit5-28.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>come</b>",
                  audio:"unit5-163.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To do",
                    audio:"unit5-13.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"did",
                    audio:"unit5-29.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>done</b>",
                  audio:"unit5-164.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To be",
                    audio:"unit5-14.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                {
                  text:"was",
                  audio:"unit5-30.mp3",
                  type: "link",
                },
                {
                  text:"/<br/>",
                },
                {
                  text:"were",
                  audio:"unit5-458.mp3",
                  type: "link",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>been</b>",
                  audio:"unit5-165.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"can",
                    audio:"unit5-15.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"was<br/> able to",
                    audio:"unit5-31.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>been<br/> able to</b>",
                  audio:"unit5-166.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To give",
                    audio:"unit5-16.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"gave",
                    audio:"unit5-32.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>given</b>",
                  audio:"unit5-167.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To eat",
                    audio:"unit5-17.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"ate",
                    audio:"unit5-33.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>eaten</b>",
                  audio:"unit5-168.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To drink",
                    audio:"unit5-18.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"drank",
                    audio:"unit5-34.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>drunk</b>",
                  audio:"unit5-169.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To get up",
                    audio:"unit5-19.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"got up",
                    audio:"unit5-35.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>gotten up</b>",
                  audio:"unit5-170.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To take",
                    audio:"unit5-20.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"took",
                    audio:"unit5-36.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>taken</b>",
                  audio:"unit5-171.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To drive",
                    audio:"unit5-21.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"drove",
                    audio:"unit5-37.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>driven</b>",
                  audio:"unit5-172.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To write",
                    audio:"unit5-22.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"wrote",
                    audio:"unit5-38.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>written</b>",
                  audio:"unit5-173.mp3",
                  type: "link",
                }
              ]
            }
          },

          {
            left: {
              tokens: [
                  {
                    text:"To know",
                    audio:"unit5-23.mp3",
                    type: "link",
                  }
              ]
            },
            middle: {
              tokens: [
                  {
                    text:"knew",
                    audio:"unit5-39.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>known</b>",
                  audio:"unit5-174.mp3",
                  type: "link",
                }
              ]
            }
          },
        ]
      }
    ]
  },

  {
    type:'english',
    id: '45',
    items: [
      {
        rows: [
          {
            text: "spoken",
            audio: "unit5-40.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "gone",
            audio: "unit5-41.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "seen",
            audio: "unit5-42.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "forgotten",
            audio: "unit5-43.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "done",
            audio: "unit5-44.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "been",
            audio: "unit5-45.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "been able to",
            audio: "unit5-46.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "given",
            audio: "unit5-47.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "eaten",
            audio: "unit5-48.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "drunk",
            audio: "unit5-49.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "gotten up",
            audio: "unit5-50.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "taken",
            audio: "unit5-51.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "driven",
            audio: "unit5-52.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "written",
            audio: "unit5-53.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "known",
            audio: "unit5-54.mp3"
          }
        ]
      },
    ]
  }
]
