export const structures_8: any = [
  {
    type: 'structures',
    id: '8',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>You have to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Tienes que &#8230;ar/er/ir",
          },
          {
            text:"<b>You don't have to </b> &#8230;",
          },
          {
            level:'secondary',
            text: "No tienes por qué &#8230;ar/er/ir",
          },
          {
            text: "<b>Do you have to </b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Tienes que &#8230;ar/er/ir?",
          },
          {
            text: "<b>Don't you have to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No tienes que &#8230;ar/er/ir?",
          },
          {
            text: "<b>Don't we have to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No tenemos que &#8230;ar/er/ir?",
          },
          {
            text: "<b>She has to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Ella tiene que &#8230;ar/er/ir",
          },
          {
            text: "<b>She doesn't have to</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Ella no tiene por qué &#8230;ar/er/ir",
          },
          {
            text: "<b>Does she have to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Ella tiene que &#8230;ar/er/ir?",
          },
          {
            text: "<b>Doesn't she have to</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Ella no tiene que &#8230;ar/er/ir?",
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '33',
    items: [
      {
        rows: [
          {text: "Tienes que hacerlo."},
          {text: "No tienes por qué hacerlo."},
          {text: "¿Tienes que hacerlo?"},
          {text: "¿Yo tengo que hacerlo?"},
          {text: "¿No tienes que hacerlo?"},
        ]
      },
      {
        rows: [
          {text: "Él tiene que pagar."},
          {text: "Él no tiene que pagar."},
          {text: "¿Él tiene que pagar?"},
          {text: "¿Él no tiene que pagar?"},
        ]
      },
      {
        rows: [
          {text: "Tengo que trabajar."},
          {text: "No tengo que trabajar."},
          {text: "¿Tienes que trabajar?"},
          {text: "¿Tengo que trabajar?"},
          {text: "¿No tengo que trabajar?"},
        ]
      },
      {
        rows: [
          {text: "Ella tiene que comprarlo."},
          {text: "Ella no tiene por qué comprarlo."},
          {text: "¿Ella tiene que comprarlo?"},
          {text: "¿Ella no tiene que comprarlo?"},
        ]
      },

    ]
  },
]
