import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-recording',
  templateUrl: './recording.page.html',
  styleUrls: ['./recording.page.scss'],
})
export class RecordingPage implements OnInit {

  countdownDate: string;

  constructor(private view: ModalController) {
    this.countdownDate = dayjs().add(10, 'second').format('MM/DD/YYYY HH:mm:ss');
  }

  ngOnInit() {
  }

  dismiss() {
    this.view.dismiss({
      'valid': false,
    })
  }

  send(){
    this.view.dismiss({
      'valid': true,
    })
  }

}
