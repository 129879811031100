export const structures_23: any = [
  {
    type: 'structures',
    id: '23',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I was going to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo iba a &#8230;ar/er/ir",
          },
          {
            text:"<b>I wasn't going to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo no iba a &#8230;ar/er/ir",
          },
          {
            text:"<b>Were you going to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Ibas a &#8230;ar/er/ir?",
          },
          {
            text:"<b>I thought you were going to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Pensé que ibas a &#8230;ar/er/ir",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '39',
    items: [
      {
        rows: [
          {text:'Yo iba a hacerlo.'},
          {text:'Yo no iba a hacerlo.'},
          {text:'¿Ibas a hacerlo?'},
          {text:'Yo pensé que tú ibas a hacerlo.'},
          {text:'Yo pensé que no ibas a hacerlo.'},
        ]
      },
      {
        rows: [
          {text:'Íbamos a empezar hoy.'},
          {text:'No íbamos a empezar hoy.'},
          {text:'¿Íbamos a empezar hoy?'},
          {text:'Yo pensé que íbamos a empezar hoy.'},
          {text:'Yo pensé que no íbamos a empezar hoy.'},
        ]
      },
      {
        rows: [
          {text:'Él dijo que iba a seguir.'},
          {text:'Él dijo que no iba a seguir.'},
          {text:'¿Él dijo que iba a seguir?'},
          {text:'Yo pensé que él iba a seguir.'},
          {text:'Yo pensé que él no iba a seguir.'},
        ]
      },
      {
        rows: [
          {text:'Ellos me dijeron que iban a venir.'},
          {text:'Ellos me dijeron que no iban a venir.'},
          {text:'¿Ellos iban a venir?'},
          {text:'Yo pensé que ellos iban a venir.'},
          {text:'Yo pensé que ellos no iban a venir.'},
        ]
      },
      {
        rows: [
          {text:'Yo estaba seguro/a que ella iba a presentar el examen.'},
          {text:'Yo estaba seguro/a que ella no iba a presentar el examen.'},
          {text:'¿Ella iba a presentar el examen?'},
          {text:'Yo pensé que ella iba a presentar el examen.'},
          {text:'Yo pensé que ella no iba a presentar el examen.'},
        ]
      },
    ]
  }
]
