export const structures_5: any = [
  {
    type: 'structures',
    id: '5',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I always &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(Yo) siempre &#8230;",
          },
          {
            text:"<b>I never &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(Yo) nunca &#8230;",
          },
          {
            text: "<b>I still &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(Yo) todavía &#8230;",
          },
          {
            text: "<b>I just &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(Yo) sólo &#8230;",
          },
          {
            text: "<b>I &#8230; from time to time</b>",
          },
          {
            level:'secondary',
            text: "Yo &#8230; de vez en cuando",
          },
                    {
            text: "<b>I don't &#8230; yet.</b>",
          },
          {
            level:'secondary',
            text: "No &#8230; todavía.",
          },
          {
            text: "<b>I don't &#8230; anymore.</b>",
          },
          {
            level:'secondary',
            text: "Ya no &#8230;",
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '17a',
    items: [
      {
        rows: [
          {
            text: "I always use it",
            audio: "unit1-342.mp3"
          },
          {
            text: "I never use it",
            audio: "unit1-343.mp3"
          },
          {
            text: "I still use it",
            audio: "unit1-344.mp3"
          },
          {
            text: "I just use it here",
            audio: "unit1-754.mp3"
          },
          {
            text: "I use it from time to time",
            audio: "unit1-765.mp3"
          },
          {
            text: "I don't use it yet",
            audio: "unit1-345.mp3"
          },
          {
            text: "I don't use it anymore",
            audio: "unit1-346.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She always works",
            audio: "unit1-734.mp3"
          },
          {
            text: "She never works",
            audio: "unit1-347.mp3"
          },
          {
            text: "She still works",
            audio: "unit1-348.mp3"
          },
          {
            text: "She just works",
            audio: "unit1-755.mp3"
          },
          {
            text: "She works from time to time",
            audio: "unit1-767.mp3"
          },
          {
            text: "She doesn't work yet",
            audio: "unit1-349.mp3"
          },
          {
            text: "She doesn't work anymore",
            audio: "unit1-350.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '17b',
    items: [
      {
        rows: [
          {text: "Siempre lo uso."},
        ]
      },
      {
        rows: [
          {text: "Nunca lo uso."},
        ]
      },
      {
        rows: [
          {text: "Todavía lo uso."},
        ]
      },
      {
        rows: [
          {text: "Sólo lo uso aquí."},
        ]
      },
      {
        rows: [
          {text: "Lo uso de vez en cuando."},
        ]
      },
      {
        rows: [
          {text: "No lo uso todavía."},
        ]
      },
      {
        rows: [
          {text: "Ya no lo uso."},
        ]
      },
      {
        rows: [
          {text: "Siempre maneja (ella)."},
        ]
      },
      {
        rows: [
          {text: "Nunca maneja (ella)."},
        ]
      },
      {
        rows: [
          {text: "Todavía maneja (ella)."},
        ]
      },
      {
        rows: [
          {text: "Ella sólo maneja."},
        ]
      },
      {
        rows: [
          {text: "Ella maneja de vez en cuando."},
        ]
      },
      {
        rows: [
          {text: "Ella no maneja todavía."},
        ]
      },
      {
        rows: [
          {text: "Ya no maneja (ella)."},
        ]
      },
      {
        rows: [
          {text: "Siempre tenemos clase."},
        ]
      },
      {
        rows: [
          {text: "Nunca tenemos clase."},
        ]
      },
      {
        rows: [
          {text: "Todavía tenemos clase."},
        ]
      },
      {
        rows: [
          {text: "Sólo tenemos clase en la mañana."},
        ]
      },
      {
        rows: [
          {text: "Tenemos clase de vez en cuando."},
        ]
      },
      {
        rows: [
          {text: "No tenemos clase todavía."},
        ]
      },
      {
        rows: [
          {text: "Ya no tenemos clase."},
        ]
      },
      {
        rows: [
          {text: "Siempre lo necesita (él)."},
        ]
      },
      {
        rows: [
          {text: "Nunca lo necesita (él)."},
        ]
      },
      {
        rows: [
          {text: "Todavía lo necesita (él)."},
        ]
      },
      {
        rows: [
          {text: "Sólo necesita uno (él)."},
        ]
      },
      {
        rows: [
          {text: "Él todavía lo necesita de vez en cuando."},
        ]
      },
      {
        rows: [
          {text: "Él no lo necesita todavía."},
        ]
      },
      {
        rows: [
          {text: "Él ya no lo necesita."},
        ]
      },
    ]
  }
]
