export const must: any = [
  {
    type: 'card',
    title: "Must(n't) | Should(n't)",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>Must(n't)</b> se usa para expresar una <b>obligación imperiosa</b> o una <b>suposición</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "You <b>must</b> eat something",
            audio: "unit2-349.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: '<b>Debes</b> comer algo.',
            audio:"none"
          },
          {
            text: "You <b>mustn't</b> tell anybody",
            audio: "unit2-350.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: '<b>No debes</b> decírselo a nadie',
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>Should(n't)</b> se usa para expresar una <b>obligación moral</b> o una <b>sugerencia</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "I <b>should</b> study",
            audio: "unit2-351.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: 'Yo <b>debería</b> estudiar.',
            audio:"none"
          },
          {
            text: "You <b>shouldn't</b> wait",
            audio: "unit2-352.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: 'No <b>deberías</b> esperar',
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Estos dos auxiliares tienen las mismas características que <i>can</i>."
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '45a',
    items: [
      {
        rows: [
          {
            text: "You must answer",
            audio: "unit2-250.mp3"
          },
          {
            text: "You mustn’t answer",
            audio: "unit2-251.mp3"
          },
          {
            text: "Must I answer?",
            audio: "unit2-252.mp3"
          },
          {
            text: "Mustn’t I answer?",
            audio: "unit2-253.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He must repeat the test",
            audio: "unit2-256.mp3"
          },
          {
            text: "He mustn’t repeat the test",
            audio: "unit2-257.mp3"
          },
          {
            text: "Must he repeat the test?",
            audio: "unit2-258.mp3"
          },
          {
            text: "Mustn’t he repeat the test?",
            audio: "unit2-259.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You should call her",
            audio: "unit2-262.mp3"
          },
          {
            text: "You shouldn’t call her",
            audio: "unit2-263.mp3"
          },
          {
            text: "Should I call her?",
            audio: "unit2-264.mp3"
          },
          {
            text: "Shouldn’t I call her?",
            audio: "unit2-265.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She should buy one",
            audio: "unit2-268.mp3"
          },
          {
            text: "She shouldn’t buy one",
            audio: "unit2-269.mp3"
          },
          {
            text: "Should she buy one?",
            audio: "unit2-270.mp3"
          },
          {
            text: "Shouldn’t she buy one?",
            audio: "unit2-271.mp3"
          }
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '45b',
    items: [
      {
        rows: [
          {text: "Debes contestar."},
          {text: "No debes contestar."},
          {text: "¿Debo contestar?"},
          {text: "¿No debo contestar?"},
        ]
      },
      {
        rows: [
          {text: "Él debe repetir el examen."},
          {text: "Él no debe repetir el examen."},
          {text: "¿Él debe repetir el examen?"},
          {text: "¿Él no debe repetir el examen?"},
        ]
      },
      {
        rows: [
          {text: "Deberías llamarla."},
          {text: "No deberías llamarla."},
          {text: "¿Debería yo llamarla?"},
          {text: "¿No debería llamarla (yo)?"},
        ]
      },
      {
        rows: [
          {text: "Ella debería comprar uno."},
          {text: "Ella no debería comprar uno."},
          {text: "¿Debería ella comprar uno?"},
          {text: "¿Ella no debería comprar uno?"},
        ]
      },
    ]
  }
]
