export const structures_14: any = [
  {
    type: 'structures',
    id: '14',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I'm &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Estoy &#8230;ando/iendo",
          },
          {
            text:"<b>I'm not &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "No estoy &#8230;ando/iendo",
          },
          {
            text: "<b>Are you &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Estás &#8230;ando/iendo?",
          },
          {
            text: "<b>Aren't you &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿No estás &#8230;ando/iendo?<br/>",
          },

          {
            text:"<b>He's &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Él está &#8230;ando/iendo",
          },
          {
            text:"<b>He isn't &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Él no está &#8230;ando/iendo",
          },
          {
            text: "<b>Is he &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Él está &#8230;ando/iendo?",
          },
          {
            text: "<b>Isn't he &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Él no está &#8230;ando/iendo?<br/>",
          },

          {
            text:"<b>They're &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Ellos están &#8230;ando/iendo",
          },
          {
            text:"<b>They aren't &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Ellos no están &#8230;ando/iendo",
          },
          {
            text: "<b>Are they &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Ellos están &#8230;ando/iendo?",
          },
          {
            text: "<b>Aren't they &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Ellos no están &#8230;ando/iendo?<br/>",
          },

          {
            text:"<b>Alan is &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Alan está &#8230;ando/iendo",
          },
          {
            text:"<b>Alan isn't &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Alan no está &#8230;ando/iendo",
          },
          {
            text: "<b>Is Alan &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Alan está &#8230;ando/iendo?",
          },
          {
            text: "<b>Isn't Alan &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Alan no está &#8230;ando/iendo?<br/>",
          },

          {
            text:"<b>I'll be &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Estaré &#8230;ando/iendo",
          },
          {
            text:"<b>I won't be &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "No estaré &#8230;ando/iendo",
          },
          {
            text: "<b>Will you be &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿Estarás &#8230;ando/iendo?",
          },
          {
            text: "<b>Won't you be &#8230;ing?</b>",
          },
          {
            level:'secondary',
            text: "¿No vas a estar &#8230;ando/iendo?<br/>",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '9',
    items: [
      {
        rows: [
          {text: "Estoy cansado(a)."},
          {text: "No estoy cansado(a)."},
          {text: "¿Estás cansado(a)?"},
          {text: "¿No estás cansado(a)."},
        ]
      },
      {
        rows: [
          {text: "Te lo estoy preguntando."},
          {text: "No te lo estoy preguntando."},
          {text: "¿Qué me estás preguntando?"},
          {text: "¿Acaso no me estás preguntando?"},
        ]
      },
      {
        rows: [
          {text: "Ella está enferma."},
          {text: "Ella no está enferma."},
          {text: "¿Ella está enferma?"},
          {text: "¿Ella no está enferma?"},
        ]
      },
      {
        rows: [
          {text: "Ella está presentando el examen."},
          {text: "Ella no está presentando el examen."},
          {text: "¿Dónde está ella presentando el examen?"},
          {text: "¿Ella no está presentando el examen?"},
        ]
      },
      {
        rows: [
          {text: "Estamos listos."},
          {text: "No estamos listos."},
          {text: "¿Estamos listos?"},
          {text: "¿No estamos listos?"},
        ]
      },
      {
        rows: [
          {text: "Estamos terminando el curso."},
          {text: "No estamos terminando el curso."},
          {text: "¿Cuándo estamos terminando el curso?"},
          {text: "¿No estamos terminando el curso?"},
        ]
      },
      {
        rows: [
          {text: "Alan está casado."},
          {text: "Alan no está casado."},
          {text: "¿Alan está casado?"},
          {text: "¿Alan no está casado?"},
        ]
      },
      {
        rows: [
          {text: "Alan está jugando."},
          {text: "Alan no está jugando."},
          {text: "¿Alan está jugando?"},
          {text: "¿Por qué Alan no está jugando?"},
        ]
      },
      {
        rows: [
          {text: "Estaré en casa."},
          {text: "No estaré en casa. "},
          {text: "¿Estarás en casa?"},
          {text: "¿No estarás en casa?"},
        ]
      },
      {
        rows: [
          {text: "Estaré trabajando."},
          {text: "No estaré trabajando. "},
          {text: "¿Hasta qué hora estarás trabajando?"},
          {text: "¿No vas a estar trabajando?"},
        ]
      },
    ]
  }
]
