export const firebaseConfig = {
  apiKey: "AIzaSyCarYjYcrHGpdI2C_qT5r1p6PrE7e3iCPE",
  authDomain: "ingles.la",
  databaseURL: "https://ionic-learn-c972b.firebaseio.com",
  projectId: "ionic-learn-c972b",
  storageBucket: "ionic-learn-c972b.appspot.com",
  messagingSenderId: "472760506130",
  appId: "1:472760506130:web:69235d725b67682766c5cb",
  measurementId: "G-GZF0N2J44R"
};
