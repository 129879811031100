export const past_perfect: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Yo pensaba que tú habías &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I thought you had &#8230;</b>",
            audio:"none"
          },
          {
            text: "Él me preguntó por qué yo no había &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He asked me why I hadn't &#8230;</b>",
            audio:"none"
          },
        ]
      },
    ]
  },

  {
    type:'english',
    id: '52',
    items: [
      {
        rows: [
          {
            text: "I thought they had come",
            audio: "unit5-146.mp3"
          },
          {
            text: "I asked them why they hadn't come",
            audio: "unit5-147.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I didn't remember I had told her",
            audio: "unit5-148.mp3"
          },
          {
            text: "She asked me why I hadn't told her",
            audio: "unit5-149.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He said he had finished the course",
            audio: "unit5-150.mp3"
          },
          {
            text: "I asked him why he hadn't finished the course",
            audio: "unit5-151.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I asked her if she had read the mail",
            audio: "unit5-152.mp3"
          },
          {
            text: "She said she hadn't read the mail",
            audio: "unit5-153.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I was sure I had brought my book",
            audio: "unit5-154.mp3"
          },
          {
            text: "I realized that I hadn't brought my book",
            audio: "unit5-155.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I was told they had lost",
            audio: "unit5-156.mp3"
          },
          {
            text: "I told you they hadn't done anything",
            audio: "unit5-157.mp3"
          }
        ]
      },

    ]
  },

  {
    type:'translate',
    id: '53',
    items: [
      {
        rows: [
          {text:'Yo pensaba que ellos habían venido.'},
        ]
      },
      {
        rows: [
          {text:'Ella me preguntó por qué yo no se lo había dicho (a ella).'},
        ]
      },
      {
        rows: [
          {text:'Él dijo que había terminado el curso.'},
        ]
      },
      {
        rows: [
          {text:'Me di cuenta que ella no había leído el correo.'},
        ]
      },
      {
        rows: [
          {text:'Yo estaba seguro que había traído mi libro.'},
        ]
      },
      {
        rows: [
          {text:'Te dije que ellos no habían hecho nada.'},
        ]
      }
    ]
  },
]
