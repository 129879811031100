export const object_pronouns: any = [
  {
    type: 'card',
    title: 'Pronombres objetivos',
    items: [
      {
        type: 'columns-2',
        display:"left",
          rows: [
            {
              left: {
                tokens: [
                  {
                    text:"me",
                    audio:"unit1-272.mp3",
                    type:"link"
                  }
                ]
              },
            right: {
              tokens: [
                {
                  text:"me",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"you",
                  audio:"unit1-613.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"te",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"him",
                  audio:"unit1-274.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"le, lo (a él)",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"her",
                  audio:"unit1-275.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"le, lo (a ella)",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"us",
                  audio:"unit1-276.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"nos",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"them",
                  audio:"unit1-277.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"los, las, les",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"with",
                  audio:"unit1-278.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"con",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"for",
                  audio:"unit1-279.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"para/por",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"before",
                  audio:"unit1-280.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"antes (de)",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"after",
                  audio:"unit1-281.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"después (de)",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"Ejemplos:",
        tokens: []
      },
      {
        type: 'example',
        rows: [
          {
            text: "Ellos me pagan.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "They pay me",
            audio: "unit1-282.mp3",
            type: 'link'
          },
          {
            text: "Ellos nos pagan.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "They pay us",
            audio: "unit1-283.mp3",
            type: 'link'
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "Él no te conoce.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He doesn't know you",
            audio: "unit1-284.mp3",
            type: 'link'
          },
          {
            text: "Él no los conoce.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He doesn't know them",
            audio: "unit1-285.mp3",
            type: 'link'
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "¿No lo necesitamos (a él)?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't we need him?",
            audio: "unit1-286.mp3",
            type: 'link'
          },
          {
            text: "No la entiendo (a ella).",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't understand her",
            audio: "unit1-287.mp3",
            type: 'link'
          },
          {
            text: "<br/>",
            audio:"none"
          },
          {
            text: "Ella estudia con nosotros.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She studies with us",
            audio: "unit1-288.mp3",
            type: 'link'
          },
          {
            text: "Tengo algo para él.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I have something for him",
            audio: "unit1-290.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:'Según se ve en todos los ejemplos, los pronombres-complemento se colocan después del verbo y de las preposiciones.'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '15a',
    items: [
      {
        rows: [
          {
            text: "She remembers me",
            audio: "unit1-291.mp3"
          },
          {
            text: "She remembers you",
            audio: "unit1-292.mp3"
          },
          {
            text: "She remembers him",
            audio: "unit1-293.mp3"
          },
          {
            text: "She remembers her",
            audio: "unit1-294.mp3"
          },
          {
            text: "She remembers us",
            audio: "unit1-295.mp3"
          },
          {
            text: "She remembers them",
            audio: "unit1-296.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He plays with them",
            audio: "unit1-297.mp3"
          },
          {
            text: "He plays for them",
            audio: "unit1-298.mp3"
          },
          {
            text: "He plays before them",
            audio: "unit1-299.mp3"
          },
          {
            text: "He plays after them",
            audio: "unit1-300.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '15b',
    items: [
      {rows: [
        {text:"Ella se acuerda de mí."},
        {text:"Ella se acuerda de ti."},
        {text:"Ella se acuerda de él."},
        {text:"Ella se acuerda de ella."},
        {text:"Ella se acuerda de nosotros."},
        {text:"Ella se acuerda de ellos."},
      ]},
      {rows: [
        {text:"Él no me conoce."},
        {text:"Él no te conoce."},
        {text:"Él no lo conoce (a él)."},
        {text:"Él no la conoce (a ella)."},
        {text:"Él no nos conoce."},
        {text:"Él no los conoce (a ellos)."},
      ]},
      {rows: [
        {text:"¿Cuánto te pagan ellos?"},
        {text:"¿Cuánto le pagan ellos (a él)?"},
        {text:"¿Cuánto nos pagan ellos?"},
        {text:"¿Cuánto les pagan ellos (a ellos)?"},
        {text:"¿Cuánto le pagan ellos (a ella)?"},
        {text:"¿Cuánto me pagan ellos?"},
      ]},
      {rows: [
        {text:"¿Por qué él no te entiende?"},
        {text:"¿Por qué él no los entiende?"},
        {text:"¿Por qué él no la entiende (a ella)?"},
        {text:"¿Por qué él no me entiende?"},
        {text:"¿Por qué él no lo entiende (a él)?"},
        {text:"¿Por qué él no nos entiende?"},
      ]}
    ]
  }
]
