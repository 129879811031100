export const to_be_past_affirmative: any = [
  {
    type: 'card',
    title: "Pasado afirmativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>I was</b>",
            audio: "unit3-565.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Yo estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>You were</b>",
            audio: "unit3-566.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Tú estabas / eras</i>",
            audio: "none",
          },
          {
            text: "<b>He was</b>",
            audio: "unit3-567.mp3",
            type: 'link'
          },
            {
            level:'secondary',
            text: "<i>Él estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>She was</b>",
            audio: "unit3-568.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ella estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>It was</b>",
            audio: "unit3-569.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Estaba /era</i>",
            audio: "none",
          },
          {
            text: "<b>We were</b>",
            audio: "unit3-570.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Estábamos / éramos</i>",
            audio: "none",
          },
          {
            text: "<b>They were</b>",
            audio: "unit3-571.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ellos estaban / eran</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
