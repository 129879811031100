export const past_vocab_2: any = [
  {
    type: 'card',
    title: 'Vocabulario Adicional',
    items: [
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"Conozco &#8594;",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>know</b>",
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Conocía &#8594;",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>knew</b>",
                }
              ]
            }
          },
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "<b>Conoces</b> a Ana.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You know Ana</b>",
            audio: "unit4-73.mp3",
            type: 'link'
          },
          {
            text: "Yo creía que tú <b>conocías</b> a Ana.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I thought you knew Ana</b>",
            audio: "unit4-74.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Salvo los dos tiempos verbales anteriores, para todos lo demás tiempos se usa "
          },
          {
            text: "<b>meet</b>",
            audio: "unit4-518.mp3",
            type: 'link'
          },
          {
            text: " - "
          },
          {
            text: "<b>met</b",
            audio: "unit4-519.mp3",
            type: 'link'
          },
          {
            text: "."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Conocí</b> a Ana allá.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I met Ana there</b>",
            audio: "unit4-75.mp3",
            type: 'link'
          },
          {
            text: "Quiero que <b>conozcas</b> a Ana.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I want you to meet Ana</b>",
            audio: "unit4-76.mp3",
            type: 'link'
          },
          {
            text: "<b>Conocerás</b> a Ana.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You'll meet Ana</b>",
            audio: "unit4-77.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Además de  tomar, agarrar, <b>To take</b> significa  <i>llevar</i>  cuando vaya acompañado de complemento de lugar."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Quién <b>agarró</b> el dinero?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Who took the money?</b>",
            audio: "unit4-78.mp3",
            type: 'link'
          },
          {
            text: "Los <b>llevé</b> al parque.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I took them to the park</b>",
            audio: "unit4-293.mp3",
            type: 'link'
          },
          {
            text: "Quiero que me <b>lleves</b> a casa",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I want you to take me home</b>",
            audio: "unit4-80.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "<i>Ir</i> / <i>Irse</i> en <i>presente</i> &#8594;	<b>go</b>"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Tengo que <b>irme</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I have to go</b>",
            audio: "unit4-81.mp3",
            type: 'link'
          },
          {
            text: "No <b>te</b> puedes <b>ir</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You can't go</b>",
            audio: "unit4-82.mp3",
            type: 'link'
          },
          {
            text: "No <b>te vayas</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Don't go</b>",
            audio: "unit4-83.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<i>Ir</i> / <i>Irse</i> en <i>pasado afirmativo</i> &#8594; <b>left</b>."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Ella <b>se fue</b> temprano.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She left early</b>",
            audio: "unit4-84.mp3",
            type: 'link'
          },
          {
            text: "Todos <b>se fueron</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Everybody left</b>",
            audio: "unit4-85.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Si hay un complemento de lugar, debe usarse <b>go</b> - <b>went</b>."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Ella <b>se fue</b> para la playa.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She went to the beach</b>",
            audio: "unit4-86.mp3",
            type: 'link'
          },
          {
            text: "Todos <b>se fueron</b> a casa.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Everybody went home</b>",
            audio: "unit4-87.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Además de irse, <b>To Leave</b> - <b>left</b> también significa <i>dejar</i>."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Dejaste</b> esto aquí.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You left this here</b>",
            audio: "unit4-109.mp3",
            type: 'link'
          },
          {
            text: "¿Dónde lo <b>dejé</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Where did I leave it?</b>",
            audio: "unit4-89.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Encontrar: <b>Find</b> - <b>found</b>"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Encontré</b> el libro.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I found the book</b>",
            audio: "unit4-296.mp3",
            type: 'link'
          },
          {
            text: "No lo <b>encontré</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I didn't find it</b>",
            audio: "unit4-91.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
]
