export const interrogative_3: any = [
  {
    type: 'card',
    title: 'Interrogativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa "
          },
          {
            type: "link",
            text: "<b>does</b>",
            audio: "unit1-468.mp3"
          },
          {
            text: ", y el verbo no lleva  <b>'s'</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Ella quiere uno?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Does</b> she want one?",
            audio: "unit1-469.mp3",
            type: 'link'
          },
          {
            text: "¿Él habla inglés?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Does</b> he speak English?",
            audio: "unit1-470.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '9a',
    items: [
      {
        rows: [
          {
            text: "Does he feel better?",
            audio: "unit1-492.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does she drive?",
            audio: "unit1-493.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does her husband speak English?",
            audio: "unit1-494.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does his wife work?",
            audio: "unit1-495.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does your friend study?",
            audio: "unit1-496.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does my mom need one?",
            audio: "unit1-497.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does my dad use it?",
            audio: "unit1-498.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does Ana know?",
            audio: "unit1-499.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Does the teacher have class today?",
            audio: "unit1-500.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '9b',
    items: [
      {rows:[{text:'¿Él se siente mejor?'}]},
      {rows:[{text: "¿Ella maneja?"}]},
      {rows:[{text: "¿El esposo habla inglés?"}]},
      {rows:[{text: "¿La esposa trabaja?"}]},
      {rows:[{text: "¿Tu amigo estudia?"}]},
      {rows:[{text: "¿Mi mamá necesita uno?"}]},
      {rows:[{text: "¿Mi papá lo usa?"}]},
      {rows:[{text: "¿Ana sabe?"}]},
      {rows:[{text: "¿El profesor tiene clase hoy?"}]}
    ]
  }
]
