import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { actions } from 'src/app/store/user';
import { AppState } from '../../store/state';

export enum LoginComponentType {
  'login', 'signup', 'reset'
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {

  @Input() title = '';
  @Input() subTitle = '';
  @Input() action = 'Ingresa';
  @Input() socialAction = 'Ingresar con ';

  ngDestroyed$ = new Subject();
  form: FormGroup;
  passwordForm: FormGroup;
  serverMessage: string;
  loading: false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    const passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}';
    const fieldValidators = {
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
      passwordConfirm: ['', [Validators.required, Validators.pattern(passwordPattern)]]
    };
    this.form = this.fb.group(fieldValidators);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
  }

  googleSignIn() {
    this.store.dispatch(actions.googleSignIn());
  }

  localRegistration(email: string, password: string) {
    this.store.dispatch(
      actions.localSignUp({ email, password }));
  }

  get emailControl() {
    return (this.form.get('email'));
  }

  get email() {
    return this.emailControl.value;
  }

  set email(newEmail) {
    this.form.patchValue({email: newEmail});
  }

  get passwordControl() {
    return (this.form.get('password'));
  }

  get password() {
    return this.passwordControl.value;
  }

  set password(newPassword) {
    this.form.patchValue({password: newPassword});
  }

  get passwordEscape() {
    return this.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  get passwordConfirmControl() {
    return (this.form.get('passwordConfirm'));
  }

  get passwordConfirm() {
    return this.passwordConfirmControl.value;
  }

  get isPasswordMatch() {
    return this.password === this.passwordConfirm;
  }

  async onSubmit() {
    this.localRegistration(this.email, this.password);
  }

}
