import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LearnPageRoutingModule } from './learn-routing.module';
import { LearnPage } from './learn.page';

import { ExpandableComponent } from '../../components/expandable/expandable.component';


@NgModule({
  imports: [
    SharedModule,
    LearnPageRoutingModule
  ],
  declarations: [LearnPage, ExpandableComponent]
})
export class LearnPageModule {}
