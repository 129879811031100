export const negative: any = [
  {
    type: 'card',
    title: 'Negativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se incluye "
          },
          {
            type: "link",
            text: "<b>don't</b>",
            audio: "unit1-351.mp3"
          },
          {
            text: " entre el pronombre y el verbo."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No lo quiero.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>don't</b> want <it>it</it>",
            audio: "unit1-352.mp3",
            type: 'link'
          },
          {
            text: "Tú no estudias.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You <b>don't</b> study",
            audio: "unit1-353.mp3",
            type: 'link'
          },
          {
            text: "No vivimos <i>aquí</i>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "We <b>don't</b> live <i>here</i>",
            audio: "unit1-354.mp3",
            type: 'link'
          },
          {
            text: "Ellos no necesitan <i>nada</i>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "They <b>don't</b> need <i>anything</i>",
            audio: "unit1-355.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '2a',
    items: [
      {
        rows: [
          {
            text: "I don't watch TV",
            audio: "unit1-356.mp3"
          },
          {
            text: "You don't watch TV",
            audio: "unit1-357.mp3"
          },
          {
            text: "We don't watch TV",
            audio: "unit1-358.mp3"
          },
          {
            text: "They don't watch TV",
            audio: "unit1-359.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I don't want anything",
            audio: "unit1-360.mp3"
          },
          {
            text: "You don't want anything",
            audio: "unit1-361.mp3"
          },
          {
            text: "We don't want anything",
            audio: "unit1-362.mp3"
          },
          {
            text: "They don't want anything",
            audio: "unit1-363.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I don't have one",
            audio: "unit1-748.mp3"
          },
          {
            text: "You don't have one",
            audio: "unit1-749.mp3"
          },
          {
            text: "We don't have one",
            audio: "unit1-750.mp3"
          },
          {
            text: "They don't have one",
            audio: "unit1-751.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '2b',
    items: [
      {
        rows: [
          {
            text:'No veo TV.'
          },
          {
            text: "Tú no ves TV."
          },
          {
            text: "No vemos TV."
          },
          {
            text: "Ellos no ven TV."
          }
        ]
      },
      {
        rows: [
          {
            text: "No quiero nada.",
          },
          {
            text: "No quieres nada.",
          },
          {
            text: "No queremos nada.",
          },
          {
            text: "Ellos no quieren nada.",
          }
        ]
      },
      {
        rows: [
          {
            text: "No hablo inglés.",
          },
          {
            text: "Tú no hablas inglés.",
          },
          {
            text: "No hablamos inglés.",
          },
          {
            text: "Ellos no hablan inglés.",
          }
        ]
      },
      {
        rows: [
          {
            text: "No conozco a nadie.",
          },
          {
            text: "Tú no conoces a nadie.",
          },
          {
            text: "No conocemos a nadie.",
          },
          {
            text: "Ellos no conocen a nadie.",
          }
        ]
      },
    ]
  }
]
