import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: '/front',
                pathMatch: 'full'
            },
            {
                path: 'login',
                loadChildren: () => import('./pages/login/login-routing.module').then( m => m.LoginPageRoutingModule)
            },
            {
                path: 'signup',
                loadChildren: () => import('./pages/signup/signup-routing.module').then( m => m.SignupPageRoutingModule)
            },
            {
                path: 'about',
                loadChildren: () => import('./pages/about/about-routing.module').then( m => m.AboutPageRoutingModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home-routing.module').then( m => m.HomePageRoutingModule)
            },
            {
                path: 'front',
                loadChildren: () => import('./pages/front/front.module').then( m => m.FrontPageModule)
            },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'learn',
                loadChildren: () => import('./pages/learn/learn.module').then(m => m.LearnPageModule)
            },
            {
                path: 'book',
                loadChildren: () => import('./pages/book/book.module').then(m => m.BookPageModule)
            },
            {
                path: 'exercises',
                loadChildren: () => import('./pages/exercises/exercises.module').then(m => m.ExercisesPageModule)
            },
            {
                path: 'review',
                loadChildren: () => import('./pages/review/review.module').then( m => m.ReviewPageModule)
            },
            {
                path: 'account',
                loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
            },
        ]
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { preloadingStrategy: PreloadAllModules }
        )],
    exports: [RouterModule]
})
export class AppRoutingModule {}
