export const to_be_past_interrogative: any = [
  {
    type: 'card',
    title: "Pasado interrogativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>Was I?</b>",
            audio: "unit3-579.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estaba? / ¿era?</i>",
            audio: "none",
          },
          {
            text: "<b>Were you?</b>",
            audio: "unit3-580.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estabas? / eras?</i>",
            audio: "none",
          },
          {
            text: "<b>Was he?</b>",
            audio: "unit3-581.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estaba (él)? / Era (él)?</i>",
            audio: "none",
          },
          {
            text: "<b>Was she?</b>",
            audio: "unit3-582.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estaba (ella)? / ¿era (ella)?</i>",
            audio: "none",
          },
          {
            text: "<b>Was it?</b>",
            audio: "unit3-583.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estaba? / ¿Era?</i>",
            audio: "none",
          },
          {
            text: "<b>Were we?</b>",
            audio: "unit3-584.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Estábamos? / éramos?</b>",
            audio: "none",
          },
          {
            text: "<b>Were they?</b>",
            audio: "unit3-585.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ellos estaban / eran?</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
