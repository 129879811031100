export const structures_2: any = [
  {
    type: 'structures',
    id: '2',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>He &#8230;s.<br/></b>",
          },
          {
            level:'secondary',
            text: "Él &#8230;",
          },
          {
            text: "<b>Who &#8230;s?</b>",
          },
          {
            level:'secondary',
            text: "¿Quién &#8230;?",
          },
          {
            text: "<b>Nobody &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Nadie &#8230;",
          },
          {
            text: "<b>Everybody &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Todo el mundo &#8230;",
          },
          {
            text: "<b>Somebody / Someone &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Alguien &#8230;",
          },
          {
            text: "<b>Her husband / His wife &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "El esposo / la esposa &#8230;",
          },
          {
            text: "<b>Your mom / your dad &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Tu mamá / tu papá &#8230;",
          },
          {
            text: "<b>My friend &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Mi amigo/a &#8230;",
          },
          {
            text: "<b>Alan &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Alan &#8230;",
          },
          {
            text: "<b>The teacher &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "El(la)  profesor(a) &#8230;",
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '7a',
    items: [
      {
        rows: [
          {
            text: "He speaks English",
            audio: "unit1-546.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Who speaks English?",
            audio: "unit1-547.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Nobody speaks English",
            audio: "unit1-548.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Everybody speaks English",
            audio: "unit1-549.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Somebody speaks English",
            audio: "unit1-550.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Her husband speaks English",
            audio: "unit1-551.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "His wife speaks English",
            audio: "unit1-552.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Your dad speaks English",
            audio: "unit1-553.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Your mom speaks English",
            audio: "unit1-554.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "My friend speaks English",
            audio: "unit1-555.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Alan speaks English",
            audio: "unit1-556.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "The teacher speaks English",
            audio: "unit1-557.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '7b',
    items: [
      {
        rows: [
          {text: "Él habla inglés."},
          {text: "¿Quién habla inglés?"},
          {text: "Nadie habla inglés."},
          {text: "Todo el mundo habla inglés."},
          {text: "Alguien habla inglés."},
          {text: "El esposo habla inglés."},
          {text: "La esposa habla inglés."},
          {text: "Tu papá habla inglés."},
          {text: "Tu mamá habla inglés."},
          {text: "Mi amigo habla inglés."},
          {text: "Alan habla inglés."},
          {text: "El profesor habla inglés."},
        ]
      },
      {
        rows: [
          {text: "La profesora lo usa."},
          {text: "Ana lo usa."},
          {text: "Tu amiga lo usa."},
          {text: "Mi mamá lo usa."},
          {text: "Mi papá lo usa."},
          {text: "La esposa lo usa."},
          {text: "El esposo lo usa."},
          {text: "Alguien lo usa."},
          {text: "Todo el mundo lo usa."},
          {text: "Nadie lo usa."},
          {text: "¿Quién lo usa?"},
          {text: "Ella lo usa."},
        ]
      },
      {
        rows: [
          {text: "Él sabe."},
          {text: "¿Quién sabe?"},
          {text: "Nadie sabe."},
          {text: "Todo el mundo sabe."},
          {text: "Alguien sabe."},
          {text: "El profesor sabe."},
          {text: "Ana sabe."},
          {text: "La esposa sabe."},
          {text: "El esposo sabe."},
          {text: "Mi mamá sabe."},
          {text: "Mi papá sabe."},
          {text: "Mi amiga sabe."},
        ]
      },
    ]
  }
]
