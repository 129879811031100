
export const lessons: any = [
  {
    title:"El presente",
    description:"Aprende a expresar lo que haces utilizando un vocabulario simple",
    status:"UNKNOWN",
    lections: [
      {
        name:"Vocabulario esencial",
        icon:"brain",
        id:"subject_pronouns",
        exercises: "hide",
        status:"UNKNOWN",
      },
      {
        name:"Afirmativo",
        icon:"language",
        id:"affirmative",
        exercises: "show",
        title:"Presente simple",
        status:"UNKNOWN",
      },
      {
        name:"Negativo",
        icon:"language",
        id:"negative",
        exercises: "show",
        title:"Presente simple",
        status:"UNKNOWN",
      },
      {
        name:"Interrogativo",
        icon:"language",
        id:"interrogative",
        exercises: "show",
        title:"Presente simple",
        status:"UNKNOWN",
      },
      {
        name:"Interrogativo negativo",
        icon:"language",
        id:"interrogative_negative",
        exercises: "show",
        title:"Presente simple",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 1",
        icon:"shapes",
        id:"structures_1",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Números cardinales",
        icon:"brain",
        id:"cardinal",
        exercises: "hide",
        status:"UNKNOWN",
      },

    ]
  },
  {
    title:"Tercera persona singular",
    description:"Aprende a expresar lo que él/ella hace",
    status:"UNKNOWN",
    lections: [
      {
        name:"Afirmativo",
        icon:"language",
        id:"affirmative_3",
        exercises: "show",
        title:"Presente 3ª persona singular",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 2",
        icon:"shapes",
        id:"structures_2",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Negativo",
        icon:"language",
        id:"negative_3",
        exercises: "show",
        title:"Presente 3ª persona singular",
        status:"UNKNOWN",

      },
      {
        name:"Interrogativo",
        icon:"language",
        id:"interrogative_3",
        exercises: "show",
        title:"Presente 3ª persona singular",
        status:"UNKNOWN",
      },
      {
        name:"Interrogativo negativo",
        icon:"language",
        id:"interrogative_negative_3",
        exercises: "show",
        title:"Presente 3ª persona singular",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 3",
        icon:"shapes",
        id:"structures_3",
        exercises: "show",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Interrogativos",
    description:"Empieza una conversación con una pregunta",
    status:"UNKNOWN",
    lections: [
      {
        name:"Vocabulario y uso",
        icon:"brain",
        id:"questions",
        exercises: "show",
        title:"Interrogativos",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 4",
        icon:"shapes",
        id:"structures_4",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Expresiones",
        icon:"brain",
        id:"expressions",
        exercises: "hide",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Vocabulario complementario",
    description:"Amplía tus capacidades para expresarte",
    status:"UNKNOWN",
    lections: [
      {
        name:"Vocabulario y uso",
        icon:"brain",
        id:"complements_vocab_1",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Pronombres objetivos",
        icon:"language",
        id:"object_pronouns",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario complementario",
        icon:"brain",
        id:"complements_vocab_2",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 1",
        icon:"flag",
        id:"fluency_1",
        exercises: "only",
        status:"UNKNOWN",
      },

    ]
  },
  {
    title:"Adverbios",
    description:"Califica los verbos de tus oraciones",
    status:"UNKNOWN",
    lections: [
      {
        name:"Vocabulario y uso",
        icon:"language",
        id:"adverbs",
        exercises: "show",
        title:"Adverbios",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 5",
        icon:"shapes",
        id:"structures_5",
        exercises: "show",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Sustantivos",
    description:"Nombra objetos comunes en conversación casual",
    status:"UNKNOWN",
    lections: [
      {
        name:"Vocabulario",
        icon:"brain",
        id:"nouns",
        exercises: "hide",
        title:"Sustantivos",
        status:"UNKNOWN",
      },
      {
        name:"Determinadores",
        icon:"language",
        id:"determiners",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Adjetivos posesivos",
        icon:"language",
        id:"possessives",
        exercises: "show",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Oraciones compuestas",
    description:"Une varias oraciones para expresar ideas más complejas",
    lections: [
      {
        name:"Conjunciones",
        icon:"language",
        id:"conjunctions",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 2",
        icon:"flag",
        id: "fluency_2",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Frases introductorias",
        icon:"language",
        id: "introductory_phrases",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 3",
        icon:"flag",
        id:"fluency_3",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Ejercicios opcionales 1",
    description:"Practica lo que has aprendido hasta ahora",
    completion:'ignore',
    lections: [
      {
        name:"Oraciones complementarias 1",
        icon:"filter",
        id:"extra1",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Verbos sequidos de To + verbo",
    description:"Utiliza dos verbos en la misma oración",
    lections: [
      {
        name:"Querer + verbo",
        icon:"language",
        id:"want_to",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 1",
        icon:"sync",
        id:"contrast_1",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 6",
        icon:"shapes",
        id:"structures_6",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Querer que",
        icon:"language",
        id:"want_someone_to",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 7",
        icon:"shapes",
        id:"structures_7",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 2",
        icon:"sync",
        id:"contrast_2",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Tener que + verbo",
        icon:"language",
        id:"have_to",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 8",
        icon:"shapes",
        id:"structures_8",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario adicional",
        icon:"brain",
        id:"to_vocab",
        exercises: "show",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"El futuro",
    description:"Aprende a expresar lo que vas a hacer en un tiempo próximo o lejano",
    lections: [
      {
        name:"El futuro inmediato",
        icon:"language",
        id:"going_to",
        title:"El futuro",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 9",
        icon:"shapes",
        id:"structures_9",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"El futuro simple",
        icon:"language",
        id:"will",
        title:"El futuro",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 10",
        icon:"shapes",
        id:"structures_10",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 3",
        icon:"sync",
        id:"contrast_3",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Oraciones compuestas",
        icon:"language",
        id:"compound",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 4",
        icon:"flag",
        id:"fluency_4",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Auxiliares modales",
    description:"Aprende a expresar una opinión sobre si algo es probable o posible",
    lections: [
      {
        name:"Can",
        icon:"language",
        id:"modal",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"El futuro de CAN",
        icon:"language",
        id:"can_future",
        exercises: "hide",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 11",
        icon:"shapes",
        id:"structures_11",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Must | Should",
        icon:"language",
        id:"must",
        exercises: "hide",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 12",
        icon:"shapes",
        id:"structures_12",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 4",
        icon:"sync",
        id:"contrast_4",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 5",
        icon:"sync",
        id:"contrast_5",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },

  {
    title:"Estructuras adicionales",
    lections: [
      {
        name:"El imperativo",
        icon:"language",
        id:"imperative",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 6",
        icon:"sync",
        id:"contrast_6",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 13",
        icon:"shapes",
        id:"structures_13",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Modismos 1",
        icon:"language",
        id:"can_extra",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 5",
        icon:"flag",
        id:"fluency_5",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Ejercicios opcionales 2",
    description:"Practica lo que has aprendido hasta ahora",
    completion:'ignore',
    lections: [
      {
        name:"Oraciones complementarias 2",
        icon:"filter",
        id:"extra2",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Ser / Estar",
    description:"Aprende a hacer descripciones y expresar estados",
    status:"UNKNOWN",
    lections: [
      {
        name:"Presente afirmativo",
        icon:"brain",
        id:"to_be_present_affirmative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Presente negativo",
        icon:"brain",
        id:"to_be_present_negative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Presente interrogativo",
        icon:"brain",
        id:"to_be_present_interrogative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Presente interrogativo negativo",
        icon:"brain",
        id:"to_be_present_interrogative_negative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario 1",
        icon:"brain",
        id:"to_be_vocab_1",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Participio presente",
        icon:"language",
        id:"present_participle",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 14",
        icon:"shapes",
        id:"structures_14",
        exercises: "show",
        title:"Estructuras 14",
        status:"UNKNOWN",
      },
      {
        name:"Modismos 2",
        icon:"language",
        id:"to_be_extra_1",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 15",
        icon:"shapes",
        id:"structures_15",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario 2",
        icon:"brain",
        id:"to_be_vocab_2",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 16",
        icon:"shapes",
        id:"structures_16",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Precisiones gramaticales",
        icon:"language",
        id:"to_be_grammar",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Pasado afirmativo",
        icon:"brain",
        id:"to_be_past_affirmative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Pasado negativo",
        icon:"brain",
        id:"to_be_past_negative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Pasado interrogativo",
        icon:"brain",
        id:"to_be_past_interrogative",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Pasado interrogativo negativo",
        icon:"brain",
        id:"to_be_past_interrogative_negative",
        title:"Ser / Estar",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 17",
        icon:"shapes",
        id:"structures_17",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 6",
        icon:"flag",
        id:"fluency_6",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Hay - Había / Hubo",
        icon:"language",
        id:"there_is_are",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Modismos 3",
        icon:"language",
        id:"to_be_extra_2",
        exercises: "show",
        title:"Ser / Estar",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 7",
        icon:"flag",
        id:"fluency_7",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Ejercicios opcionales 3",
    description:"Practica lo que has aprendido hasta ahora",
    completion:'ignore',
    lections: [
      {
        name:"Oraciones complementarias",
        icon:"filter",
        id:"extra3",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"El pasado",
    description:"Aprende a decir lo sucedido en pasado",
    status:"UNKNOWN",
    lections: [
      {
        name:"Negativo e interrogativo",
        icon:"language",
        id:"past_negative_interrogative",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Verbos regulares",
        icon:"language",
        id:"past_regular",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario 1",
        icon:"brain",
        id:"past_vocab_1",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 19",
        icon:"shapes",
        id:"structures_19",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Verbos irregulares",
        icon:"language",
        id:"past_irregular",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Vocabulario 2",
        icon:"brain",
        id:"past_vocab_2",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Ejemplos adicionales",
        icon:"brain",
        id:"past_examples",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"El pasado contínuo",
        icon:"language",
        id:"past_ing",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Oraciones compuestas",
        icon:"language",
        id:"past_compound",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 8",
        icon:"flag",
        id:"fluency_8",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Wanted to",
        icon:"language",
        id:"wanted_to",
        exercises: "hide",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 20",
        icon:"shapes",
        id:"structures_20",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Wanted someone to",
        icon:"language",
        id:"wanted_someone_to",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 21",
        icon:"shapes",
        id:"structures_21",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 7",
        icon:"sync",
        id:"contrast_7",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"Had to",
        icon:"language",
        id:"had_to",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Was/were supposed to",
        icon:"language",
        id:"was_were_supposed_to",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 22",
        icon:"shapes",
        id:"structures_22",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Contraste 8",
        icon:"sync",
        id:"contrast_8",
        exercises: "only",
        status:"UNKNOWN",
      },
      {
        name:"El pasado de going to",
        icon:"language",
        id:"past_going_to",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 23",
        icon:"shapes",
        id:"structures_23",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"El pasado de can",
        icon:"language",
        id:"past_can",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras 24",
        icon:"shapes",
        id:"structures_24",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Estructuras adicionales",
        icon:"language",
        id:"past_extra_1",
        exercises: "show",
        title:"El pasado",
        status:"UNKNOWN",
      },
      {
        name:"Modismos 4",
        icon:"language",
        id:"past_extra_2",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Fluidez 9",
        icon:"flag",
        id:"fluency_9",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"Ejercicios opcionales 4",
    description:"Practica lo que has aprendido hasta ahora",
    completion:'ignore',
    lections: [
      {
        name:"Oraciones complementarias",
        icon:"filter",
        id:"extra4",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
  {
    title:"The present perfect",
    status:"UNKNOWN",
    lections: [
      {
        name:"The past participle",
        icon:"brain",
        id:"past_participle",
        exercises: "hide",
        title:"The present perfect",
        status:"UNKNOWN",
      },
      {
        name:"The present perfect",
        icon:"language",
        id:"present_perfect",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"For a long time / since then",
        icon:"language",
        id:"pp_extra_1",
        exercises: "show",
        title:"The present perfect",
        status:"UNKNOWN",
      },
      {
        name:"The present perfect continuous",
        icon:"language",
        id:"pp_continuous",
        exercises: "show",
        title:"The present perfect",
        status:"UNKNOWN",
      },
      {
        name:"The past perfect",
        icon:"language",
        id:"past_perfect",
        exercises: "show",
        title:"The past perfect",
        status:"UNKNOWN",
      },
      {
        name:"Must have",
        icon:"language",
        id:"pp_extra_2",
        exercises: "show",
        title:"The past perfect",
        status:"UNKNOWN",
      },
      {
        name:"The conditional",
        icon:"language",
        id:"conditional",
        exercises: "show",
        status:"UNKNOWN",
      },
      {
        name:"Should have / Could have",
        icon:"language",
        id:"conditional_extra_1",
        exercises: "show",
        title:"The conditional",
        status:"UNKNOWN",
      },
      {
        name:"The conditional perfect",
        icon:"language",
        id:"conditional_perfect",
        exercises: "show",
        title:"The conditional",
        status:"UNKNOWN",
      }
    ]
  },
  {
    title:"Ejercicios opcionales 5",
    description:"Practica lo que has aprendido hasta ahora",
    completion:'ignore',
    lections: [
      {
        name:"Oraciones complementarias",
        icon:"filter",
        id:"extra5",
        exercises: "only",
        status:"UNKNOWN",
      },
    ]
  },
]
