export const wanted_someone_to: any = [
  {
    type: 'card',
    title:"Wanted someone to",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'La misma construcción que en el presente. Sólo <strong>want</strong> se pondrá en el <b>pasado</b>. El segundo verbo no cambia.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo quería que Uds. ganaran.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I wanted you to win</b>",
            audio:"unit4-487.mp3"
          },
          {
            text: "Yo no quería que él lo viera.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I didn't want him to see it</b>",
            audio:"unit4-488.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'english',
    id: '33',
    items: [
      {
        rows: [
          {
            text: "I wanted you to know",
            audio: "unit4-489.mp3"
          },
          {
            text: "I wanted us to be together",
            audio: "unit4-490.mp3"
          },
          {
            text: "I wanted her to stay",
            audio: "unit4-491.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I didn't want him to spend so much",
            audio: "unit4-492.mp3"
          },
          {
            text: "I didn't want her to hear",
            audio: "unit4-493.mp3"
          },
          {
            text: "I didn't want them to forget",
            audio: "unit4-494.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How did you want me to understand?",
            audio: "unit4-495.mp3"
          },
          {
            text: "Where did you want him to go?",
            audio: "unit4-496.mp3"
          },
          {
            text: "What did you want her to do?",
            audio: "unit4-497.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why didn't you want us to help you?",
            audio: "unit4-498.mp3"
          },
          {
            text: "Why didn't you want them to give you a ride?",
            audio: "unit4-499.mp3"
          },
          {
            text: "Why didn't you want me to pay?",
            audio: "unit4-500.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Where did you want us to go?",
            audio: "unit4-529.mp3"
          },
          {
            text: "Why didn't you want him to help you?",
            audio: "unit4-530.mp3"
          },
          {
            text: "Why didn't you want them to help you?",
            audio: "unit4-531.mp3"
          },
        ]
      },
    ]
  },
]
