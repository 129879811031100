export const imperative: any = [
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "El imperativo se usa para dar <b>órdenes</b>, <b>consejos</b> o hacer <b>peticiones</b> a una o más personas. Su característica principal es que <b>no se usa pronombre sujeto</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Espérame.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Wait for me",
            audio: "unit2-123.mp3",
            type: 'link'
          },
          {
            text: "No estés triste.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't be sad",
            audio: "unit2-133.mp3",
            type: 'link'
          },
          {
            text: "Empecemos.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Let's start",
            audio: "unit2-137.mp3",
            type: 'link'
          },
          {
            text: "Déjame terminar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Let me finish",
            audio: "unit2-144.mp3",
            type: 'link'
          },
          {
            text: "Dile (a él) que venga.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Tell him to come",
            audio: "unit2-127.mp3",
            type: 'link'
          },
          {
            text: "Diles que no vayan.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Tell them not to go",
            audio: "unit2-128.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '47a',
    items: [
      {
        rows: [
          {
            text: "Wait",
            audio: "unit2-129.mp3"
          },
          {
            text: "Give me time",
            audio: "unit2-130.mp3"
          },
          {
            text: "Come back",
            audio: "unit2-131.mp3"
          },
          {
            text: "Call Ana",
            audio: "unit2-132.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Don't be sad",
            audio: "unit2-133.mp3"
          },
          {
            text: "Don't forget",
            audio: "unit2-134.mp3"
          },
          {
            text: "Don't ask",
            audio: "unit2-135.mp3"
          },
          {
            text: "Don't lose it",
            audio: "unit2-136.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let's start",
            audio: "unit2-137.mp3"
          },
          {
            text: "Let's do it",
            audio: "unit2-138.mp3"
          },
          {
            text: "Let's ask Alan",
            audio: "unit2-139.mp3"
          },
          {
            text: "Let's try",
            audio: "unit2-140.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Let me pay",
            audio: "unit2-141.mp3"
          },
          {
            text: "Let me talk to him",
            audio: "unit2-142.mp3"
          },
          {
            text: "Let me hear",
            audio: "unit2-143.mp3"
          },
          {
            text: "Let me finish",
            audio: "unit2-144.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Tell him to come back",
            audio: "unit2-353.mp3"
          },
          {
            text: "Tell her to study",
            audio: "unit2-354.mp3"
          },
          {
            text: "Tell them to wait for me",
            audio: "unit2-355.mp3"
          },
          {
            text: "Tell him to try",
            audio: "unit2-356.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Tell her not to forget",
            audio: "unit2-357.mp3"
          },
          {
            text: "Tell them not to do anything",
            audio: "unit2-358.mp3"
          },
          {
            text: "Tell him not to go",
            audio: "unit2-359.mp3"
          },
          {
            text: "Tell her not to pay",
            audio: "unit2-360.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '47b',
    items: [
      {
        rows: [
          {text: "Quédate aquí."},
          {text: "Usa el mío."},
          {text: "Levántate."},
          {text: "Sé feliz."},
        ]
      },
      {
        rows: [
          {text: "No te preocupes."},
          {text: "No hagas nada."},
          {text: "No se lo digas a nadie."},
          {text: "Que no se te olvide."},
        ]
      },
      {
        rows: [
          {text: "Veamos TV."},
          {text: "Vámonos."},
          {text: "Hagámoslo."},
          {text: "Preguntemos."},
        ]
      },
      {
        rows: [
          {text: "Déjame decirte algo."},
          {text: "Déjame recordar."},
          {text: "Déjame pensar."},
          {text: "Déjame ver."},
        ]
      },
      {
        rows: [
          {text: "Dile a él que regrese."},
          {text: "Dile a ella que estudie."},
          {text: "Diles que me esperen."},
          {text: "Dile a él que lo intente."},
        ]
      },
      {
        rows: [
          {text: "Dile a ella que no se olvide."},
          {text: "Diles que no hagan nada."},
          {text: "Dile a él que no vaya."},
          {text: "Dile a ella que no pague."},
        ]
      },
    ]
  },
]
