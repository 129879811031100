export const to_be_grammar: any = [
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>Always</b>, <b>never</b>, <b>still</b> y <b>just</b> se colocan <b>después de <i>to be.</i></b>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Siempre está cerrado.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "It's <b>always</b> closed",
            audio:"unit3-420.mp3"

          },
          {
            text: "Él nunca está en casa.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He's <b>never</b> at home",
            audio:"unit3-551.mp3"

          },
          {
            text: "¿Todavía es posible?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Is it <b>still</b> possible?",
            audio:"unit3-422.mp3"

          },
          {
            text: "Sólo estoy cansado.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I'm <b>just</b> tired",
            audio:"unit3-649.mp3"

          },

        ]
      }
    ]
  },
  {
    type:'translate',
    id: '13a',
    items: [
      {
        rows: [
          {text: "Él siempre está aquí."},
          {text: "Nunca está aquí (él)."},
          {text: "Todavía está aquí (él)."},
          {text: "Él no está aquí todavía."},
          {text: "Ya no está aquí (él)."},
        ]
      },
      {
        rows: [
          {text: "Siempre está abierto."},
          {text: "Nunca está abierto."},
          {text: "Todavía está abierto."},
          {text: "No está abierto todavía."},
          {text: "Ya no está abierto."},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Si un <b>sustantivo</b> es el sujeto de la oración, éste <b>sustituye</b> a <b>It</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Está lista.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b>'s ready",
            audio:"unit3-423.mp3"

          },
          {
            text: "La computadora está lista.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "The computer <b>is</b> ready",
            audio:"unit3-424.mp3"

          },
          {
            text: "¿Dónde está?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Where is <b>it</b>?",
            audio:"unit3-425.mp3"

          },
          {
            text: "¿Dónde está mi libro?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Where <b>is</b> my book?",
            audio:"unit3-426.mp3"

          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '13b',
    items: [
      {
        rows: [
          {text: "Es fácil."},
          {text: "El curso es fácil."},
        ]
      },
      {
        rows: [
          {text: "No está listo."},
          {text: "El carro no está listo."},
        ]
      },
      {
        rows: [
          {text: "¿Dónde está?"},
          {text: "¿Dónde está mi libro?"},
        ]
      },
      {
        rows: [
          {text: "Él te está esperando."},
          {text: "El profesor te está esperando."},
        ]
      },
      {
        rows: [
          {text: "Son blancos."},
          {text: "Los míos son blancos."},
        ]
      },
      {
        rows: [
          {text: "Ella está brava conmigo."},
          {text: "Mi mamá está brava conmigo."},
        ]
      },
      {
        rows: [
          {text: "Ellos están allá."},
          {text: "Mis padres están allá."},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>No hay forma interrogativa ni contracción</b> en las oraciones compuestas."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Quién es ella?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Who is she?",
            audio:"unit3-427.mp3"

          },
          {
            text: "¿Sabes quién es ella?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <b>who she is?</b>",
            audio:"unit3-428.mp3"

          },
          {
            text: "¿Cuánto es?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "How much is it?",
            audio:"unit3-429.mp3"

          },
          {
            text: "¿Sabes cuánto es?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you know <b>how much it is?</b>",
            audio:"unit3-430.mp3"

          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '13c',
    items: [
      {
        rows: [
          {text: "¿Dónde estoy?"},
          {text: "¿Sabes dónde estoy? "},
          {text: "¿Dónde estamos? "},
          {text: "¿Sabes dónde estamos? "},
          {text: "¿Dónde está ella?"},
          {text: "¿Sabes dónde está ella? "},
          {text: "¿Dónde están mis llaves? "},
          {text: "¿Sabes dónde están mis llaves?"},
        ]
      },
      {
        rows: [
          {text: "¿Qué es? "},
          {text: "¿Sabes qué es? "},
          {text: "¿Cuánto es?"},
          {text: "¿Sabes cuánto es?"},
          {text: "¿Qué hora es?"},
          {text: "¿Sabes qué hora es?"},
          {text: "¿Cuándo es el examen?"},
          {text: "¿Sabes cuándo es el examen?"},
        ]
      },
      {
        rows: [
          {text: "¿Quién es él? "},
          {text: "¿Sabes quién es él? "},
          {text: "¿Quién soy yo? "},
          {text: "¿Sabes quién soy yo?"},
          {text: "¿Quiénes son ellos?"},
          {text: "¿Sabes quiénes son ellos?"},
          {text: "¿Quién es Alan? "},
          {text: "¿Sabes quién es Alan?"},
        ]
      }
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "En inglés, el <b>adjetivo</b> siempre precede al sustantivo. Si el adjetivo empieza por <b>vocal</b>, debe usarse<b> an.</b>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "It's <b>a long</b> story",
            audio:"unit3-431.mp3"

          },
          {
            level:'secondary',
            text: "Es una historia larga",
            audio:"none"
          },
          {
            text: "It's<b> an</b> excellent course",
            audio:"unit3-432.mp3"

          },
          {
            level:'secondary',
            text: "Es un curso excelente",
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '13d',
    items: [
      {
        rows: [
          {text: "Es una historia larga."},
          {text: "Es una historia interesante. "},
          {text: "Es una historia verdadera."},
        ]
      },
      {
        rows: [
          {text: "Ella es una profesora ocupada."},
          {text: "Ella es una profesora excelente."},
          {text: "Ella es una buena profesora."},
        ]
      },
      {
        rows: [
          {text: "Es un carro rápido."},
          {text: "Es un carro caro."},
          {text: "Es un mejor carro."},
        ]
      },
      {
        rows: [
          {text: "Es una palabra difícil."},
          {text: "Es una palabra importante."},
          {text: "Es una mala palabra."},
        ]
      },
      {
        rows: [
          {text: "Es un curso largo."},
          {text: "Es un curso excelente."},
          {text: "Es un curso corto."},
        ]
      },
      {
        rows: [
          {text: "Es una pregunta interesante."},
          {text: "Es una buena pregunta."},
          {text: "Es una pregunta fácil."},
        ]
      }
    ]
  },
]
