export const past_going_to: any = [
  {
    type: 'card',
    title:"El pasado de going to",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa el pasado de <i>to be</i>: <b>was(n't)</b> / <b>were(n't)</b> seguido de <b>going to</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Yo te iba a preguntar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I was going to ask you</b>",
            audio:"unit4-368.mp3"
          },
          {
            text: "Íbamos a empezar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>We were going to start</b>",
            audio:"unit4-363.mp3"
          },
          {
            text: "Yo sabía que no ibas a jugar",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I knew you weren't going to play</b>",
            audio:"unit4-364.mp3"
          },
          {
            text: "¿Qué iba yo a decir?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>What was I going to say?</b>",
            audio:"unit4-365.mp3"
          },
          {
            text: "¿No ibas a ver TV?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Weren't you going to watch TV?</b>",
            audio:"unit4-366.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Esta última estructura es casi siempre sustituída por una estructura afirmativa o negativa introducida por "I thought":'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            level:'secondary',
            text: "<b>I thought you were going to watch TV</b>",
            audio:"unit4-367.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '38',
    items: [
      {
        rows: [
          {
            text: "I was going to ask you",
            audio: "unit4-368.mp3"
          },
          {
            text: "He was going to play",
            audio: "unit4-369.mp3"
          },
          {
            text: "You were going to say something",
            audio: "unit4-370.mp3"
          },
          {
            text: "She was going to buy one",
            audio: "unit4-371.mp3"
          },
          {
            text: "They were going to help me",
            audio: "unit4-372.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I wasn't going to come",
            audio: "unit4-373.mp3"
          },
          {
            text: "She wasn't going to be ready",
            audio: "unit4-374.mp3"
          },
          {
            text: "We weren't going to use it",
            audio: "unit4-375.mp3"
          },
          {
            text: "He wasn't going to continue",
            audio: "unit4-376.mp3"
          },
          {
            text: "They weren't going to pass the test",
            audio: "unit4-377.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What were you going to tell me?",
            audio: "unit4-378.mp3"
          },
          {
            text: "Was I going to play?",
            audio: "unit4-379.mp3"
          },
          {
            text: "When were they going to buy the house?",
            audio: "unit4-380.mp3"
          },
          {
            text: "How much was he going to pay?",
            audio: "unit4-381.mp3"
          },
          {
            text: "Till what time were we going to practice?",
            audio: "unit4-382.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Weren't you going to help me?",
            audio: "unit4-504.mp3"
          },
          {
            text: "I thought you were going to help me",
            audio: "unit4-383.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Wasn't he going to give you a ride?",
            audio: "unit4-505.mp3"
          },
          {
            text: "I thought he was going to give you a ride",
            audio: "unit4-384.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Weren't they going to do the course?",
            audio: "unit4-506.mp3"
          },
          {
            text: "I thought they were going to do the course",
            audio: "unit4-385.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Wasn't she going to work?",
            audio: "unit4-507.mp3"
          },
          {
            text: "I thought she was going to work",
            audio: "unit4-386.mp3"
          }
        ]
      },

    ]
  },
]
