export const pp_extra_2: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>Debes haber</b>lo oído",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You <b>must have</b> heard it",
            audio:"unit5-192.mp3"
          },
          {
            text: "Él <b>debe haber</b>se ido",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>must have</b> left",
            audio:"unit5-193.mp3"
          },

        ]
      },
    ]
  },

  {
    type:'english',
    id: '54a',
    items: [
      {
        rows: [
          {
            text: "I think I lost it",
            audio: "unit5-184.mp3"
          },
          {
            text: "I must have lost it",
            audio: "unit5-185.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "I think she finished the course",
            audio: "unit5-186.mp3"
          },
          {
            text: "She must have finished the course",
            audio: "unit5-187.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "I think it was easy for you",
            audio: "unit5-188.mp3"
          },
          {
            text: "It must have been easy for you",
            audio: "unit5-189.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "I think something happened",
            audio: "unit5-190.mp3"
          },
          {
            text: "Something must have happened",
            audio: "unit5-191.mp3"
          }
        ]
      },
    ]
  },

  {
    type:'translate',
    id: '54b',
    items: [
      {
        rows: [
          {text:'Creo que se me perdió.'},
          {text:'Debo haberlo perdido.'},
        ]
      },
      {
        rows: [
          {text:'Creo que ella terminó el curso.'},
          {text:'Ella debe haber terminado el curso.'},
        ]
      },
      {
        rows: [
          {text:'Creo que te resultó fácil.'},
          {text:'Debe haber sido fácil para ti.'},
        ]
      },
      {
        rows: [
          {text:'Creo que pasó algo.'},
          {text:'Debe haber pasado algo.'},
        ]
      },
    ]
  },
]
