export const structures_16: any = [
  {
    type: 'structures',
    id: '16',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>It'll be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Será &#8230;",
          },
          {
            text:"<b>It won't be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No será &#8230;",
          },
          {
            level:'secondary',
            text: "No estará &#8230;",
          },
          {
            text:"<b>It has to be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Tiene que ser &#8230;",
          },
          {
            text: "<b>It doesn't have to be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No tiene por qué ser &#8230;",
          },
          {
            text: "<b>It can('t) be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(No) puede ser &#8230;<br/>",
          },
          {
            text:"<b>It must(n't) be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(No) debe ser &#8230;",
          },
          {
            text:"<b>It should (n't) be &#8230;</b>",
          },
          {
            level:'secondary',
            text: "(No) debería ser &#8230;",
          }
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "En estas estructuras impersonales de <i>to be</i>, lo fundamental es recordar<b> que no puede omitirse<i> It:</i></b>"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Será difícil.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It'll</b> be hard",
            audio:"unit3-248.mp3"

          },
          {
            text: "No será necesario.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> won't be necessary",
            audio:"unit3-249.mp3"

          },
          {
            text: "Tiene que estar funcionando.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> has to be working",
            audio:"unit3-250.mp3"

          },
          {
            text: "No tiene por qué ser hoy.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> doesn't have to be today",
            audio:"unit3-259.mp3"

          },
          {
            text: "Debe ser importante.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> must be important",
            audio:"unit3-251.mp3"

          },
          {
            text: "No puede ser verdad.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> can't be true",
            audio:"unit3-252.mp3"

          },
          {
            text: "Debería ser fácil para ti.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>It</b> should be easy for you",
            audio:"unit3-253.mp3"

          },
        ]
      }
    ]
  },
  {
    type:'english',
    id: '12a',
    items: [
      {
      rows: [
          {
            text: "It's the same thing",
            audio: "unit3-254.mp3"
          },
          {
            text: "It'll be the same thing",
            audio: "unit3-255.mp3"
          }
        ]
      },
      {
      rows: [
          {
            text: "It isn't possible",
            audio: "unit3-256.mp3"
          },
          {
            text: "It won't be possible",
            audio: "unit3-257.mp3"
          }
        ]
      },
      {
      rows: [
          {
            text: "It's orange",
            audio: "unit3-628.mp3"
          },
          {
            text: "It has to be orange",
            audio: "unit3-629.mp3"
          }
        ]
      },
      {
      rows: [
          {
            text: "It isn't today",
            audio: "unit3-258.mp3"
          },
          {
            text: "It doesn't have to be today",
            audio: "unit3-259.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "It isn't yours",
            audio: "unit3-262.mp3"
          },
          {
            text: "It can't be yours",
            audio: "unit3-263.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "It's closed",
            audio: "unit3-260.mp3"
          },
          {
            text: "It must be closed",
            audio: "unit3-261.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "It's working",
            audio: "unit3-264.mp3"
          },
          {
            text: "It should be working",
            audio: "unit3-265.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '12b',
    items: [
      {
        rows: [
          {text: "Es necesario."},
          {text: "Será necesario."},
        ]
      },
      {
        rows: [
          {text: "No es hoy."},
          {text: "No será hoy."},
        ]
      },
      {
        rows: [
          {text: "Es negro."},
          {text: "Tiene que ser negro."},
        ]
      },
      {
        rows: [
          {text: "No es caro."},
          {text: "No tiene por qué ser caro."},
        ]
      },
      {
        rows: [
          {text: "Es peor."},
          {text: "Puede ser peor."},
        ]
      },
      {
        rows: [
          {text: "No es verdad."},
          {text: "No puede ser verdad."},
        ]
      },
      {
        rows: [
          {text: "Es de Ana."},
          {text: "Deber ser de Ana."},
        ]
      },
      {
        rows: [
          {text: "No es fácil para él."},
          {text: "No deber ser fácil para él."},
        ]
      },
      {
        rows: [
          {text: "Está abierto."},
          {text: "Debería estar abierto."},
        ]
      },
      {
        rows: [
          {text: "No es difícil para ti."},
          {text: "No debería resultarte difícil."},
        ]
      },
    ]
  },
]
