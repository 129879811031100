import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { ContentProviderService } from '../../services/content-provider.service'

import { User } from '../../store/user';
import { AppState } from 'src/app/store/state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage {

  user$: Observable<User>;

  content: any;

  collapse = false;

  constructor(
    private store: Store<AppState>,
    public toastController: ToastController,
    public contentProvider: ContentProviderService
  ) {
    this.content = this.contentProvider.getTOC();
    this.user$ = this.store.select('user');
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      duration: 2000,
      message
    });
    toast.present();
  }
}
