export const want_to: any = [
  {
    type: 'card',
    title: 'WANT TO',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cuando <b>want</b> vaya seguido de un verbo, <b>to</b> debe usarse antes del segundo verbo. Ejemplo:"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Quiero <b>decir</b> algo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>want to</b> say something",
            audio: "unit2-220.mp3",
            type: 'link'
          },
          {
            text: "Él no quiere <b>esperar</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He doesn't <b>want to</b> wait",
            audio: "unit2-325.mp3",
            type: 'link'
          },
          {
            text: "¿Quieres <b>jugar</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Do you <b>want to</b> play?",
            audio: "unit2-222.mp3",
            type: 'link'
          },
          {
            text: "¿Qué quiere <b>hacer</b> ella?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What does she <b>want to</b> do?",
            audio: "unit2-223.mp3",
            type: 'link'
          },
          {
            text: "¿Por qué no quieres <b>venir</b>?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Why don't you <b>want to</b> come?",
            audio: "unit2-224.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Si el complemento no es un verbo no puede usarse <b>to</b>. Ejemplo:"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Quiero algo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I want something",
            audio: "unit2-225.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '25',
    items: [
      {
        rows: [
          {
            text: "I want to play",
            audio: "unit2-226.mp3"
          },
          {
            text: "He wants to play",
            audio: "unit2-227.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't want to do it",
            audio: "unit2-237.mp3"
          },
          {
            text: "She doesn't want to do it",
            audio: "unit2-229.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "When do you want to start?",
            audio: "unit2-230.mp3"
          },
          {
            text: "When does she want to start?",
            audio: "unit2-231.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you want to wait?",
            audio: "unit2-232.mp3"
          },
          {
            text: "Why doesn't he want to wait?",
            audio: "unit2-233.mp3"
          },
        ]
      },
    ]
  },
]
