export const possessives: any = [
  {
    type: 'card',
    title: 'Adjetivos posesivos',
    items: [
      {
        type: 'columns-2',
        display:"left",
          rows: [
            {
              left: {
                tokens: [
                  {
                    text:"My",
                    audio:"unit1-324.mp3",
                    type:"link"
                  }
                ]
              },
            right: {
              tokens: [
                {
                  text:"mi",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Your",
                  audio:"unit1-325.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"tu",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"His",
                  audio:"unit1-326.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"su (de él)",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Her",
                  audio:"unit1-327.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"su (de ella)",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Our",
                  audio:"unit1-328.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"nuestro",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Their",
                  audio:"unit1-329.mp3",
                  type:"link"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"su (de ellos)",
                  audio:"none"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'p_link',
        subtitle:"Ejemplos:",
        tokens: []
      },
      {
        type: 'example',
        rows: [
          {
            text: "I love <b>my</b> parents",
            audio: "unit1-330.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Amo a <i>mis</i> padres.",
            audio:"none"
          },
          {
            text: "You have <b>your</b> house",
            audio: "unit1-331.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Tienes <i>tu</i> casa.",
            audio:"none"
          },
          {
            text: "He needs <b>his</b> book",
            audio: "unit1-332.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Él necesita <i>su</i> libro.",
            audio:"none"
          },
          {
            text: "She wants <b>her</b> money",
            audio: "unit1-333.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Ella quiere <i>su</i> dinero.",
            audio:"none"
          },
          {
            text: "We use <b>our</b> car",
            audio: "unit1-334.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Usamos <i>nuestro</i> auto.",
            audio:"none"
          },
          {
            text: "They like <b>their</b> course",
            audio: "unit1-335.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "Les gusta <i>su</i> curso.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:'Según se ve en todos los ejemplos, los adjetivos posesivos se colocan delante del sustantivo, y son invariables: tienen la misma forma en masculino, femenino, singular y plural.'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:'Para escoger la opción correcta de <strong>su</strong>  en inglés, debe tenerse presente que es el <i>poseedor</i>  quién determina el posesivo correspondiente:'
          }
        ]
      },
      {
        type: 'columns-2',
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"Su casa <br/> <b>(de Ud.)</b>",
                  audio:"none"
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>Your</b> house",
                  audio:"none",
                },
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Su casa <br/> <b>(de él)</b>",
                  audio:"none"
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>His</b> house",
                  audio:"none",
                },
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Su casa <br/> <b>(de ella)</b>",
                  audio:"none"
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>Her</b> house",
                  audio:"none",
                },
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Su casa <br/> <b>(de ellos)</b>",
                  audio:"none"
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>Their</b> house",
                  audio:"none",
                },
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"Su casa <br/> <b>(de ellas)</b>",
                  audio:"none"
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"<b>Their</b> house",
                  audio:"none",
                },
              ]
            }
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:'Cuando se habla de los miembros de la familia, generalmente el español sustituye <i>su(s)</i> por el artículo definido para evitar confusiones en cuanto al poseedor:<br/>'
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            level:'secondary',
            text: "Ella trabaja con <b>el</b> papá.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "Ellos viven con <b>los</b> padres.",
            audio: "none",
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:'En inglés, como no existe esta posibilidad de confusión, siempre se usa el posesivo correspondiente:'
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            level:'secondary',
            text: "She works with <b>her</b> dad.",
            audio: "none",
          },
          {
            level:'secondary',
            text: "They live with <b>their</b> parents.",
            audio: "none",
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '20a',
    items: [
      {
        rows: [
          {
            text: "I have my money",
            audio: "unit1-336.mp3"
          },
          {
            text: "You have your money",
            audio: "unit1-337.mp3"
          },
          {
            text: "He has his money",
            audio: "unit1-338.mp3"
          },
          {
            text: "She has her money",
            audio: "unit1-339.mp3"
          },
          {
            text: "We have our money",
            audio: "unit1-340.mp3"
          },
          {
            text: "They have their money",
            audio: "unit1-341.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '20b',
    items: [
      {rows: [
        {text:"Tengo mi plata."},
        {text:"Tú tienes tu plata."},
        {text:"Él tiene su plata."},
        {text:"Ella tiene su plata."},
        {text:"Tenemos nuestra plata."},
        {text:"Ellos tienen su plata."},
      ]},
      {rows: [
        {text:"Necesito mis llaves."},
        {text:"Ella necesita sus llaves."},
        {text:"Necesitamos nuestras llaves."},
        {text:"Él necesita sus llaves."},
        {text:"Tú necesitas tus llaves."},
        {text:"Ellos necesitan sus llaves."},
      ]},
      {rows: [
        {text:"Él vive con la mamá."},
        {text:"Él vive con la familia de ella."},
        {text:"Ella trabaja con el papá."},
        {text:"Ella trabaja con el papá de él."},
        {text:"Él estudia con el hermano."},
        {text:"Él estudia con el hermano de ella."},
        {text:"Ella ama a sus hijos."},
        {text:"Ella ama a los hijos de él."},
      ]},
      {rows: [{text:"Ella no estudia con él, estudia con el hermano de él."},]},
      {rows: [{text:"No lo conozco a él, conozco a la hermana."},]},
      {rows: [{text:"Ella no lo ama a él, quiere su dinero."},]},
      {rows: [{text:"Lo recuerdo a él, no recuerdo su nombre."},]},
    ]
  }
]
