export const to_be_present_interrogative_negative: any = [
  {
    type: 'card',
    title: "Presente interrogativo negativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>Am I not?</b>",
            audio: "unit3-266.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estoy / soy?</i>",
            audio: "none",
          },
          {
            text: "<b>Aren't you?</b>",
            audio: "unit3-267.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estás / eres?</i>",
            audio: "none",
          },
          {
            text: "<b>Isn't he?</b>",
            audio: "unit3-268.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Él no está / es?</i>",
            audio: "none",
          },
          {
            text: "<b>Isn't she?</b>",
            audio: "unit3-269.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ella no está / es?</i>",
            audio: "none",
          },
          {
            text: "<b>Isn't it?</b>",
            audio: "unit3-270.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No está / es?</i>",
            audio: "none",
          },
          {
            text: "<b>Aren't we?</b>",
            audio: "unit3-271.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿No estamos / somos?</i>",
            audio: "none",
          },
          {
            text: "<b>Aren't they?</b>",
            audio: "unit3-272.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>¿Ellos no están / son?</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
