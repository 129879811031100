export const structures_17: any = [
  {
    type: 'structures',
    id: '17',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I was &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Yo estaba &#8230;ando/iendo",
          },
          {
            text:"<b>I  wasn't &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Yo no estaba &#8230;ando/iendo",
          },
          {
            text:"<b>Were you &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "¿Estabas &#8230;ando/iendo?",
          },
          {
            text:"<b>Weren't  you &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "¿No estabas &#8230;ando/iendo?",
          },
          {
            text: "",
          },
          {
            text:"<b>They were &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Ellos estaban &#8230;ando/iendo",
          },
          {
            text:"<b>They weren't &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "Ellos no estaban &#8230;ando/iendo",
          },
          {
            text:"<b>Were they &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "¿Ellos estaban &#8230;ando/iendo?",
          },
          {
            text:"<b>Weren't they &#8230;ing</b>",
          },
          {
            level:'secondary',
            text: "¿Ellos no estaban &#8230;ando/iendo?",
          },
          {
            text: "",
          },
          {
            text:"<b>It was &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Fue / Era &#8230;",
          },
          {
            text:"<b>It  wasn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No fue / era &#8230;",
          },
          {
            text:"<b>Was it &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿Fue / Era &#8230;?",
          },
          {
            text:"<b>Wasn't  it &#8230;</b>",
          },
          {
            level:'secondary',
            text: "¿No fue / era &#8230;?",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '14',
    items: [
      {
        rows: [
          {text: "Yo estaba allá. "},
          {text: "Yo no estaba allá. "},
          {text: "¿Estabas allá?"},
          {text: "¿No estabas allá?"},
        ]
      },
      {
        rows: [
          {text: "Yo estaba estudiando."},
          {text: "Yo no estaba estudiando. "},
          {text: "¿Estabas estudiando?"},
          {text: "¿No estabas estudiando?"},
        ]
      },
      {
        rows: [
          {text: "Estábamos juntos. "},
          {text: "No estábamos juntos. "},
          {text: "¿Estábamos juntos? "},
          {text: "¿No estábamos juntos?"},
        ]
      },
      {
        rows: [
          {text: "Estábamos viendo TV. "},
          {text: "No estábamos viendo TV."},
          {text: "¿Estábamos viendo TV?"},
          {text: "¿No estábamos viendo TV?"},
        ]
      },
      {
        rows: [
          {text: "Era verdad. "},
          {text: "No era verdad. "},
          {text: "¿Era verdad? "},
          {text: "¿No era verdad?"},
        ]
      },
      {
        rows: [
          {text: "Estaba lloviendo. "},
          {text: "No estaba lloviendo. "},
          {text: "¿Estaba lloviendo? "},
          {text: "¿No estaba lloviendo?"},
        ]
      },
      {
        rows: [
          {text: "Ellos estaban nerviosos."},
          {text: "Ellos no estaban nerviosos."},
          {text: "¿Ellos estaban nerviosos? "},
          {text: "¿Ellos no estaban nerviosos?"},
        ]
      },
      {
        rows: [
          {text: "Ellos estaban hablando inglés. "},
          {text: "Ellos no estaban hablando inglés. "},
          {text: "¿Ellos estaban hablando inglés? "},
          {text: "¿Ellos no estaban hablando inglés?"},
        ]
      },
      {
        rows: [
          {text: "Él estaba preocupado. "},
          {text: "Él no estaba preocupado. "},
          {text: "¿Él estaba preocupado?"},
          {text: "¿Él no estaba preocupado?"},
        ]
      },
      {
        rows: [
          {text: "Él me estaba ayudando. "},
          {text: "Él no me estaba ayudando. "},
          {text: "¿Él te estaba ayudando? "},
          {text: "¿Él no te estaba ayudando?"},
        ]
      },
      {
        rows: [
          {text: "Tú estabas conmigo. "},
          {text: "Tú no estabas conmigo. "},
          {text: "¿Yo estaba contigo?"},
          {text: "¿Yo no estaba contigo?"},
        ]
      },
      {
        rows: [
          {text: "Estabas jugando bien."},
          {text: "No estabas jugando bien. "},
          {text: "¿Yo estaba jugando bien?"},
          {text: "¿Yo no estaba jugando bien?"},
        ]
      },
      {
        rows: [
          {text: "Fue peor. "},
          {text: "No fue peor. "},
          {text: "¿Fue peor? "},
          {text: "¿No fue peor?"},
        ]
      },
      {
        rows: [
          {text: "Estaba funcionando. "},
          {text: "No estaba funcionando. "},
          {text: "¿Estaba funcionando? "},
          {text: "¿No estaba funcionando?"},
        ]
      },
    ]
  }
]
