export const structures_10: any = [
  {
    type: 'structures',
    id: '10',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I'll</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Yo &#8230;ré",
          },
          {
            text:"<b>I won't </b> &#8230;",
          },
          {
            level:'secondary',
            text: "No &#8230;ré",
          },
          {
            text: "<b>Will you </b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿&#8230;rás?",
          },
          {
            text: "<b>Won't you</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No &#8230;rás?",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '39',
    items: [
      {
        rows: [
          {text: "Se lo diré a Alan."},
          {text: "No se lo diré a Alan."},
          {text: "¿Se lo dirás a Alan?"},
          {text: "¿No se lo dirás a Alan?"},
        ]
      },
      {
        rows: [
          {text: "Ella vendrá."},
          {text: "Ella no vendrá."},
          {text: "¿Vendrá ella?"},
          {text: "¿Ella no vendrá?"},
        ]
      },
      {
        rows: [
          {text: "Tendremos clase."},
          {text: "No tendremos clase."},
          {text: "¿Tendremos clase?"},
          {text: "¿No tendremos clase?"},
        ]
      },
      {
        rows: [
          {text: "Lo necesitarás."},
          {text: "No lo necesitarás."},
          {text: "¿Lo necesitaré?"},
          {text: "¿No lo necesitaré?"},
        ]
      },
      {
        rows: [
          {text: "Ellos estarán aquí."},
          {text: "Ellos no estarán aquí."},
          {text: "¿Ellos estarán aquí?"},
          {text: "¿Ellos no estarán aquí?"},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "En las formas <b>negativas</b> generalmente se usa <b>won't</b> en vez de <i>isn't/aren't going to</i>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No voy a hacer el curso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I won't do the course",
            audio: "unit2-79.mp3",
            type: 'link'
          },
          {
            text: "No vas a pagar nada.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "You won't pay anything",
            audio: "unit2-80.mp3",
            type: 'link'
          },
          {
            text: "¿No vas a jugar?	",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Won't you play?",
            audio: "unit2-81.mp3",
            type: 'link'
          },
          {
            text: "¿Ella no te va a ayudar?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Won't she help you?",
            audio: "unit2-82.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Sin embargo, esta preferencia no se convierte en regla, y pueden perfectamente emplearse las estructuras negativas con <i>going to</i>  si se desea.'
          }
        ]
      },
    ]
  }
]
