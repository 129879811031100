export const past_vocab_1: any = [
  {
    type: 'card',
    title: 'Vocabulario Adicional',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>yesterday</b>",
            audio: "unit4-23.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "ayer",
            audio:"none"
          },
          {
            text: "<b>this morning</b>",
            audio: "unit4-24.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "esta mañana",
            audio:"none"
          },
          {
            text: "<b>the other day</b>",
            audio: "unit4-25.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en estos días",
            audio:"none"
          },
          {
            text: "<b>then</b>",
            audio: "unit4-26.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "entonces",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>last night</b><sup>*</sup>",
            audio: "unit4-27.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "anoche",
            audio:"none"
          },
          {
            text: "<b>last week</b><sup>*</sup>",
            audio: "unit4-28.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "la semana pasada",
            audio:"none"
          },
          {
            text: "<b>last month</b><sup>*</sup>",
            audio: "unit4-29.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "el mes pasado",
            audio:"none"
          },
          {
            text: "<b>last year</b><sup>*</sup>",
            audio: "unit4-30.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "el año pasado",
            audio:"none"
          },
          {
            text: "<b>last Sunday</b><sup>*</sup>",
            audio: "unit4-31.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "el domingo pasado",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<sup>*</sup>Nótese la ausencia de artículo en inglés"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>about 3 months ago</b>",
            audio: "unit4-32.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "hace como 3 meses",
            audio:"none"
          },
          {
            text: "<b>a couple of years ago</b>",
            audio: "unit4-33.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "hace un par de años",
            audio:"none"
          },
          {
            text: "<b>about an hour ago</b>",
            audio: "unit4-34.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "hará como una hora",
            audio:"none"
          },
          {
            text: "<b>a long time ago</b>",
            audio: "unit4-35.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "hace mucho tiempo",
            audio:"none"
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>in January</b>",
            audio: "unit4-36.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en enero",
            audio:"none"
          },
          {
            text: "<b>in February</b>",
            audio: "unit4-37.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en febrero",
            audio:"none"
          },
          {
            text: "<b>in March</b>",
            audio: "unit4-38.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en marzo",
            audio:"none"
          },
          {
            text: "<b>in April</b>",
            audio: "unit4-39.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en abril",
            audio:"none"
          },
          {
            text: "<b>in May</b>",
            audio: "unit4-40.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en mayo",
            audio:"none"
          },
          {
            text: "<b>in June</b>",
            audio: "unit4-41.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en junio",
            audio:"none"
          },
          {
            text: "<b>in July</b>",
            audio: "unit4-42.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en julio",
            audio:"none"
          },
          {
            text: "<b>in August</b>",
            audio: "unit4-43.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en agosto",
            audio:"none"
          },
          {
            text: "<b>in September</b>",
            audio: "unit4-44.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en septiembre",
            audio:"none"
          },
          {
            text: "<b>in October</b>",
            audio: "unit4-45.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en octubre",
            audio:"none"
          },
          {
            text: "<b>in November</b>",
            audio: "unit4-46.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en noviembre",
            audio:"none"
          },
          {
            text: "<b>in December</b>",
            audio: "unit4-47.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "en diciembre",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '23',
    items: [
      {
        rows: [
          {text:'Ella llamó ayer.'},
          {text:'Ella llamó en diciembre.'},
          {text:'Ella llamó esta mañana.'},
          {text:'Ella llamó en marzo.'},
          {text:'Ella llamó en estos días.'},
          {text:'Ella llamó en agosto.'},
          {text:'Ella llamó anoche.'},
          {text:'Ella llamó en noviembre.'},
        ]
      },
      {
        rows: [
          {text:'Ella llamó la semana pasada.'},
          {text:'Ella llamó en julio.'},
          {text:'Ella llamó el mes pasado.'},
          {text:'Ella llamó en noviembre.'},
          {text:'Ella llamó hace 5 minutos.'},
          {text:'Ella llamó en enero.'},
          {text:'Ella llamó hace un par de semanas.'},
          {text:'Ella llamó entonces.'},
        ]
      },
      {
        rows: [
          {text:'Ellos empezaron el año pasado.'},
          {text:'Ellos empezaron en enero.'},
          {text:'Ellos empezaron hace como 3 meses.'},
          {text:'Ellos empezaron en septiembre.'},
          {text:'Ellos empezaron el mes pasado.'},
          {text:'Ellos empezaron entonces.'},
          {text:'Ellos empezaron en abril.'},
          {text:'Ellos empezaron hace mucho tiempo.'},
          {text:'Ellos empezaron en febrero.'},
          {text:'Ellos empezaron el lunes pasado.'},
        ]
      },
      {
        rows: [
          {text:'Él estuvo aquí en agosto.'},
          {text:'Él estuvo aquí hace mucho tiempo.'},
          {text:'Él estuvo aquí esta mañana.'},
          {text:'Él estuvo aquí en enero.'},
          {text:'Él estuvo aquí entonces.'},
          {text:'Él estuvo aquí en noviembre.'},
          {text:'Él estuvo aquí el año pasado.'},
          {text:'Él estuvo aquí en julio.'},
        ]
      },
      {
        rows: [
          {text:'Él estuvo aquí el mes pasado.'},
          {text:'Él estuvo aquí en febrero.'},
          {text:'Él estuvo aquí hace como tres años.'},
          {text:'Él estuvo aquí en abril.'},
          {text:'Él estuvo aquí en estos días.'},
          {text:'Él estuvo aquí en octubre.'},
          {text:'Él estuvo aquí hará unos 6 meses.'},
          {text:'Él estuvo aquí en marzo.'},
        ]
      },
      {
        rows: [
          {text:'Él estuvo aquí ayer.'},
          {text:'Él estuvo aquí la semana pasada.'},
          {text:'Él estuvo aquí en junio.'},
          {text:'Él estuvo aquí hasta las 5.'},
          {text:'Él estuvo aquí en septiembre.'},
          {text:'Él estuvo aquí hace un par de años.'},
          {text:'Él estuvo aquí anoche.'},
        ]
      },
    ]
  }
]
