import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { ReplaySubject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LoggerService } from '../services/logger.service'
import { AppState } from '../store/state'
import { actions, nullUser } from '../store/user'


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  loggedIn: boolean = false
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

  constructor(
    private store: Store<AppState>,
    private logger: LoggerService,
  ) {
    this.logger.debug('initializing auth guard')
    this.store.select('user').
      pipe(takeUntil(this.destroyed$)).
      subscribe(
        user => {
          this.loggedIn = (user !== nullUser)
        })
  }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.logger.debug(`evaluating auth guard for ${state.url} (${this.loggedIn})`)
    if (!this.loggedIn) {
      this.logger.debug(`redirecting to login page, referrer: ${state.url}`)
      this.store.dispatch(actions.signIn({referrer: state.url}))
    }
    return this.loggedIn
  }

}
