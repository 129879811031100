export const structures_6: any = [
  {
    type: 'structures',
    id: '6',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I want to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Quiero &#8230;ar/er/ir",
          },
          {
            text:"<b>I don't want to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No quiero &#8230;ar/er/ir",
          },
          {
            text: "<b>Do you want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Quieres &#8230;ar/er/ir?",
          },
          {
            text: "<b>Don't you want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿No quieres &#8230;ar/er/ir?",
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text:"<b>He wants to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Él quiere &#8230;ar/er/ir",
          },
          {
            text:"<b>He doesn't want to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Él no quiere &#8230;ar/er/ir",
          },
          {
            text: "<b>Does he want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Él quiere &#8230;ar/er/ir?",
          },
          {
            text: "<b>Doesn't he want to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Él no quiere &#8230;ar/er/ir?",
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '26',
    items: [
      {
        rows: [
          {text: "Quiero saber."},
          {text: "No quiero saber."},
          {text: "¿Quieres saber?"},
          {text: "¿Qué quieres saber?"},
          {text: "¿No quieres saber?"},
          {text: "¿Por qué no quieres saber?"},
        ]
      },
      {
        rows: [
          {text: "Ella quiere quedarse."},
          {text: "Ella no se quiere quedar."},
          {text: "¿Ella quiere quedarse?"},
          {text: "¿Hasta qué hora quiere quedarse ella?"},
          {text: "¿Ella no se quiere quedar?"},
          {text: "¿Por qué ella no quiere quedarse?"},
        ]
      },
      {
        rows: [
          {text: "Ellos quieren esperar."},
          {text: "Ellos no quieren esperar."},
          {text: "¿Ellos quieren esperar?"},
          {text: "¿Cuánto tiempo quieren esperar ellos?"},
          {text: "Ellos no quieren esperar?"},
          {text: "¿Por qué ellos no quieren esperar?"},
        ]
      },
      {
        rows: [
          {text: "Él quiere venir."},
          {text: "Él no quiere venir."},
          {text: "¿Él quiere venir?"},
          {text: "¿Cuándo quiere venir él?"},
          {text: "¿Él no quiere venir?"},
          {text: "¿Por qué él no quiere venir?"},
        ]
      },
    ]
  },
  {
    type:'english',
    id: '27a',
    items: [
      {
        rows: [
          {
            text: "I do it",
            audio: "unit2-234.mp3"
          },
          {
            text: "I want to do it",
            audio: "unit2-235.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't do it",
            audio: "unit2-236.mp3"
          },
          {
            text: "I don't want to do it",
            audio: "unit2-237.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Do you do it?",
            audio: "unit2-238.mp3"
          },
          {
            text: "Do you want to do it?",
            audio: "unit2-239.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you do it?",
            audio: "unit2-240.mp3"
          },
          {
            text: "Why don't you want to do it?",
            audio: "unit2-241.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He practices",
            audio: "unit2-242.mp3"
          },
          {
            text: "He wants to practice",
            audio: "unit2-243.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He doesn't practice",
            audio: "unit2-244.mp3"
          },
          {
            text: "He doesn't want to practice",
            audio: "unit2-245.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "How long does he practice?",
            audio: "unit2-246.mp3"
          },
          {
            text: "How long does he want to practice?",
            audio: "unit2-247.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why doesn't he practice?",
            audio: "unit2-248.mp3"
          },
          {
            text: "Why doesn't he want to practice?",
            audio: "unit2-249.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '27b',
    items: [
      {
        rows: [
          {text: "Lo hago."},
          {text: "Quiero hacerlo."},
        ]
      },
      {
        rows: [
          {text: "No lo hago."},
          {text: "No quiero hacerlo."},
        ]
      },
      {
        rows: [
          {text: "¿Tú lo haces?"},
          {text: "¿Quieres hacerlo?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué no lo haces?"},
          {text: "¿Por qué no quieres hacerlo?"},
        ]
      },
      {
        rows: [
          {text: "Él practica."},
          {text: "Él quiere practicar."},
        ]
      },
      {
        rows: [
          {text: "Él no practica."},
          {text: "Él no quiere practicar."},
        ]
      },
      {
        rows: [
          {text: "¿Cuánto tiempo practica él?"},
          {text: "¿Cuánto tiempo quiere practicar él?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué él no practica?"},
          {text: "¿Por qué él no quiere practicar?"},
        ]
      },
      {
        rows: [
          {text: "Ellos tienen clase."},
          {text: "Ellos quieren tener clase."},
        ]
      },
      {
        rows: [
          {text: "Ellos no tienen clase."},
          {text: "Ellos no quieren tener clase."},
        ]
      },
      {
        rows: [
          {text: "¿Cuándo tienen clase ellos?"},
          {text: "¿Cuándo quieren tener clase ellos?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué ellos no tienen clase?"},
          {text: "¿Por qué ellos no quieren tener clase? "},
        ]
      },
      {
        rows: [
          {text: "Ana trabaja."},
          {text: "Ana quiere trabajar."},
        ]
      },
      {
        rows: [
          {text: "Ana no trabaja."},
          {text: "Ana no quiere trabajar."},
        ]
      },
      {
        rows: [
          {text: "¿Dónde trabaja Ana?"},
          {text: "¿Dónde quiere trabajar Ana?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué Ana no trabaja?"},
          {text: "¿Por qué Ana no quiere trabajar?"},
        ]
      },
    ]
  }
]
