export const to_be_present_negative: any = [
  {
    type: 'card',
    title: "Presente negativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>I'm not</b>",
            audio: "unit3-352.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No estoy / soy</i>",
            audio: "none",
          },
          {
            text: "<b>You aren't</b>",
            audio: "unit3-353.mp3",
            type: 'link'
          },
          {
            text: "<b>You're not</b>",
            audio: "unit3-354.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No estás / eres</i>",
            audio: "none",
          },
          {
            text: "<b>He isn't</b>",
            audio: "unit3-355.mp3",
            type: 'link'
          },
          {
            text: "<b>He's not</b>",
            audio: "unit3-356.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Él no está / es</i>",
            audio: "none",
          },
          {
            text: "<b>She isn't</b>",
            audio: "unit3-357.mp3",
            type: 'link'
          },
          {
            text: "<b>She's not</b>",
            audio: "unit3-358.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Élla no está / es</i>",
            audio: "none",
          },
          {
            text: "<b>It isn't</b>",
            audio: "unit3-359.mp3",
            type: 'link'
          },
          {
            text: "<b>It's not</b>",
            audio: "unit3-360.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No está / es</i>",
            audio: "none",
          },
          {
            text: "<b>We aren't</b>",
            audio: "unit3-361.mp3",
            type: 'link'
          },
          {
            text: "<b>We're not</b>",
            audio: "unit3-362.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No estamos / somos</i>",
            audio: "none",
          },
          {
            text: "<b>They aren't</b>",
            audio: "unit3-363.mp3",
            type: 'link'
          },
          {
            text: "<b>They're not</b>",
            audio: "unit3-364.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ellos no están / son</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
