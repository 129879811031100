export const subject_pronouns: any = [
  {
    type: 'card',
    title: 'Pronombres personales',
    items: [
      {
        type: 'p',
        text: 'Los pronombres personales deben ser memorizados.'
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"I",
                    audio:"unit1-612.mp3",
                    type: "link",
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"yo",
                    audio:"none"
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"You",
                  audio:"unit1-613.mp3",
                  type: "link",
                },
              ]
            },
            right: {
              tokens: [
                {
                  text:"tú, Ud., Uds.",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"We",
                  audio:"unit1-614.mp3",
                  type: "link",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"nosotros",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"They",
                  audio:"unit1-615.mp3",
                  type: "link",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"ellas, ellos",
                  audio:"none"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"He",
                  audio:"unit1-616.mp3",
                  type: "link",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"él",
                  audio:"none"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"She",
                  audio:"unit1-617.mp3",
                  type: "link",
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"ella",
                  audio:"none"
                }
              ]
            }
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Verbos',
    items: [
      {
        type: 'p',
        text: 'Aprende el vocabulario mínimo para expresar ideas sencillas.'
      },
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>want</b>",
                  audio: "unit1-395.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>querer</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>something</i>",
                  audio: "unit1-396.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>algo</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>remember</b>",
                  audio: "unit1-397.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>recordar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>that</i>",
                  audio: "unit1-398.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>eso</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>use</b>",
                  audio: "unit1-399.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>usar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>it</i>",
                  audio: "unit1-400.mp3"

                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>lo</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>mine</i>",
                  audio: "unit1-401.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el mío</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>yours</i>",
                  audio: "unit1-402.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el tuyo</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>have</b>",
                  audio: "unit1-403.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>tener</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>time</i>",
                  audio: "unit1-404.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>tiempo</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>class</i>",
                  audio: "unit1-405.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>clase</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>problems</i>",
                  audio: "unit1-406.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>problemas</i>"
                }
              ]
            }
          },

          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>a lot of money</i>",
                  audio: "unit1-735.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>mucho dinero</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>say</b>",
                  audio: "unit1-407.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>decir</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>many things</i>",
                  audio: "unit1-408.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>muchas cosas</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>feel</b>",
                  audio: "unit1-409.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>sentirse</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>well</i>",
                  audio: "unit1-410.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>bien</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>bad</i>",
                  audio: "unit1-411.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>mal</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>better today</i>",
                  audio: "unit1-412.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>mejor hoy</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>play</b>",
                  audio: "unit1-413.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>jugar / tocar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>on Sundays</i>",
                  audio: "unit1-414.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>los domingos</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the piano</i>",
                  audio: "unit1-733.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el piano</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>understand</b>",
                  audio: "unit1-416.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>entender</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>everything</i>",
                  audio: "unit1-417.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>todo</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>drive</b>",
                  audio: "unit1-418.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>conducir</b>, <b>manejar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>fast</i>",
                  audio: "unit1-419.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>rápido</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the car</i>",
                  audio: "unit1-420.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el auto</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>speak</b>",
                  audio: "unit1-421.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>hablar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>English</i>",
                  audio: "unit1-422.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>inglés</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>Spanish</i>",
                  audio: "unit1-423.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>español</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>come</b>",
                  audio: "unit1-424.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>venir</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>to class</i>",
                  audio: "unit1-425.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>a clase</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>every day</i>",
                  audio: "unit1-426.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>todos los días</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>need</b>",
                  audio: "unit1-427.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>necesitar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>money</i>",
                  audio: "unit1-428.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>dinero</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the book</i>",
                  audio: "unit1-429.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>el libro</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>one</i>",
                  audio: "unit1-736.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>uno</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>know</b>",
                  audio: "unit1-430.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>saber / conocer</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: " <i>anything</i> <sup>1</sup>",
                  audio: "unit1-431.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>nada</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: " <i>anybody</i> <sup>1</sup>",
                  audio: "unit1-432.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>nadie</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>live</b>",
                  audio: "unit1-433.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>vivir</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>here</i>",
                  audio: "unit1-434.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>aquí</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>there</i>",
                  audio: "unit1-435.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>allá</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>in Madrid</i>",
                  audio: "unit1-436.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>en Madrid</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>work</b>",
                  audio: "unit1-437.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>trabajar</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>all day</i>",
                  audio: "unit1-438.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>todo el día</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>study</b>",
                  audio: "unit1-439.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>estudiar</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>together</i>",
                  audio: "unit1-440.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>juntos</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>practice</b>",
                  audio: "unit1-441.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>practicar</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>all the time</i>",
                  audio: "unit1-442.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>todo el tiempo</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>watch TV</b>",
                  audio: "unit1-443.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>ver televisión</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>in the evening</i>",
                  audio: "unit1-444.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>en la noche</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>get up</b>",
                  audio: "unit1-445.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>levantarse</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>so early</i>",
                  audio: "unit1-446.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>tan temprano</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>go to bed</b>",
                  audio: "unit1-447.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>acostarse</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>too late</i>",
                  audio: "unit1-448.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>demasiado tarde</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>start</b>",
                  audio: "unit1-449.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>empezar</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>at 5 o'clock</i>",
                  audio: "unit1-450.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>a las 5</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>finish</b>",
                  audio: "unit1-451.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>terminar</b>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>at 8 thirty</i>",
                  audio: "unit1-452.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>a las 8 y media</i>"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'p',
        text: '<sup>1</sup> en oraciones negativas.'
      },
    ]
  },
  {
    type: 'card',
    title: 'Memorización de los verbos',
    items: [
      {
        type: 'p',
        text: 'Para facilitar este proceso de memorización se sugiere separar los verbos en grupos de 4 ó 5 y asociar el verbo español con su equivalente en inglés tantas veces como sea necesario hasta que esta asociación sea automática.'
      },
      {
        type: 'p',
        text: 'Los 22 verbos estudiados se habrán memorizado cuando se puedan repetir en inglés, de memoria y sin omisiones.'
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"querer",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"want",
                  audio:"unit1-453.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"recordar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"remember",
                  audio:"unit1-454.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"usar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"use",
                  audio:"unit1-455.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"tener",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"have",
                  audio:"unit1-456.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"decir",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"say",
                  audio:"unit1-457.mp3",
                  type:"link"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"sentir(se)",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"feel",
                  audio:"unit1-458.mp3",
                  type:"link"
                }
              ]
            },
          },
          {
            left: {
              tokens: [
                {
                  text:"jugar / tocar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"play",
                  audio:"unit1-459.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"entender",
                  audio:"none"
                }
              ]
            },
            right: {
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"understand",
                  audio:"unit1-460.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"manejar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"drive",
                  audio:"unit1-461.mp3",
                  type:"link"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"hablar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"speak",
                  audio:"unit1-462.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"venir",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"come",
                  audio:"unit1-463.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"necesitar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"need",
                  audio:"unit1-464.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"saber / conocer",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"know",
                  audio:"unit1-745.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"vivir",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"live",
                  audio:"unit1-737.mp3",
                  type:"link"
                }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"trabajar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"work",
                  audio:"unit1-738.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"estudiar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"study",
                  audio:"unit1-739.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"practicar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"practice",
                  audio:"unit1-740.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"ver televisión",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"watch TV",
                  audio:"unit1-741.mp3",
                  type:"link"
                }
              ]
            }
          }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                {
                  text:"levantarse",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"get up",
                  audio:"unit1-742.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"acostarse",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"go to bed",
                  audio:"unit1-743.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"empezar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"start",
                  audio:"unit1-744.mp3",
                  type:"link"
                }
              ]
            }
          },
          {
            left: {
              tokens: [
                {
                  text:"terminar",
                  audio:"none"
                }
              ]
            },
            right:{
              tokens: [
                {
                  text:"→ ",
                  audio:"none"
                },
                {
                  text:"finish",
                  audio:"unit1-746.mp3",
                  type:"link"
                }
              ]
            }
          }
        ]
      }
    ]
  }
]
