export const structures_15: any = [
  {
    type: 'structures',
    id: '15',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>It's &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Es &#8230;",
          },
          {
            text:"<b>It isn't &#8230;</b>",
          },
          {
            text: "<b>It's not &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No es &#8230;",
          },
          {
            text: "<b>Is it &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Es &#8230;?<br/>",
          },

          {
            text:"<b>Isn't it &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿No es &#8230;?",
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '11a',
    items: [
      {
      rows: [
          {
            text: "It's ready",
            audio: "unit3-365.mp3"
          },
          {
            text: "It's not ready",
            audio: "unit3-366.mp3"
          },
          {
            text: "Is it ready?",
            audio: "unit3-367.mp3"
          },
          {
            text: "Isn't it ready?",
            audio: "unit3-368.mp3"
          },
        ]
      },
      {
      rows: [
          {
            text: "It's yours",
            audio: "unit3-369.mp3"
          },
          {
            text: "It isn't yours",
            audio: "unit3-370.mp3"
          },
          {
            text: "Is it yours?",
            audio: "unit3-371.mp3"
          },
          {
            text: "Isn't it yours?",
            audio: "unit3-372.mp3"
          },
        ]
      },
      {
      rows: [
          {
            text: "It's true",
            audio: "unit3-373.mp3"
          },
          {
            text: "It's not true",
            audio: "unit3-374.mp3"
          },
          {
            text: "Is it true?",
            audio: "unit3-375.mp3"
          },
          {
            text: "Isn't it true?",
            audio: "unit3-376.mp3"
          },
        ]
      },
      {
      rows: [
          {
            text: "It's too late",
            audio: "unit3-377.mp3"
          },
          {
            text: "It isn't too late",
            audio: "unit3-378.mp3"
          },
          {
            text: "Is it too late?",
            audio: "unit3-379.mp3"
          },
          {
            text: "Isn't it to late?",
            audio: "unit3-380.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '11b',
    items: [
      {
        rows: [
          {text: "Es hoy."},
          {text: "No es hoy."},
          {text: "¿Es hoy?"},
          {text: "¿No es hoy?"},
        ]
      },
      {
        rows: [
          {text: "Está arriba."},
          {text: "No está arriba."},
          {text: "¿Está arriba?"},
          {text: "¿No está arriba?"},
        ]
      },
      {
        rows: [
          {text: "Es tu libro."},
          {text: "No es tu libro."},
          {text: "¿Es tu libro?"},
          {text: "¿No es tu libro?"},
        ]
      },
      {
        rows: [
          {text: "Está sobre la mesa."},
          {text: "No está sobre la mesa."},
          {text: "¿Está sobre la mesa?"},
          {text: "¿No está sobre la mesa?"},
        ]
      }
    ]
  }
]
