export const complements_vocab_1: any = [
  {
    type: 'card',
    title: 'Vocabulario adicional',
    items: [
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>like</b>",
                  audio: "unit1-75.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "gustar <br/> (<b>con</b> complemento)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To like <b>it</b>",
                  audio: "unit1-76.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "gustar <br/>(<b>sin</b> complemento)"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>forget</b>",
                  audio: "unit1-77.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>olvidar</b>"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>do</b>",
                  audio: "unit1-78.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>hacer</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>so much</i>",
                  audio: "unit1-79.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>tanto</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>pay</b>",
                  audio: "unit1-80.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>pagar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>more</i>",
                  audio: "unit1-81.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>más</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>learn</b>",
                  audio: "unit1-82.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>aprender</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>French</i>",
                  audio: "unit1-83.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>francés</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>Italian</i>",
                  audio: "unit1-84.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>italiano</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>stay</b>",
                  audio: "unit1-85.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>quedarse</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>at home</i>",
                  audio: "unit1-86.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>en casa</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>sleep</b>",
                  audio: "unit1-87.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>dormir</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>enough</i>",
                  audio: "unit1-88.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>suficiente</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>too much</i>",
                  audio: "unit1-89.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>demasiado</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>eat</b>",
                  audio: "unit1-90.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>comer</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "  <i>chicken</i>",
                  audio: "unit1-91.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>pollo</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>fish</i>",
                  audio: "unit1-92.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>pescado</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>meat</i>",
                  audio: "unit1-93.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>carne</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>drink</b>",
                  audio: "unit1-94.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>tomar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>water</i>",
                  audio: "unit1-95.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>agua</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>coffee</i>",
                  audio: "unit1-96.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>café</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>milk</i>",
                  audio: "unit1-97.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>leche</i>"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>buy</b>",
                  audio: "unit1-98.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>comprar</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>food</i>",
                  audio: "unit1-99.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>comida</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>a car</i>",
                  audio: "unit1-100.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>un carro</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>sell</b>",
                  audio: "unit1-101.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>vender</b>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>clothes</i>",
                  audio: "unit1-102.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>ropa</i>"
                }
              ]
            }
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<i>the house</i>",
                  audio: "unit1-103.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<i>la casa</i>"
                }
              ]
            }
          },

          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>pass</b> the<br/>&nbsp; test",
                  audio: "unit1-105.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>aprobar</b> el<br/>&nbsp; examen"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>get</b> here",
                  audio: "unit1-107.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>llegar</b> aquí"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "To <b>get</b> home",
                  audio: "unit1-108.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "<b>llegar</b> a casa"
                }
              ]
            }
          },
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Precisiones sobre el uso del vocabulario',
    items: [
      {
        type: 'p_link',
        subtitle:"<i>To <b>like.</b></i>",
        tokens: [
          {
            text: 'Si en español <i>gustar</i> va seguido de un complemento, éste se expresa en inglés. (Si se habla en general, se omite el artículo). Si no se expresa el complemento en español, <b>it</b> debe usarse en inglés.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Me gusta el inglés.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I like English",
            audio:"none"
          },
          {
            text: "¿El inglés? Me gusta.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "English?  I like <b>it</b>",
            audio:"none"
          },
          {
            text: "A él no le gusta el café.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He doesn't like coffee",
            audio:"none"
          },
          {
            text: "¿El café?  A él no le gusta.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Coffee?  He doesn't like <strong>it</strong>",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        subtitle:"<i>To <b>do.</b></i>",
        tokens: [
          {
            text: '3ª persona: '
          },
          {
            text: 'He does',
            audio: "unit1-770.mp3",
            type: 'link'
          },
          {
            text: '.<br>En negativo, interrogativo e interrogativo-negativo no puede omitirse <i>do</i> aunque parezca redundante.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Qué haces tú?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What <b>do</b> you <b>do</b>?",
            audio:"none"
          },
          {
            text: "¿Ana no hace nada?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Doesn't</b> Ana <b>do</b> anything?",
            audio:"none"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '14',
    items: [
      {
        rows: [
          {
            text: "Se me olvida.",
          },
          {
            text: "Duermo bien.",
          },
          {
            text: "Me gusta.",
          },
          {
            text: "Compro comida.",
          }
        ]
      },
      {
        rows: [
          {
            text: "No me gusta.",
          },
          {
            text: "No vendo nada.",
          },
          {
            text: "No tomo.",
          },
          {
            text: "No hago nada.",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Qué comes?",
          },
          {
            text: "¿A qué hora llegas a casa?",
          },
          {
            text: "¿Cuánto pagas?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿No se te olvida nada?",
          },
          {
            text: "¿No te gusta?",
          },
          {
            text: "¿Tú no tomas?",
          }
        ]
      },
      {
        rows: [
          {
            text: "Él lo hace.",
          },
          {
            text: "¿Quién duerme ahí?",
          },
          {
            text: "Todo el mundo aprende",
          },
          {
            text: "Nadie llega aquí temprano.",
          },
          {
            text: "A alguien le gusta.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Ella no hace nada.",
          },
          {
            text: "Ella no aprende nada.",
          },
          {
            text: "Ella no paga nada.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Cuántas horas duerme el esposo?",
          },
          {
            text: "¿Qué hace el esposo?",
          },
          {
            text: "¿Cuánto paga el esposo?",
          },
          {
            text: "¿Por qué el esposo come tanto?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Por qué a Ana no le gusta?",
          },
          {
            text: "¿Por qué Ana no duerme bien?",
          },
          {
            text: "¿Por qué Ana no hace eso?",
          },
          {
            text: "¿Por qué Ana no compra la casa?",
          }
        ]
      },
      {
        rows: [
          {
            text: "A él le gusta el café; a la esposa no le gusta.",
          },
        ]
      },
      {
        rows: [
          {
            text: "A la esposa no le gusta el francés; a él le gusta.",
          },
        ]
      },
      {
        rows: [
          {
            text: "A Ana le gusta el libro; a mí no me gusta; ¿a ti te gusta?",
          },
        ]
      },
    ]
  }
]
