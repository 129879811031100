export const pp_continuous: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "¿Cuánto tiempo tienes &#8230;ando/iendo?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>How long have you been &#8230;ing?</b>",
            audio:"none"
          },
          {
            text: "Tengo &#8230; &#8230;ando/iendo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I've been &#8230;ing for &#8230;.</b>",
            audio:"none"
          },
          {
            text: "Estoy &#8230;ando/iendo desde &#8230;.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I've been &#8230;ing since &#8230;.</b>",
            audio:"none"
          },

        ]
      },
    ]
  },

  {
    type:'english',
    id: '48',
    items: [
      {
        rows: [
          {
            text: "How long have you been studying English?",
            audio: "unit5-131.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How long has he been talking?",
            audio: "unit5-132.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How long have we been practicing?",
            audio: "unit5-134.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How long has it been raining?",
            audio: "unit5-137.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "How long have we been married?",
            audio: "unit5-135.mp3"
          }
        ]
      },
    ]
  },

  {
    type:'translate',
    id: '49',
    items: [
      {
        rows: [
          {text:'¿Cuánto tiempo llevas estudiando inglés?'},
        ]
      },
      {
        rows: [
          {text:'¿Cuánto tiempo tiene él hablando?'},
        ]
      },
      {
        rows: [
          {text:'¿Cuánto tiempo llevamos practicando?'},
        ]
      },
      {
        rows: [
          {text:'¿Cuánto tiempo llevas casado/a?'},
        ]
      }
    ]
  },

  {
    type: 'card',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Questions in the present perfect continuous can be answered using a short answer or a sentence. In both cases, <b>for</b> or <b>since</b> followed by a time expression must be used. The following indication will help you select the right preposition."
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "If <b><i>desde</i></b> (not <i>desde hace</i>) is used in the Spanish sentence, <b>since</b> must be used in English."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>How long has he been talking?</b>",
            audio:"none"
          },
          {
            text: "<b>Short answer:</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i>Desde</i> las 2",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Since</b> 2 o'clock.",
            audio:"none"
          },
          {
            text: "<b>Long answer:</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Él está hablando <i>desde</i> las 2",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He's been talking <b>since</b> 2",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "If <i>desde</i> is not used, <b>for</b> is the choice in English."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>How long has he been talking?</b>",
            audio:"none"
          },
          {
            text: "<b>Short answer:</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "2 horas.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>For</b> 2 hours.",
            audio:"none"
          },
          {
            text: "<b>Long answer:</b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Él tiene 2 horas hablando",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He's been talking <b>for</b> 2 hours",
            audio:"none"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '50',
    items: [
      {
        rows: [
          {
            text: "I've been waiting for 1 hour",
            audio: "unit5-138.mp3"
          },
          {
            text: "I've been waiting since 1 o'clock",
            audio: "unit5-139.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "She's been sleeping for 2 hours",
            audio: "unit5-140.mp3"
          },
          {
            text: "She's been sleeping since 2 o'clock",
            audio: "unit5-141.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "They've been practicing for 20 minutes",
            audio: "unit5-142.mp3"
          },
          {
            text: "They've been practicing since this morning",
            audio: "unit5-143.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "It's been raining for 3 days",
            audio: "unit5-144.mp3"
          },
          {
            text: "It's been raining since Friday",
            audio: "unit5-145.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '51',
    items: [
      {
        rows: [
          {text:'Estoy esperando desde la 1.'},
          {text:'Llevo 1 hora esperando.'},
        ]
      },
      {
        rows: [
          {text:'Ella lleva 2 horas durmiendo.'},
          {text:'Ella está durmiendo desde las 2.'},
        ]
      },
      {
        rows: [
          {text:'Ellos tienen 20 minutos practicando.'},
          {text:'Ellos están practicando desde esta mañana.'},
        ]
      },
      {
        rows: [
          {text:'Hace 3 días que está lloviendo.'},
          {text:'Está lloviendo desde el viernes.'},
        ]
      },
    ]
  },
]
