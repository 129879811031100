export const past_examples: any = [
  {
    type:'english',
    id: '26',
    items: [
      {
        rows: [
          {
            text: "I bought one",
            audio: "unit4-92.mp3"
          },
          {
            text: "I called her last night",
            audio: "unit4-93.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We watched TV",
            audio: "unit4-94.mp3"
          },
          {
            text: "We saw what they did",
            audio: "unit4-95.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I took the test",
            audio: "unit4-96.mp3"
          },
          {
            text: "I stayed; she went home",
            audio: "unit4-97.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "They believed it",
            audio: "unit4-98.mp3"
          },
          {
            text: "You knew they were there",
            audio: "unit4-99.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I repeated the test",
            audio: "unit4-100.mp3"
          },
          {
            text: "I went to bed late",
            audio: "unit4-101.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You heard me",
            audio: "unit4-102.mp3"
          },
          {
            text: "You read what he wrote",
            audio: "unit4-103.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I forgot we had class",
            audio: "unit4-104.mp3"
          },
          {
            text: "I felt I needed it",
            audio: "unit4-105.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Who answered the phone?",
            audio: "unit4-106.mp3"
          },
          {
            text: "Who passed the test?",
            audio: "unit4-107.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You practiced",
            audio: "unit4-108.mp3"
          },
          {
            text: "You left this here",
            audio: "unit4-109.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Everybody paid",
            audio: "unit4-110.mp3"
          },
          {
            text: "Everybody studied",
            audio: "unit4-111.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I understood everything",
            audio: "unit4-112.mp3"
          },
          {
            text: "You were late",
            audio: "unit4-527.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I went on",
            audio: "unit4-501.mp3"
          },
          {
            text: "I continued",
            audio: "unit4-113.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "They played well",
            audio: "unit4-152.mp3"
          },
          {
            text: "They won; we lost",
            audio: "unit4-115.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I met her last year",
            audio: "unit4-116.mp3"
          },
          {
            text: "I told you I knew her",
            audio: "unit4-117.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She spent too much",
            audio: "unit4-118.mp3"
          },
          {
            text: "She came back on Sunday",
            audio: "unit4-119.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We brought you this",
            audio: "unit4-120.mp3"
          },
          {
            text: "We texted her",
            audio: "unit4-121.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He lived there",
            audio: "unit4-122.mp3"
          },
          {
            text: "There were many people",
            audio: "unit4-528.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She waited for us",
            audio: "unit4-509.mp3"
          },
          {
            text: "She looked for her key",
            audio: "unit4-126.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We spent the day together",
            audio: "unit4-127.mp3"
          },
          {
            text: "We ate and drank",
            audio: "unit4-128.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Ana took mine",
            audio: "unit4-129.mp3"
          },
          {
            text: "Ana felt better and came",
            audio: "unit4-130.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I remembered yesterday",
            audio: "unit4-131.mp3"
          },
          {
            text: "I listened to music",
            audio: "unit4-132.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Nobody spoke English",
            audio: "unit4-133.mp3"
          },
          {
            text: "Nobody talked to him",
            audio: "unit4-134.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He drove; she slept",
            audio: "unit4-510.mp3"
          },
          {
            text: "They worked well",
            audio: "unit4-135.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I told you I wanted one",
            audio: "unit4-136.mp3"
          },
          {
            text: "It was the same thing",
            audio: "unit4-137.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "They were for you",
            audio: "unit4-138.mp3"
          },
          {
            text: "They gave me the money",
            audio: "unit4-139.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He stopped drinking",
            audio: "unit4-140.mp3"
          },
          {
            text: "He took her home",
            audio: "unit4-141.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We helped them",
            audio: "unit4-142.mp3"
          },
          {
            text: "We got there early",
            audio: "unit4-143.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Who found the key?",
            audio: "unit4-144.mp3"
          },
          {
            text: "Who lent you the money?",
            audio: "unit4-145.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I asked the teacher",
            audio: "unit4-146.mp3"
          },
          {
            text: "I thought you used it",
            audio: "unit4-147.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She loved him",
            audio: "unit4-148.mp3"
          },
          {
            text: "She left last week",
            audio: "unit4-149.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I finished at 10",
            audio: "unit4-150.mp3"
          },
          {
            text: "I got up late",
            audio: "unit4-151.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "They played well",
            audio: "unit4-152.mp3"
          },
          {
            text: "They started talking",
            audio: "unit4-153.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You said you liked it",
            audio: "unit4-154.mp3"
          },
          {
            text: "You put it there",
            audio: "unit4-155.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "We sold the apartment",
            audio: "unit4-156.mp3"
          },
          {
            text: "We learned something",
            audio: "unit4-157.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I tried to study",
            audio: "unit4-158.mp3"
          },
          {
            text: "I kept working",
            audio: "unit4-159.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '27a',
    items: [
      {
        rows: [
          {
            text: "I drove",
            audio: "unit4-160.mp3"
          },
          {
            text: "I didn't drive",
            audio: "unit4-161.mp3"
          },
          {
            text: "Did you drive?",
            audio: "unit4-162.mp3"
          },
          {
            text: "Didn't you drive?",
            audio: "unit4-163.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "He paid",
            audio: "unit4-164.mp3"
          },
          {
            text: "He didn't pay",
            audio: "unit4-165.mp3"
          },
          {
            text: "Did he pay?",
            audio: "unit4-166.mp3"
          },
          {
            text: "Didn't he pay?",
            audio: "unit4-167.mp3"
          },
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '27b',
    items: [
      {
        rows: [
          {text:'Lo hice.'},
          {text:'No lo hice.'},
          {text:'¿Lo hiciste?'},
          {text:'¿No lo hiciste?'},
        ]
      },
      {
        rows: [
          {text:' Ella encontró su dinero.'},
          {text:'Ella no encontró su dinero.'},
          {text:'¿Ella encontró su dinero?'},
          {text:'¿Ella no encontró su dinero?'},
        ]
      },
      {
        rows: [
          {text:'Tuvimos clase.'},
          {text:'No tuvimos clase.'},
          {text:'¿A qué hora tuvimos clase?'},
          {text:'¿Por qué no tuvimos clase?'},
        ]
      },
      {
        rows: [
          {text:'Me lo dijiste.'},
          {text:'No me lo dijiste.'},
          {text:'¿Te lo dije?'},
          {text:'¿No te lo dije?'},
        ]
      },
      {
        rows: [
          {text:'Mis amigos me ayudaron.'},
          {text:'Mis amigos no me ayudaron.'},
          {text:'¿Tus amigos te ayudaron? '},
          {text:'¿Tus amigos no te ayudaron?'},
        ]
      },
      {
        rows: [
          {text:'Él la conoció allá'},
          {text:' Él no la conoció allá.'},
          {text:'¿Dónde la conoció él?'},
          {text:'¿Él no la conoció allá?'},
        ]
      },
    ]
  }
]
