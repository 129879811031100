export const present_perfect: any = [
  {
    type: 'card',
    title:"To have + past participle",
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Like in Spanish, the present perfect is used to express that an action can still be performed. Negative structures are the most important ones:'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>I haven't + <i>past participle</i></b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "No he &#8230;ado/ido",
            audio:"none"
          },
          {
            text: "<b>He hasn't + <i>past participle</i></b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Él no ha &#8230;ado/ido",
            audio:"none"
          },
          {
            text: "<b>We haven't been able to + <i>past participle</i></b>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "No hemos podido &#8230;ar/er/ir",
            audio:"none"
          },
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "No hablé con él.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I didn't talk to him",
            audio:"unit5-107.mp3"
          },
          {
            text: "No <b>he hablado</b> con él.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>haven't talked</b> to him",
            audio:"unit5-108.mp3"
          },
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "Él no me dio el dinero.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He didn't give me the money",
            audio:"unit5-175.mp3"
          },
          {
            text: "Él no me <b>ha dado</b> el dinero.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>hasn't given</b> me the money",
            audio:"unit5-176.mp3"
          },
        ]
      },

      {
        type: 'example',
        rows: [
          {
            text: "No pude estudiar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I couldn't study",
            audio:"unit5-109.mp3"
          },
          {
            text: "No <b>he podido</b> estudiar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>haven't been able to</b> study",
            audio:"unit5-110.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Obviously, there are also affirmative and interrogative structures, but these are used to a lesser degree.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>I've never</b> eaten that<sup>1</sup>",
            audio:"unit5-177.mp3"
          },
          {
            level:'secondary',
            text: "<b>Nunca he</b> comido eso.",
            audio: 'none'
          },
          {
            text: "<b>Have you ever</b> worked?",
            audio:"unit5-178.mp3"
          },
          {
            level:'secondary',
            text: "¿<b>Alguna vez has</b> trabajado?",
            audio: 'none'
          },
          {
            text: "<b>Hasn't she</b> called?",
            audio:"unit5-179.mp3"
          },
          {
            level:'secondary',
            text: "¿<b>Ella no ha</b> llamado?",
            audio: 'none'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: '<sup>1</sup>En el afirmativo, generalmente se usan contracciones:'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>I've</b> been busy",
            audio:"unit5-180.mp3"
          },
          {
            level:'secondary',
            text: "He estado ocupado/a.",
            audio: 'none'
          },
          {
            text: "<b>We've</b> finished",
            audio:"unit5-181.mp3"
          },
          {
            level:'secondary',
            text: "Hemos terminado.",
            audio: 'none'
          },
          {
            text: "<b>He's</b> gone",
            audio:"unit5-182.mp3"
          },
          {
            level:'secondary',
            text: "Él se ha ido.",
            audio: 'none'
          },
          {
            text: "<b>You've</b> had enough time",
            audio:"unit5-183.mp3"
          },
          {
            level:'secondary',
            text: "Has tenido suficiente tiempo.",
            audio: 'none'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '46',
    items: [
      {
        rows: [
          {
            text: "I haven't seen him",
            audio: "unit5-111.mp3"
          },
          {
            text: "I haven't been able to see him",
            audio: "unit5-112.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "He hasn't come",
            audio: "unit5-113.mp3"
          },
          {
            text: "He hasn't been able to come",
            audio: "unit5-114.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "We haven't practiced",
            audio: "unit5-115.mp3"
          },
          {
            text: "We haven't been able to practice",
            audio: "unit5-116.mp3"
          }
        ]
      },

      {
        rows: [
          {
            text: "She hasn't gone to work",
            audio: "unit5-117.mp3"
          },
          {
            text: "She hasn't been able to go to work",
            audio: "unit5-118.mp3"
          }
        ]
      },

    ]
  },

  {
    type:'translate',
    id: '47',
    items: [
      {
        rows: [
          {text:'No se lo dije (a ella).'},
          {text:'No se lo he dicho (a ella).'}
        ]
      },
      {
        rows: [
          {text:'Él no vino.'},
          {text:'Él no ha venido.'},
        ]
      },
      {
        rows: [
          {text:'No pude llamar a Alan.'},
          {text:'No he podido llamar a Alan.'},
        ]
      },
      {
        rows: [
          {text:'Ella no pudo estudiar.'},
          {text:'Ella no ha podido estudiar.'},
        ]
      },
      {
        rows: [
          {text:'No hicimos nada.'},
          {text:'No hemos hecho nada.'},
        ]
      },
      {
        rows: [
          {text:'No pudimos venderlo.'},
          {text:'No hemos podido venderlo.'},
        ]
      },
      {
        rows: [
          {text:'Ella no tuvo tiempo.'},
          {text:'Ella no ha tenido tiempo.'},
        ]
      },
      {
        rows: [
          {text:'Ella no pudo ir.'},
          {text:'Ella no ha podido ir.'},
        ]
      },
      {
        rows: [
          {text:'Ellos no pagaron.'},
          {text:'Ellos no han pagado.'},
        ]
      },
      {
        rows: [
          {text:'Ellos no pudieron seguir.'},
          {text:'Ellos no han podido seguir.'},
        ]
      },
    ]
  },
]
