export const past_regular: any = [
  {
    type: 'card',
    title: 'Verbos regulares',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se forma agregando <b><i>-ed</i></b> al verbo (sólo <b><i>-d</i></b> si el verbo termina en <b><i>-e</i></b>)."
          },
        ]
      },

      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To play"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>played</b>",
                  audio: "unit4-432.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To stay"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>stayed</b>",
                  audio: "unit4-433.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To study"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>studied</b>",
                  audio: "unit4-434.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To remember"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>remembered</b>",
                  audio: "unit4-435.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To answer"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>answered</b>",
                  audio: "unit4-436.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To call"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>called</b>",
                  audio: "unit4-437.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To help"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>helped</b>",
                  audio: "unit4-438.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To learn"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>learned</b>",
                  audio: "unit4-439.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To listen"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>listened</b>",
                  audio: "unit4-440.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To believe"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>believed</b>",
                  audio: "unit4-441.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To love"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>loved</b>",
                  audio: "unit4-442.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To live"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>lived</b>",
                  audio: "unit4-443.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To continue"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>continued</b>",
                  audio: "unit4-444.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To try"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>tried</b>",
                  audio: "unit4-445.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To cry"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>cried</b>",
                  audio: "unit4-523.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To stop"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>stopped</b><br/>",
                  audio: "unit4-446.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To practice"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>practiced</b>",
                  audio: "unit4-447.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To finish"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>finished</b>",
                  audio: "unit4-448.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To use"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>used</b>",
                  audio: "unit4-449.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To watch TV"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>watched TV</b>",
                  audio: "unit4-419.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To pass the test"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>passed the test</b><br/>",
                  audio: "unit4-451.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To like"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>liked</b>",
                  audio: "unit4-452.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To work"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>worked</b>",
                  audio: "unit4-453.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To ask"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>asked</b>",
                  audio: "unit4-454.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To talk to"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>talked to</b>",
                  audio: "unit4-424.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To look for"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>looked for</b></br>",
                  audio: "unit4-425.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To want"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>wanted</b>",
                  audio: "unit4-457.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To start"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>started</b>",
                  audio: "unit4-458.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To wait"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>waited</b>",
                  audio: "unit4-459.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To text"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>texted</b>",
                  audio: "unit4-460.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To repeat"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>repeated</b>",
                  audio: "unit4-522.mp3"
                }
              ]
            }
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "To need"
                }
              ]
            },
            right: {
              tokens:[
                {
                  type: "link",
                  text: "<b>needed</b>",
                  audio: "unit4-462.mp3"
                }
              ]
            }
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '22a',
    items: [
      {
        rows: [
          {
            text: "played",
            audio: "unit4-432.mp3"
          },
          {
            text: "stayed",
            audio: "unit4-433.mp3"
          },
          {
            text: "studied",
            audio: "unit4-434.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "remembered",
            audio: "unit4-435.mp3"
          },
          {
            text: "answered",
            audio: "unit4-436.mp3"
          },
          {
            text: "called",
            audio: "unit4-437.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "helped",
            audio: "unit4-438.mp3"
          },
          {
            text: "learned",
            audio: "unit4-439.mp3"
          },
          {
            text: "listened",
            audio: "unit4-440.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "believed",
            audio: "unit4-441.mp3"
          },
          {
            text: "loved",
            audio: "unit4-442.mp3"
          },
          {
            text: "lived",
            audio: "unit4-443.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "continued",
            audio: "unit4-444.mp3"
          },
          {
            text: "tried",
            audio: "unit4-445.mp3"
          },
          {
            text: "stopped",
            audio: "unit4-446.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "practiced",
            audio: "unit4-447.mp3"
          },
          {
            text: "finished",
            audio: "unit4-448.mp3"
          },
          {
            text: "used",
            audio: "unit4-449.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "watched TV",
            audio: "unit4-450.mp3"
          },
          {
            text: "passed the test",
            audio: "unit4-451.mp3"
          },
          {
            text: "liked",
            audio: "unit4-452.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "worked",
            audio: "unit4-453.mp3"
          },
          {
            text: "asked",
            audio: "unit4-454.mp3"
          },
          {
            text: "talked",
            audio: "unit4-455.mp3"
          },
          {
            text: "looked",
            audio: "unit4-456.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "wanted",
            audio: "unit4-457.mp3"
          },
          {
            text: "started",
            audio: "unit4-458.mp3"
          },
          {
            text: "waited",
            audio: "unit4-459.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "texted",
            audio: "unit4-460.mp3"
          },
          {
            text: "repeated the test",
            audio: "unit4-461.mp3"
          },
          {
            text: "needed",
            audio: "unit4-462.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '22b',
    items: [
      {
        rows: [
          {text:'Terminé temprano'},
          {text:'Ella se quedó aquí'},
          {text:'No hicimos nada'},
          {text:'Ellos lo usaron'},
          {text:'Jugaste bien'},
        ]
      },
      {
        rows: [
          {text:'Alguien llamó'},
          {text:'Él estudió aquí'},
          {text:'Vimos TV'},
          {text:'Me gustó'},
          {text:'Aprendiste algo'},
        ]
      },
      {
        rows: [
          {text:'Él habló conmigo'},
          {text:'Ellos vivieron allá'},
          {text:'Ella preguntó por qué'},
          {text:'Lo necesitábamos'},
          {text:'¿Quién empezó?'},
          {text:'Ellos me mandaron un SMS'},
        ]
      },
      {
        rows: [
          {text:'Repetí el examen'},
          {text:'Nadie aprobó el examen'},
          {text:'Ellos practicaron'},
          {text:'Lo intenté'},
          {text:'Queríamos uno'},
        ]
      },
      {
        rows: [
          {text:'Todo el mundo continuó'},
          {text:'Ella nos ayudó'},
          {text:'Me lo creí'},
          {text:'Él la amaba'},
          {text:'Trabajaste con ellos'},
        ]
      },
      {
        rows: [
          {text:'Oí música'},
          {text:'Él te esperó'},
          {text:'Me acordé el domingo'},
          {text:'¿Quién contestó?'},
          {text:'Ellos dejaron de venir'},
        ]
      },
    ]
  }
]
