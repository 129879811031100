export const to_be_past_negative: any = [
  {
    type: 'card',
    title: "Pasado negativo",
    items: [
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "<b>I wasn't</b>",
            audio: "unit3-572.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Yo no estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>You weren't</b>",
            audio: "unit3-573.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Tú no estabas / eras</i>",
            audio: "none",
          },
          {
            text: "<b>He wasn't</b>",
            audio: "unit3-574.mp3",
            type: 'link'
          },
            {
            level:'secondary',
            text: "<i>Él no estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>She wasn't</b>",
            audio: "unit3-575.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ella no estaba / era</i>",
            audio: "none",
          },
          {
            text: "<b>It wasn't</b>",
            audio: "unit3-576.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No estaba /era</i>",
            audio: "none",
          },
          {
            text: "<b>We weren't</b>",
            audio: "unit3-577.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>No estábamos / éramos</i>",
            audio: "none",
          },
          {
            text: "<b>They weren't</b>",
            audio: "unit3-578.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "<i>Ellos no estaban / eran</i>",
            audio: "none",
          },

        ]
      },
    ]
  }
]
