export const cardinal: any = [
  {
    type: 'card',
    items: [
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"1",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One",
                    audio:"unit1-724.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"2",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Two",
                    audio:"unit1-510.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"3",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Three",
                    audio:"unit1-511.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"4",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Four",
                    audio:"unit1-512.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"5",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Five",
                    audio:"unit1-513.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"6",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Six",
                    audio:"unit1-514.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"7",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Seven",
                    audio:"unit1-515.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"8",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Eight",
                    audio:"unit1-516.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"9",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Nine",
                    audio:"unit1-517.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"10",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Ten",
                    audio:"unit1-518.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"11",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Eleven",
                    audio:"unit1-519.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"12",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Twelve",
                    audio:"unit1-520.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"13",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Thirteen",
                    audio:"unit1-521.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"14",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Fourteen",
                    audio:"unit1-522.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"15",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Fifteen",
                    audio:"unit1-523.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"16",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Sixteen",
                    audio:"unit1-524.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"17",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Seventeen",
                    audio:"unit1-525.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"18",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Eighteen",
                    audio:"unit1-526.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"19",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Nineteen",
                    audio:"unit1-527.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"20",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Twenty",
                    audio:"unit1-528.mp3",
                    type: "link",
                  }
              ]
            }
          },
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"21",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Twenty-one",
                    audio:"unit1-529.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"30",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Thirty",
                    audio:"unit1-530.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"32",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Thirty-two",
                    audio:"unit1-531.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"40",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Forty",
                    audio:"unit1-532.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"43",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Forty-three",
                    audio:"unit1-533.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"50",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Fifty",
                    audio:"unit1-534.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"54",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Fifty-four",
                    audio:"unit1-535.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"60",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Sixty",
                    audio:"unit1-536.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"65",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Sixty-five",
                    audio:"unit1-537.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"70",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Seventy",
                    audio:"unit1-538.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"76",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Seventy-six",
                    audio:"unit1-539.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"80",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Eighty",
                    audio:"unit1-540.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"87",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Eighty-seven",
                    audio:"unit1-541.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"90",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Ninety",
                    audio:"unit1-542.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"98",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Ninety-eight",
                    audio:"unit1-543.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"100",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One hundred",
                    audio:"unit1-544.mp3",
                    type: "link",
                  }
              ]
            },
          },
          {
            left: {
              tokens: [
                  {
                    text:"125",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One hundred twenty-five",
                    audio:"unit1-759.mp3",
                    type: "link",
                  }
              ]
            },
          },
          {
            left: {
              tokens: [
                  {
                    text:"450",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Four hundred fifty",
                    audio:"unit1-760.mp3",
                    type: "link",
                  }
                ]
              }
            }
        ]
      },
      {
        type: 'columns-2',
        display:"left",
        rows: [
          {
            left: {
              tokens: [
                  {
                    text:"1,000",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One thousand",
                    audio:"unit1-545.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"1,500",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One thousand five hundred",
                    audio:"unit1-762.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"15,000",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"Fifteen thousand",
                    audio:"unit1-761.mp3",
                    type: "link",
                  }
              ]
            }
          },
          {
            left: {
              tokens: [
                  {
                    text:"1,250,000",
                    audio:"none"
                  }
              ]
            },
            right: {
              tokens: [
                  {
                    text:"One million two hundred fifty thousand",
                    audio:"unit1-763.mp3",
                    type: "link",
                  }
              ]
            }
          }
        ]
      }
    ]
  }
]
