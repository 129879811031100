export const going_to: any = [
  {
    type: 'card',
    title: 'El futuro inmediato',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "El futuro inmediato se expresa mediante  <b>going to</b>. Indica una acción que se ha decidido llevar a cabo en un plazo breve."
          }
        ]
      },
    ]
  },
  {
    type: 'card',
    title: 'Affirmativo',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "I'm going to &#8230;",
            audio: "unit2-327.mp3",
          },
          {
            level:'secondary',
            text: "Voy a &#8230;",
            audio:"none"
          },
          {
            text: "You're going to &#8230;",
            audio: "unit2-328.mp3",
          },
          {
            level:'secondary',
            text: "Vas a &#8230;",
            audio:"none"
          },
          {
            text: "He's going to &#8230;",
            audio: "unit2-329.mp3",
          },
          {
            level:'secondary',
            text: "Él va a &#8230;",
            audio:"none"
          },
          {
            text: "We're going to &#8230;",
            audio: "unit2-330.mp3",
          },
          {
            level:'secondary',
            text: "Vamos a &#8230;",
            audio:"none"
          },
          {
            text: "They're going to &#8230;",
            audio: "unit2-331.mp3",
          },
          {
            level:'secondary',
            text: "Ellos (ellas) van a &#8230;",
            audio:"none"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "La contracción no es obligatoria con  <i>we</i> y <i>they</i>. Es posible decir <i><b>we are</b> going to &#8230;</i> o <i><b>they are</b> going to &#8230;</i>."
          }
        ]
      }
    ]
  },
  {
    type: 'card',
    title: 'Negativo',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "I'm not going to &#8230;",
            audio: "unit2-332.mp3",
          },
          {
            level:'secondary',
            text: "No voy a &#8230;",
            audio:"none"
          },
          {
            text: "You aren't going to &#8230;",
            audio: "unit2-333.mp3",
          },
          {
            level:'secondary',
            text: "No vas a &#8230;",
            audio:"none"
          },
          {
            text: "He isn't going to &#8230;",
            audio: "unit2-334.mp3",
          },
          {
            level:'secondary',
            text: "Él no va a &#8230;",
            audio:"none"
          },
          {
            text: "We aren't going to &#8230;",
            audio: "unit2-335.mp3",
          },
          {
            level:'secondary',
            text: "No vamos a &#8230;",
            audio:"none"
          },
          {
            text: "They aren't going to &#8230;",
            audio: "unit2-336.mp3",
          },
          {
            level:'secondary',
            text: "Ellos (ellas) no van a &#8230;",
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type: 'card',
    title: 'Interrogativo',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "¿Am I going to &#8230;?",
            audio: "unit2-337.mp3",
          },
          {
            level:'secondary',
            text: "¿Voy a &#8230;?",
            audio:"none"
          },
          {
            text: "Are you going to &#8230;?",
            audio: "unit2-338.mp3",
          },
          {
            level:'secondary',
            text: "¿Vas a &#8230;?",
            audio:"none"
          },
          {
            text: "Is he going to &#8230;?",
            audio: "unit2-339.mp3",
          },
          {
            level:'secondary',
            text: "¿Él va a &#8230;?",
            audio:"none"
          },
          {
            text: "Are we going to &#8230;?",
            audio: "unit2-340.mp3",
          },
          {
            level:'secondary',
            text: "¿Vamos a &#8230;?",
            audio:"none"
          },
          {
            text: "Are they going to &#8230;?",
            audio: "unit2-341.mp3",
          },
          {
            level:'secondary',
            text: "¿Ellos (ellas) van a &#8230;?",
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type: 'card',
    title: 'Interrogativo negativo',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "¿Am I not going to &#8230;?",
            audio: "unit2-342.mp3",
          },
          {
            level:'secondary',
            text: "¿No voy a &#8230;?",
            audio:"none"
          },
          {
            text: "Aren't you going to &#8230;?",
            audio: "unit2-343.mp3",
          },
          {
            level:'secondary',
            text: "¿No vas a &#8230;?",
            audio:"none"
          },
          {
            text: "Isn't he going to &#8230;?",
            audio: "unit2-344.mp3",
          },
          {
            level:'secondary',
            text: "¿Él no va a &#8230;?",
            audio:"none"
          },
          {
            text: "Aren't we going to &#8230;?",
            audio: "unit2-345.mp3",
          },
          {
            level:'secondary',
            text: "¿No vamos a &#8230;?",
            audio:"none"
          },
          {
            text: "Aren't they going to &#8230;?",
            audio: "unit2-346.mp3",
          },
          {
            level:'secondary',
            text: "¿Ellos (ellas) no van a &#8230;?",
            audio:"none"
          },
        ]
      }
    ]
  },
  {
    type: 'card',
    title: 'Ejemplos',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Voy a ganar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I'm going to win",
            audio: "unit2-39.mp3",
            type: 'link'
          },
          {
            text: "Todo el mundo va a venir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Everybody's going to come",
            audio: "unit2-40.mp3",
            type: 'link'
          },
          {
            text: "Vamos a gastar más.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "We're going to spend more",
            audio: "unit2-41.mp3",
            type: 'link'
          },
          {
            text: "No voy a jugar.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I'm not going to play",
            audio: "unit2-42.mp3",
            type: 'link'
          },
          {
            text: "Ella no va a seguir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She isn't going to continue",
            audio: "unit2-43.mp3",
            type: 'link'
          },
          {
            text: "¿Qué vas a hacer?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "What are you going to do?",
            audio: "unit2-44.mp3",
            type: 'link'
          },
          {
            text: "¿Voy a jugar?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Am I going to play?",
            audio: "unit2-59.mp3",
            type: 'link'
          },
          {
            text: "¿No me vas a ayudar?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Aren't you going to help me?",
            audio: "unit2-46.mp3",
            type: 'link'
          },
          {
            text: "¿Ana no va a manejar?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Isn't Ana going to drive?",
            audio: "unit2-47.mp3",
            type: 'link'
          },
        ]
      }
    ]
  },

  {
    type:'english',
    id: '35a',
    items: [
      {
        rows: [
          {
            text: "I'm going to be here",
            audio: "unit2-48.mp3"
          },
          {
            text: "We're going to be here",
            audio: "unit2-49.mp3"
          },
          {
            text: "She's going to be here",
            audio: "unit2-50.mp3"
          },
          {
            text: "They're going to be here",
            audio: "unit2-51.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I'm not going to continue",
            audio: "unit2-52.mp3"
          },
          {
            text: "They aren't going to continue",
            audio: "unit2-53.mp3"
          },
          {
            text: "He isn't going to continue",
            audio: "unit2-54.mp3"
          },
          {
            text: "We aren't going to continue",
            audio: "unit2-55.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Are you going to play?",
            audio: "unit2-56.mp3"
          },
          {
            text: "Are we going to play?",
            audio: "unit2-57.mp3"
          },
          {
            text: "Is Alan going to play?",
            audio: "unit2-58.mp3"
          },
          {
            text: "Am I going to play?",
            audio: "unit2-59.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Aren't you going to have class?",
            audio: "unit2-60.mp3"
          },
          {
            text: "Isn't he going to have class?",
            audio: "unit2-61.mp3"
          },
          {
            text: "Aren't they going to have class?",
            audio: "unit2-62.mp3"
          },
          {
            text: "Am I not going to have class?",
            audio: "unit2-63.mp3"
          },
          {
            text: "Aren't we going to have class?",
            audio: "unit2-64.mp3"
          }
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '35b',
    items: [
      {
        rows: [
          {
            text: "Voy a estar aquí.",
          },
          {
            text: "Vamos a estar aquí.",
          },
          {
            text: "Ella va a estar aquí.",
          },
          {
            text: "Ellos van a estar aquí.",
          }
        ]
      },
      {
        rows: [
          {
            text: "No voy a continuar.",
          },
          {
            text: "Ellos no van a continuar.",
          },
          {
            text: "Él no va continuar.",
          },
          {
            text: "No vamos a continuar.",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿Vas a jugar?",
          },
          {
            text: "¿Vamos a jugar?",
          },
          {
            text: "¿Alan va a jugar?",
          },
          {
            text: "¿Voy a jugar?",
          }
        ]
      },
      {
        rows: [
          {
            text: "¿No vas a necesitar nada?.",
          },
          {
            text: "¿Él no va a necesitar nada?",
          },
          {
            text: "¿Ellos no van a necesitar nada?",
          },
          {
            text: "¿No voy a necesitar nada?",
          },
          {
            text: "¿No vamos a necesitar nada?",
          }
        ]
      },
    ]
  }
]
