export const conditional_extra_1: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "Debiste / Has debido &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You should have &#8230;</b>",
            audio:"none"
          },
          {
            text: "Él no ha debido &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He shouldn't have  &#8230;</b>",
            audio:"none"
          },
          {
            text: "Hubieras podido &#8230;",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You could have  &#8230;</b>",
            audio:"none"
          },
        ]
      },
    ]
  },

  {
    type:'english',
    id: '56a',
    items: [
      {
        rows: [
          {
            text: "You should have eaten something",
            audio: "unit5-83.mp3"
          },
          {
            text: "They should have won",
            audio: "unit5-84.mp3"
          },
          {
            text: "I should have known",
            audio: "unit5-85.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You shouldn't have sold it",
            audio: "unit5-87.mp3"
          },
          {
            text: "He shouldn't have played",
            audio: "unit5-88.mp3"
          },
          {
            text: "I shouldn't have said anything",
            audio: "unit5-89.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You could have eaten with us",
            audio: "unit5-91.mp3"
          },
          {
            text: "I could have helped you",
            audio: "unit5-92.mp3"
          },
          {
            text: "We could have finished before",
            audio: "unit5-93.mp3"
          }
        ]
      }
    ]
  },

  {
    type:'translate',
    id: '56b',
    items: [
      {
        rows: [
          {text:'Has debido comer algo.'},
          {text:'Ellos han debido ganar.'},
          {text:'Debí saberlo.'},
        ]
      },
      {
        rows: [
          {text:'No debiste venderlo.'},
          {text:'Él no ha debido jugar.'},
          {text:'No he debido decir nada.'},
        ]
      },
      {
        rows: [
          {text:'Hubieras podido comer con nosotros.'},
          {text:'Yo podría haberte ayudado.'},
          {text:'Hubiéramos podido terminar antes.'},
        ]
      },
    ]
  },
]
