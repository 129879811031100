export const affirmative: any = [
  {
    type: 'card',
    title: 'Afirmativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se antepone "
          },
          {
            type: "link",
            text: "<b>I</b>",
            audio: "unit1-612.mp3"
          },
          {
            text: "/"
          },
          {
            type: "link",
            text: "<b>You</b>",
            audio: "unit1-732.mp3"
          },
          {
            text: "/"
          },
          {
            type: "link",
            text: "<b>We</b>",
            audio: "unit1-729.mp3"
          },
          {
            text: "/"
          },
          {
            type: "link",
            text: "<b>They</b>",
            audio: "unit1-693.mp3"
          },
          {
            text: " al verbo sin <i>to</i>.  Estos pronombres no pueden omitirse como en español."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Me acuerdo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I</b> remember",
            audio: "unit1-694.mp3",
            type: 'link'
          },
          {
            text: "Tú hablas inglés.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>You</b> speak English",
            audio: "unit1-695.mp3",
            type: 'link'
          },
          {
            text: "Necesitamos uno.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>We</b> need one",
            audio: "unit1-696.mp3",
            type: 'link'
          },
          {
            text: "Ellos saben.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>They</b> know",
            audio: "unit1-697.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '1a',
    items: [
      {
        rows: [
          {
            text: "I study",
            audio: "unit1-698.mp3"
          },
          {
            text: "You study",
            audio: "unit1-699.mp3"
          },
          {
            text: "We study",
            audio: "unit1-700.mp3"
          },
          {
            text: "They study",
            audio: "unit1-701.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I use it",
            audio: "unit1-702.mp3"
          },
          {
            text: "You use it",
            audio: "unit1-703.mp3"
          },
          {
            text: "We use it",
            audio: "unit1-704.mp3"
          },
          {
            text: "They use it",
            audio: "unit1-705.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "I have one",
            audio: "unit1-706.mp3"
          },
          {
            text: "You have one",
            audio: "unit1-707.mp3"
          },
          {
            text: "We have one",
            audio: "unit1-708.mp3"
          },
          {
            text: "They have one",
            audio: "unit1-709.mp3"
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '1b',
    items: [
      {
        rows: [
          {
            text: "Yo estudio.",
          },
          {
            text: "Tú estudias.",
          },
          {
            text: "Estudiamos.",
          },
          {
            text: "Ellos estudian.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Lo uso.",
          },
          {
            text: "Tú lo usas.",
          },
          {
            text: "Lo usamos.",
          },
          {
            text: "Ellos lo usan.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Tengo uno.",
          },
          {
            text: "Tienes uno.",
          },
          {
            text: "Tenemos uno.",
          },
          {
            text: "Ellos tienen uno.",
          }
        ]
      },
      {
        rows: [
          {
            text: "Entiendo.",
          },
          {
            text: "Tú entiendes.",
          },
          {
            text: "Entendemos.",
          },
          {
            text: "Ellos entienden.",
          }
        ]
      },
    ]
  }
]
