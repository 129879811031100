import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AlertController } from '@ionic/angular'
import { Actions, ofType } from '@ngrx/effects'
import { of, ReplaySubject } from 'rxjs'
import { delay, filter, takeUntil, tap } from 'rxjs/operators'
import { actions } from 'src/app/store/user'



@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {

  loadTimeout = 1000
  loggedIn = false
  loading = true

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private actions$: Actions,
    private router: Router,
    private alertController: AlertController
  ) {
    this.loading = true
  }

  ngOnInit() {

    this.clearLoad()

  }

  async loginAlert() {
    const alert = await this.alertController.create({
      header: 'Error de autenticacion',
      message: 'Usuario o contrasena invalidos',
      buttons: ['OK']
    })
    await alert.present()
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setLoad() {
    this.loading = true
  }

  clearLoad() {
    this.loading = false
  }

}
