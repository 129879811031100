export const affirmative_3: any = [
  {
    type: 'card',
    title: 'Afirmativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text:"Se agrega  <b>'s'</b>  al verbo."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él sabe eso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He</b> know<b>s</b> that",
            audio: "unit1-364.mp3",
            type: 'link'
          },
          {
            text: "Ella maneja bien.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She</b> drive<b>s</b> well",
            audio: "unit1-365.mp3",
            type: 'link'
          },
          {
            text: "Él se levanta temprano.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He</b> get<b>s</b> up early",
            audio: "unit1-366.mp3",
            type: 'link'
          },
          {
            text: "Ella quiere algo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>She</b> want<b>s</b> something",
            audio: "unit1-367.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "A los verbos terminados en <b>silbante</b> (-se, -ce, -ch, -sh, -x, -ge) se les agrega <b>-es</b> (sólo <b>-s</b> si el verbo ya termina en <b>-e</b>) en la tercera persona singular."
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Fonéticamente, estos verbos se caracterizan por tener una sílaba más en la tercera persona singular."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i>I use</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He use<b>s</b>",
            audio: "unit1-368.mp3",
            type: 'link'
          },
          {
            text: "<i>I practice</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He practice<b>s</b>",
            audio: "unit1-369.mp3",
            type: 'link'
          },
          {
            text: "<i>I watch TV</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He watch<b>es</b> TV",
            audio: "unit1-370.mp3",
            type: 'link'
          },
          {
            text: "<i>I finish</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He finish<b>es</b>",
            audio: "unit1-371.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Ortográficamente, Los verbos terminados en <strong>consonante + y</strong> cambian  y  por  <strong>-ies</strong>  en la 3ª persona singular."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i>I study</i>",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He stud<b>ies</b>",
            audio: "unit1-373.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Esto no sucede si el verbo termina en <b>vocal + y</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i>I play</i>",
            audio:"none"
          },
          {
            level:'secondary',
            type: "link",
            text: "He play<b>s</b>",
            audio: "unit1-374.mp3"
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<b>To go</b> tiene una variación ortográfica."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<i>I go to bed</i>",
            audio:"none"
          },
          {
            type: "link",
            level:'secondary',
            text: "He go<b>es</b> to bed",
            audio: "unit1-375.mp3"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Los verbos terminados en <strong>-ve</strong> siguen la regla general con la excepción de <b>Have:</b><br/>"
          },
          {
            type: "link",
            text: "<b>He has</b>",
            audio: "unit1-372.mp3"
          },
          {
            text: "."
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '6a',
    items: [
      {
        rows: [
          {
            text: "She needs time",
            audio: "unit1-376.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She practices here",
            audio: "unit1-377.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She starts at 2 thirty",
            audio: "unit1-378.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She watches TV all the time",
            audio: "unit1-379.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She gets up early",
            audio: "unit1-380.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She studies here",
            audio: "unit1-382.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She finishes at 7",
            audio: "unit1-383.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "She feels better today",
            audio: "unit1-384.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '6b',
    items: [
      {rows: [{text:"Ella necesita tiempo."}]},
      {rows: [{text:"Ella practica aquí."}]},
      {rows: [{text:"Ella empieza a las 2:30."}]},
      {rows: [{text:"Ella se la pasa viendo TV."}]},
      {rows: [{text:"Ella se levanta temprano."}]},
      {rows: [{text:"Ella usa el libro."}]},
      {rows: [{text:"Ella estudia aquí."}]},
      {rows: [{text:"Ella termina a las 7."}]},
      {rows: [{text:"Ella se siente mejor hoy."}]},
      {rows: [{text:"Él quiere uno."}]},
      {rows: [{text:"Él juega los domingos."}]},
      {rows: [{text:"Él se acuesta tarde."}]},
      {rows: [{text:"Él vive ahí."}]},
      {rows: [{text:"Él maneja rápido."}]},
      {rows: [{text:"Él trabaja todo el día."}]},
      {rows: [{text:"Él viene a clase todos los días."}]},
      {rows: [{text:"Él habla inglés. "}]},
      {rows: [{text:"Él tiene plata."}]},
      {rows: [{text:"Él conoce a Ana."}]},
      {rows: [{text:"Él se lo sabe todo."}]},
    ]
  },
  {
    type: 'card',
    title: 'Otros sujetos',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text:"No sólo <b>he</b> y <b>she</b> sino también sustantivos, nombres propios y otros pronombres pueden usarse como sujetos. Por supuesto, estos sujetos alternos sustituyen a <i>he/she</i> con el <b>verbo en 3ª persona</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Who</b> say<b>s</b> that?",
            audio:"unit1-385.mp3"
          },
          {
            level:'secondary',
            text: "¿Quién dice eso?",
            audio:"none"
          },
          {
            text: "<b>Nobody</b> come<b>s</b> to class <sup>1</sup>",
            audio:"unit1-386.mp3"
          },
          {
            level:'secondary',
            text: "Nadie viene a clase.",
            audio:"none"
          },
          {
            text: "<b>Someone</b> need<b>s</b> it",
            audio:"unit1-387.mp3"
          },
          {
            level:'secondary',
            text: "Alguien lo necesita.",
            audio:"none"
          },
          {
            text: "<b>Everybody</b> use<b>s</b> it",
            audio:"unit1-388.mp3"
          },
          {
            level:'secondary',
            text: "Todo el mundo lo usa.",
            audio:"none"
          },
          {
            text: "<b>My friend</b> studie<b>s</b> here",
            audio:"unit1-389.mp3"
          },
          {
            level:'secondary',
            text: "Mi amigo/a estudia aquí.",
            audio:"none"
          },
          {
            text: "<b>Your mom </b> know<b>s</b>",
            audio:"unit1-390.mp3"
          },
          {
            level:'secondary',
            text: "Tu mamá sabe.",
            audio:"none"
          },
          {
            text: "<b>Her husband</b> play<b>s</b> well",
            audio:"unit1-391.mp3"
          },
          {
            level:'secondary',
            text: "El esposo juega bien.",
            audio:"none"
          },
          {
            text: "<b>His wife</b> drive<b>s</b>",
            audio:"unit1-392.mp3"
          },
          {
            level:'secondary',
            text: "La esposa maneja.",
            audio:"none"
          },
          {
            text: "<b>Alan</b> need<b>s</b> the car",
            audio:"unit1-393.mp3"
          },
          {
            level:'secondary',
            text: "Alan necesita el carro.",
            audio:"none"
          },
          {
            text: "<b>The teacher</b> speak<b>s</b> English",
            audio:"unit1-394.mp3"
          },
          {
            level:'secondary',
            text: "El profesor habla inglés.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text:"<sup>1</sup> Nadie <b>después</b> del verbo:  <b>anybody</b>.<br/><sup>&ensp;</sup> Nadie <b>antes</b> el verbo:  <b>nobody</b>."
          }
        ]
      },
    ]
  }
]
