import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, BehaviorSubject } from 'rxjs';

import { ExerciseProviderService } from './exercise-provider.service'

import { lessons } from './content/lessons'
import { subject_pronouns } from './content/subject_pronouns'
import { affirmative } from './content/affirmative'
import { negative } from './content/negative'
import { interrogative } from './content/interrogative'
import { interrogative_negative } from './content/interrogative_negative'
import { structures_1 } from './content/structures_1'
import { affirmative_3 } from './content/affirmative_3'
import { structures_2 } from './content/structures_2'
import { negative_3 } from './content/negative_3'
import { interrogative_3 } from './content/interrogative_3'
import { interrogative_negative_3 } from './content/interrogative_negative_3'
import { structures_3 } from './content/structures_3'
import { questions } from './content/questions'
import { structures_4 } from './content/structures_4'
import { complements_vocab_1 } from './content/complements_vocab_1'
import { object_pronouns } from './content/object_pronouns'
import { complements_vocab_2 } from './content/complements_vocab_2'
import { adverbs } from './content/adverbs'
import { structures_5 } from './content/structures_5'
import { nouns } from './content/nouns'
import { determiners } from './content/determiners'
import { possessives } from './content/possessives'
import { conjunctions } from './content/conjunctions'
import { introductory_phrases } from './content/introductory_phrases'
import { cardinal } from './content/cardinal'
import { expressions } from './content/expressions'
import { want_to } from './content/want_to'
import { structures_6 } from './content/structures_6'
import { want_someone_to } from './content/want_someone_to'
import { structures_7 } from './content/structures_7'
import { have_to } from './content/have_to'
import { structures_8 } from './content/structures_8'
import { to_vocab } from './content/to_vocab'
import { going_to } from './content/going_to'
import { structures_9 } from './content/structures_9'
import { will } from './content/will'
import { structures_10 } from './content/structures_10'
import { compound } from './content/compound'
import { modal } from './content/modal'
import { structures_11 } from './content/structures_11'
import { must } from './content/must'
import { can_future } from './content/can_future'
import { structures_12 } from './content/structures_12'
import { imperative } from './content/imperative'
import { structures_13 } from './content/structures_13'
import { can_extra } from './content/can_extra'
import { to_be_present_affirmative } from './content/to_be_present_affirmative'
import { to_be_present_negative } from './content/to_be_present_negative'
import { to_be_present_interrogative } from './content/to_be_present_interrogative'
import { to_be_present_interrogative_negative } from './content/to_be_present_interrogative_negative'
import { to_be_vocab_1 } from './content/to_be_vocab_1'
import { present_participle } from './content/present_participle'
import { structures_14 } from './content/structures_14'
import { to_be_extra_1 } from './content/to_be_extra_1'
import { to_be_vocab_2 } from './content/to_be_vocab_2'
import { structures_15 } from './content/structures_15'
import { structures_16 } from './content/structures_16'
import { to_be_grammar } from './content/to_be_grammar'
import { to_be_past_affirmative } from './content/to_be_past_affirmative'
import { to_be_past_negative } from './content/to_be_past_negative'
import { to_be_past_interrogative } from './content/to_be_past_interrogative'
import { to_be_past_interrogative_negative } from './content/to_be_past_interrogative_negative'
import { structures_17 } from './content/structures_17'
import { there_is_are } from './content/there_is_are'
import { to_be_extra_2 } from './content/to_be_extra_2'
import { past_negative_interrogative } from './content/past_negative_interrogative'
import { past_regular } from './content/past_regular'
import { past_vocab_1 } from './content/past_vocab_1'
import { structures_19 } from './content/structures_19'
import { past_irregular } from './content/past_irregular'
import { past_vocab_2 } from './content/past_vocab_2'
import { past_examples } from './content/past_examples'
import { past_ing } from './content/past_ing'
import { past_compound } from './content/past_compound'
import { wanted_to } from './content/wanted_to'
import { structures_20 } from './content/structures_20'
import { wanted_someone_to } from './content/wanted_someone_to'
import { structures_21 } from './content/structures_21'
import { had_to } from './content/had_to'
import { was_were_supposed_to } from './content/was_were_supposed_to'
import { structures_22 } from './content/structures_22'
import { past_going_to } from './content/past_going_to'
import { structures_23 } from './content/structures_23'
import { past_can } from './content/past_can'
import { structures_24 } from './content/structures_24'
import { past_extra_1 } from './content/past_extra_1'
import { past_extra_2 } from './content/past_extra_2'
import { past_participle } from './content/past_participle'
import { present_perfect } from './content/present_perfect'
import { pp_extra_1 } from './content/pp_extra_1'
import { pp_continuous } from './content/pp_continuous'
import { past_perfect } from './content/past_perfect'
import { pp_extra_2 } from './content/pp_extra_2'
import { conditional } from './content/conditional'
import { conditional_extra_1 } from './content/conditional_extra_1'
import { conditional_perfect } from './content/conditional_perfect'

@Injectable({
  providedIn: 'root'
})
export class ContentProviderService {

    contentMap: any = {};
    lessons: any;
    lessons$: BehaviorSubject<any>;
    order:any;

  constructor(
    private router: Router,
    public exerciseProvider: ExerciseProviderService
  ) {
      this.lessons = lessons;
      this.order = [
        'subject_pronouns',
        'affirmative',
        'negative',
        'interrogative',
        'interrogative_negative',
        'structures_1',
        'cardinal',
        'affirmative_3',
        'structures_2',
        'negative_3',
        'interrogative_3',
        'interrogative_negative_3',
        'structures_3',
        'questions',
        'structures_4',
        'expressions',
        'complements_vocab_1',
        'object_pronouns',
        'complements_vocab_2',
        'fluency_1',
        'adverbs',
        'structures_5',
        'nouns',
        'determiners',
        'possessives',
        'conjunctions',
        'fluency_2',
        'introductory_phrases',
        'fluency_3',
        'extra1',
        'want_to',
        'contrast_1',
        'structures_6',
        'want_someone_to',
        'contrast_2',
        'structures_7',
        'have_to',
        'structures_8',
        'to_vocab',
        'going_to',
        'structures_9',
        'will',
        'structures_10',
        'contrast_3',
        'compound',
        'fluency_4',
        'modal',
        'can_future',
        'structures_11',
        'must',
        'structures_12',
        'contrast_4',
        'contrast_5',
        'imperative',
        'contrast_6',
        'structures_13',
        'can_extra',
        'fluency_5',
        'extra2',
        'to_be_present_affirmative',
        'to_be_present_negative',
        'to_be_present_interrogative',
        'to_be_present_interrogative_negative',
        'to_be_vocab_1',
        'present_participle',
        'structures_14',
        'to_be_extra_1',
        'structures_15',
        'to_be_vocab_2',
        'structures_16',
        'to_be_grammar',
        'to_be_past_affirmative',
        'to_be_past_negative',
        'to_be_past_interrogative',
        'to_be_past_interrogative_negative',
        'structures_17',
        'there_is_are',
        'to_be_extra_2',
        'fluency_6',
        'there_is_are',
        'to_be_extra_2',
        'fluency_7',
        'extra3',
        'past_negative_interrogative',
        'past_regular',
        'past_vocab_1',
        'structures_19',
        'past_irregular',
        'past_vocab_2',
        'past_examples',
        'past_ing',
        'past_compound',
        'fluency_8',
        'wanted_to',
        'structures_20',
        'wanted_someone_to',
        'structures_21',
        'contrast_7',
        'had_to',
        'was_were_supposed_to',
        'structures_22',
        'contrast_8',
        'past_going_to',
        'structures_23',
        'past_can',
        'structures_24',
        'past_extra_1',
        'past_extra_2',
        'fluency_9',
        'past_participle',
        'present_perfect',
        'pp_extra_1',
        'pp_continuous',
        'past_perfect',
        'pp_extra_2',
        'conditional',
        'conditional_extra_1',
        'conditional_perfect'
      ]

      this.contentMap['subject_pronouns'] = subject_pronouns;
      this.contentMap['affirmative'] = affirmative;
      this.contentMap['negative'] = negative;
      this.contentMap['interrogative'] = interrogative;
      this.contentMap['interrogative_negative'] = interrogative_negative;
      this.contentMap['structures_1'] = structures_1;
      this.contentMap['affirmative_3'] = affirmative_3;
      this.contentMap['structures_2'] = structures_2;
      this.contentMap['negative_3'] = negative_3;
      this.contentMap['interrogative_3'] = interrogative_3;
      this.contentMap['interrogative_negative_3'] = interrogative_negative_3;
      this.contentMap['structures_3'] = structures_3;
      this.contentMap['questions'] = questions;
      this.contentMap['structures_4'] = structures_4;
      this.contentMap['complements_vocab_1'] = complements_vocab_1;
      this.contentMap['object_pronouns'] = object_pronouns;
      this.contentMap['complements_vocab_2'] = complements_vocab_2;
      this.contentMap['adverbs'] = adverbs;
      this.contentMap['structures_5'] = structures_5;
      this.contentMap['nouns'] = nouns;
      this.contentMap['determiners'] = determiners;
      this.contentMap['possessives'] = possessives;
      this.contentMap['conjunctions'] = conjunctions;
      this.contentMap['introductory_phrases'] = introductory_phrases;
      this.contentMap['cardinal'] = cardinal;
      this.contentMap['expressions'] = expressions;
      this.contentMap['want_to'] = want_to;
      this.contentMap['structures_6'] = structures_6;
      this.contentMap['want_someone_to'] = want_someone_to;
      this.contentMap['structures_7'] = structures_7;
      this.contentMap['have_to'] = have_to;
      this.contentMap['structures_8'] = structures_8;
      this.contentMap['to_vocab'] = to_vocab;
      this.contentMap['going_to'] = going_to;
      this.contentMap['structures_9'] = structures_9;
      this.contentMap['will'] = will;
      this.contentMap['structures_10'] = structures_10;
      this.contentMap['compound'] = compound;
      this.contentMap['modal'] = modal;
      this.contentMap['structures_11'] = structures_11;
      this.contentMap['can_future'] = can_future;
      this.contentMap['must'] = must;
      this.contentMap['structures_12'] = structures_12;
      this.contentMap['imperative'] = imperative;
      this.contentMap['structures_13'] = structures_13;
      this.contentMap['can_extra'] = can_extra;
      this.contentMap['to_be_present_affirmative'] = to_be_present_affirmative;
      this.contentMap['to_be_present_negative'] = to_be_present_negative;
      this.contentMap['to_be_present_interrogative'] = to_be_present_interrogative;
      this.contentMap['to_be_present_interrogative_negative'] = to_be_present_interrogative_negative;
      this.contentMap['to_be_vocab_1'] = to_be_vocab_1;
      this.contentMap['present_participle'] = present_participle;
      this.contentMap['structures_14'] = structures_14;
      this.contentMap['to_be_extra_1'] = to_be_extra_1;
      this.contentMap['to_be_vocab_2'] = to_be_vocab_2;
      this.contentMap['past_examples'] = past_examples;
      this.contentMap['structures_15'] = structures_15;
      this.contentMap['structures_16'] = structures_16;
      this.contentMap['to_be_grammar'] = to_be_grammar;
      this.contentMap['to_be_past_affirmative'] = to_be_past_affirmative;
      this.contentMap['to_be_past_negative'] = to_be_past_negative;
      this.contentMap['to_be_past_interrogative'] = to_be_past_interrogative;
      this.contentMap['to_be_past_interrogative_negative'] = to_be_past_interrogative_negative;
      this.contentMap['structures_17'] = structures_17;
      this.contentMap['there_is_are'] = there_is_are;
      this.contentMap['to_be_extra_2'] = to_be_extra_2;
      this.contentMap['past_negative_interrogative'] = past_negative_interrogative;
      this.contentMap['past_regular'] = past_regular;
      this.contentMap['past_vocab_1'] = past_vocab_1;
      this.contentMap['structures_19'] = structures_19;
      this.contentMap['past_irregular'] = past_irregular;
      this.contentMap['past_vocab_2'] = past_vocab_2;
      this.contentMap['past_ing'] = past_ing;
      this.contentMap['past_compound'] = past_compound;
      this.contentMap['wanted_to'] = wanted_to;
      this.contentMap['structures_20'] = structures_20;
      this.contentMap['wanted_someone_to'] = wanted_someone_to;
      this.contentMap['structures_21'] = structures_21;
      this.contentMap['had_to'] = had_to;
      this.contentMap['was_were_supposed_to'] = was_were_supposed_to;
      this.contentMap['structures_22'] = structures_22;
      this.contentMap['past_going_to'] = past_going_to;
      this.contentMap['structures_23'] = structures_23;
      this.contentMap['past_can'] = past_can;
      this.contentMap['structures_24'] = structures_24;
      this.contentMap['past_extra_1'] = past_extra_1;
      this.contentMap['past_extra_2'] = past_extra_2;
      this.contentMap['past_participle'] = past_participle;
      this.contentMap['present_perfect'] = present_perfect;
      this.contentMap['pp_extra_1'] = pp_extra_1;
      this.contentMap['pp_continuous'] = pp_continuous;
      this.contentMap['past_perfect'] = past_perfect;
      this.contentMap['pp_extra_2'] = pp_extra_2;
      this.contentMap['conditional'] = conditional;
      this.contentMap['conditional_extra_1'] = conditional_extra_1;
      this.contentMap['conditional_perfect'] = conditional_perfect;

      this.lessons$ = new BehaviorSubject<any>(this.lessons);

      this.exerciseProvider.getCompletion().subscribe(data => {
        for (let j in this.lessons){
          let lesson = this.lessons[j]
          lesson['status'] = 'UNKNOWN'
          if (lesson['completion']=='ignore'){
            lesson['status'] = 'COMPLETED'
          }
          for (let k in lesson['lections']){
            let lection = lesson['lections'][k];
            lection['status'] = 'UNKNOWN'
          }
        }
        for (let i of data) {
          for (let j in this.lessons){
            let lesson = this.lessons[j]
            let completed = true;
            for (let k in lesson['lections']){
              let lection = lesson['lections'][k];
              if (lection['id'] == i){
                lection['status'] = 'COMPLETED'
              } else {
                if (i.indexOf("extra")<0){
                  completed = false
                }
              }
            }
            if (completed){
              lesson['status'] = 'COMPLETED'
            }
          }
        }

        this.lessons$.next(this.lessons);
      })
   }

  getTOC() {
    return this.lessons$;
  }

  getContent(id){
    return Promise.resolve(this.contentMap[id]);
  }

  getParams(lessonIdx, lectionIdx){
    let title = this.lessons[lessonIdx].lections[lectionIdx].title;
    let lection = this.lessons[lessonIdx].lections[lectionIdx].name;
    if (title == undefined){
      title = lection;
    }
    const id = this.lessons[lessonIdx].lections[lectionIdx].id;
    const exercises = this.lessons[lessonIdx].lections[lectionIdx].exercises;

    return {
      title: title,
      lection: lection,
      id: id,
      exercises: exercises
    }
  }

  navigateIdx(lessonIdx, lectionIdx){

    const params = this.getParams(lessonIdx, lectionIdx)

    if (params['exercises'] == 'only'){

      this.router.navigate(['/exercises', params]);

    } else {

      this.router.navigate(['/book', params]);

    }
  }

  navigateContent(lessonIdx, lectionIdx){

    const params = this.getParams(lessonIdx, lectionIdx)
    this.router.navigate(['/book', params]);

  }

  navigateExercises(lessonIdx, lectionIdx){

    const params = this.getParams(lessonIdx, lectionIdx)
    this.router.navigate(['/exercises', params]);

  }

  navigateReview(lessonIdx, lectionIdx){

    const params = this.getParams(lessonIdx, lectionIdx)
    this.router.navigate(['/review', params]);

  }

  navigate(id){
    this.getPage(id).then(params => {
      if (params['exercises'] == 'only'){
        this.router.navigate(['/exercises', params]);
      } else {
        this.router.navigate(['/book', params]);
      }
    })
  }

  getPage(id){
    const content = this.contentMap[id];
    let title = "";
    let exercises = "hide";
    let lection = "";
    for (let l of this.lessons){
      for (let i of l['lections']){
        if (i['id']==id){
          title = i['title'];
          if (title == undefined){
            title = i['name'];
          }
          exercises = i['exercises'];
          lection = i['name'];
        }
      }
    }
    return Promise.resolve({
      'id': id,
      'title' : title,
      'lection' : lection,
      'exercises': exercises,
      'content': content
    })
  }

  goToExercise(id){
    this.getPage(id).then(params => {
      this.router.navigate(['/exercises', params]);
    })
  }

  navigateNext(id){
    const index = this.order.indexOf(id)
    if (index<this.order.length){
      let nextId = this.order[index + 1]
      if (nextId.startsWith("extra")){
        if (index<this.order.length-1 ){
          nextId = this.order[index + 2]
          this.navigate(nextId);
        }
      } else {
        this.navigate(nextId);
      }
    }
  }

  navigatePrevious(id){
    const index = this.order.indexOf(id)
    if (index!=0){
      let previousId = this.order[index - 1]
      if (previousId.startsWith("extra")){
        if (index>1){
          previousId = this.order[index - 2]
          this.navigate(previousId);
        }
      } else {
        this.navigate(previousId);
      }
    }
  }

  next(id){
    const index = this.order.indexOf(id)
    if (index>=this.order.length){
      return this.getPage(id);
    }
    let nextId = this.order[index + 1]
    if (nextId.startsWith("extra")){
      if (index<this.order.length-1 ){
        nextId = this.order[index + 2]
        console.log('1', nextId)
        return this.getPage(nextId);
      }
    } else {
      console.log('2', nextId)
      return this.getPage(nextId);
    }
    return this.getPage(nextId);
  }

  previous(id){
    const index = this.order.indexOf(id)
    if (index==0){
      return this.getPage(id);
    }
    let previousId = this.order[index - 1]
    if (previousId.startsWith("extra")){
      if (index>1){
        previousId = this.order[index - 2]
        return this.getPage(previousId);
      }
    } else {
      return this.getPage(previousId);
    }
    return this.getPage(previousId);
  }

  playAudio(audioId){
    if(audioId){
      let audio = new Audio();
      audio.src = '../assets/audio/' + audioId;
      audio.load();
      audio.play();
    }
  }
}
