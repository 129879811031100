export const structures_24: any = [
  {
    type: 'structures',
    id: '24',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>I couldn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "No pude / No podía &#8230;ar/er/ir",
          },
          {
            text:"<b>Were(n't) you able to &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿(No) pudiste &#8230;ar/er/ir?",
          },
          {
            text:"<b>I thought I could &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Yo pensé que yo podía &#8230;ar/er/ir",
          },
          {
            text:"<b>I was able to &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Pude &#8230;ar/er/ir",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '41',
    items: [
      {
        rows: [
          {text:'No pude jugar.'},
          {text:'¿Pudiste jugar?'},
          {text:'Pensé que (yo) podía jugar.'},
          {text:'Pude jugar.'},
        ]
      },
      {
        rows: [
          {text:'Ellos no pudieron presentar el examen.'},
          {text:'¿Ellos pudieron presentar el examen?'},
          {text:'Ellos pensaron que podían presentar el examen.'},
          {text:'Ellos pudieron presentar el examen.'},
        ]
      },
      {
        rows: [
          {text:'Ella no pudo estudiar.'},
          {text:'¿Pudo estudiar (ella)?'},
          {text:'Ella pensó que podía estudiar en la tarde.'},
          {text:'Ella pudo estudiar en la tarde.'},
        ]
      }
    ]
  }
]
