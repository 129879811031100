import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

function passwordValidator(validatePassword: boolean, minLength: number) {
  function passwordValidatorHelper(_: FormControl) {
    if (validatePassword) {
      return Validators.compose([
        Validators.required,
        Validators.minLength(minLength)
      ]);
    } else {
      return Validators.required;
    }
  }
  return passwordValidatorHelper;
}


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() controlName = 'password';
  @Input() placeHolder = 'Password';
  @Input() validatePassword = true;
  @Input() pattern = '^.*$';

  readonly minLength = 6;
  passwordHidden = true;

  constructor() {}

  ngOnInit() {
    this.formGroup.addControl(
      this.controlName,
      new FormControl(
        '',
        passwordValidator(this.validatePassword, this.minLength)));
  }

  showPassword() {
    this.passwordHidden = false;
  }

  hidePassword() {
    this.passwordHidden = true;
  }

  togglePassword() {
    this.passwordHidden = !this.passwordHidden;
  }

  passwordControlType() {
    return(this.passwordHidden ? 'password' : 'text');
  }

}
