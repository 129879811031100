// Model
export enum UserPlan {

  free = 'free',
  bronze = 'bronze',
  silver = 'silver',
  gold = 'gold'

}

export interface User {

    uid: string,
    email: string,
    displayName: string,
    photoURL: string,
    plan: UserPlan

}

export const nullUser = null

export type NullUser = typeof nullUser
