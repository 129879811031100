export const structures_3: any = [
  {
    type: 'structures',
    id: '3',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>He &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Él &#8230;",
          },
          {
            text: "<b>He doesn't  &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "Él no &#8230;",
          },
          {
            text: "<b>Does he &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Él &#8230;?",
          },
          {
            text: "<b>Doesn't he &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Él no &#8230;?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>My friend &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Mi amigo &#8230;",
          },
          {
            text: "<b>My friend doesn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Mi amigo no &#8230;",
          },
          {
            text: "<b>Does your friend &#8230;?	</b>",
          },
          {
            level:'secondary',
            text: "¿Tu amigo &#8230;?",
          },
          {
            text: "<b>Doesn't your friend &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Tu amigo no &#8230;?",
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Her husband &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "El esposo &#8230;",
          },
          {
            text: "<b>Her husband doesn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "El esposo no &#8230;",
          },
          {
            text: "<b>Does her husband &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿El esposo &#8230;?",
          },
          {
            text: "<b>Doesn't her husband &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿El esposo no &#8230;?",
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "<b>Ana &#8230;s.</b>",
          },
          {
            level:'secondary',
            text: "Ana &#8230;",
          },
          {
            text: "<b>Ana  doesn't &#8230;</b>",
          },
          {
            level:'secondary',
            text: "Ana no &#8230;",
          },
          {
            text: "<b>Does  Ana &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Ana &#8230;?",
          },
          {
            text: "<b>Doesn't  Ana &#8230;?</b>",
          },
          {
            level:'secondary',
            text: "¿Ana no &#8230;?",
          }
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '12',
    items: [
      {
        rows: [
          {text: "Él se acuerda."},
          {text: "Él no se acuerda."},
          {text: "¿Él se acuerda?"},
          {text: "¿Él no se acuerda?"}
        ]
      },
      {
        rows: [
          {text: "Mi amiga habla inglés."},
          {text: "Mi amiga no habla inglés."},
          {text: "¿Tu amiga habla inglés?"},
          {text: "¿Tu amiga no habla inglés?"}
        ]
      },
      {
        rows: [
          {text: "El esposo juega golf."},
          {text: "El esposo no juega golf."},
          {text: "¿El esposo juega golf?"},
          {text: "¿El esposo no juega golf?"}
        ]
      },
      {
        rows: [
          {text: "Ana sabe."},
          {text: "Ana no sabe."},
          {text: "¿Ana sabe?"},
          {text: "¿Ana no sabe?"}
        ]
      },
    ]
  }
]
