import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContentProviderService } from 'src/app/services/content-provider.service';
import { AppState } from 'src/app/store/state';
import { User } from 'src/app/store/user';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.page.html',
  styleUrls: ['./learn.page.scss'],
})
export class LearnPage implements OnInit {

  user$: Observable<User>;

  content: any;

  collapse = false;

  expansion: any;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public toastController: ToastController,
    public contentProvider: ContentProviderService
  ) {
    this.content = this.contentProvider.getTOC();
    this.expansion = [];
    this.content.forEach(lesson => {
      let expansion = {}
      lesson.forEach(ll => {
        ll['lections'].forEach(l => {
          expansion[l['id']] = false;
        });
        this.expansion.push(expansion);
      });
    });
    this.user$ = this.store.select('user');
  }

  expandItem(idx, id): void {
    if (this.expansion[idx][id]) {
      this.expansion[idx][id] = false;
    } else {
      for (let lid in this.expansion[idx]) {
        if (id == lid) {
          this.expansion[idx][lid] = !this.expansion[idx][lid];
        } else {
          this.expansion[idx][lid] = false;
        }
      }
    }
  }

  ngOnInit() {
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      duration: 2000,
      message
    });
    toast.present();
  }
}
