import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/store/state';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit, OnDestroy {

  ngDestroyed$ = new Subject();
  loggedIn = false;
  loading = true;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private alertController: AlertController
  ) {
    this.setLoad();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
  }

  ngOnInit() {}

  async signupAlert(message ='Usuario o contrasena invalidos') {
    const alert = await this.alertController.create({
      header: 'Error de creacion de cuenta',
      buttons: ['OK'],
      message
    });
    await alert.present();
  }

  setLoad() {
    this.loading = true;
  }

  clearLoad() {
    this.loading = false;
  }


}
