export const to_be_vocab_2: any = [
  {
    type: 'card',
    items: [
      {
        type: 'vocab',
        rows: [
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>easy</b><br/>",
                  audio: "unit3-496.mp3"
                },
                {
                  type: "link",
                  text: "<b>possible</b>",
                  audio: "unit3-497.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "fácil<br/>posible"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>difficult</b>/",
                  audio: "unit3-498.mp3"
                },
                {
                  type: "link",
                  text: "<b>hard</b>",
                  audio: "unit3-499.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "dificil"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>impossible</b>",
                  audio: "unit3-500.mp3"
                },
              ]
            },
            right: {
              tokens:[
                {
                  text: "imposible"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>expensive</b><br/>",
                  audio: "unit3-501.mp3"
                },
                {
                  type: "link",
                  text: "<b>necessary</b>",
                  audio: "unit3-502.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "caro<br/>necesario"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>true</b><br/>",
                  audio: "unit3-503.mp3"
                },
                {
                  type: "link",
                  text: "<b>worse</b>",
                  audio: "unit3-504.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "verdad<br/>peor"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>interesting</b><br/>",
                  audio: "unit3-505.mp3"
                },
                {
                  type: "link",
                  text: "<b>important</b>",
                  audio: "unit3-506.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "interesante<br/>important"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  text: "(",
                  audio: "none"
                },
                {
                  type: "link",
                  text: "<b>on</b>",
                  audio: "unit3-562.mp3"
                },
                {
                  text: " - ",
                  audio: "none"
                },
                {
                  type: "link",
                  text: "<b>under</b>",
                  audio: "unit3-563.mp3"
                },
                {
                  text: ")<br/>",
                  audio: "none"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "(sobre - debajo de)<br/>"
                }
              ]
            },
          },
          {
            type:'secondary',
            left: {
              tokens:[
                {
                  level:'secondary',
                  type: "link",
                  text: "the table",
                  audio: "unit3-508.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "la mesa"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>the same thing</b><br/>",
                  audio: "unit3-509.mp3"
                },
                {
                  type: "link",
                  text: "<b>like that</b><br/>",
                  audio: "unit3-510.mp3"
                },
              ]
            },
            right: {
              tokens:[
                {
                  text: "lo mismo<br/>así"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>open</b><br/>",
                  audio: "unit3-511.mp3"
                },
                {
                  type: "link",
                  text: "<b>closed</b>",
                  audio: "unit3-512.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "abierto<br/>cerrado"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>raining</b><br/>",
                  audio: "unit3-513.mp3"
                },
                {
                  type: "link",
                  text: "<b>working</b>",
                  audio: "unit3-514.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "lloviendo<br/>funcionando"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>long</b><br/>",
                  audio: "unit3-515.mp3"
                },
                {
                  type: "link",
                  text: "<b>short</b>",
                  audio: "unit3-516.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "largo<br/>corto"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>good</b><br/>",
                  audio: "unit3-517.mp3"
                },
                {
                  type: "link",
                  text: "<b>bad</b>",
                  audio: "unit3-518.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "bueno<br/>malo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>mine</b><br/>",
                  audio: "unit3-519.mp3"
                },
                {
                  type: "link",
                  text: "<b>yours</b>",
                  audio: "unit3-520.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "mio<br/>tuyo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>Ana's</b>",
                  audio: "unit3-521.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "de Ana"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>What color</br> is it?</b>",
                  audio: "unit3-615.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "¿De qué</br> color es?"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>white</b>",
                  audio: "unit3-616.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "blanco"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>yellow</b>",
                  audio: "unit3-617.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "amarillo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>blue</b>",
                  audio: "unit3-618.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "azul"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>red</b>",
                  audio: "unit3-619.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "rojo"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>green</b>",
                  audio: "unit3-620.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "verde"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>purple</b>",
                  audio: "unit3-621.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "morado"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>brown</b>",
                  audio: "unit3-622.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "marrón / café"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>grey</b>",
                  audio: "unit3-623.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "gris"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>pink</b>",
                  audio: "unit3-624.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "rosado"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>orange</b>",
                  audio: "unit3-625.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "anaranjado"
                }
              ]
            },
          },
          {
            type:'primary',
            left: {
              tokens:[
                {
                  type: "link",
                  text: "<b>black</b>",
                  audio: "unit3-626.mp3"
                }
              ]
            },
            right: {
              tokens:[
                {
                  text: "negro"
                }
              ]
            },
          },
        ]
      },
    ]
  },
]
