export const interrogative_negative_3: any = [
  {
    type: 'card',
    title: 'Interrogativo negativo',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "Se usa "
          },
          {
            type: "link",
            text: "<b>doesn't</b>",
            audio: "unit1-471.mp3"
          },
          {
            text: ", y el verbo no lleva  <b>'s'</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Él no maneja?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Doesn't</b> he drive?",
            audio: "unit1-472.mp3",
            type: 'link'
          },
          {
            text: "¿Ella no trabaja?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Doesn't</b> she work?",
            audio: "unit1-473.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '10a',
    items: [
      {
        rows: [
          {
            text: "Doesn't he feel well?",
            audio: "unit1-501.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Doesn't she drive?",
            audio: "unit1-502.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doesn't her husband speak English?",
            audio: "unit1-503.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doesn't his wife work?",
            audio: "unit1-504.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "Doesn't your friend study?",
            audio: "unit1-505.mp3"
          },
        ]
      },
      {
        rows: [
        {
            text: "Doesn't my mom need one?",
            audio: "unit1-506.mp3"
          },
        ]
      },
      {
        rows: [
        {
            text: "Doesn't my dad use it?",
            audio: "unit1-507.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doesn't Ana know?",
            audio: "unit1-508.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doesn't the teacher have class today?",
            audio: "unit1-509.mp3"
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '10b',
    items: [
      {rows: [{text:'¿Él no se siente bien?'}]},
      {rows: [{text: "¿Ella no maneja?"}]},
      {rows: [{text: "¿El esposo no habla inglés?"}]},
      {rows: [{text: "¿La esposa no trabaja?"}]},
      {rows: [{text: "¿Tu amigo no estudia?"}]},
      {rows: [{text: "¿Mi mamá no necesita uno?"}]},
      {rows: [{text: "¿Mi papá no lo usa?"}]},
      {rows: [{text: "¿Ana no sabe?"}]},
      {rows: [{text: "¿El profesor no tiene clase hoy?"}]}
    ]
  },
  {
    type: 'card',
    title: 'Precisiones finales',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "En resumen, el verbo lleva <b>'s'</b> sólo en la <b>3ª persona afirmativa</b>."
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Cómo ya se indicó, el uso de otros sujetos no afecta las estructuras de la 3ª persona singular."
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "La esposa no trabaja.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "His wife doesn't work",
            audio: "unit1-486.mp3",
            type: 'link'
          },
          {
            text: "Alan no se levanta temprano.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Alan doesn't get up early",
            audio: "unit1-475.mp3",
            type: 'link'
          },
          {
            text: "Tu amigo no viene a clase.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Your friend doesn't come to class",
            audio: "unit1-476.mp3",
            type: 'link'
          },
          {
            text: "¿Tú mamá maneja?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Does your mom drive?",
            audio: "unit1-477.mp3",
            type: 'link'
          },
          {
            text: "¿El esposo habla inglés?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Does her husband speak English?",
            audio: "unit1-478.mp3",
            type: 'link'
          },
          {
            text: "¿Mi amiga tiene clase?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Does my friend have class?",
            audio: "unit1-479.mp3",
            type: 'link'
          },
          {
            text: "¿Tú papá no dice nada?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Doesn't your dad say anything?",
            audio: "unit1-480.mp3",
            type: 'link'
          },
          {
            text: "¿Ana no quiere el libro?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Doesn't Ana want the book?",
            audio: "unit1-481.mp3",
            type: 'link'
          },
          {
            text: "¿El esposo no vive allá?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Doesn't her husband live there?",
            audio: "unit1-482.mp3",
            type: 'link'
          }
        ]
      }
    ]
  },
  {
    type:'translate',
    id: '11',
    items: [
      {
        rows: [
          {text:'No entiendo.'},
          {text:'Él no entiende.'},
          {text:'¿Entiendes?'},
          {text:'¿Él entiende?'},
          {text:'¿No entiendes?'},
          {text:'¿Él no entiende?'},
        ]
      },
      {
        rows: [
          {text:'No vivo aquí.'},
          {text:'Ella no vive aquí.'},
          {text:'¿Vives aquí?'},
          {text:'¿Ella vive aquí?'},
          {text:'¿No vives aquí?'},
          {text:'¿Ella no vive aquí?'},
        ]
      },
      {
        rows: [
          {text:'No tengo plata.'},
          {text:'Él no tiene plata.'},
          {text:'¿Tienes plata?'},
          {text:'¿Él tiene plata?'},
          {text:'¿No tienes plata?'},
          {text:'¿Él no tiene plata?'},
        ]
      },
      {
        rows: [
          {text:'No trabajo.'},
          {text:'Ella no trabaja.'},
          {text:'¿Tú trabajas?'},
          {text:'¿Ella trabaja?'},
          {text:'¿No trabajas?'},
          {text:'¿Ella no trabaja?'},
        ]
      },
      {
        rows: [
          {text:'No manejo.'},
          {text:'Alan no maneja.'},
          {text:'¿Tú manejas?'},
          {text:'¿Alan maneja?'},
          {text:'¿No manejas?'},
          {text:'¿Alan no maneja?'},
        ]
      },
      {
        rows: [
          {text:'No vengo todos los días.'},
          {text:'Ella no viene todos los días.'},
          {text:'¿Vienes todos los días?'},
          {text:'¿Ella viene todos los días?'},
          {text:'¿No vienes todos los días?'},
          {text:'¿Ella no viene todos los días?'},
        ]
      }
    ]
  },
]
