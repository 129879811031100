export const past_extra_1: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>We decided to buy the car</b>",
            audio:"unit4-282.mp3"
          },
          {
            level:'secondary',
            text: "Decidimos comprar el carro.",
            audio:"none"
          },
          {
            text: "<b>I decided not to continue</b>",
            audio:"unit4-283.mp3"
          },
          {
            level:'secondary',
            text: "Decidí no seguir.",
            audio:"none"
          }

        ]
      },
    ]
  },
  {
    type:'translate',
    id: '42',
    items: [
      {
        rows: [
          {text:'Decidí esperar.'},
          {text:'Decidí no esperar.'},
        ]
      },
      {
        rows: [
          {text:'Él decidió regresar aquí.'},
          {text:'Él decidió no regresar aquí.'},
        ]
      },
      {
        rows: [
          {text:'Decidimos ir al banco.'},
          {text:'Decidimos no ir al banco.'},
        ]
      },
      {
        rows: [
          {text:'Ella decidió decírselo a él.'},
          {text:'Ella decidió no decírselo a él.'},
        ]
      },
      {
        rows: [
          {text:'Ellos decidieron empezar'},
          {text:'Ellos decidieron no empezar.'},
        ]
      },
      {
        rows: [
          {text:'Tú decidiste estudiar allá.'},
          {text:'Tú decidiste no estudiar allá.'},
        ]
      },
    ]
  },
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>You told me to wait</b>",
            audio:"unit4-284.mp3"
          },
          {
            level:'secondary',
            text: "Me dijiste que esperara.",
            audio:"none"
          },
          {
            text: "<b>You told us not to do it</b>",
            audio:"unit4-285.mp3"
          },
          {
            level:'secondary',
            text: "Nos dijiste que no lo hiciéramos.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'Estas estructuras se usan únicamente si la terminación del segundo verbo en español es <b><i>-ara</b> / <b>-iera</i></b>.'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: 'De no ser así, se usa el pasado en el segundo verbo.'
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Él me dijo que estudiara.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He told me to study</b>",
            audio:"unit4-286.mp3"
          },
          {
            text: "Me dijo que estaba estudiando.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He told me he was studying</b>",
            audio:"unit4-287.mp3"
          },
          {
            text: "Él me dijo que no hiciera eso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He told me not to do that</b>",
            audio:"unit4-288.mp3"
          },
          {
            text: "Él me dijo que él no hacía eso.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>He told me he didn't do that</b>",
            audio:"unit4-289.mp3"
          },
          {
            text: "Le dije (a él) que se fuera.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I told him to go</b>",
            audio:"unit4-290.mp3"
          },
          {
            text: "Le dije (a él) que podía irse.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>I told him he could go</b>",
            audio:"unit4-291.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '43',
    items: [
      {
        rows: [
          {text:'Te dije que me esperaras.'},
          {text:'Te dije que no me esperaras.'},
        ]
      },
      {
        rows: [
          {text:'Él me dijo que llamara.'},
          {text:'Él me dijo que no llamara.'},
        ]
      },
      {
        rows: [
          {text:'Me dijiste que lo usara.'},
          {text:'Me dijiste que no lo usara.'},
        ]
      },
      {
        rows: [
          {text:'Le dije a ella que fuera hoy.'},
          {text:'Le dije a ella que no fuera hoy.'},
        ]
      },
      {
        rows: [
          {text:'Uds. me dijeron que viniera.'},
          {text:'Uds. me dijeron que no viniera.'},
        ]
      },
      {
        rows: [
          {text:'¿Quién te dijo que lo hicieras?'},
          {text:'¿Quién te dijo que no lo hicieras?'},
        ]
      },
      {
        rows: [
          {text:'Le dije a él que lo comprara.'},
          {text:'Le dije a él que no lo comprara.'},
        ]
      },
      {
        rows: [
          {text:'Tú nos dijiste que siguiéramos.'},
          {text:'Tú nos dijiste que no siguiéramos.'},
        ]
      },
    ]
  },
]
