import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppState } from '../store/state';
import { Store } from '@ngrx/store';
import { actions } from '../store/user';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class InitModule {

  constructor(private store: Store<AppState>,
              @Optional() @SkipSelf() parentModule?: InitModule) {
    if (parentModule) {
      throw new Error(
        'SharedModule instance conflict, import from AppModule only'
      )
    }
    this.store.dispatch(actions.init())
  }

}
