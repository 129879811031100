export const past_extra_2: any = [
  {
    type: 'card',
    items: [
      {
        type: 'example',
        rows: [
          {
            text: "<b>What happened (to you)?</b>",
            audio:"unit4-503.mp3"
          },
          {
            level:'secondary',
            text: "¿Qué (te) pasó?",
            audio:"none"
          },
          {
            text: "<b>Nothing happened</b>",
            audio:"unit4-298.mp3"
          },
          {
            level:'secondary',
            text: "No paso nada.",
            audio:"none"
          },
          {
            text: "<b>How much did you get?</b>",
            audio:"unit4-299.mp3"
          },
          {
            level:'secondary',
            text: "¿Cuánto sacaste?",
            audio:"none"
          },
          {
            text: "<b>I got 18 out of 20</b>",
            audio:"unit4-300.mp3"
          },
          {
            level:'secondary',
            text: "Saqué 18 sobre 20.",
            audio:"none"
          },
          {
            text: "<b>I realized that</b> it was true",
            audio:"unit4-301.mp3"
          },
          {
            level:'secondary',
            text: "Me di cuenta que era verdad.",
            audio:"none"
          },
          {
            text: "<b>I was told</b> to call today <sup>*</sup>",
            audio:"unit4-302.mp3"
          },
          {
            level:'secondary',
            text: "Me dijeron que llamara hoy.",
            audio:"none"
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "<sup>*</sup>Forma alterna de <b>They told me</b>."
          },
        ]
      },
    ]
  },

  {
    type:'translate',
    id: '44',
    items: [
      {
        rows: [
          {text:'¿Qué pasó?'},
          {text:'¿Qué te pasó?'},
        ]
      },
      {
        rows: [
          {text:'¿Sabes qué pasó?'},
          {text:'¿Sabes qué nos pasó?'},
        ]
      },
      {
        rows: [
          {text:'No pasó nada.'},
          {text:'Yo sé que pasó algo.'},
          {text:'A mí me pasó lo mismo.'},
        ]
      },
      {
        rows: [
          {text:'¿Cuánto sacaste?'},
          {text:'Saqué 16 sobre 20.'},
          {text:'Nunca me dijiste cuánto saqué.'},
        ]
      },
      {
        rows: [
          {text:'Me di cuenta que él estaba enamorado de ella.'},
          {text:'Nos dimos cuenta que íbamos a tener un problema.'},
          {text:'Me di cuenta que él no quería que la esposa manejara.'},
        ]
      },
      {
        rows: [
          {text:'Me dijeron que estuviera aquí a las 2.'},
          {text:'Me dijeron que tú podías ayudarme.'},
          {text:'Me dijeron que querías verme.'},
          {text:'Me dijeron que no empezara todavía.'},
        ]
      },
    ]
  }
]
