export const will: any = [
  {
    type: 'card',
    title: 'El futuro simple',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "El futuro simple se usa para indicar una acción que se realizará en un futuro, sin el matiz de determinación implícito en <i>going to</i>."
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "En el futuro <b>afirmativo</b>, se usa <i>will</i>  para todas las personas.<br>Generalmente se contrae este auxiliar: <b>'ll</b> para todas las personas."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Lo intenta<b>ré</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>will</b> try",
            audio: "unit2-296.mp3",
            type: 'link'
          },
          {
            level:'secondary',
            text: "I<b>'ll</b> try",
            audio: "unit2-297.mp3",
            type: 'link'
          },
          {
            text: "Te ayuda<b>remos</b>.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "We<b>'ll</b> help you",
            audio: "unit2-298.mp3",
            type: 'link'
          },
          {
            text: "Ella trae<b>rá</b> a una amiga.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She<b>'ll</b>  bring a friend",
            audio: "unit2-299.mp3",
            type: 'link'
          },
          {
            text: "Alguien ten<b>drá</b> que hacerlo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Somebody <b>will have to</b> do it",
            audio: "unit2-300.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "En el <b>interrogativo</b>, se antepone  <b>will</b>  al pronombre."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "¿Te acordarás?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Will</b> you remember?",
            audio: "unit2-301.mp3",
            type: 'link'
          },
          {
            text: "¿Necesitaré el libro?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Will</b> I need the book?",
            audio: "unit2-302.mp3",
            type: 'link'
          },
          {
            text: "¿Jugará Henry?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Will</b> Henry play?",
            audio: "unit2-303.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "En las formas <b>negativas</b> se usa la contracción de <i>will not</i>: <b>won't</b>."
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "No se me olvidará.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I <b>won't</b> forget",
            audio: "unit2-304.mp3",
            type: 'link'
          },
          {
            text: "Él no vendrá.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He <b>won't</b> come",
            audio: "unit2-305.mp3",
            type: 'link'
          },
          {
            text: "No tendremos tiempo.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "We <b>won't</b> have time",
            audio: "unit2-306.mp3",
            type: 'link'
          },
          {
            text: "Ella no querrá venir.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "She <b>won't</b> want to come",
            audio: "unit2-307.mp3",
            type: 'link'
          },
          {
            text: "¿No dirás nada?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Won't</b>  you say anything?",
            audio: "unit2-308.mp3",
            type: 'link'
          },
          {
            text: "¿No estaremos juntos?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Won't</b> we be together?",
            audio: "unit2-309.mp3",
            type: 'link'
          },
          {
            text: "¿No tendré clase?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<b>Won't</b> I have class?",
            audio: "unit2-310.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '38a',
    items: [
      {
        rows: [
          {
            text: "I'll be there",
            audio: "unit2-311.mp3"
          },
          {
            text: "She'll be there",
            audio: "unit2-312.mp3"
          },
          {
            text: "Ana will be there",
            audio: "unit2-313.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I won't go",
            audio: "unit2-314.mp3"
          },
          {
            text: "They won't go",
            audio: "unit2-315.mp3"
          },
          {
            text: "We won't go",
            audio: "unit2-316.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Will you play?",
            audio: "unit2-317.mp3"
          },
          {
            text: "Will I play?",
            audio: "unit2-318.mp3"
          },
          {
            text: "Will Alan play?",
            audio: "unit2-319.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Won't you come?",
            audio: "unit2-320.mp3"
          },
          {
            text: "Won't she come?",
            audio: "unit2-321.mp3"
          },
          {
            text: "Won't anybody come?",
            audio: "unit2-322.mp3"
          },
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '38b',
    items: [
      {
        rows: [
          {text: "Estaré allá."},
          {text: "Ella estará allá."},
          {text: "Ana estará allá."},
        ]
      },
      {
        rows: [
          {text: "No iré."},
          {text: "Ellos no irán."},
          {text: "No iremos."},
        ]
      },
      {
        rows: [
          {text: "¿Jugarás?"},
          {text: "¿Jugaré yo?"},
          {text: "¿Jugará Alan?"},
        ]
      },
      {
        rows: [
          {text: "¿No vendrás?"},
          {text: "¿Ella no vendrá?"},
          {text: "¿No vendrá nadie?"},
        ]
      },
    ]
  },
]
