export const structures_12: any = [
  {
    type: 'structures',
    id: '12',
    items: [
      {
        type: 'example',
        rows: [
          {
            text:"<b>You  must</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Debes &#8230;ar/er/ir",
          },
          {
            text:"<b>You  mustn't</b> &#8230;",
          },
          {
            level:'secondary',
            text: "No debes &#8230;ar/er/ir",
          },
          {
            text: "<b>Must  I</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Debo &#8230;ar/er/ir?",
          },
          {
            text: "<b>Mustn't I</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No debo &#8230;ar/er/ir",
          },
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text:"<b>You  should</b> &#8230;",
          },
          {
            level:'secondary',
            text: "Deberías &#8230;ar/er/ir",
          },
          {
            text:"<b>You  shouldn't</b> &#8230;",
          },
          {
            level:'secondary',
            text: "No deberías &#8230;ar/er/ir",
          },
          {
            text: "<b>Should  I</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿Debería (yo) &#8230;ar/er/ir?",
          },
          {
            text: "<b>Shouldn't  I</b> &#8230;?",
          },
          {
            level:'secondary',
            text: "¿No debería (yo) &#8230;ar/er/ir",
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '46a',
    items: [
      {
        rows: [
          {
            text: "You must answer",
            audio: "unit2-250.mp3"
          },
          {
            text: "You mustn't answer",
            audio: "unit2-251.mp3"
          },
          {
            text: "Must I answer?",
            audio: "unit2-252.mp3"
          },
          {
            text: "Mustn't I answer?",
            audio: "unit2-253.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "He must repeat the test",
            audio: "unit2-256.mp3"
          },
          {
            text: "He mustn't repeat the test",
            audio: "unit2-257.mp3"
          },
          {
            text: "Must he repeat the test?",
            audio: "unit2-258.mp3"
          },
          {
            text: "Mustn't he repeat the test?",
            audio: "unit2-259.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "You should call her",
            audio: "unit2-262.mp3"
          },
          {
            text: "You shouldn't call her",
            audio: "unit2-263.mp3"
          },
          {
            text: "Should I call her?",
            audio: "unit2-264.mp3"
          },
          {
            text: "Shouldn't I call her?",
            audio: "unit2-265.mp3"
          }
        ]
      },
      {
        rows: [
          {
            text: "She should buy one",
            audio: "unit2-268.mp3"
          },
          {
            text: "She shouldn't buy one",
            audio: "unit2-269.mp3"
          },
          {
            text: "Should she buy one?",
            audio: "unit2-270.mp3"
          },
          {
            text: "Shouldn't she buy one?",
            audio: "unit2-271.mp3"
          }
        ]
      },

    ]
  },
  {
    type:'translate',
    id: '46b',
    items: [
      {
        rows: [
          {text: "Debes contestar."},
          {text: "No debes contestar."},
          {text: "¿Debo contestar?"},
          {text: "¿No debo contestar?"},
        ]
      },
      {
        rows: [
          {text: "Él debe repetir el examen."},
          {text: "Él no debe repetir el examen."},
          {text: "¿Él debe repetir el examen?"},
          {text: "¿Él no debe repetir el examen?"},
        ]
      },
      {
        rows: [
          {text: "Deberías llamarla."},
          {text: "No deberías llamarla."},
          {text: "¿Debería yo llamarla?"},
          {text: "¿No debería llamarla (yo)?"},
        ]
      },
      {
        rows: [
          {text: "Ella debería comprar uno."},
          {text: "Ella no debería comprar uno."},
          {text: "¿Debería ella comprar uno?."},
          {text: "¿Ella no debería comprar uno?"},
        ]
      },
    ]
  }
]
