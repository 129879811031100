export const want_someone_to: any = [
  {
    type: 'card',
    title: 'WANT SOMEONE TO',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "A diferencia del español, el segundo verbo no varía en inglés. Sólo se <b>intercala el pronombre complemento</b> correspondiente entre <i>want</i> y <i>to</i>. Ejemplo:"
          }
        ]
      },
      {
        type: 'example',
        rows: [
          {
            text: "Quiero que te acuerdes.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I want <i>you</i> to remember",
            audio: "unit2-199.mp3",
            type: 'link'
          },
          {
            text: "Él quiere que ellos paguen.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "He wants <i>them</i> to pay",
            audio: "unit2-200.mp3",
            type: 'link'
          },
          {
            text: "No quiero que ella lo olvide.",
            audio:"none"
          },
          {
            level:'secondary',
            text: "I don't want <i>her</i> to forget",
            audio: "unit2-201.mp3",
            type: 'link'
          },
          {
            text: "¿Cómo quieres que yo sepa?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "How do <i>you</i> want me to know?",
            audio: "unit2-202.mp3",
            type: 'link'
          },
          {
            text: "¿No quieres que te ayudemos?",
            audio:"none"
          },
          {
            level:'secondary',
            text: "Don't you want <i>us</i> to help you?",
            audio: "unit2-203.mp3",
            type: 'link'
          }
        ]
      },
    ]
  },
  {
    type:'english',
    id: '28a',
    items: [
      {
        rows: [
          {
            text: "I want you to like it",
            audio: "unit2-204.mp3"
          },
          {
            text: "I want him to like it",
            audio: "unit2-205.mp3"
          },
          {
            text: "I want her to like it",
            audio: "unit2-206.mp3"
          },
          {
            text: "I want them to like it",
            audio: "unit2-207.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I don't want him to come",
            audio: "unit2-208.mp3"
          },
          {
            text: "I don't want her to come",
            audio: "unit2-209.mp3"
          },
          {
            text: "I don't want them to come",
            audio: "unit2-210.mp3"
          },
          {
            text: "I don't want her husband to come",
            audio: "unit2-211.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "What do you want me to bring you?",
            audio: "unit2-212.mp3"
          },
          {
            text: "What do you want Ana to bring you?",
            audio: "unit2-213.mp3"
          },
          {
            text: "What do you want them to bring you?",
            audio: "unit2-214.mp3"
          },
          {
            text: "What do you want us to bring you?",
            audio: "unit2-215.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Why don't you want us to drink coffee?",
            audio: "unit2-216.mp3"
          },
          {
            text: "Why don't you want her to drink coffee?",
            audio: "unit2-217.mp3"
          },
          {
            text: "Why don't you want me to drink coffee?",
            audio: "unit2-218.mp3"
          },
          {
            text: "Why don't you want Alan to drink coffee?",
            audio: "unit2-219.mp3"
          },
        ]
      },
    ]
  },

  {
    type:'translate',
    id: '28b',
    items: [
      {
        rows: [
          {text: "Quiero que te guste."},
          {text: "Quiero que a él le guste."},
          {text: "Quiero que a ella le guste."},
          {text: "Quiero que a ellos les guste."},
        ]
      },
      {
        rows: [
          {text: "No quiero que él venga."},
          {text: "No quiero que ella venga."},
          {text: "No quiero ellos vengan."},
          {text: "No quiero que el esposo venga."},
        ]
      },
      {
        rows: [
          {text: "¿Qué quieres yo te traiga?"},
          {text: "¿Qué quieres que Santa te traiga?"},
          {text: "¿Qué quieres que ellos te traigan?"},
          {text: "¿Qué quieres que te traigamos?"},
        ]
      },
      {
        rows: [
          {text: "¿Por qué no quieres que tomemos café?"},
          {text: "¿Por qué quieres que ella tome café?"},
          {text: "¿Por qué no quieres que yo tome café?"},
          {text: "¿Por qué no quieres que Alan tome café?"},
        ]
      },

    ]
  }
]
