export const present_participle: any = [
  {
    type: 'card',
    title: 'El participio presente',
    items: [
      {
        type: 'p_link',
        tokens: [
          {
            text: "El participio presente, frecuentemente llamado gerundio (cuando el verbo termina en -ando / -iendo), se forma agregando  <i><b>-ing</b></i>  al verbo en inglés. Ejemplo:"
          }
        ]
      },
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "To work",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i>work<b>ing</b></i>",
            audio: "unit3-4.mp3",
            type: 'link',
          },
          {
            text: "To eat",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i>eat<b>ing</b></i>",
            audio: "unit3-15.mp3",
            type: 'link'
          },
          {
            text: "To play",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i>play<b>ing</b></i>",
            audio: "unit3-17.mp3",
            type: 'link'
          },
          {
            text: "To do",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i>do<strong>ing</strong></i>",
            audio: "unit3-6.mp3",
            type: 'link'
          }
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Si el verbo termina en <i>-e</i> muda (live, come, etc), se suprime esta <i>-e</i> antes de agregar <i>-ing</i>."
          }
        ]
      },
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "To live",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>living</b></i>",
            audio: "unit3-595.mp3",
            type: 'link'
          },
          {
            text: "To come",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>coming</b></i>",
            audio: "unit3-596.mp3",
            type: 'link'
          },
          {
            text: "To have",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>having</b></i>",
            audio: "unit3-597.mp3",
            type: 'link'
          },
          {
            text: "To lose",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>losing</b></i>",
            audio: "unit3-598.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Esto no sucede si suena la <i>-e</i> final."
          }
        ]
      },
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "To see",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>seeing</b></i>",
            audio: "unit3-593.mp3",
            type: 'link'
          },
          {
            text: "To be",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>being</b></i>",
            audio: "unit3-594.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Los <b>monisílabos</b> que constan de <b>1 vocal + consonante</b> doblan esta consonante antes de agregar <b>-ing</b>."
          }
        ]
      },
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "To get",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>getting</b></i>",
            audio: "unit3-599.mp3",
            type: 'link'
          },
          {
            text: "To put",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>putting</b></i>",
            audio: "unit3-600.mp3",
            type: 'link'
          },
        ]
      },
      {
        type: 'p_link',
        tokens: [
          {
            text: "Esto no sucede si el monosílabo tiene dos vocales o termina con dos consonantes."
          }
        ]
      },
      {
        type: 'example',
        skip_termination: true,
        rows: [
          {
            text: "To eat",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>eating</b></i>",
            audio: "unit3-601.mp3",
            type: 'link'
          },
          {
            text: "To help",
            audio:"none"
          },
          {
            level:'secondary',
            text: "<i><b>helping</b></i>",
            audio: "unit3-602.mp3",
            type: 'link'
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '6',
    items: [
      {
        rows: [
          {
            text: "Working",
            audio: "unit3-4.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Living",
            audio: "unit3-5.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Doing",
            audio: "unit3-6.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Being",
            audio: "unit3-7.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Finishing",
            audio: "unit3-8.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Going",
            audio: "unit3-9.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Sleeping",
            audio: "unit3-10.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Studying",
            audio: "unit3-11.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Calling",
            audio: "unit3-12.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Using",
            audio: "unit3-13.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Asking",
            audio: "unit3-14.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Eating",
            audio: "unit3-15.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Telling",
            audio: "unit3-16.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Playing",
            audio: "unit3-17.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Feeling",
            audio: "unit3-18.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Buying",
            audio: "unit3-19.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Having",
            audio: "unit3-20.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Starting",
            audio: "unit3-21.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Talking",
            audio: "unit3-22.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Winning",
            audio: "unit3-23.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Learning",
            audio: "unit3-24.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Drinking",
            audio: "unit3-25.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Getting up",
            audio: "unit3-26.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Saying",
            audio: "unit3-27.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Waiting",
            audio: "unit3-28.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Losing",
            audio: "unit3-29.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Reading",
            audio: "unit3-30.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Selling",
            audio: "unit3-31.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Thinking",
            audio: "unit3-32.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'english',
    id: '7a',
    items: [
      {
        rows: [
          {
            text: "I'm using it",
            audio: "unit3-33.mp3"
          },
          {
            text: "He's using it",
            audio: "unit3-34.mp3"
          },
          {
            text: "We're using it",
            audio: "unit3-35.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "I'm not working",
            audio: "unit3-36.mp3"
          },
          {
            text: "You're not working",
            audio: "unit3-37.mp3"
          },
          {
            text: "She isn't working",
            audio: "unit3-38.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Are you sleeping?",
            audio: "unit3-39.mp3"
          },
          {
            text: "Is he sleeping?",
            audio: "unit3-40.mp3"
          },
          {
            text: "Are they sleeping?",
            audio: "unit3-41.mp3"
          },
        ]
      },
      {
        rows: [
          {
            text: "Aren't you using it?",
            audio: "unit3-42.mp3"
          },
          {
            text: "Isn't she using it?",
            audio: "unit3-43.mp3"
          },
          {
            text: "Aren't we using it?",
            audio: "unit3-44.mp3"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '7b',
    items: [
      {
        rows: [
          {
            text: "Estoy trabajando."
          },
          {
            text: "Estoy pensando."
          },
        ]
      },
      {
        rows: [
          {
            text: "Él está durmiendo."
          },
          {
            text: "Él está viendo TV."
          },
        ]
      },
      {
        rows: [
          {
            text: "Estamos tomando café."
          },
          {
            text: "Estamos estudiando."
          },
        ]
      },
      {
        rows: [
          {
            text: "No estoy diciendo eso."
          },
          {
            text: "No estoy haciendo nada."
          },
        ]
      },
      {
        rows: [
          {
            text: "Ella no te está preguntando."
          },
          {
            text: "Ella no está aprendiendo."
          },
        ]
      },
      {
        rows: [
          {
            text: "Ellos no están jugando bien."
          },
          {
            text: "Ellos no están comiendo nada."
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Estás durmiendo?"
          },
          {
            text: "¿Dónde estás viviendo?"
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Ella está esperando?"
          },
          {
            text: "¿Cuánto está pagando ella?"
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Ellos te están ayudando?"
          },
          {
            text: "¿Qué están vendiendo (ellos)?"
          },
        ]
      },
      {
        rows: [
          {
            text: "¿No lo estás usando?"
          },
          {
            text: "¿Por qué no estás practicando?"
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Él no te está llamando?"
          },
          {
            text: "¿Por qué él no está manejando?"
          },
        ]
      },
      {
        rows: [
          {
            text: "¿Acaso no estamos ganando?"
          },
          {
            text: "¿Por qué no estamos hablando inglés?"
          },
        ]
      },
    ]
  },
  {
    type:'translate',
    id: '8',
    items: [
      {rows: [
        {text: "La llamo todos los días."},
        {text: "La estoy llamando."},
      ]},
      {rows: [
        {text: "Él me espera."},
        {text: "Él me está esperando."},
      ]},
      {rows: [
        {text: "Practicamos juntos."},
        {text: "Estamos practicando juntos."},
      ]},
      {rows: [
        {text: "Ellos juegan mejor."},
        {text: "Ellos están jugando mejor."},
      ]},
      {rows: [
        {text: "Tú lo usas bien."},
        {text: "Lo estás usando bien."},
      ]},
      {rows: [
        {text: "Ellos no pagan."},
        {text: "Ellos no están pagando."},
      ]},
      {rows: [
        {text: "Yo no digo eso."},
        {text: "Yo no estoy diciendo eso."},
      ]},
      {rows: [
        {text: "Tú no me ayudas."},
        {text: "Tú no me estás ayudando."},
      ]},
      {rows: [
        {text: "Ana no viene a clase."},
        {text: "Ana no está viniendo a clase."},
      ]},
      {rows: [
        {text: "No hacemos nada."},
        {text: "No estamos haciendo nada."},
      ]},
      {rows: [
        {text: "¿Tú la ayudas?"},
        {text: "¿La estás ayudando?"},
      ]},
      {rows: [
        {text: "¿Cuánto gastamos?"},
        {text: "¿Cuánto estamos gastando?"},
      ]},
      {rows: [
        {text: "¿Dónde vive ella?"},
        {text: "¿Dónde esta viviendo ella?"},
      ]},
      {rows: [
        {text: "¿Qué hacen ellos?"},
        {text: "¿Qué están haciendo ellos?"},
      ]},
      {rows: [
        {text: "¿Pago demasiado?"},
        {text: "¿Estoy pagando demasiado?"},
      ]},
      {rows: [
        {text: "¿Por qué no estudias?"},
        {text: "¿Por qué no estás estudiando?"},
      ]},
      {rows: [
        {text: "¿Ella no trabaja?"},
        {text: "¿Ella no está trabajando?"},
      ]},
      {rows: [
        {text: "¿Acaso no practicamos?"},
        {text: "¿Acaso no estamos practicando?"},
      ]},
      {rows: [
        {text: "¿Ellos no ven TV?"},
        {text: "¿Ellos no están viendo TV?"},
      ]},
      {rows: [
        {text: "¿Tu novia no te espera?"},
        {text: "¿Tu novia no te está esperando?"},
      ]},
      {rows: [
        {text: "¿Por qué él no bebe?"},
        {text: "¿Por qué él no está bebiendo?"},
      ]},
    ]
  },
]
